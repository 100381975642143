import { IIRCauseGroupModel } from 'api/v2/iir/model/iirReportComponent.model';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import IIRReportItemBaseComponent from './iirBaseItem.component';
import { IIRCausesType } from 'api/enums/IIR';
import { useEffect } from 'react';

export default function IIRCauseViewComponent({ datas, isReadOnly }: { datas: IIRCauseGroupModel[]; isReadOnly: boolean }) {
    function getCauseTitle(type: IIRCausesType) {
        if (type === IIRCausesType.JobFactors) {
            return (
                <span>
                    {lang.text(Translations.iir.rootCauses)} - <span className="text-warning">{lang.text(Translations.iir.cause.jobFactors)}</span>
                </span>
            );
        } else if (type === IIRCausesType.PersonalFactors) {
            return (
                <span>
                    {lang.text(Translations.iir.rootCauses)} - <span className="text-warning">{lang.text(Translations.iir.cause.personalFactors)}</span>
                </span>
            );
        } else if (type === IIRCausesType.UnsafeAction) {
            return (
                <span>
                    {lang.text(Translations.iir.indirectCause)} - <span className="text-warning">{lang.text(Translations.iir.cause.unsafeAction)}</span>
                </span>
            );
        } else if (type === IIRCausesType.UnsafeCondition) {
            return (
                <span>
                    {lang.text(Translations.iir.indirectCause)} - <span className="text-warning">{lang.text(Translations.iir.cause.unsafeCondition)}</span>
                </span>
            );
        }
    }
    return (
        <div>
            {datas.map((group, i) => (
                <IIRReportItemBaseComponent
                    isReadOnly={isReadOnly}
                    title={getCauseTitle(group.type) as any}
                    datas={group.causes?.map((cause) => {
                        return {
                            cause: (
                                <span>
                                    {cause.causeName}
                                    <br />
                                    <i>{cause.causeName_Es}</i>
                                </span>
                            ),
                        };
                    })}
                    columns={[
                        {
                            field: 'cause',
                            title: lang.text(Translations.common.description),
                        },
                    ]}
                />
            ))}
        </div>
    );
}
