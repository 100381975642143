import { Fragment, useEffect, useState } from 'react';
import TripCreatePage from './tripCreatePage';
import { Card, Row, Tab, Tabs } from 'react-bootstrap';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import InputFieldSimple from 'components/FormControl/InputFieldSimple';
import tripApi from 'api/v2/trip/tripApi';
import { TripCreateRequest, TripDetailResponse, TripStatus } from 'api/v2/trip/model/trip.model';
import { useParams } from 'react-router-dom';
import { formatDateTime } from 'helper';
import useModalHook from 'components/ModalHook';
import TripRequestChangeRoute from './requestChangeRoute';
import TripButton from './components/actionButton.component';
import usePromptInputText from 'components/Modal/PromptInputText';
import TimelineComponent, { TimelineItem } from 'components/Common/TimeLine';

const TripDetailPage = () => {
    const { id } = useParams();
    const [detail, setDetail] = useState<TripDetailResponse | null>(null);
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    const { openPromptInputText, PromptInputText } = usePromptInputText();
    const [activeKey, setActiveKey] = useState<'trip' | 'tdtt' | 'history'>('trip');
    const [histories, setHistories] = useState<TimelineItem[]>([]);
    const onRequestChangeRoute = async () => {
        var result = await openModal({
            title: lang.text(Translations.trip.changeRouteRequest),
            body: <TripRequestChangeRoute detail={detail!.requestChangeRoute!} onClose={onClose} onCompleted={onCompleted} />,
        });
        if (result === true) await getTripDetail(id!);
    };
    const onRejectChangeRoute = async () => {
        detail?.requestChangeRoute?.id &&
            tripApi
                .rejectChangeRoute(detail?.requestChangeRoute.id)
                .then(() => getTripDetail(id!))
                .catch(() => {});
    };
    const onApproveChangeRoute = async () => {
        detail?.requestChangeRoute?.id &&
            tripApi
                .approveChangeRoute(detail?.requestChangeRoute.id)
                .then(() => getTripDetail(id!))
                .catch(() => {});
    };
    const onChangeStatus = async (status: TripStatus) => {
        const text: string = await openPromptInputText({
            title: lang.text(Translations.common.description),
            defaultValue: '',
        });

        text != null &&
            tripApi
                .updateStatus({
                    description: text,
                    status: status,
                    tripId: id!,
                })
                .then(() => getTripDetail(id!))
                .catch(() => {});
    };
    useEffect(() => {
        id && getTripDetail(id);
    }, []);

    useEffect(() => {
        detail?.histories &&
            setHistories(
                detail.histories.map((item) => {
                    var rs: TimelineItem = {
                        date: item.createDate,
                        title: item.crudActionType,
                        description: item.content,
                        createdFulName: item.createUserName,
                    };
                    return rs;
                })
            );
    }, [detail]);
    // export interface TimelineItem {
    //     title: string;
    //     description?: any;
    //     date: Date;
    //     createdFulName?: string;
    // }
    const getTripDetail = (id: string) => {
        tripApi
            .getById(id)
            .then((res: TripDetailResponse) => {
                setDetail(res);
            })
            .catch(() => {});
    };
    return !detail ? (
        <div></div>
    ) : (
        <Fragment>
            <Tabs activeKey={activeKey} onSelect={(k) => setActiveKey(k as any)} className="mb-3">
                <Tab eventKey="trip" title={lang.text(Translations.common.generalInfo)}>
                    <Card>
                        <Card.Header>
                            <div className="d-flex justify-content-end">
                                <TripButton
                                    onApproveChangeRoute={onApproveChangeRoute}
                                    onRejectChangeRoute={onRejectChangeRoute}
                                    onRequestChangeRoute={onRequestChangeRoute}
                                    onChangeStatus={onChangeStatus}
                                    trip={{ ...detail, id: detail.id }}
                                />
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <InputFieldSimple label={lang.text(Translations.common.status)} md={4} disabled value={detail.status} />
                                <InputFieldSimple label={lang.text(Translations.common.createdBy)} md={4} disabled value={detail.createdUserName} />
                                <InputFieldSimple label={lang.text(Translations.common.createdTime)} md={4} disabled value={formatDateTime(detail.createdTime)} />
                            </Row>
                        </Card.Body>
                    </Card>
                    <Row>
                        <TripCreatePage detail={detail} />
                    </Row>
                </Tab>
                <Tab eventKey="tdtd" title={lang.text(Translations.trip.changeRouteRequest)}>
                    <Card>
                        <Card.Body>
                            <TripRequestChangeRoute isReadOnly={true} detail={detail!.requestChangeRoute!} onClose={onClose} onCompleted={onCompleted} />
                        </Card.Body>
                    </Card>
                </Tab>
                <Tab eventKey="history" title={lang.text(Translations.common.history)}>
                    <Card>
                        <Card.Body>
                            <TimelineComponent title={lang.text(Translations.common.history)} items={histories} />
                        </Card.Body>
                    </Card>
                </Tab>
            </Tabs>
            {Prompt}   {PromptInputText}
        </Fragment>
    );
};
export default TripDetailPage;
