import client from '../client';
import { PaginationBase, PaginationBaseRequest } from 'api/models/common';
import { SelectOption } from 'components/FormControl';
import { KPITemplateCreateRequest, KPITemplateFilter, KPITemplateResponse, KPITemplateUpdateRequest } from './model/kpiTemplate.model';

const baseURL = '/KPITemplate';
const kpiTemplateApi = {
    getById(id: string): Promise<KPITemplateResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<KPITemplateResponse>({
            endpoint: url,
        });
    },
    getPagination(filter: KPITemplateFilter, isLoading: boolean = true): Promise<PaginationBase<KPITemplateResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<KPITemplateResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: KPITemplateCreateRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: KPITemplateUpdateRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
    getDropdown(): Promise<SelectOption[]> {
        const url = `${baseURL}/dropdown`;
        return client.getNonWrap<SelectOption[]>({
            endpoint: url,
        });
    },
};

export default kpiTemplateApi;
