import { ResponseApi } from 'api/models/common/apiResponse';
import clientRequest from './client';
import {
    ContractorCreateRequest,
    ContractorResponse,
    ContractorUpdateRequest,
    GoldenRuleResponse,
    GoldenRuleUpdateRequest,
} from '../models/organization';
import { PaginationBase, PaginationBaseRequest } from '../models/common';
import { data } from 'jquery';

const baseUrl = '/contractor';
const configContractorApi = {
    getByCurrentCustomer: (isLoading: boolean = true) => {
        var paginationRequest: PaginationBaseRequest = {
            rowsPerPage: 100,
            currentPage: 1,
        };
        const url = `${baseUrl}/getbyindex`;
        var rs = clientRequest.getNonWrap<PaginationBase<ContractorResponse>>({
            data: paginationRequest,
            endpoint: url,
            isLoading: isLoading,
        });
        return rs;
    },
    async create(data: ContractorCreateRequest) {
        const url = `${baseUrl}/create`;
        return await clientRequest.post<string>(url, data, true, true);
    },
    async update(data: ContractorUpdateRequest) {
        const url = `${baseUrl}/update`;
        return await clientRequest.post(url, data, true, true);
    },
    async getById(id: string) {
        const url = `${baseUrl}/getbyid/${id}`;
        return await clientRequest.get<GoldenRuleResponse>(url);
    },
    async delete(id: string) {
        const url = `${baseUrl}/delete/${id}`;
        return await clientRequest.post(url, null, true, true);
    },
};

export default configContractorApi;
