import { useLocation } from 'react-router-dom';

interface QueryParams {
  [key: string]: string | null;
}

function useQueryParams(): {
  getQueryParam: (paramName: string) => string | null;
  getAllQueryParams: () => QueryParams;
} {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Function to get a specific query parameter by name
  const getQueryParam = (paramName: string): string | null => {
    return queryParams.get(paramName);
  };

  // Function to get all query parameters as an object
  const getAllQueryParams = (): QueryParams => {
    const queryParamsObj: QueryParams = {};
    for (let [key, value] of queryParams.entries()) {
      queryParamsObj[key] = value;
    }
    return queryParamsObj;
  };

  return {
    getQueryParam,
    getAllQueryParams
  };
}

export default useQueryParams;
