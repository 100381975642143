export enum PPRTaskType {
    AREA = 'AREA',
    USER = 'USER',
    DEPARTMENT = 'DEPARTMENT',
}

export enum PPRStatus {
    OPENING = 'OPENING',
    CLOSED = 'CLOSED',
}
export enum SkpiStatus {
    OPENING = 'OPENING',
    CLOSED = 'CLOSED',
    CLOSED_FROM_PARENT = 'CLOSED_FROM_PARENT',
    CLOSED_FROM_CHILD = 'CLOSED_FROM_CHILD',
}
