import { yupResolver } from '@hookform/resolvers/yup';
import { EquipmentReportTemplateCreateRequest, EquipmentReportTemplateResponse, EquipmentReportTemplateUpdateRequest } from 'api/models';
import equipmentApi from 'api/v2/equipmentApi';
import secGateApi from 'api/v2/sec/secGateApi';
import OrganizationUnitMultiSelect from 'components/Common/OrganizationUnitMultiSelect';
import { SelectField, SelectOption, InputField, UploadImageField, TextAreaField, RadioField, RadioOption } from 'components/FormControl';
import { ModalHookComponentProps } from 'components/ModalHook';
import { EntityConst } from 'constants/EntityConst';
import { EquipmentReportType } from 'enum';
import { EnumHelper } from 'helper/enum.helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { t } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
interface CheckInOutModalModalProps extends ModalHookComponentProps {
    organizationId: string;
}
const CheckInOutModalModal = ({ onClose, onCompleted, organizationId }: CheckInOutModalModalProps) => {
    const [gates, setGates] = useState<RadioOption[]>([]);
    const schema = yup.object().shape({
        description: yup.string().notRequired().min(1).max(EntityConst.descriptionShort),
        gateId: yup.string().required(),
    });
    useEffect(() => {
        secGateApi
            .getDropdown(organizationId)
            .then((res) => {
                setGates(
                    res.map((n) => ({
                        label: n.label,
                        value: n.value,
                        defaultChecked: false,
                        className: '',
                    }))
                );
            })
            .catch(() => {});
    }, []);

    const { control, handleSubmit, getValues, reset, formState } = useForm({
        defaultValues: {},
        resolver: yupResolver(schema),
    });

    const submit = async () => {
        let form = getValues();
        onCompleted({
            ...form,
        });
    };
    return (
        <form onSubmit={handleSubmit(submit)}>
            {gates && <RadioField orientation="vertical" required inline={true} label={lang.text(Translations.sec.gate)} name="gateId" control={control} options={gates} />}
            <hr />
            <RadioField
                required
                inline={true}
                label={lang.text(Translations.common.status)}
                name="isApproved"
                control={control}
                options={[
                    {
                        label: lang.text(Translations.sec.approve),
                        value: 1,
                        className: 'text-success',
                    },
                    {
                        label: lang.text(Translations.sec.unApprove),
                        value: 0,
                        className: 'text-danger',
                    },
                ]}
            />
            <hr />
            <TextAreaField name="reason" control={control} label={lang.text(Translations.common.description)} />
            <UploadImageField name="imagePath" control={control} />
            <Modal.Footer>
                <Button variant="danger" onClick={onClose}>
                    {lang.text(Translations.modal.confirm.cancel)}
                </Button>
                <Button variant="primary" type="submit">
                    {lang.text(Translations.modal.confirm.ok)}
                </Button>
            </Modal.Footer>
        </form>
    );
};
export default CheckInOutModalModal;
