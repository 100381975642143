import { TripReportType, TripStatus } from 'api/v2/trip/model/trip.model';
import { SelectOption } from 'components/FormControl';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';

export const getTripStatus = () => [
    {
        label: lang.text(Translations.trip.status.active),
        value: TripStatus.ACTIVE,
    },
    {
        label: lang.text(Translations.trip.status.approved),
        value: TripStatus.APPROVED,
    },
    {
        label: lang.text(Translations.trip.status.completed),
        value: TripStatus.COMPLETED,
    },
    {
        label: lang.text(Translations.trip.status.processing),
        value: TripStatus.PROCESSING,
    },
    {
        label: lang.text(Translations.trip.status.refused),
        value: TripStatus.REFUSE,
    },
];

