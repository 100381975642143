import { yupResolver } from '@hookform/resolvers/yup';
import { TNTopicUpdateRequest } from 'api/v2/training/models/tnTopic.model';
import { InputField } from 'components/FormControl';
import { ModalHookComponentProps } from 'components/ModalHook';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface TNTopicFormProps extends TNTopicUpdateRequest, ModalHookComponentProps {}

const TNTopicForm = ({ id, text, onClose, onCompleted }: TNTopicFormProps) => {
    const { control, handleSubmit, getValues } = useForm<{
        text: string;
    }>({
        resolver: yupResolver(
            yup.object().shape({
                text: yup.string().required(),
            })
        ),
    });

    const onSubmit = async () => {
        var form = getValues();
        let data: TNTopicUpdateRequest = {
            ...form,
            id: id,
        };
        onCompleted(data);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputField name={'text'} control={control} defaultValue={text} label={lang.text(Translations.common.name)} required={true} />
            <Modal.Footer>
                <Button variant="danger" onClick={onClose}>
                    {lang.text(Translations.modal.confirm.cancel)}
                </Button>
                <Button variant="primary" type="submit">
                    {lang.text(Translations.modal.confirm.ok)}
                </Button>
            </Modal.Footer>
        </form>
    );
};
export default TNTopicForm;
