import { Fragment, memo } from 'react';

// Redux Selector / Action
import { useSelector } from 'react-redux';

// Import selectors & action from setting store
import * as SettingSelector from 'app/setting/selectors';

//React-router
import { Link } from 'react-router-dom';
const Footer = memo(() => {
  const footer = useSelector(SettingSelector.footer);
  return (
    <Fragment>
      <footer className={`footer ${footer}`}>
        <div className="footer-body">
          <ul className="left-panel list-inline mb-0 p-0">
            <li className="list-inline-item">
              <Link to="/dashboard/extra/privacy-policy">Privacy Policy</Link>
            </li>
            <li className="list-inline-item">
              <Link to="/dashboard/extra/terms-of-service">Terms of Use</Link>
            </li>
          </ul>
          <div className="right-panel">© {new Date().getFullYear().toString()}</div>
        </div>
      </footer>
    </Fragment>
  );
});

Footer.displayName = 'Footer';
export default Footer;
