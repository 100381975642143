import tnCourseSubmissionAPI from 'api/v2/training/tnCourseSubmission';
import { Empty } from 'components/shared';
import { TNQuestionType } from 'enum/training';
import { formatDateTime } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, Nav, Row, Tab } from 'react-bootstrap';
import { useAuth } from 'providers/contexts';
import { TNSubmissionDetailResponse } from 'api/v2/training/models/tnCourseSubmission.model';
import { TNCourseDetailResponse } from 'api/v2/training/models/tnCourse.model';

const TNHistoryComponent = ({ courseId, course }: { courseId: string; course: TNCourseDetailResponse }) => {
    const [userSubmission, setUserSubmission] = useState<TNSubmissionDetailResponse | undefined>();
    const { tokenProfile } = useAuth();
    useEffect(() => {
        loadSubmission();
    }, [courseId]);
    const loadSubmission = () => {
        courseId &&
            tnCourseSubmissionAPI
                .getByCourseAndUser(courseId, tokenProfile!.userId)
                .then((res) => {
                    setUserSubmission(res);
                    // reset(res);
                })
                .catch((err) => {
                    console.log(err);
                });
    };
    return !userSubmission ? (
        <Empty />
    ) : (
        <Tab.Container id="left-tabs-example" defaultActiveKey={String(userSubmission.items[0]?.id)}>
            <Row>
                <Col sm={2}>
                    <Card className="h-100">
                        <Card.Body>
                            <Nav variant="pills" className="flex-column">
                                {userSubmission.items?.map((submissionItem, index) => {
                                    return (
                                        <Nav.Item key={index}>
                                            <Nav.Link className="nav-timeline" eventKey={submissionItem.id}>
                                                <div>
                                                    <small>
                                                        {formatDateTime(submissionItem.createdTime)} <br />
                                                        {submissionItem.status}
                                                    </small>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                    );
                                })}
                            </Nav>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={10} style={{ height: 'calc(100vh - 100px)', overflowY: 'auto' }}>
                    <Tab.Content>
                        {userSubmission.items?.map((submissionItem) => {
                            return (
                                <Tab.Pane eventKey={submissionItem.id}>
                                    <Card>
                                        <Card.Body>
                                            <div className="w-100 text-center">
                                                <h4>Kết quả bài làm</h4>
                                            </div>
                                            <div className="w-100 ">
                                                <h6>
                                                    <span className="text-secondary">Score</span>: {submissionItem.point}
                                                </h6>
                                                <h6>
                                                    <span className="text-secondary">Comment</span>: <i>{submissionItem.comment}</i>
                                                </h6>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <div>
                                                {course.questions.map((question, index) => {
                                                    const userAnswer = submissionItem.answers.find((sa) => sa.questionId === question.id);
                                                    return (
                                                        <div>
                                                            <div key={index} className="mb-3">
                                                                <b> {index + 1} </b>: <b> {question.question} </b>
                                                                {question.quizType === TNQuestionType.Foreword && (
                                                                    <p>{userAnswer?.answerContent}</p>
                                                                    // <TextAreaField disabled control={control} name={`answers.${index}.userAnswer`} defaultValue={userAnswer?.answerContent} />
                                                                )}
                                                                {/* {question.quizType === TNQuestionType.Foreword && (
                                                                        <div>
                                                                            <label className="pr-2">{lang.text(Translations.training.correctAnswer)}: </label>
                                                                            <i> {question.correctAnswer}</i>
                                                                        </div>
                                                                    )} */}
                                                                {question.answers && question.answers.length > 0 && (
                                                                    <div>
                                                                        {question?.answers?.map((answer, idx) => {
                                                                            const isUserAnswer = userAnswer?.answerIds.includes(answer?.id!);
                                                                            return (
                                                                                <div key={idx} className="d-flex align-items-center">
                                                                                    <Form.Check inline type={'checkbox'} checked={isUserAnswer} />
                                                                                    <div
                                                                                    // className={
                                                                                    //     answer.isCorrect
                                                                                    //         ? 'correct-answer'
                                                                                    //         : answer.isCorrect || (!answer.isCorrect && isUserAnswer)
                                                                                    //         ? 'wrong-answer'
                                                                                    //         : ''
                                                                                    // }
                                                                                    >
                                                                                        {answer.content}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {/* <InputField control={control} name={`answers.${index}.comment`} label={'Nhận xét'} /> */}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                            );
                        })}
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    );
};

export default TNHistoryComponent;
