import React, { useEffect, useState } from 'react';
import { Control, Controller, ControllerRenderProps, useController, useForm } from 'react-hook-form';
import { SelectOption } from 'components/FormControl';
import Multiselect from 'multiselect-react-dropdown';
import { Form } from 'react-bootstrap';
import _ from 'lodash';

export interface SelectProps {
    readonly?: boolean;  
    control: Control<any>;
    name: string;
    //defaultValue?: any;
    errorMessage?: string;
    displayName?: string;
    required?: boolean;
    md?: 2 | 3 | 4 | 5 | 6 | 10 | 8 | 12;
    disable?: boolean;
    label?: string;
    options?: SelectOption[];
    onValueChange?: (value: any) => void;
    onSearch?: (key: string) => void;
    onOptionChange?: (value?: SelectOption | SelectOption[] | undefined | null) => void; //onValueChange for only Id, onValueOptionChange for Id and Label
    isMulti?: boolean;
    unSelectValue?: number | string;
}
const Select = (props: SelectProps) => {
    const [selectedValue, setSelectedValue] = useState<SelectOption[] | undefined>();
    const [options, setOptions] = useState<SelectOption[]>();
    const {
        field,
        fieldState: { invalid, error },
    } = useController({
        name: props.name,
        control: props.control,
        //defaultValue: props.defaultValue,
    });

    /// Changed valued, if selected value != value, changed selected value
    useEffect(() => {
        handleSetSelected();
    }, [field.value, options]);

    const handleSetSelected = () => {
        if (!field.value || !options?.hasValue()) {
            setSelectedValue([]);
        } else {
            if (IsSameValue(field.value, selectedValue?.map((n) => n.value) ?? [])) {
                return;
            }

            if (props.isMulti) {
                let newSelected = options?.filter((n) => (field.value as any[]).includes(n.value)) ?? [];
                setSelectedValue(newSelected);
                props.onOptionChange && props.onOptionChange(newSelected);
                props.onValueChange && props.onValueChange(newSelected.map((n) => n.value));
            } else {
                let singleVal = options?.find((n) => n.value == field.value);
                setSelectedValue(singleVal ? [singleVal] : []);
                props.onOptionChange && props.onOptionChange(singleVal);
                props.onValueChange && props.onValueChange(singleVal?.value);
            }
        }
    };
    const IsSameValue = (array1: (string | number)[], array2: (string | number)[]): boolean => {
        // Check if the arrays have the same length
        if (array1.length !== array2.length) {
            return false;
        }

        // Sort both arrays and compare values
        const sortedArray1 = [...array1].sort();
        const sortedArray2 = [...array2].sort();

        return sortedArray1.every((value, index) => value === sortedArray2[index]);
    };
    /*Change option, reset selected valúe*/
    useEffect(() => {
        handleSetSelected();
        const defaultSelct: SelectOption[] = [];
        if (!props.isMulti) {
            defaultSelct.push({
                label: '---- select ----',
                value: '',
            });
        }
        setOptions([...defaultSelct, ...(props.options ?? [])]);
    }, [props.options]);

    const onSelectedChanged = (selected: SelectOption[] | null | undefined) => {
        if (props.isMulti) {
            field.onChange((selected as SelectOption[]).map((n) => n.value));
        } else {
            field.onChange(selected ? selected[0].value : undefined);
        }
    };
    return (
        <div className={`col-md-${props.md} `}>
            <Form.Group className={`mb-3 `}>
                {props.label && <Form.Label htmlFor={props.name}>{props.label}</Form.Label>}
                <Multiselect
                    id={props.name + '_select'}
                    disable={props.disable}
                    avoidHighlightFirstOption={true}
                    options={options ?? []} // Options to display in the dropdown
                    displayValue={props.displayName ?? 'label'} // Property name to display in the dropdown options
                    showCheckbox={false}
                    isObject={true}
                    onSelect={(selected) => onSelectedChanged(selected)}
                    onRemove={(selected) => onSelectedChanged(selected)}
                    singleSelect={!props.isMulti}
                    // groupBy={'groupCompany'}
                    // className={field.state?.message ? 'is-invalid' : ''}
                    selectedValues={selectedValue}
                    onSearch={props.onSearch}
                    showArrow={true}
                    placeholder={props.isMulti ? 'select' : '--- select ---'}
                />

                <div className="invalid-feedback d-blocksecond d-block ">{error?.message}</div>
            </Form.Group>
        </div>
    );
};

export default Select;
