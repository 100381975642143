import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import Card from 'components/shared/Card';
import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { PaginationTable } from 'components/shared/PaginationTable';
import { lang } from 'i18n/lang';
import { AddButton } from 'components/Button';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import orgUnitApi from 'api/v2/organizationUnitApi';
import { OrganizationUnitType } from 'api/enums/OrganizationUnitType';
import { CompanySelect } from 'components/Common';
import { OrganizationUnitResponse } from 'api/models';
import usePromptInputText from 'components/Modal/PromptInputText';
import { FilterButton } from 'components/Button/FilterButton';

const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);

// eslint-disable-next-line react-hooks/exhaustive-deps
const DepartmentPage = () => {
    const [paginationData, setPaginationData] = useState<PaginationBase<any>>();
    // const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    const { openPromptInputText, PromptInputText } = usePromptInputText();

    useEffect(() => {
        handleFetchData(defaultPaginationRequest);
    }, []);
    const cols = useMemo(
        () => [
            {
                field: 'name',
                title: lang.text(Translations.form.site.name),
            },
            {
                field: 'createdTime',
                title: lang.text(Translations.common.createdTime),
            },
            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    const { control } = useForm();
    const [companyId, setCompanyId] = useState<string>('');
    useEffect(() => {
        handleFetchData(defaultPaginationRequest);
    }, [companyId]);

    useEffect(() => {
        handleFetchData(defaultPaginationRequest);
    }, []);
    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        orgUnitApi
            .getPagination({
                unitType: OrganizationUnitType.Department,
                parentId: companyId,
                ...pagination,
            })
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                action: <ActionButtons2 id={n.id} onDelete={onDelete} editAction={() => onUpdate(n)} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };
    const onAdd = async () => {
        console.log('add nè', companyId, 'âdfjlksjlk');
        if (!companyId.hasValue()) return;
        const text: string = await openPromptInputText({
            title: lang.text(Translations.common.name),
            defaultValue: '',
        });
        text &&
            orgUnitApi
                .create({
                    name: text,
                    unitType: OrganizationUnitType.Department,
                    parentId: companyId,
                })
                .then(() => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch(() => {});
    };

    const onUpdate = async (data: OrganizationUnitResponse) => {
        const text: string = await openPromptInputText({
            title: lang.text(Translations.common.name),
            defaultValue: data.name,
        });
        text &&
            orgUnitApi
                .update({
                    name: text,
                    parentId: companyId,
                    id: data.id,
                })
                .then(() => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch(() => {});
    };
    const onDelete = async (id: string) => {
        orgUnitApi
            .delete(id)
            .then(() => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch(() => {});
    };
    return (
        <Fragment>
            <Card>
                <Card.Header>
                    <div className="row w-100">
                        <CompanySelect md={4} name="companyId" control={control} required={true} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                        <div className="col-md-8">
                            <div className=" d-flex justify-content-end">
                                
                                <AddButton  onClick={onAdd}  isRender={true}/>
                                <FilterButton onClick={() => handleFetchData(defaultPaginationRequest)}  />
                            </div>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <PaginationTable
                        onSearch={handleFetchData}
                        onPaginate={handleFetchData}
                        onRowClick={(item) => {}}
                        columns={cols}
                        pagination={paginationData}
                        isStriped={true}
                        disableSearch={true}
                        wrapperClass="mt-5"
                    />
                </Card.Body>
            </Card>
            {PromptInputText}
        </Fragment>
    );
};

export default DepartmentPage;
