import client from '../client';
import { PaginationBase } from 'api/models/common';
import { PPRReportCloseRequest, PPRReportCreateRequest, PPRReportDetailResponse, PPRReportFilterRequest, PPRReportResponse } from './model/ppr.model';

const baseURL = '/pprReport';
const pprReportApi = {
    getById(id: string): Promise<PPRReportDetailResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<PPRReportDetailResponse>({
            endpoint: url,
        });
    },
    getPagination(filter: PPRReportFilterRequest, isLoading: boolean = true): Promise<PaginationBase<PPRReportResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<PPRReportResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: PPRReportCreateRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: PPRReportCreateRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },

    closeSReport(payload: PPRReportCloseRequest): Promise<unknown> {
        const url = `${baseURL}/close`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: payload,
        });
    },
    downloadReport(id: string): Promise<any> {
        const url = `${baseURL}/download/${id}`;
        return client.download({
            endpoint: url,
            fileName: 'Báo cáo hiệu quả công việc',
        });
    },
    // getDashboard(filter: any): Promise<SORDashboardResponse> {
    //     const url = `${baseURL}/dashboard`;
    //     return client.getNonWrap<any>({
    //         endpoint: url,
    //         data: filter,
    //     });
    // },
};

export default pprReportApi;
