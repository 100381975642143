import { Fragment, memo, useEffect } from 'react';

//router
import { Link } from 'react-router-dom';

//components
import { Logo } from 'components/shared';
import VerticalNav from './verticalNav';

// Import selectors & action from setting store
import * as SettingSelector from 'app/setting/selectors';

// Redux Selector / Action
import { ArrowLeft } from 'assets/icons/outlined';
import Routers from 'router/const/routers';
import { useSelector } from 'react-redux';
import { minisidebar, resizeScrollbar } from './actions';

const Sidebar = memo((props) => {
  const sidebarColor = useSelector(SettingSelector.sidebar_color);
  const sidebarHide = useSelector(SettingSelector.sidebar_show); // array
  const sidebarType = useSelector(SettingSelector.sidebar_type); // array
  const sidebarMenuStyle = useSelector(SettingSelector.sidebar_menu_style);

  useEffect(() => {
    resizeScrollbar();
  });

  return (
    <Fragment>
      <aside
        className={` ${sidebarColor} ${sidebarType.join(' ')} ${sidebarMenuStyle} ${
          sidebarHide.join(' ') ? 'sidebar-none' : 'sidebar'
        }   sidebar-base  `}
        data-sidebar="responsive"
      >
        <div className="sidebar-header d-flex align-items-center justify-content-start">
          <span className="navbar-brand d-flex w-100">
            <Link to={Routers.root}>
              <Logo />
            </Link>
            <h4 className="logo-title flex flex-col white-space-initial">
              HSEVN <br />
              <small>Health Safety Security and Eviroment</small>
            </h4>
          </span>
          <div
            className="sidebar-toggle"
            data-toggle="sidebar"
            data-active="true"
            onClick={minisidebar}
          >
            <ArrowLeft className="icon-20" />
          </div>
        </div>
        <div className="pt-0 sidebar-body data-scrollbar" data-scroll="1" id="my-scrollbar">
          {/* sidebar-list class to be added after replace css */}
          <div className="sidebar-list navbar-collapse" id="sidebar">
            <VerticalNav />
          </div>
        </div>
        <div className="sidebar-footer"></div>
      </aside>
    </Fragment>
  );
});

Sidebar.displayName = 'Sidebar';
export default Sidebar;
