import React from 'react';
import LegalDocumentCreatePage from './legalDocumentCreatePage';

export default function LegalDocumentDetailPage() {
    return (
        <div>
            <LegalDocumentCreatePage />
        </div>
    );
}
