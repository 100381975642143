import { ImagePlaceholder } from 'assets/images';
import Translations from 'i18n/translation';
import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const UploadImage = ({
  className = '',
  value,
  altText,
}: {
  className?: string;
  value?: string;
  altText?: string;
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleUpload = () => {
    const input = inputRef.current;

    if (input) {
      input.click();
    }
  };

  return (
    <div className={`${className} text-start`}>
      <div className="ratio ratio-16x9 overflow-hidden" style={{ maxWidth: '300px' }}>
        <img
          src={value ? value : ImagePlaceholder}
          alt={altText ? altText : 'placeholder'}
          loading="lazy"
          className="rounded"
          style={{
            objectFit: 'contain',
          }}
        />
        <input type="file" ref={inputRef} hidden />
      </div>
      <Button
        onClick={handleUpload}
        className="mt-4 hvr-float-shadow btn btn-info w-100"
        style={{ maxWidth: '300px' }}
      >
        {t(Translations.common.upload)}
      </Button>
    </div>
  );
};

export default UploadImage;
