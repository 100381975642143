import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { Button } from 'react-bootstrap';
import { BaseButtonProps } from './BaseButton.props';

// export interface DownloadButtonProps {
//     onClick?: MouseEventHandler;
//     disable?: boolean;
// }

export function DownloadButton(props: BaseButtonProps) {
    return (
        <Button onClick={props.onClick} variant={props.color ?? 'success'} className={'rounded  ' + props.className ?? ''} size={'sm'}>
            <div className="d-flex flex-row align-items-center ">
                <span className="btn-inner me-1" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-cloud-arrow-down-fill" viewBox="0 0 16 16">
                        <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z" />
                    </svg>
                </span>
                <span>{lang.text(Translations.common.download)} </span>
            </div>
        </Button>
    );
}
