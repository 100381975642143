import { SECReportFilterRequest, SECReportRequest, SECReportResponse } from 'api/v2/sec/model/register.model';
import client from '../client';
import { PaginationBase, PaginationBaseRequest } from 'api/models/common';
import { SelectOption } from 'components/FormControl';
import { SECGateFilterRequest, SECGateUpdateRequest, SECGateResponse, SECGateCreateRequest } from './model/gate.model';
import { SECRegisterCreateRequest, SECRegisterDetailResponse, SECRegisterFilterRequest, SECRegisterResponse } from './model/register.model';
import fileApi from 'api/fileApi';
import { formatDate } from 'helper';

const baseURL = '/SECReport';
const secReportApi = {
    create(data: SECReportRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },
    getPagination(filter: SECReportFilterRequest, isLoading: boolean = true): Promise<PaginationBase<SECReportResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<SECReportResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    downloadReport(data: SECReportFilterRequest): Promise<any> {
        const url = `${baseURL}/Download`;
        return client.download({
            endpoint: url,
            fileName: 'Báo cáo ra vào cổng ' + formatDate(new Date()),
            params: data,
        });
        // return client.postNonWrap<string>({
        //     endpoint: url,
        //     data: data,
        // });
    },
};

export default secReportApi;
