import Translations from 'i18n/translation';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import usePromptInputText from 'components/Modal/PromptInputText';
import { SelectField, SelectOption } from 'components/FormControl';
import documentApi from 'api/v2/document/documentApi';
import { DocGroupResponse } from 'api/v2/document/model/docGroup.model';

export interface DocGroupSelectProps extends BaseFormControlProps {
    isMulti: boolean | undefined;
    defaultValue?: any;
    isCanCreate?: boolean;
    companyId?: string;
    onValueChanged?: (value: string) => void;
    onValuesChanged?: (values: string[]) => void;
}

export function DocGroupSelect({ onValueChanged, onValuesChanged, isCanCreate = false, name, control, required, md = 4, companyId, readOnly, isMulti, defaultValue }: DocGroupSelectProps) {
    const [options, setOptions] = useState<SelectOption[]>([]);
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
    });
    const { openPromptInputText, PromptInputText } = usePromptInputText();

    const onAddTag = async () => {
        const text: string = await openPromptInputText({
            title: t(Translations.common.name),
            defaultValue: '',
        });
        if (text)
            documentApi.group
                .create({ name: text, id: null, companyId: companyId })
                .then((id: string) => {
                    setOptions([...options, { value: id, label: text }]);
                })
                .catch((res) => {});
    };

    useEffect(() => {
        documentApi.group
            .getByCompany(companyId)
            .then((group: DocGroupResponse[]) => {
                setOptions(group.toSelectOptions('name', 'id'));
            })
            .catch((e) => {});
    }, []);
    return (
        <SelectField
            readOnly={readOnly}
            label={t(Translations.document.group)}
            options={options}
            name={name ?? 'groupId'}
            control={control}
            required={required}
            isMutiple={false}
            md={md}
            onValueChange={(e) => {
                onValueChanged && onValueChanged(e);
            }}
            onValuesChange={onValuesChanged}
        />
    );
}

export default DocGroupSelect;
