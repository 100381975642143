import { yupResolver } from '@hookform/resolvers/yup';
import { TNCourseCreateRequest, TNCourseDetailResponse, TNCourseDocument, TNCourseQuestion, TNCourseQuestionAnswer } from 'api/v2/training/models/tnCourse.model';
import tnCourseAPI from 'api/v2/training/tnCourseApi';
import { DeleteButton, SaveButton, UpdateButton } from 'components/Button';
import { InputField, TextAreaField } from 'components/FormControl';
import { CourseLimitType, TNInfoTab, TNQuestionType, TNDocumentType } from 'enum/training';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useAuth } from 'providers/contexts';
import { Fragment, useState } from 'react';
import { Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { FieldErrors, Resolver, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { TNCourseQuestionComponent } from '../component/tnCourseQuestions.component';
import { TNCourseDocumentComponent } from '../component/tnCourseDocuments.component';
import { TNCourseUsersComponent } from '../component/tnCourseUsers.component';
import { toast } from 'react-toastify';
import DateTimeField from 'components/FormControl/DateTimeField';
import TNTopicSelect from '../component/tnTopic.select';
import { TNCourseSchema } from './tnCourseDetailPage';

const defaultToDate = new Date();
const defaultFromDate = new Date();
defaultToDate.setDate(defaultFromDate.getDate() + 365);

export default function TNCourseCreatePage() {
    const [isReadOnly, seIsReadOnly] = useState<boolean>(false);
    const [userIds, setUserIds] = useState<string[]>([]);
    const { userPms } = useAuth();

    const [detail, setDetail] = useState<TNCourseDetailResponse | null>(null);

    const {
        control,
        watch,
        handleSubmit,
        getValues,
        reset,
        setValue,
        formState: { isSubmitting, errors },
    } = useForm<TNCourseCreateRequest>({
        resolver: yupResolver(TNCourseSchema) as Resolver<TNCourseCreateRequest>,
        defaultValues: {
            courseLimitType: CourseLimitType.Unlimit,
            questions: [],
            documents: [],
        },
    });

    const onSubmit = (values: TNCourseCreateRequest) => {
        if (detail?.id) {
            tnCourseAPI
                .update(values)
                .then(() => {})
                .catch((n) => {});
        } else {
            tnCourseAPI
                .create({
                    ...values,
                    userIds: userIds,
                })
                .then(() => {
                    reset({
                        courseLimitType: CourseLimitType.Unlimit,
                        questions: [],
                        documents: [],
                    });
                })
                .catch((n) => {});
        }
    };

  
    const invalidSubmit = (e: FieldErrors<TNCourseCreateRequest>) => {
        if (getValues('questions')?.length === 0) {
            toast.error('Cần ít nhất 1 câu hỏi');
            return true;
        }
        const firstErrorKey = Object.keys(e)[0];
        if (firstErrorKey) {
            const errorMessage = Array.isArray((e as any)[firstErrorKey])
                ? (e as any)[firstErrorKey].map((err: any) => err.message).join(', ')
                : (e as any)[firstErrorKey]?.message || 'Vui lòng nhập đủ thông tin';
            toast.error(errorMessage);
        }
    };
    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit, invalidSubmit)}>
                <Card>
                    <Card.Header>
                        <div className="d-flex justify-content-between">
                            <h5>{lang.text(Translations.common.generalInfo)}</h5>
                            <div className="d-flex ">
                                {!isReadOnly && <SaveButton type="submit" />}
                                <UpdateButton onClick={() => seIsReadOnly(false)} className="mx-2" isRender={userPms.ldAdmin && isReadOnly} />
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <TNTopicSelect name="topicId" control={control} required={true} md={3} />

                            <Col md={3}>
                                <div className="d-flex flex-column gap-1 hand">
                                    <Form.Label>{lang.text(Translations.training.limitTime)}</Form.Label>
                                    <Form.Check className="form-switch d-flex">
                                        <Form.Check.Input
                                            disabled={isReadOnly}
                                            type="checkbox"
                                            className="inline-block"
                                            onChange={(e) => {
                                                setValue('courseLimitType', e.target.checked ? CourseLimitType.Limit : CourseLimitType.Unlimit);
                                            }}
                                        />
                                    </Form.Check>
                                </div>
                            </Col>
                            {watch('courseLimitType') === CourseLimitType.Limit && (
                                <Fragment>
                                    <DateTimeField label={lang.text(Translations.common.fromDate)} md={3} control={control} readOnly={false} name={'quizStartDate'} defaultDate={defaultFromDate} />
                                    <DateTimeField label={lang.text(Translations.common.toDate)} md={3} control={control} readOnly={false} name={'quizEndDate'} defaultDate={defaultToDate} />{' '}
                                </Fragment>
                            )}
                        </Row>
                        <Row>
                            <InputField md={12} name={'title'} control={control} defaultValue={''} label={'Tên khóa học'} readOnly={isReadOnly} />
                            <TextAreaField rows={2} md={12} name={'summary'} control={control} defaultValue={''} label={'Tóm tắt'} readOnly={isReadOnly} />
                            <TextAreaField rows={2} md={12} name={'require'} control={control} defaultValue={''} label={'Yêu cầu sau khi hoàn thành'} readOnly={isReadOnly} />
                            <TextAreaField rows={2} md={12} name={'content'} control={control} defaultValue={''} label={'Nội dung'} readOnly={isReadOnly} />
                        </Row>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Tabs defaultActiveKey={TNInfoTab.QUESTION} id="uncontrolled-tab-example" className="mb-3 border-bottom border-primary rounded-0 course-tabs">
                            <Tab className="rounded-0" eventKey={TNInfoTab.QUESTION} title="Câu hỏi">
                                <TNCourseQuestionComponent control={control} isReadOnly={isReadOnly} watch={watch} />
                            </Tab>
                            <Tab className="rounded-0" eventKey={TNInfoTab.DOCUMENT} title="Tài liệu">
                                <TNCourseDocumentComponent control={control} isReadOnly={isReadOnly} watch={watch} />
                            </Tab>
                            <Tab className="rounded-0" eventKey={TNInfoTab.USER} title="Học viên" style={{ minHeight: '500px' }}>
                                <Row>
                                    <div className="col-md-12 ">
                                        <TNCourseUsersComponent isReadOnly={false} checked={userIds} onValueChanged={setUserIds} />
                                    </div>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Card.Body>
                </Card>
            </form>
        </Fragment>
    );
}
