import { CSSProperties, FC, ReactNode, memo } from 'react';

interface CardProps {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

const Card: FC<CardProps> & {
  Header: FC<CardProps>;
  Body: FC<CardProps>;
  Footer: FC<CardProps>;
  HeaderTitle: FC<CardProps>;
  HeaderAction: FC<CardProps>;
} = memo(({ className, style, children }: CardProps) => (
  <div className={`card ${className ? className : ''}`} style={style}>
    {' '}
    {children}{' '}
  </div>
)) as any;

Card.Header = memo(({ className, children }) => (
  <div className={`card-header d-flex justify-content-between ${className ? className : ''}`}>
    {' '}
    {children}{' '}
  </div>
));

Card.Body = memo(({ className, children }) => (
  <div className={`card-body ${className ? className : ''}`}> {children} </div>
));

Card.Footer = memo(({ className, children }) => (
  <div className={`card-footer ${className}`}> {children} </div>
));

Card.HeaderTitle = memo(({ className, children }) => (
  <div className={`header-title ${className ? className : ''}`}> {children} </div>
));

Card.HeaderAction = memo(({ className, children }) => (
  <div className={`header-action ${className ? className : ''}`}> {children} </div>
));

Card.displayName = 'Card';
export default Card;
