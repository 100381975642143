import { yupResolver } from '@hookform/resolvers/yup';
import { SelectField, InputField, TextAreaField } from 'components/FormControl';
import { ModalHookComponentProps } from 'components/ModalHook';
import { EquipmentReportLevel } from 'enum';
import { EquipmentHelper } from 'helper/Feature/equipment.help';
import Translations from 'i18n/translation';
import { t } from 'i18next';
import { useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
interface EquipmentReportRatingComponentProps extends ModalHookComponentProps {
    description?: string;
    level?: EquipmentReportLevel;
}
export interface EquipmentReportRatingModel {
    description: string;
    level: EquipmentReportLevel;
}
const EquipmentReportRateComponent: React.FC<EquipmentReportRatingComponentProps> = ({
    onClose,
    onCompleted,
    description,
    level,
}) => {
    // const [types, setTypes] = useState<SelectOption[]>([]);
    const schema = yup.object().shape({
        description: yup.string().min(1).max(1000),
        level: yup.mixed().oneOf(Object.values(EquipmentReportLevel)).required()
    });
    const { control, handleSubmit, getValues, reset, formState } = useForm({
        defaultValues: {
            description: description,
            level: level,
        },
        resolver: yupResolver(schema),
    });

    const levels = useMemo(() => EquipmentHelper.getLevelOptions(), []);

    const onSubmit = async () => {
        var form = getValues();
        var rs: EquipmentReportRatingModel = {
            description: form.description ?? '',
            level: form.level as EquipmentReportLevel,
        };
        onCompleted(rs);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SelectField
                md={12}
                label={t(Translations.equipment.reportRating)}
                options={levels}
                name="level"
                control={control}
                required={true}
                isMutiple={false}
                defaultValue={level}
            />

            <TextAreaField
                name={`description`}
                control={control}
                defaultValue={description}
                label={t(Translations.form.safetyInspections.notOkDescription)}
            />
            <Modal.Footer>
                <Button variant="danger" onClick={onClose}>
                    {t(Translations.modal.confirm.cancel)}
                </Button>
                <Button variant="primary" type="submit">
                    {t(Translations.modal.confirm.ok)}
                </Button>
            </Modal.Footer>
        </form>
    );
};
export default EquipmentReportRateComponent;
