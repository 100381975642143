import client from '../client';
import { PaginationBase } from 'api/models/common';
import { TNCourseCreateRequest, TNCourseDetailResponse, TNCourseFilterRequest, TNCourseResponse } from './models/tnCourse.model';

const baseURL = '/course';

const tnCourseAPI = {
    getById(id: string): Promise<TNCourseDetailResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<TNCourseDetailResponse>({
            endpoint: url,
        });
    },
    getPagination(filter: TNCourseFilterRequest, isLoading: boolean = true): Promise<PaginationBase<TNCourseResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<TNCourseResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: TNCourseCreateRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },
    update(data: TNCourseCreateRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
};

export default tnCourseAPI;
