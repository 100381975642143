import { AddButton } from 'components/Button';
import { Card } from 'components/shared';
import { CardBody, CardHeader, Col, Row } from 'react-bootstrap';
import { Fragment, useEffect, useMemo, useState } from 'react';
import Translations from 'i18n/translation';
import customerApi from 'api/v2/customerApi';
import { CustomerCreateRequest, CustomerResponse } from 'api/models';
import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { ColumnType, PaginationTable } from 'components/shared/PaginationTable';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import ImageWithDefault from 'components/bootstrap/ImageWithDefault';
import { AddForm } from './addForm';
import { useAuth } from 'providers/contexts';
import { formatDateTime } from 'helper';
import useModalHook from 'components/ModalHook';
import { lang } from 'i18n/lang';

const CustomerPage = () => {
    const [paginationData, setPaginationData] = useState<PaginationBase<CustomerResponse>>();
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    useEffect(() => {
        handleFetchData(defaultPaginationRequest);
    }, []);
    const { userPms } = useAuth();

    const onDelete = async (id: string) => {
        customerApi
            .delete(id)
            .then(() => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch((n) => {});
    };

    const onAdd = async () => {
        var rs = await openModal({
            title: lang.text(Translations.pages.customer.add),
            body: <AddForm onClose={onClose} onCompleted={onCompleted} />,
        });
        rs &&
            customerApi
                .create(rs as CustomerCreateRequest)
                .then((res) => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch((err) => {});
    };

    const onUpdate = async (cus: CustomerResponse) => {
        var rs = await openModal({
            title: lang.text(Translations.pages.customer.update),
            body: <AddForm onClose={onClose} onCompleted={onCompleted} name={cus.name} logo={cus.logo ?? undefined} />,
        });
        rs &&
            customerApi
                .update({
                    ...rs,
                    id: cus.id,
                })
                .then((res) => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch((err) => {});
    };

    const handleFetchData = async (paginate: PaginationBaseRequest) => {
        customerApi
            .getPagination(paginate)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                createdTime: formatDateTime(n.createdTime),
                                logoImg: <ImageWithDefault className=" avatar-40" src={n.logo ?? undefined} />,
                                action: <ActionButtons2 id={n.id} editPms={userPms.tenantAdmin} deletePms={userPms.tenantAdmin} onDelete={onDelete} editAction={(id: string) => onUpdate(n)} />, // replace 'value' with the actual value you want to set
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((n) => {});
    };

    const cols: ColumnType[] = useMemo(
        () => [
            {
                field: 'logoImg',
                title: '',
                style: {
                    width: '50px',
                },
            },
            {
                field: 'name',
                title: lang.text(Translations.form.name),
            },
            {
                field: 'createdTime',
                title: lang.text(Translations.common.createdTime),
            },
            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );

    return (
        <Fragment>
            <Row>
                <Col sm="12">
                    <Card>
                        <CardHeader>
                            <div className="d-flex justify-content-end">
                                <AddButton onClick={onAdd} isRender={userPms.supperAdmin} /> 
                            </div>
                        </CardHeader>
                        <CardBody>
                            <PaginationTable
                                // onSearch={(pagi) => {
                                //     defaultPaginationRequest.keySearch = pagi.keySearch;
                                // }}
                                onPaginate={handleFetchData}
                                columns={cols}
                                pagination={paginationData}
                                isStriped={true}
                                disableSearch={true}
                                wrapperClass="mt-5"
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {Prompt}
        </Fragment>
    );
};

export default CustomerPage;
