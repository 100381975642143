import Translations from 'i18n/translation';
import React, { ReactNode } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type PropsType = {
  show?: boolean;
  handleHide?: () => void;
  content: string | ReactNode;
  handleCancel?: (value: any) => void;
  handleAccept?: (value: any) => void;
  title?: string;
  okText?: string;
};

const ConfirmModal = (props: PropsType) => {
  const { t } = useTranslation();

  return (
    <Modal show={props.show} backdrop={true} onHide={props.handleHide} centered>
      <Modal.Header>
        <Modal.Title>{props.title ? props.title : t(Translations.modal.confirm.title)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.content}</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.handleCancel}>
          {t(Translations.modal.confirm.cancel)}
        </Button>
        <Button variant="primary" onClick={props.handleAccept}>
          {props.okText ? props.okText : t(Translations.modal.confirm.ok)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
