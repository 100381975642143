import Translations from 'i18n/translation';
import { t } from 'i18next';
import { MouseEventHandler } from 'react';
import { Button } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { BaseButtonProps } from './BaseButton.props';
import { BaseButton } from './BaseButton';
import ToastConfirm from 'components/Modal/ToastConfirm';
import { lang } from 'i18n/lang';

interface DeleteButtonProps extends BaseButtonProps {
    showConfirm?: boolean;
    onConfirmed?: () => void;
}

export function DeleteIconButton({ onClick }: DeleteButtonProps) {
    return (
        <div onClick={onClick} className="d-flex btn-delete align-items-center justify-content-center btn btn-danger btn-icon btn-sm rounded-circle">
            <Trash />
        </div>
    );
}

export function DeleteButton(props: DeleteButtonProps) {
    var isRender = props.isRender ?? true;
    if (props.isRender === undefined) isRender = true;
    return !props.showConfirm ? (
        <BaseButton {...props}  type='button' isRender={isRender} icon={<Trash />} color="danger" text={props.text ?? t(Translations.button.delete)} />
    ) : (
        <ToastConfirm
            onConfirm={() => {
                props.onConfirmed && props.onConfirmed();
            }}
            message={lang.text(Translations.modal.confirm.confirmDelete)}
        >
            <BaseButton {...props} type='button' isRender={isRender} icon={<Trash />} color="danger" text={props.text ?? t(Translations.button.delete)} />
        </ToastConfirm>
    );
}
