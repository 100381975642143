import { SelectField, SelectOption } from 'components/FormControl';
import Translations from 'i18n/translation';
import { useCustomer } from 'providers/contexts';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import sorCategoryApi from 'api/v2/sor/sorCategoryApi';

export interface SORCategorySelectProps extends BaseFormControlProps {
    onValueChange?: (id: string) => void;
    isAddAll?: boolean;
    control: Control<any>;
}
export function SORCategorySelect({ name = 'sorCategoryId', control, required, md = 4, onValueChange, isAddAll, readOnly }: SORCategorySelectProps) {
    const { currentCustomer } = useCustomer();
    const [options, setOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        loadDatas();
    }, [currentCustomer]);
    useEffect(() => {
        loadDatas();
    }, []);
    const loadDatas = () => {
        sorCategoryApi
            .getDropdown()
            .then((res) => {
                setOptions(res);
            })
            .catch(() => {});
    };

    return (
        <SelectField
            readOnly={readOnly}
            label={t(Translations.sor.category)}
            options={options}
            name={name}
            control={control}
            required={required}
            isMutiple={false}
            md={md}
            isAddAll={isAddAll}
            onValueChange={(e) => {
                onValueChange && onValueChange(e);
            }}
        />
    );
}
export default SORCategorySelect;
