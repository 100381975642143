import { ReactNode, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

interface ModalFullScreenProps {
    title?: string;
    showModal: boolean;
    handleCloseModal: () => void;
    bodyModal: ReactNode;
    footerModal?: ReactNode;
    scrollable?: boolean;
}
export function ModalFullScreen({
    showModal,
    handleCloseModal,
    bodyModal,
    footerModal, title
}: ModalFullScreenProps) {
    return (
        <Modal
            fullscreen={true}
            scrollable={true}
            size="lg"
            backdrop="static"
            show={showModal}
            onHide={handleCloseModal}
            centered

        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{bodyModal}</Modal.Body>
            <Modal.Footer>{footerModal}</Modal.Footer>
        </Modal>
    );
}
