import React from 'react';
import CustomerContext from './customerContext';
import AuthContext from './authContext';

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined || context === null) {
        throw new Error('useAuth must be used within profile');
    }
    return context;
};

export const useCustomer = () => {
    const context = React.useContext(CustomerContext);
    if (context === undefined) {
        throw new Error('useCustomer must be used within a useCustomer');
    }
    return context;
};
