import { SelectField, SelectOption } from 'components/FormControl';
import Translations from 'i18n/translation';
import { useCustomer } from 'providers/contexts';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import sorCategoryApi from 'api/v2/sor/sorCategoryApi';
import secGateApi from 'api/v2/sec/secGateApi';
import { lang } from 'i18n/lang';
import { toast } from 'react-toastify';

export interface SORCategorySelectProps extends BaseFormControlProps {
    onValueChange?: (id: string) => void;
    isAddAll?: boolean;
    control: Control<any>;
    organizationId?: string;
}
export function SECGateSelect({ name = 'gateId', control, required, md = 4, onValueChange, isAddAll, readOnly, organizationId }: SORCategorySelectProps) {
    const [options, setOptions] = useState<SelectOption[]>([]);
    useEffect(() => {
        // toast.success("vao ne")
        secGateApi
            .getDropdown(organizationId ?? '')
            .then((res) => {
                setOptions(res);
            })
            .catch(() => {});
    }, [organizationId]);

    return (
        <SelectField
            readOnly={readOnly}
            label={lang.text(Translations.sec.gate)}
            options={options}
            name={name}
            control={control}
            required={required}
            isMutiple={false}
            md={md}
            isAddAll={isAddAll}
            onValueChange={(e) => {
                onValueChange && onValueChange(e);
            }}
        />
    );
}
export default SECGateSelect;
