import React from 'react';
import { Outlet } from 'react-router-dom';

// Import selectors & action from setting store
import * as SettingSelector from 'app/setting/selectors';

// Redux Selector / Action
import { useSelector } from 'react-redux';
import { useCustomer } from 'providers/contexts';

const Content = () => {
    const pageLayout = useSelector(SettingSelector.page_layout);
    const { currentCustomer } = useCustomer();
    return (
        <div className={`${pageLayout} content-inner pb-0`} style={{ paddingTop: 100 }}>
            {currentCustomer?.id && <Outlet></Outlet>}
        </div>
    );
};

export default Content;
