import { yupResolver } from '@hookform/resolvers/yup';
import { SKPIActionRequest, SKPIObjectiveRequest } from 'api/v2/skpi/model/skpiRequest.model';
import { AddButton, SaveButton } from 'components/Button';
import { AreaSelect } from 'components/Common';
import { DepartmentSelect } from 'components/Common/DepartmentSelect';
import UserSelectField from 'components/Common/UserSelect';
import { InputField, SelectField, SelectOption, TextAreaField } from 'components/FormControl';
import DateTimeField from 'components/FormControl/DateTimeField';
import { PPRTaskType } from 'enum/ppr';
import { generateGUID } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { Fragment, useEffect, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { SKPIObjectTypeSelect } from './skpiTaskTypeSelect';
import { Card, Table } from 'react-bootstrap';
import DataListView from 'components/shared/DataListView';
import { toast } from 'react-toastify';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import SkpiActionComponent from './actionViewList.component.';

const defaultToDate = new Date();
const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);

const SKPIAddObjectiveComponent = ({
    companyId,
    currentTaskUpdate,
    onSubmidAddObjective,
}: {
    companyId: string;
    onSubmidAddObjective: (objectives: SKPIObjectiveRequest) => void;
    currentTaskUpdate?: SKPIObjectiveRequest;
}) => {
    const [targetObjectName, setTargetObjectName] = useState<string>(''); // [1
    const [currentActionPicFullNames, setCurrentActionPicFullNames] = useState<string>(''); // [1
    const [currentObjectivePicFullNames, setCurrentObjectivePicFullNames] = useState<string>(''); // [1
    const [currentActions, setCurrentActions] = useState<SKPIActionRequest[]>([]); // [1

    ///Khi thay đổi task update thì reset lại form
    useEffect(() => {
        resetObjectives({
            name: currentTaskUpdate?.name,
            pICId: currentTaskUpdate?.pICId ?? '',
            taskType: currentTaskUpdate?.taskType,
            fromDate: currentTaskUpdate?.fromDate,
            toDate: currentTaskUpdate?.toDate,
            targetObjectId: currentTaskUpdate?.targetObjectId,
        });
        setCurrentObjectivePicFullNames(currentTaskUpdate?.pICFullName ?? '');
        setTargetObjectName(currentTaskUpdate?.targetObjectName ?? '');
        setCurrentActions(currentTaskUpdate?.actions ?? []);
    }, [currentTaskUpdate]);

    const schemaTask = yup.object().shape({
        name: yup.string().required(),
        pICId: yup.string().required(),
        fromDate: yup.date().required(),
        toDate: yup.date().required(),
        targetObjectId: yup.string().required(),
        taskType: yup.string().required(),
        areaId: yup.string().nullable().notRequired(),
    });

    const {
        reset: resetObjectives,
        control: control,
        getValues: getValuesStep,
        handleSubmit: handleSubmitStep,
        formState: { errors: errorsStep },
    } = useForm({ resolver: yupResolver(schemaTask) });

    // submit acc objectives
    const submit = () => {
        var form = getValuesStep() as SKPIObjectiveRequest;
        if (currentActions.length === 0) {
            toast.error('Nhập ít nhất 1 hành động');
            return;
        }
        var payload: SKPIObjectiveRequest = {
            ...form,
            pICFullName: currentObjectivePicFullNames,
            actions: currentActions,
            targetObjectName: targetObjectName,
            uiId: generateGUID(),
        };
        onSubmidAddObjective(payload);
        resetObjectives({ name: '', pICId: '', fromDate: defaultFromDate, toDate: defaultToDate, taskType: PPRTaskType.AREA, targetObjectId: '' });
        setTargetObjectName('');
        setCurrentActions([]);
    };
    const schemaAction = yup.object().shape({
        name: yup.string().required(),
        pICId: yup.string().required(),
    });
    const {
        reset: resetActions,
        control: controlAction,
        getValues: getValuesAction,
        handleSubmit: handleSubmitAction,
        formState: { errors: errorsAction },
    } = useForm({ resolver: yupResolver(schemaAction) });

    const onSaveAddAction = () => {
        var form = getValuesAction();
        setCurrentActions([
            {
                uiId: generateGUID(),
                pICFullName: currentActionPicFullNames,
                name: form.name,
                pICId: form.pICId,
            },
            ...currentActions,
        ]);
        resetActions({ name: '', pICId: form.pICId });
    };
    const onEditAction = (action: SKPIActionRequest) => {
        setCurrentActions(currentActions.filter((n) => n.uiId !== action.uiId));
        resetActions({ name: action.name, pICId: action.pICId });
        setCurrentActionPicFullNames(action.pICFullName ?? '');
    };
    const onDeleteAction = (action: SKPIActionRequest) => {
        setCurrentActions(currentActions.filter((n) => n.uiId !== action.uiId));
        return Promise.resolve();
    };
    return (
        <Fragment>
            <form onSubmit={handleSubmitStep(submit)} >
                <div className="row">
                    <InputField label={lang.text(Translations.form.name)} md={6} name="name" control={control} placeholder="" />
                    <UserSelectField
                        label={lang.text(Translations.common.pic)}
                        md={6}
                        name="pICId"
                        readOnly={false}
                        control={control}
                        required={true}
                        isMutiple={false}
                        onSelectedOptionChange={(user) => {
                            setCurrentObjectivePicFullNames(user?.label ?? '');
                        }}
                    />
                    <DateTimeField label={lang.text(Translations.common.fromDate)} md={6} name={'fromDate'} control={control} readOnly={false} defaultDate={defaultFromDate} />
                    <DateTimeField label={lang.text(Translations.common.toDate)} md={6} control={control} readOnly={false} name={'toDate'} defaultDate={defaultToDate} />
                    <SKPIObjectTypeSelect companyId={companyId} md={6} control={control} onTargetObjectNameChanged={setTargetObjectName} />
                    <div className="d-flex justify-content-end my-2">
                        <SaveButton text={lang.text(Translations.wpr.skpi.saveObjectives)} type="submit" />
                    </div>
                </div>
            </form>
            <form onSubmit={handleSubmitAction(onSaveAddAction)} >
                <Card className="border border-opacity-25 border-warning">
                    <Card.Body>
                        <TextAreaField name={'name'} control={controlAction} label={lang.text(Translations.wpr.skpi.actions)} />
                        <UserSelectField
                            md={12}
                            label={lang.text(Translations.common.pic)}
                            name="pICId"
                            control={controlAction}
                            required={true}
                            onSelectedOptionChange={(user) => {
                                setCurrentActionPicFullNames(user?.label ?? '');
                            }}
                        />
                        <div className="d-flex justify-content-end my-2">
                            <SaveButton type="submit" text={lang.text(Translations.wpr.skpi.addAction)} />
                        </div>
                        <SkpiActionComponent actions={currentActions} onDelete={onDeleteAction} onEdit={onEditAction} />
                    </Card.Body>
                </Card>
            </form>
        </Fragment>
    );
};
export default SKPIAddObjectiveComponent;
