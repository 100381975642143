import { selectLanguage } from 'app/appSlice';
import { useAppSelector } from 'app/hooks';
import { Card, FancyTable } from 'components/shared';
import Translations from 'i18n/translation';
import { ResponseApi } from 'api/models/common/apiResponse';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Supporter } from 'api/models/common';

const HotlinePage = memo(() => {
    const [data, setData] = useState<Supporter[]>([]);
    const selectedLanguage: string = useAppSelector(selectLanguage) as string;
    const { t } = useTranslation();

    useEffect(() => {
        // getProfile().then((res: ResponseApi<Supporter[]>) => {
        //   setData(res.result);
        // });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cols = useMemo(
        () => [
            {
                field: 'fullName',
                title: t(Translations.datatable.fullname),
            },
            {
                field: 'position',
                title: t(Translations.datatable.authorisation),
            },
            {
                field: 'phoneNumber',
                title: t(Translations.datatable.phoneNumber),
            },
        ],

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedLanguage]
    );

    const tableData = data.map((item) => ({
        ...item,
        phoneNumber: (
            <a href={`tel:${item.phoneNumber}`} rel="noreferrer" target="_blank">
                {item.phoneNumber}
            </a>
        ),
    }));

    return (
        <Card>
            <Card.Header>
                <div className="text-end w-100 d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">{t(Translations.sidebar.hotline.name)}</h2>
                </div>
            </Card.Header>
            <Card.Body>
                <FancyTable hideHead={true} columns={cols} data={tableData} isStriped={true} />
            </Card.Body>
        </Card>
    );
});
export default HotlinePage;
