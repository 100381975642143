import fileApi from 'api/fileApi';
import { FileHelper } from 'helper/file.helper';
import { Fragment, useEffect, useState } from 'react';
import { bool } from 'yup';

const LinkFileDownload = ({ path, fileName }: { path: string; fileName?: string }) => {
    const [isImage, setIsImage] = useState<boolean>(false);
    const domain = process.env.REACT_APP_FILE_SEVER_URL;
    useEffect(() => {
        if (!path) return;
        const extension = path.substring(path.lastIndexOf('.'));
        if (extension === '.jpg' || extension === '.png' || extension === '.heic') {
            setIsImage(true);
        } else {
            setIsImage(false);
        }
    }, []);

    const download = (e: any) => {
        e.preventDefault();
        fileApi.downloadFile(path, fileName ?? FileHelper.getFileNameFromPath(path)).catch((er) => {});
    };
    return (
        <Fragment>
            {isImage && <a href={domain + path}>{fileName}</a>}
            {!isImage && (
                <a href={'#'} onClick={download}>
                    {fileName}
                </a>
            )}
        </Fragment>
    );
};
export default LinkFileDownload;

// generateLi: (path: string, fileName: string) => {
//     let domain = process.env.REACT_APP_FILE_SEVER_URL;
//     const extension = path.substring(path.lastIndexOf('.'));
//      else {
//         console.log('File is not an image');
//         return domain + '/file?path=' + path;
//     }
// },
