import { BaseButtonProps } from './BaseButton.props';
import { Button } from 'react-bootstrap';
import { Filter } from 'react-bootstrap-icons';
import Translations from 'i18n/translation';
import { Fragment } from 'react';
import { lang } from 'i18n/lang';

export function FilterButton(opt: BaseButtonProps) {

    return (
        <Fragment>
            <Button {...opt} variant="info" className={'rounded px-2 mx-2 py-1 ' + opt.className ?? ''} type={opt.type} size="sm">
                <div className="d-flex flex-row align-items-center ">
                    <span className="btn-inner me-2">
                        <Filter />
                    </span>
                    <div>{opt.text ?? lang.text(Translations.common.apply)}</div>
                </div>
            </Button>
        </Fragment>
    );
}
