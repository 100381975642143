import { FileResponse } from '../models/common/form';
import StorageKeys from 'constants/storageKeys';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { ResponseApi } from '../models';
import CommonConstants from 'constants/common';
import { Store } from '@reduxjs/toolkit';
import { loadingPageAction, setCustomer, setIsLoggin } from 'app/appSlice';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import Translations from 'i18n/translation';
let store: Store;
export const injectStore = (_store: any) => {
    store = _store;
};

// import { createStore } from 'redux';
// import rootReducer from './reducers'; // import your root reducer

// // Create the store
// const store = createStore(rootReducer);

// // Dispatch an action
// store.dispatch({ type: 'YOUR_ACTION_TYPE', payload: 'your payload' });

interface PostRequestProps {
    endpoint: string;
    data?: any;
    isShowError?: boolean | null | undefined;
    isShowSuccess?: boolean | null | undefined;
    isLoading?: boolean | null | undefined;
}

export class ClientRequest {
    private axiosInstance: AxiosInstance;

    constructor() {
        this.axiosInstance = axios.create({
            baseURL: `${process.env.REACT_APP_API_URL}/api/v2`,
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': localStorage.getItem(StorageKeys.language) || 'vi',
                Flatformtype: 'WEB',
            },
        });

        this.axiosInstance.defaults.timeout = 500000;

        this.axiosInstance.interceptors.request.use(
            (config) => {
                config.headers['Authorization'] = `Bearer ${localStorage.getItem(StorageKeys.token)}`;
                config.headers['tenant'] = store.getState().app.customer?.id;
                // remove empty property in payload
                if (config.data) {
                    config.data = JSON.parse(JSON.stringify(config.data));
                }
                // remove empty property in query params
                if (config.params) {
                    Object.keys(config.params).forEach((key) => {
                        if (config.params[key] === '' || config.params[key] === null || config.params[key] === undefined) {
                            delete config.params[key];
                        }
                    });
                }
                return config;
            },
            (error) => Promise.reject(error)
        );
        this.axiosInstance.interceptors.response.use(
            function (response) {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response.data;
            },
            function (error: AxiosError) {
                
                let errorMessage = '';
                const status = error.response?.status;
                if (status === 500) {
                    errorMessage = (error.response?.data as any).title ?? (error.response?.data as string);
                } else if (status == 400) {
                    errorMessage = (error.response?.data as any).title ?? (error.response?.data as string);
                    var errors = (error as any).response?.data?.errors;
                    if (errors) {
                        const firstProperty = Object.keys(errors)[0];
                        const firstErrorMessage = errors[firstProperty][0];
                        errorMessage = firstErrorMessage;
                    }
                } else if (status === 401) {
                    localStorage.removeItem(StorageKeys.token);
                    localStorage.removeItem(StorageKeys.customer);
                    store.dispatch(setIsLoggin(false));
                    store.dispatch(setCustomer());
                    window.location.href = '/sign-in?url=' + window.location.pathname;
                    errorMessage = CommonConstants.UnAuthorizationMessage;
                } else if (status === 403) {
                    errorMessage = 'Bạn không có quyền truy cập vào resource này!' + error.request.responseURL;
                } else if (status === 404) {
                    errorMessage = 'Không tìm thấy resource yêu cầu ' + error.request.responseURL;
                }
                return Promise.reject(errorMessage);
            }
        );
    }

    async get<T>(endpoint: string, params?: any, isShowError?: boolean | null | undefined, isLoading?: boolean | null | undefined): Promise<ResponseApi<T>> {
        try {
            if (isLoading === true) {
                store.dispatch(loadingPageAction(true));
            }
            // const queryString = qs.stringify(params);
            // const requestURL = queryString ? `${endpoint}?${queryString}` : endpoint;
            const response: AxiosResponse<T> = await this.axiosInstance.get(endpoint, { params });
            const dataResponse = response as T;
            const newObj: ResponseApi<T> = {
                isSuccess: true,
                message: '',
                result: dataResponse,
            };

            return newObj;
        } catch (errorMessage) {
            const newObj: ResponseApi<T> = {
                isSuccess: false,
                message: errorMessage as string,
                result: null as T,
            };
            if (isShowError) {
                toast.error(newObj.message);
            }
            return Promise.reject(newObj);
        } finally {
            if (isLoading === true) {
                store.dispatch(loadingPageAction(false));
            }
        }
    }
    ///this methos return promise with object response, not wrap by responseApi
    async getNonWrap<T>({ endpoint, data, isShowError = true, isLoading = true, isShowSuccess = false }: PostRequestProps): Promise<T> {
        var rs = await this.get<T>(endpoint, data, isShowError, isLoading);
        if (rs.isSuccess) {
            return Promise.resolve(rs.result);
        } else {
            return Promise.reject(rs.message);
        }
    }

    ///this methos return promise with object response, not wrap by responseApi
    async postNonWrap<T>({ endpoint, data, isShowError = true, isLoading = true, isShowSuccess = true }: PostRequestProps): Promise<T> {
        var rs = await this.post<T>(endpoint, data, isShowError, isShowSuccess, isLoading);
        if (rs.isSuccess) {
            return Promise.resolve(rs.result);
        } else {
            return Promise.reject(rs.message);
        }
    }
    ///this methos return promise with object response,  wrap by responseApi

    async post<T>(endpoint: string, data?: any, isShowError?: boolean | null | undefined, isShowSuccess?: boolean | null | undefined, isLoading?: boolean | null | undefined): Promise<ResponseApi<T>> {
        try {
            if (isLoading === true) {
                store.dispatch(loadingPageAction(true));
            }
            // below script for test in mobile
            // var axiosInstance = axios.create({
            //     baseURL: `${process.env.REACT_APP_API_URL}/api/v2`,
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Accept-Language': localStorage.getItem(StorageKeys.language) || 'vi',
            //         Flatformtype: 'WEB',
            //     },
            // });
            // var a = await axiosInstance.post(endpoint, data);
            // console.log(a);
            const response: AxiosResponse<T> = await this.axiosInstance.post(endpoint, data);
            var dataResponse = response as T;

            const newObj: ResponseApi<T> = {
                isSuccess: true,
                message: '',
                result: dataResponse,
            };

            if (isShowSuccess) {
                toast.success(t(Translations.alert.success));
            }
            return Promise.resolve(newObj);
        } catch (errorMessage) {
            var error: string = '';
            error = !errorMessage ? 'Có lỗi xảy ra' : (errorMessage as string);
            if (isShowError) {
                toast.error(error);
            }
            const newObj: ResponseApi<T> = {
                isSuccess: false,
                message: error,
                result: null as T,
            };
            return Promise.reject(newObj);
        } finally {
            if (isLoading === true) {
                store.dispatch(loadingPageAction(false));
            }
        }
    }

    async upload<T>(endpoint: string, data: FormData): Promise<FileResponse> {
        try {
            const response: FileResponse = await this.axiosInstance.post(endpoint, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response;
        } catch (errorMessage) {
            const newObj: ResponseApi<T> = {
                isSuccess: false,
                message: errorMessage as string,
                result: null as T,
            };
            return Promise.reject(errorMessage);
        }
    }

    async download({
        endpoint,
        fileName,
        params,
        isShowError = true,
        isLoading = true,
    }: {
        endpoint: string;
        fileName: string;
        params?: Record<string, any>;
        isShowError?: boolean | null | undefined;
        isLoading?: boolean | null | undefined;
    }): Promise<Blob> {
        try {
            if (isLoading) {
                store.dispatch(loadingPageAction(true));
            }
            const response: Blob = await this.axiosInstance.get(endpoint, {
                params,
                responseType: 'blob',
            });

            // console.log(response);
            // const contentDisposition = response.headers['content-disposition'];
            // let fileName = 'default-filename';  // Fallback filename

            // if (contentDisposition) {
            //     const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
            //     if (fileNameMatch && fileNameMatch.length > 1) {
            //         fileName = fileNameMatch[1];
            //     }
            // }
            const url = window.URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            return response;
        } catch (errorMessage) {
            if (isShowError) {
                toast.error('Có lỗi xảy ra, vui lòng thử lại sau!' + errorMessage ?? '');
            }
            return Promise.reject(errorMessage);
        } finally {
            if (isLoading === true) {
                store.dispatch(loadingPageAction(false));
            }
        }
    }
}

export default new ClientRequest();
