import { RouteObject } from 'react-router-dom';

import Routers from 'router/const/routers';
import { PPRReportCreatePage, SKPICreatePage, SKPIListPage } from 'views/wpr/index';
import PPRListPage from 'views/wpr/ppr/pprListPage';
import PPRLevelPage from 'views/wpr/rateLevel/rateLevel';

const wprRouters: RouteObject[] = [
    {
        path: Routers.wpr.pprCreate,
        element: <PPRReportCreatePage />,
    },
    {
        path: Routers.wpr.root,
        element: <PPRListPage />,
    },
    {
        path: Routers.wpr.levelPPR,
        element: <PPRLevelPage />,
    },
    {
        path: Routers.wpr.skpiCreate,
        element: <SKPICreatePage />,
    },
    {
        path: Routers.wpr.skpiReview,
        element: <SKPIListPage />,
    },
];

export default wprRouters;
