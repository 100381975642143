import client from '../client';
import { PaginationBase, PaginationBaseRequest } from 'api/models/common';
import { SelectOption } from 'components/FormControl';
import { SECGateFilterRequest, SECGateUpdateRequest, SECGateResponse, SECGateCreateRequest } from './model/gate.model';

const baseURL = '/SECGate';
const secGateApi = {
    getById(id: string): Promise<SECGateResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<SECGateResponse>({
            endpoint: url,
        });
    },
    getPagination(filter: SECGateFilterRequest, isLoading: boolean = true): Promise<PaginationBase<SECGateResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<SECGateResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: SECGateCreateRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: SECGateUpdateRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
    getDropdown(organizationId: string): Promise<SelectOption[]> {
        const url = `${baseURL}/dropdown`;
        return client.getNonWrap<SelectOption[]>({
            endpoint: url,
            isLoading: false,
            data: {
                organizationId: organizationId,
            },
        });
    },
};

export default secGateApi;
