import { TNSubmissionStatus } from 'api/v2/training/models/tnCourseSubmission.model';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';

export const getTrainingStatus = () => [
    {
        label: lang.text(Translations.training.submissionStatus.active),
        value: TNSubmissionStatus.Active,
    },
    {
        label: lang.text(Translations.training.submissionStatus.finish),
        value: TNSubmissionStatus.Finishe,
    },
    {
        label: lang.text(Translations.training.submissionStatus.remake),
        value: TNSubmissionStatus.Remake,
    },
    {
        label: lang.text(Translations.training.submissionStatus.userCompleted),
        value: TNSubmissionStatus.UserCompleted,
    },
];
