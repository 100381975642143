import { TNCourseCreateRequest, TNCourseDocument, TNCourseQuestion } from 'api/v2/training/models/tnCourse.model';
import { AddButton, DeleteButton } from 'components/Button';
import { InputField, SelectField, UploadImageField } from 'components/FormControl';
import UploadFileField from 'components/FormControl/UploadFileField';
import CommonConstants from 'constants/common';
import { TNDocumentType } from 'enum/training';
import { Fragment } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Control, useFieldArray, UseFormWatch } from 'react-hook-form';
import { FileEarmark, Image, Link45deg } from 'react-bootstrap-icons';
import DataListView from 'components/shared/DataListView';
import { mappingFilePath } from 'helper';

interface TNCourseDocumentComponentProps {
    control: Control<TNCourseCreateRequest>;
    isReadOnly?: boolean;
    watch: UseFormWatch<TNCourseCreateRequest>;
}

export const DOCUMENT_TYPES = [
    {
        label: 'Link',
        value: TNDocumentType.Link,
    },
    {
        label: 'File',
        value: TNDocumentType.File,
    },
    {
        label: 'Image',
        value: TNDocumentType.Image,
    },
];

const DOCUMENT_TEMPLATE: TNCourseDocument = {
    name: '',
    type: TNDocumentType.Link,
    link: '',
};

export const TNCourseDocumentComponent = ({ control, isReadOnly, watch }: TNCourseDocumentComponentProps) => {
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: 'documents',
    });

    const documents = watch('documents') || [];

    return isReadOnly ? (
        <Row>
            <TNDocumentFileList documents={documents} />
        </Row>
    ) : (
        <Fragment>
            {fields.map((field, index) => {
                return (
                    <Fragment>
                        <Row key={index} style={{ borderTop: index !== 0 ? '1px solid #ddd' : '' }} className="mb-2 pt-2">
                            <SelectField
                                md={2}
                                label={'Loại tài liệu'}
                                control={control}
                                name={`documents.${index}.type`}
                                options={DOCUMENT_TYPES}
                                isAddAll={false}
                                onValueChange={(e: TNDocumentType) => {
                                    update(index, { ...field, type: e });
                                }}
                                readOnly={isReadOnly}
                                className="mr-2"
                            />
                            <InputField md={4} name={`documents.${index}.name`} control={control} defaultValue={''} label={`Tài liệu #${index + 1}`} readOnly={isReadOnly} />
                            {documents[index].type === TNDocumentType.Link && <InputField md={5} name={`documents.${index}.link`} control={control} label={'Đường dẫn'} readOnly={isReadOnly} />}

                            {/* {[TNDocumentType.Image, TNDocumentType.File].includes(documents[index].type) && <label></label>} */}
                            {documents[index].type === TNDocumentType.File && (
                                <UploadFileField
                                    text="Chọn file"
                                    md={5}
                                    control={control}
                                    name={`documents.${index}.link`}
                                    // onUploaded={(n) => {
                                    //     // update(index, { ...field, link: (process.env.REACT_APP_FILE_SEVER_URL ?? '') + n.filePath });
                                    // }}
                                />
                            )}
                            {documents[index].type === TNDocumentType.Image && (
                                <UploadFileField
                                    text="Chọn file"
                                    md={5}
                                    control={control}
                                    name={`documents.${index}.link`}
                                    accepts={CommonConstants.imageAccepts}
                                    onValueChange={(n) => {
                                        update(index, { ...field, link: process.env.REACT_APP_FILE_SEVER_URL! + n });
                                    }}
                                />
                            )}
                            <div className="col-md-1  col-sm-6 d-flex align-items-center ">
                                <div className="mt-3">
                                    <DeleteButton onClick={() => remove(index)}></DeleteButton>
                                </div>
                            </div>
                        </Row>
                    </Fragment>
                );
            })}

            <div className="col-md-12 col-sm-6 d-flex justify-content-end">
                <AddButton type="button" onClick={() => append(DOCUMENT_TEMPLATE)} text="Thêm tài liệu" isRender={!isReadOnly} />
            </div>
        </Fragment>
    );
};

interface FileListProps {
    documents: TNCourseDocument[];
}

export const TNDocumentFileList = ({ documents }: FileListProps) => {
    const groupedDocuments = documents.reduce((acc, doc) => {
        if (!acc[doc.type]) {
            acc[doc.type] = [];
        }
        acc[doc.type].push(doc);
        return acc;
    }, {} as Record<TNDocumentType, TNCourseDocument[]>);
    const getIcon = (type: TNDocumentType) => {
        switch (type) {
            case TNDocumentType.File:
                return <FileEarmark />;
            case TNDocumentType.Image:
                return <Image />;
            case TNDocumentType.Link:
                return <Link45deg />;
            default:
                return null;
        }
    };

    return (
        <Fragment>
            {Object.keys(groupedDocuments).map((type) => (
                <div key={type}>
                    <DataListView
                        firstColumnStyle={{
                            width: '50px',
                        }}
                        datas={
                            groupedDocuments[type as TNDocumentType].map((doc, index) => ({
                                label: '',
                                value: (
                                    <a href={mappingFilePath(doc.link)} target="_blank" className="text-b">
                                        {getIcon(doc.type)} {doc.name ?? doc.link}
                                    </a>
                                ),
                            })) ?? []
                        }
                    />
                </div>
            ))}
        </Fragment>
    );
};
