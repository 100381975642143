import { EquipmentReportGroupRequest, EquipmentReportItemRequest } from 'api/models';
import { Accordion } from 'react-bootstrap';
import ItemContent from './item.component';

const GroupContent = ({
    group,
    readonly,
    onItemChange,
}: {
    group: EquipmentReportGroupRequest;
    readonly: boolean;
    onItemChange: (group: EquipmentReportGroupRequest, item: EquipmentReportItemRequest) => void;
}) => {
    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="2">
                <Accordion.Header>{<span className={group.items.some((n) => !n.isOk) ? 'text-danger' : ''}>{group.groupTemplateName}</span>}</Accordion.Header>
                <Accordion.Body>
                    <div className="row">
                        {group.items?.map((item) => (
                            <div key={item.itemTemplateId} id={item.itemTemplateId} className="col-md-6">
                                <ItemContent onItemChange={onItemChange} group={group} readonly={readonly} key={group.groupTemplateId} item={item} />
                            </div>
                        ))}
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};
export default GroupContent;
