import { memo, useEffect, useState } from 'react';

//react-bootstrap
import { Button, Container, Dropdown, Nav, Navbar } from 'react-bootstrap';

//router
import { Link, Router, useLocation } from 'react-router-dom';

//component
import Card from 'components/shared/Card';
import { CustomToggle, SelectLanguage } from 'components/shared';

//img

// logo
import { Logo } from 'components/shared';

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';

// Import selectors & action from setting store
import * as SettingSelector from 'app/setting/selectors';

import { setShowCustomerModal } from 'app/appSlice';
import { ZoomIn, ZoomOut } from 'assets/icons';
import { ArrowRight } from 'assets/icons/outlined';
import Routers from 'router/const/routers';
import Translations from 'i18n/translation';
import { useTranslation } from 'react-i18next';
import { fullscreen, minisidebar, hidenMiniSideBar } from './actions';
import { useAuth } from 'providers/contexts';
import { isMobileDevide } from 'helper';
import { findRouteByCurrentPath } from 'router/const/pageInfos';
import { lang } from 'i18n/lang';

const Headerpro = memo(() => {
    const { t } = useTranslation();
    const navbarHide = useSelector(SettingSelector.navbar_show); // array
    const themeFontSize = useSelector(SettingSelector.theme_font_size);
    const headerNavbar = useSelector(SettingSelector.header_navbar);

    const { tokenProfile } = useAuth();
    // set hide menu vertical sizebar if is mobile
    useEffect(() => {
        if (isMobileDevide()) {
            hidenMiniSideBar();
        }
    }, []);
    useEffect(() => {
        if (headerNavbar === 'navs-sticky' || headerNavbar === 'nav-glass') {
            window.onscroll = () => {
                if (document.documentElement.scrollTop > 50) {
                    document.getElementsByTagName('nav')[0].classList.add('menu-sticky');
                } else {
                    document.getElementsByTagName('nav')[0].classList.remove('menu-sticky');
                }
            };
        }

        document.getElementsByTagName('html')[0].classList.add(themeFontSize);

        //offcanvase code
        const result = window.matchMedia('(max-width: 1200px)');
        window.addEventListener('resize', () => {
            if (result.matches === true) {
                if (show1 === true) {
                    document.documentElement.style.setProperty('overflow', 'hidden');
                } else {
                    document.documentElement.style.removeProperty('overflow');
                }
            } else {
                document.documentElement.style.removeProperty('overflow');
            }
        });
        if (window.innerWidth <= 1200) {
            if (show1 === true) {
                document.documentElement.style.setProperty('overflow', 'hidden');
            } else {
                document.documentElement.style.removeProperty('overflow');
            }
        } else {
            document.documentElement.style.removeProperty('overflow');
        }
    });

    const [show, setShow] = useState(true);

    const [show1, setShow1] = useState(false);

    let location = useLocation();

    const dispatch = useDispatch();
    // set current page name
    const pageInfo = findRouteByCurrentPath();
    // const pageInfoText = pageInfos.filter((p) => {
    //     return location.pathname.startsWith(p.path) && p.path !== Routers.root;
    // });

    // logout hook
    const { logOut } = useAuth();

    // show modal choose customer
    const showCustomerModal = () => {
        dispatch(setShowCustomerModal(true));
    };
    return (
        <Navbar expand="xl" className={`nav iq-navbar header-hover-menu left-border ${headerNavbar} ${navbarHide.join(' ')}`}>
            <Container fluid className="navbar-inner">
                {/* Logo */}
                <Link to={Routers.root} className="navbar-brand">
                    <Logo />
                    <h4 className="logo-title ms-3 mb-0">HSEVN</h4>
                </Link>

                <div className="sidebar-toggle" data-toggle="sidebar" data-active="true" onClick={minisidebar}>
                    <ArrowRight />
                </div>

                <div className="breadcrumb-title border-end me-3 pe-3 d-none d-xl-block">
                    <small className="mb-0 text-capitalize text-primary">
                        {lang.text(pageInfo?.groupName ?? '')} {pageInfo?.groupName ? ' > ' : ''}
                    </small>
                    <Link to={pageInfo?.path ?? '#'}>
                        <small className="mb-0 text-capitalize">{lang.text(pageInfo?.title ?? '')}</small>
                    </Link>
                    <div className={`offcanvas-backdrop fade  ${show1 === true ? 'show d-block' : 'd-none'}`} onClick={() => setShow1(false)}></div>
                </div>

                {/* Toggle mobile nav */}
                <div className="d-flex align-items-center">
                    <Button
                        id="navbar-toggle"
                        bsPrefix="navbar-toggler"
                        type="button"
                        aria-expanded={show1}
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        onClick={() => setShow1(!show1)}
                    >
                        <span className="navbar-toggler-icon">
                            <span className="mt-1 navbar-toggler-bar bar1"></span>
                            <span className="navbar-toggler-bar bar2"></span>
                            <span className="navbar-toggler-bar bar3"></span>
                        </span>
                    </Button>
                </div>
                <div className={` navbar-collapse ${show1 === true ? 'collapse show' : 'collapse'}`} id="navbarSupportedContent">
                    <ul className="mb-2 navbar-nav ms-auto align-items-center navbar-list mb-lg-0">
                        {/* Full screen action */}
                        <Nav.Item className=" iq-full-screen d-none d-xl-block" onClick={() => setShow(!show)}>
                            <Nav.Link id="btnFullscreen" onClick={fullscreen}>
                                <div className="btn btn-primary btn-icon btn-sm rounded-pill">
                                    <span className="btn-inner">
                                        <ZoomIn className={`normal-screen ${show === true ? '' : 'd-none'} icon-24`} />
                                        <ZoomOut className={`full-normal-screen ${show === false ? '' : 'd-none'} icon-24`} />
                                    </span>
                                </div>
                            </Nav.Link>
                        </Nav.Item>

                        {/* Select language */}
                        {/* <SelectLanguage isRounded={true} /> */}

                        <Dropdown as="li" className="nav-item iq-tour ps-3 ps-lg-0">
                            <Dropdown.Toggle as={CustomToggle} variant="py-0  d-flex align-items-center nav-link">
                                <div className="btn btn-primary btn-icon btn-sm rounded-pill">
                                    <span className="btn-inner">
                                        <svg width="32" className="icon-32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.997 15.1746C7.684 15.1746 4 15.8546 4 18.5746C4 21.2956 7.661 21.9996 11.997 21.9996C16.31 21.9996 19.994 21.3206 19.994 18.5996C19.994 15.8786 16.334 15.1746 11.997 15.1746Z"
                                                fill="currentColor"
                                            ></path>
                                            <path
                                                opacity="0.4"
                                                d="M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z"
                                                fill="currentColor"
                                            ></path>
                                        </svg>
                                    </span>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant="end">
                                <Dropdown.Item>
                                    <Link to={Routers.user.profile}>{t(Translations.common.profile)}</Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to={Routers.auth.changePassword}>{t(Translations.common.changePassword)}</Link>
                                </Dropdown.Item>
                                <Dropdown.Item className={`${tokenProfile?.isSuperAdmin ? 'd-block' : 'd-none'}`} onClick={showCustomerModal}>
                                    {t(Translations.form.customer.name)}
                                </Dropdown.Item>
                                <hr className="dropdown-divider" />
                                <Dropdown.Item onClick={() => logOut()}>{t(Translations.common.logout)}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        {/* Notification */}
                        <Dropdown as="li" className="nav-item d-none">
                            <Dropdown.Toggle as={CustomToggle} variant="nav-link ">
                                <div className="btn btn-primary btn-icon btn-sm rounded-pill btn-action">
                                    <span className="btn-inner">
                                        <i className="icon">
                                            <svg className="icon-20" width="20" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    opacity="0.4"
                                                    d="M21.9999 15.9402C21.9999 18.7302 19.7599 20.9902 16.9699 21.0002H16.9599H7.04991C4.26991 21.0002 1.99991 18.7502 1.99991 15.9602V15.9502C1.99991 15.9502 2.00591 11.5242 2.01391 9.29821C2.01491 8.88021 2.49491 8.64621 2.82191 8.90621C5.19791 10.7912 9.44691 14.2282 9.49991 14.2732C10.2099 14.8422 11.1099 15.1632 12.0299 15.1632C12.9499 15.1632 13.8499 14.8422 14.5599 14.2622C14.6129 14.2272 18.7669 10.8932 21.1789 8.97721C21.5069 8.71621 21.9889 8.95021 21.9899 9.36721C21.9999 11.5762 21.9999 15.9402 21.9999 15.9402Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M21.476 5.6736C20.61 4.0416 18.906 2.9996 17.03 2.9996H7.05001C5.17401 2.9996 3.47001 4.0416 2.60401 5.6736C2.41001 6.0386 2.50201 6.4936 2.82501 6.7516L10.25 12.6906C10.77 13.1106 11.4 13.3196 12.03 13.3196C12.034 13.3196 12.037 13.3196 12.04 13.3196C12.043 13.3196 12.047 13.3196 12.05 13.3196C12.68 13.3196 13.31 13.1106 13.83 12.6906L21.255 6.7516C21.578 6.4936 21.67 6.0386 21.476 5.6736Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </i>
                                    </span>
                                    <span className="notification-alert"></span>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant="end p-0 sub-drop">
                                <Card className="m-0 shadow-none ">
                                    <Card.Header className="py-3 d-flex justify-content-between bg-primary">
                                        <div className="header-title">
                                            <h5 className="mb-0 text-white">All Carts</h5>
                                        </div>
                                    </Card.Header>
                                    {/* <Card.Body className="p-0 max-17 scroll-thin">
                    <div className="iq-sub-card">
                      <div className="d-flex align-items-center">
                        <Image
                          className="p-1 avatar-40 rounded-pill bg-soft-primary"
                          src={img1}
                          alt=""
                          loading="lazy"
                        />
                        <div className="ms-3 flex-grow-1">
                          <h6 className="mb-0 ">Biker’s Jacket</h6>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-0">$56.00</p>
                          </div>
                        </div>
                        <Button variant="icon text-danger " size="sm">
                          <div className="btn-inner">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                opacity="0.4"
                                d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                        </Button>
                      </div>
                    </div>
                    <div className="iq-sub-card">
                      <div className="d-flex align-items-center">
                        <Image
                          className="p-1 avatar-40 rounded-pill bg-soft-primary"
                          src={img2}
                          alt=""
                          loading="lazy"
                        />
                        <div className="ms-3 flex-grow-1">
                          <h6 className="mb-0 ">Casual Shoes</h6>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-0">$56.00</p>
                          </div>
                        </div>
                        <Button variant="icon text-danger " size="sm">
                          <div className="btn-inner">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                opacity="0.4"
                                d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                        </Button>
                      </div>
                    </div>
                    <div className="iq-sub-card">
                      <div className="d-flex align-items-center">
                        <Image
                          className="p-1 avatar-40 rounded-pill bg-soft-primary"
                          src={img3}
                          alt=""
                          loading="lazy"
                        />
                        <div className="ms-3 flex-grow-1">
                          <h6 className="mb-0 ">Knitted Shrug</h6>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-0">$56.00</p>
                          </div>
                        </div>
                        <Button size="sm" variant="icon text-danger">
                          <div className="btn-inner">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                opacity="0.4"
                                d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                        </Button>
                      </div>
                    </div>
                    <div className="iq-sub-card">
                      <div className="d-flex align-items-center">
                        <Image
                          className="p-1 avatar-40 rounded-pill bg-soft-primary"
                          src={img4}
                          alt=""
                          loading="lazy"
                        />
                        <div className="ms-3 flex-grow-1">
                          <h6 className="mb-0 ">Blue Handbag</h6>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-0">$56.00</p>
                          </div>
                        </div>
                        <Button variant="icon text-danger" size="sm">
                          <div className="btn-inner">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                opacity="0.4"
                                d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                        </Button>
                      </div>
                    </div>
                    <div className="iq-sub-card">
                      <div className="d-flex align-items-center">
                        <Image
                          className="p-1 avatar-40 rounded-pill bg-soft-primary"
                          src={img1}
                          alt=""
                          loading="lazy"
                        />
                        <div className="ms-3 flex-grow-1">
                          <h6 className="mb-0 ">Biker’s Jacket</h6>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-0">$56.00</p>
                          </div>
                        </div>
                        <Button variant="icon text-danger" size="sm">
                          <div className="btn-inner">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                opacity="0.4"
                                d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                        </Button>
                      </div>
                    </div>
                    <div className="iq-sub-card">
                      <div className="d-flex align-items-center">
                        <Image
                          className="p-1 avatar-40 rounded-pill bg-soft-primary"
                          src={img2}
                          alt=""
                          loading="lazy"
                        />
                        <div className="ms-3 flex-grow-1">
                          <h6 className="mb-0 ">Casual Shoes</h6>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-0">$56.00</p>
                          </div>
                        </div>
                        <Button variant="icon text-danger " size="sm">
                          <div className="btn-inner">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                opacity="0.4"
                                d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                        </Button>
                      </div>
                    </div>
                    <div className="iq-sub-card">
                      <div className="d-flex align-items-center">
                        <Image
                          className="p-1 avatar-40 rounded-pill bg-soft-primary"
                          src={img3}
                          alt=""
                          loading="lazy"
                        />
                        <div className="ms-3 flex-grow-1">
                          <h6 className="mb-0 ">Knitted Shrug</h6>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-0">$56.00</p>
                          </div>
                        </div>
                        <Button variant="icon text-danger " size="sm">
                          <div className="btn-inner">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                opacity="0.4"
                                d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                        </Button>
                      </div>
                    </div>
                    <div className="iq-sub-card">
                      <div className="d-flex align-items-center">
                        <Image
                          className="p-1 avatar-40 rounded-pill bg-soft-primary"
                          src={img4}
                          alt=""
                          loading="lazy"
                        />
                        <div className="ms-3 flex-grow-1">
                          <h6 className="mb-0 ">Blue Handbag</h6>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-0">$56.00</p>
                          </div>
                        </div>
                        <Button variant="icon text-danger " size="sm">
                          <div className="btn-inner">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                opacity="0.4"
                                d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                        </Button>
                      </div>
                    </div>
                  </Card.Body> */}
                                    <Card.Footer className="p-0">
                                        <div className="d-grid">
                                            <Link to="/e-commerce/order-process" className="btn btn-primary">
                                                View All
                                            </Link>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </Dropdown.Menu>
                        </Dropdown>
                    </ul>
                </div>
            </Container>
        </Navbar>
    );
});

Headerpro.displayName = 'Headerpro';
export default Headerpro;
