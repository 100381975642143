import { yupResolver } from '@hookform/resolvers/yup';
import { OrganizationUnitResponse } from 'api/models';
import { InputField } from 'components/FormControl';
import { ModalComponent } from 'components/Modal';
import { ConfirmModal } from 'components/shared';
import Translations from 'i18n/translation';
import { on } from 'events';
import { t } from 'i18next';
import { set } from 'lodash';
import { Suspense, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface PromptModalProps {
    onCanceled?: () => void;
    onSubmitForm: (value: string, id: string | null) => void;
    value: string;
    id?: string | null;
    title: string;
    children?: React.ReactNode;
    isUseCustomShow?: boolean; // if true not use isShow state of this component
    isCustomShow?: boolean; // if true show modal, this state manage by parent component
}

export const PromptModal = ({
    id,
    value,
    title,
    children,
    onCanceled,
    onSubmitForm,
    isUseCustomShow = false,
    isCustomShow = false,
}: PromptModalProps) => {
    const schema = yup.object().shape({
        value: yup.string().required(t(Translations.form.validate.nameCannotEmpty)),
    });

    const [isShow, setIsShow] = useState<boolean>(false);
    // const [isOpenConfim, setIsOpenConfim] = useState<boolean>(false);
    const {
        control,
        handleSubmit,
        formState: { isSubmitting },
        watch,
        getValues,
        reset,
    } = useForm<{ value: string }>({
        defaultValues: {
            value: value,
        },
        resolver: yupResolver(schema),
    });
    const onCloseModal = () => {
        setIsShow(false);
        onCanceled && onCanceled();
    };

    return (
        <>
            <ModalComponent
                titleModal={title}
                showModal={isUseCustomShow ? isCustomShow ?? false : isShow}
                closeButton
                handleCloseModal={onCloseModal}
                bodyModal={
                    <form
                        onSubmit={handleSubmit(() => {
                            onSubmitForm(getValues().value, id ?? null);
                            if (!isUseCustomShow) {
                                setIsShow(false);
                                reset({ value: '' });
                            }
                        })}
                    >
                        <InputField
                            control={control}
                            name="value"
                            label={t(Translations.form.name)}
                        />
                    </form>
                }
                footerModal={
                    <>
                        <Button onClick={onCloseModal} type="submit" variant="light">
                            {t(Translations.modal.action.close)}
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                            onClick={handleSubmit(() => {
                                onSubmitForm(getValues().value, id ?? null);
                                if (!isUseCustomShow) {
                                    setIsShow(false);
                                    reset({ value: '' });
                                }
                            })}
                        >
                            {t(Translations.modal.action.save)}
                        </Button>
                    </>
                }
            />
            <div onClick={() => setIsShow(true)}> {children}</div>
        </>
    );
};

export default PromptModal;
