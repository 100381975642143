//react-bootstrap

//router

// img
import { Error404 } from 'components/errors';

export default function NoutFoundPage() {
  return (
    <>
      <Error404 />
    </>
  );
}

NoutFoundPage.displayName = 'NoutFoundPage';
