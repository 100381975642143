import client from '../client';
import { PaginationBase } from 'api/models/common';
import { TNCourseDetailResponse, TNCourseFilterRequest, TNCourseResponse, TNMyCourseResponse, TNScoreFilterRequest } from './models/tnCourse.model';
import { TNSubmitQuizRequest } from './models/tnMyCourse.model';

const baseURL = '/MyCourse';

const tnMyCourseAPI = {
    canStartQuiz(id: string): Promise<boolean> {
        const url = `${baseURL}/canStartQuiz/${id}`;
        return client.getNonWrap<boolean>({
            endpoint: url,
            isLoading: false,
            isShowError:false
        });
    },
    getById(id: string): Promise<TNCourseDetailResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<TNCourseDetailResponse>({
            endpoint: url,
        });
    },
    getPagination(filter: TNCourseFilterRequest, isLoading: boolean = true): Promise<PaginationBase<TNMyCourseResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<TNMyCourseResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    getScorePagination(filter: TNScoreFilterRequest, isLoading: boolean = true): Promise<PaginationBase<TNCourseResponse>> {
        const url = `${baseURL}/score/GetByIndex`;
        return client.getNonWrap<PaginationBase<TNCourseResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    startQuiz(id: string): Promise<unknown> {
        const url = `${baseURL}/startQuiz/${id}`;
        return client.getNonWrap<unknown>({
            endpoint: url,
        });
    },
    submitQuiz(id: string, data: TNSubmitQuizRequest[]): Promise<unknown> {
        const url = `${baseURL}/submitQuiz/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
};

export default tnMyCourseAPI;
