import { SelectOption } from 'components/FormControl';
import Translations from 'i18n/translation';
import { useEffect, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import { Form, FormGroup } from 'react-bootstrap';
import { lang } from 'i18n/lang';
import usePromptInputText from 'components/Modal/PromptInputText';
import legaldocumentTagApi from 'api/v2/legal-document/legalDocumentTagApi';
import { LDTagResponse } from 'api/v2/legal-document/legalDocument.models';
import { PaginationBase } from 'api/models/common';
import { PlusCircleFill } from 'react-bootstrap-icons';
import { useAuth } from 'providers/contexts';
import Select, { SelectProps } from 'components/FormControl/Select';

export interface LegalDocumentTagSelectProps extends SelectProps {
    isMulti: boolean | undefined;
    defaultValue?: any;
    isCanCreate: boolean;
}

export const LegalDocumentTagSelect = (props: LegalDocumentTagSelectProps) => {
    const { tokenProfile } = useAuth();
    const isCanCreateTag = tokenProfile?.pms.ldTagCreate;
    const [options, setOptions] = useState<SelectOption[]>([]);
    const {
        field,
        fieldState: { error },
    } = useController({
        name: props.name,
        control: props.control,
        defaultValue: props.defaultValue,
    });
    const { openPromptInputText, PromptInputText } = usePromptInputText();

    const onAddTag = async () => {
        const text: string = await openPromptInputText({
            title: lang.text(Translations.common.name),
            defaultValue: '',
        });
        if (text)
            legaldocumentTagApi
                .create({ label: text, id: null })
                .then((id: string) => {
                    setOptions([...options, { value: id, label: text }]);
                })
                .catch((res) => {});
    };

    useEffect(() => {
        legaldocumentTagApi
            .getAll()
            .then((tags: PaginationBase<LDTagResponse>) => {
                setOptions(tags.datas?.toSelectOptions('label', 'id') ?? []);
            })
            .catch((e) => {});
    }, []);
    return (
        <div className={`col-md-${props.md}`}>
            <FormGroup className="row">
                <div className="col-12">
                    <Form.Label>
                        {lang.text(Translations.ra.tag)}
                        {props.required && <span className="text-danger">*</span>} &nbsp;
                        {isCanCreateTag && props.isCanCreate && (
                            <span className={`btn-inner cursor-pointer-hover`}>
                                <PlusCircleFill onClick={onAddTag} />
                            </span>
                        )}
                    </Form.Label>
                </div>
                <Select
                    {...props}
                    md={12}
                    onOptionChange={(n: any) => {
                        if (Array.isArray(n)) {
                            field.onChange(n?.map((x: any) => x.value) ?? []);
                        } else {
                            field.onChange(n.value);
                        }
                    }}
                    options={options}
                    isMulti={props.isMulti}
                />
            </FormGroup>
            {PromptInputText}
        </div>
    );
};

export default LegalDocumentTagSelect;
