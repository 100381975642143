import { PaginationBase, PaginationBaseRequest } from 'api/models/common';
import client from '../client';
import {  LDTagRequest, LDTagResponse,} from './legalDocument.models';

const baseURL = '/legaldocument/tag';
const legaldocumentTagApi = {
    create(data: LDTagRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: LDTagRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
    getAll(): Promise<PaginationBase<LDTagResponse>> {
        const url = `${baseURL}/getbyindex`;
        const payload:PaginationBaseRequest ={
            rowsPerPage : 1000,
            currentPage:1
        }
        return client.getNonWrap<PaginationBase<LDTagResponse>>({
            data: payload,
            endpoint: url,
        });
    },
};

export default legaldocumentTagApi;
