import { yupResolver } from '@hookform/resolvers/yup';
import { ProfileCertificateRequest } from 'api/v2/user/model';
import { InputField } from 'components/FormControl';
import DateTimeField from 'components/FormControl/DateTimeField';
import UploadFileField from 'components/FormControl/UploadFileField';
import { ModalHookComponentProps } from 'components/ModalHook';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useState } from 'react';
import { Button, Form, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface ProfileCertificateFormProps extends ModalHookComponentProps {
    certificate?: ProfileCertificateRequest;
}
const ProfileCertificateForm = ({ certificate, onClose, onCompleted }: ProfileCertificateFormProps) => {
    const { control, handleSubmit, getValues } = useForm<ProfileCertificateRequest>({
        resolver: yupResolver(
            yup.object().shape({
                name: yup.string().required(),
            })
        ),
    });
    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const onSubmit = async () => {
        var form = getValues();
        let data: ProfileCertificateRequest = {
            ...form,
            fileName: fileName,
            id: certificate?.id,
        };
        onCompleted(data);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputField name={'name'} control={control} defaultValue={certificate?.name} label={lang.text(Translations.common.name)} readOnly={false} />
            <DateTimeField name={'issueDate'} control={control} defaultDate={certificate?.issueDate} label={lang.text(Translations.common.issuedDate)} readOnly={false} />
            <DateTimeField name={'expiredDate'} control={control} defaultDate={certificate?.expiredDate} label={lang.text(Translations.common.expireDate)} readOnly={false} />
            <InputField name={'issuePlace'} control={control} defaultValue={certificate?.issuePlace} label={lang.text(Translations.common.issuedPlace)} readOnly={false} />
            <UploadFileField
                accepts=".pdf"
                md={6}
                name="pathCertificate"
                control={control}
                onUploaded={(n) => {
                    setFileName(n.fileName);
                }}
                defaultFileName={certificate?.fileName ?? ''}
                defaultFilePath={certificate?.pathCertificate ?? ''}
            />
            <Modal.Footer>
                <Button variant="danger" onClick={onClose}>
                    {lang.text(Translations.modal.confirm.cancel)}
                </Button>
                <Button variant="primary" type="submit">
                    {lang.text(Translations.modal.confirm.ok)}
                </Button>
            </Modal.Footer>
        </form>
    );
};


export default ProfileCertificateForm;
