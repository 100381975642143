import { TripDetailResponse, TripStatus } from 'api/v2/trip/model/trip.model';
import tripApi from 'api/v2/trip/tripApi';
import usePromptInputText from 'components/Modal/PromptInputText';
import useModalHook from 'components/ModalHook';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { Fragment, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Routers from 'router/const/routers';
import TripRequestChangeRoute from '../requestChangeRoute';

interface TripButtonProps {
    trip?: TripDetailResponse;
    onRequestChangeRoute?: () => void;
    onApproveChangeRoute?: () => void;
    onRejectChangeRoute?: () => void;
    onChangeStatus?: (status: TripStatus) => void;
}
interface ButtonActionTrip {
    action: (btn?: TripStatus) => void;
    text: string;
}

const TripButton = ({ trip, onRequestChangeRoute, onChangeStatus, onApproveChangeRoute, onRejectChangeRoute }: TripButtonProps) => {
    const [actions, setActions] = useState<ButtonActionTrip[]>([]);
    const navigate = useNavigate();
    const { openPromptInputText, PromptInputText } = usePromptInputText();

    const changeStatus = async (status: TripStatus) => {
        onChangeStatus && onChangeStatus(status);
    };

    useEffect(() => {
        if (trip?.buttonsAction) {
            var newActions: ButtonActionTrip[] = [];

            if (trip?.buttonsAction?.isVisibleBtnApprove) {
                newActions.push({
                    action: () => changeStatus(TripStatus.APPROVED),
                    text: 'Chấp nhận',
                });
            }
            if (trip?.buttonsAction?.isVisibleBtnRefuse) {
                newActions.push({
                    action: () => changeStatus(TripStatus.REFUSE),
                    text: 'Từ chối',
                });
            }
            if (trip?.buttonsAction?.isVisibleChangeRoute) {
                newActions.push({
                    action: () => onRequestChangeRoute && onRequestChangeRoute(),
                    text: 'Thay đổi tuyến đường',
                });
            }

            if (trip?.buttonsAction?.isVisibleApproveChangeRoute) {
                newActions.push({
                    action: () => onApproveChangeRoute && onApproveChangeRoute(),
                    text: 'Chấp nhận thay đổi tuyến đường',
                });
            }
            if (trip?.buttonsAction?.isVisibleRejectChangeRoute) {
                newActions.push({
                    action: () => onRejectChangeRoute && onRejectChangeRoute(),
                    text: 'Từ chối thay đổi tuyến đường',
                });
            }
            if (trip?.buttonsAction?.isVisibleBtnStart) {
                newActions.push({
                    action: () => changeStatus(TripStatus.PROCESSING),
                    text: 'Bắt đầu',
                });
            }
            if (trip?.buttonsAction?.isVisibleBtnComplete) {
                newActions.push({
                    action: () => changeStatus(TripStatus.COMPLETED),
                    text: 'Hoàn thành',
                });
            }
            setActions(newActions);
        }
    }, [trip]);

    return actions.length === 0 ? (
        <Fragment></Fragment>
    ) : (
        <Fragment>
            <Dropdown className="custom-trip-btn  ">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Thao tác
                </Dropdown.Toggle>

                <Dropdown.Menu className="py-2">
                    {actions?.map((item, index) => (
                        <Dropdown.Item key={index} onClick={() => item.action()}>
                            {item.text}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>

            {PromptInputText}
        </Fragment>
    );
};

export default TripButton;
