import { PaginationBase } from 'api/models/common';
import client from '../client';
import { TNCourseSubmissionFilterRequest, TNCourseSubmissionSetPointRequest, TNSubmissionDetailResponse } from './models/tnCourseSubmission.model';

const baseURL = '/Submission';

const tnCourseSubmissionAPI = {
    getByIndex(filter: TNCourseSubmissionFilterRequest, isLoading: boolean = true): Promise<PaginationBase<TNSubmissionDetailResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<TNSubmissionDetailResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    setPoint(data: TNCourseSubmissionSetPointRequest): Promise<unknown> {
        const url = `${baseURL}/setPoint`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    getByCourseAndUser(couseId: string, userId: string): Promise<TNSubmissionDetailResponse> {
        const url = `${baseURL}/course/${couseId}/user/${userId}`;
        return client.getNonWrap<TNSubmissionDetailResponse>({
            endpoint: url
        });
    },
};

export default tnCourseSubmissionAPI;
