import client from '../client';
import { PaginationBase, PaginationBaseRequest } from 'api/models/common';
import { SORCategoryRequest, SORCategoryResponse } from './model/sorCategory.model';
import { SelectOption } from 'components/FormControl';

const baseURL = '/SORCategory';
const sorCategoryApi = {
    getById(id: string): Promise<SORCategoryResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<SORCategoryResponse>({
            endpoint: url,
        });
    },
    getPagination(filter: PaginationBaseRequest, isLoading: boolean = true): Promise<PaginationBase<SORCategoryResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<SORCategoryResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: SORCategoryRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: SORCategoryRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
    getDropdown(): Promise<SelectOption[]> {
        const url = `${baseURL}/dropdown`;
        return client.getNonWrap<SelectOption[]>({
            endpoint: url,
        });
    },
};

export default sorCategoryApi;
