import client from './client';
import {
    CustomerInfraResponse,
    CustomerResponse,
    CustomerCreateRequest,
    CustomerUpdateRequest,
} from '../models';
import { PaginationBase, PaginationBaseRequest } from '../models/common';

const baseUrl = '/customer';
const customerApi = {
    getInfrastructures: async () => {
        const url = `${baseUrl}/GetInfrastructure`;
        return client.getNonWrap<CustomerInfraResponse[]>({
            endpoint: url,
            isLoading: true,
            isShowError: true,
            isShowSuccess: false,
        });
    },
    getPagination: async (filter: PaginationBaseRequest) => {
        const url = `${baseUrl}/getbyindex`;
        return (await client.get<PaginationBase<CustomerResponse>>(url, filter, true)).result;
    },
    create: (data: CustomerCreateRequest) => {
        const url = `${baseUrl}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
            isShowError: true,
            isShowSuccess: true,
            isLoading: true,
        });
    },
    update: (data: CustomerUpdateRequest) => {
        const url = `${baseUrl}/update`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
            isShowError: true,
            isShowSuccess: true,
            isLoading: true,
        });
    },
    getById: (id: string) => {
        const url = `${baseUrl}/getbyid/${id}`;
        return client.get<CustomerResponse>(url);
    },
    delete: (id: string) => {
        const url = `${baseUrl}/delete/${id}`;
        return client.post(url, null, true, true).catch(() => {});
    },
};

export default customerApi;
