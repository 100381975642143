import { LocationModel } from 'api/models/common/locationModel';
import client from '../client';

const baseURL = '/Location';
const locationApi = {
    search(search: string): Promise<LocationModel[]> {
        const url = `${baseURL}`;
        return client.getNonWrap<LocationModel[]>({
            endpoint: url,
            data: { keysearch: search },
            isLoading: false,
            isShowError: false,
        });
    },
    parse(data: { lon: any; lat: any }): Promise<LocationModel> {
        const url = `${baseURL}/parse`;
        return client.getNonWrap<LocationModel>({
            endpoint: url,
            data,
            isLoading: false,
            isShowError: false,
        });
    },
};

export default locationApi;
