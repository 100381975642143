import iirReportApi from 'api/v2/iir/iirReportApi';
import { IIRActionRequest, IIRReportDetailResponse } from 'api/v2/iir/model/iirReport.model';
import { IIRUserModel } from 'api/v2/iir/model/iirReportComponent.model';
import TimelineComponent from 'components/Common/TimeLine';
import DataListView from 'components/shared/DataListView';
import { avataUserDefault } from 'constants/common';
import { formatDate } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import React, { Fragment, useEffect, useState } from 'react';
import { Card, ListGroup, Row, Col, Badge, Image, Collapse, Button } from 'react-bootstrap';
import { Circle, Facebook, SendCheck } from 'react-bootstrap-icons';
import { useNavigate, useParams } from 'react-router-dom';
import IIRReportItemComponent from './component/iirPerson.component';
import IIRPersonViewComponent from './component/iirPerson.component';
import IIREquipmentViewComponent from './component/iirEquipment.component';
import IIREnvironmentalDamageViewComponent from './component/iirEnvironmentalDamage.component';
import IIRSequenOfEventViewComponent from './component/iirSequenOfEvent.component';
import IIREvidentViewComponent from './component/iirEvident.component';
import IIRActionFollowUpViewComponent from './component/iirActionFollowUp.component';
import IIRTextListViewComponent from './component/iirTextListView.component';
import IIRCauseViewComponent from './component/iirCause.component';
import IIRHistoryViewComponent from './component/iirHistory.component';
import { DeleteButton, UpdateButton } from 'components/Button';
import { BaseButton } from 'components/Button/BaseButton';
import { Ban, Check } from 'heroicons-react';
import { IIRReportStatus, IIRUserStatus, IIRUserType } from 'api/enums/IIR';
import IIRUserComponent from './component/iirUser.component';
import usePromptInputText from 'components/Modal/PromptInputText';
import { Empty } from 'components/shared';
import Routers from 'router/const/routers';

const IIRReportDetaiPage: React.FC = () => {
    const { id } = useParams();
    const [report, setReport] = useState<IIRReportDetailResponse>();
    const { openPromptInputText, PromptInputText } = usePromptInputText();
    const navigate = useNavigate();
    useEffect(() => {
        loadReport();
    }, []);
    const loadReport = async () => {
        id &&
            iirReportApi
                .getById(id)
                .then((res) => {
                    setReport(res);
                })
                .catch((err) => {});
    };
    const onChangeStatus = async (status: IIRUserStatus, userType: IIRUserType) => {
        const text: string = await openPromptInputText({
            title: lang.text(Translations.common.description),
            description: lang.text(Translations.common.description),
            defaultValue: '',
        });
        if (id && text) {
            let payload = {
                comment: text,
                newStatus: status,
                reportId: id,
            };
            if (userType == IIRUserType.Investigator) {
                iirReportApi
                    .investigatorAction(payload)
                    .then((n) => {
                        loadReport();
                    })
                    .catch((err) => {});
            } else if (userType == IIRUserType.Boss) {
                iirReportApi
                    .bossAction(payload)
                    .then((n) => {
                        loadReport();
                    })
                    .catch((err) => {});
            }
        }
    };
    const onCreateResendRate = async () => {
        const text: string = await openPromptInputText({
            title: lang.text(Translations.common.description),
            description: lang.text(Translations.common.description),
            defaultValue: '',
        });

        if (id && text) {
            let payload: IIRActionRequest = {
                comment: text,
                newStatus: IIRUserStatus.New,
                reportId: id,
            };
            iirReportApi
                .creatorResendAction(payload)
                .then((n) => {
                    loadReport();
                })
                .catch((err) => {});
        }
    };
    const onDelete = async () => {
        id &&
            iirReportApi
                .delete(id)
                .then((n) => {
                    navigate(-1);
                })
                .catch((err) => {});
    };
    const generateAction = () => {
        return report && (report.canDelete || report.canUpdate || report.canInvestigatorAgree || report.canInvestigatorRefuse || report.canBossAgree) ? (
            <Card>
                <Card.Body className="d-flex justify-content-end">
                    <DeleteButton isRender={report.canDelete} onConfirmed={() => onDelete()} showConfirm={true} />
                    {report && (
                        <UpdateButton
                            isRender={report.canUpdate}
                            onClick={() => {
                                id && navigate(Routers.iir.report.update.replace(':id', id));
                            }}
                        />
                    )}
                    <BaseButton
                        onClick={() => onChangeStatus(IIRUserStatus.Agree, IIRUserType.Investigator)}
                        isRender={report.canInvestigatorAgree}
                        text={lang.text(Translations.button.agree)}
                        icon={<Check />}
                    />
                    <BaseButton
                        onClick={() => onChangeStatus(IIRUserStatus.Refuse, IIRUserType.Investigator)}
                        isRender={report.canInvestigatorRefuse}
                        text={lang.text(Translations.button.refuse)}
                        icon={<Ban />}
                        color="danger"
                    />
                    <BaseButton onClick={() => onChangeStatus(IIRUserStatus.Agree, IIRUserType.Boss)} isRender={report.canBossAgree} text={lang.text(Translations.button.agree)} icon={<Check />} />
                    <BaseButton
                        onClick={() => onChangeStatus(IIRUserStatus.Refuse, IIRUserType.Boss)}
                        isRender={report.canBossRefuse}
                        text={lang.text(Translations.button.refuse)}
                        icon={<Ban />}
                        color="danger"
                    />
                    <BaseButton onClick={() => onCreateResendRate()} isRender={report.canCreatorResendRate} text={lang.text(Translations.iir.creatorResendRate)} icon={<SendCheck />} color="warning" />
                </Card.Body>
            </Card>
        ) : (
            <Fragment></Fragment>
        );
    };
    return !report ? (
        <Empty />
    ) : (
        <div>
            <Row>
                <Col>{generateAction()}</Col>
            </Row>
            <Row>
                <Col md={8}>
                    <Card>
                        <Card.Body>
                            <DataListView
                                header={lang.text(Translations.common.generalInfo)}
                                datas={[
                                    { label: lang.text(Translations.iir.reportName), value: <span className="text-primary">{report.reportName}</span> },
                                    { label: lang.text(Translations.iir.incidentType), value: report.incidentTypeName },
                                    { label: lang.text(Translations.iir.incidentLevel), value: report.levelName },
                                    { label: lang.text(Translations.form.site.name), value: report.companyName },
                                    { label: lang.text(Translations.form.area.name), value: report.areaName },
                                    { label: lang.text(Translations.iir.incidentTime), value: formatDate(report.incidentTime) },
                                    { label: lang.text(Translations.common.status), value: report.status },
                                    { label: lang.text(Translations.common.createdBy), value: report.createdUserName },
                                    { label: lang.text(Translations.common.createdTime), value: formatDate(report.createdTime) },
                                ]}
                            />
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>
                            <h6 className="text-primary">{lang.text(Translations.iir.descriptionIncident)} </h6>
                        </Card.Header>
                        <Card.Body>{report.description}</Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    {/* Điều tra viên  */}
                    <div>
                        <IIRUserComponent title={lang.text(Translations.iir.investigator)} users={report.investigators} />
                    </div>
                    {/* Lãnh đạo */}
                    <div>
                        <IIRUserComponent title={lang.text(Translations.iir.boss)} users={report.boss} />
                    </div>
                    {/* history */}
                    <div>
                        <IIRHistoryViewComponent datas={report.histories} />
                    </div>
                </Col>
            </Row>
            <Row>
                {/* Thông tin con người */}
                <IIRPersonViewComponent datas={report.persons} isReadOnly={true} />
            </Row>

            <Row>
                {/* Thông tin thiết bị */}
                <IIREquipmentViewComponent isReadOnly={true} datas={report.equipments} />
            </Row>
            <Row>
                {/* Tổn thất môi trương */}
                <IIREnvironmentalDamageViewComponent isReadOnly={true} datas={report.environmentalDamages} />
            </Row>

            <Row>
                {/* Môi trường làm việc khi  xảy ra sự cố */}
                <Card>
                    <Card.Header>
                        <h6 className="text-primary">{lang.text(Translations.iir.workActivities)} </h6>
                    </Card.Header>
                    <Card.Body>{report.workActivities}</Card.Body>
                </Card>
            </Row>
            <Row>
                {/* Công việc thực hiện khi xảy ra sự cố */}
                <Card>
                    <Card.Header>
                        <h6 className="text-primary">{lang.text(Translations.iir.workEnvironment)} </h6>
                    </Card.Header>
                    <Card.Body>{report.workEnvironment}</Card.Body>
                </Card>
            </Row>
            <Row>
                {/* Các yếu tố liên quan khác */}
                <Card>
                    <Card.Header>
                        <h6 className="text-primary">{lang.text(Translations.iir.involvedFactors)} </h6>
                    </Card.Header>
                    <Card.Body>{report.involvedFactors}</Card.Body>
                </Card>
            </Row>
            {/* Trình tự diễn biến*/}
            <IIRSequenOfEventViewComponent isReadOnly={true} datas={report.sequenOfEvents} />
            <Row>
                {/* Mô tả chứng cứ*/}
                <IIREvidentViewComponent isReadOnly={true} datas={report.evidences} />
            </Row>

            <Row>
                {/* Công việc tiếp theo */}
                <IIRActionFollowUpViewComponent isReadOnly={true} datas={report.actionFollowUps} />
            </Row>

            <Row>
                {/*  Nguyên nhân trực tiếp */}
                <IIRTextListViewComponent isReadOnly={true} datas={report.directCauses} headerColumn={lang.text(Translations.common.description)} title={lang.text(Translations.iir.directCauses)} />
            </Row>
            <Row>
                {/*  Nguyên nhân gián tiếp */}
                {/*  Nguyên nhân gốc */}
                <IIRCauseViewComponent isReadOnly={true} datas={report.causeGroups} />
            </Row>
            <Row>
                {/*  Bài học rút kinh nghiệm*/}
                <IIRTextListViewComponent isReadOnly={true} datas={report.lessons} headerColumn={lang.text(Translations.common.description)} title={lang.text(Translations.iir.lessonsLearned)} />
            </Row>
            <Row>
                {/*  Các ý kiến khác*/}
                <IIRTextListViewComponent isReadOnly={true} datas={report.otherComments} headerColumn={lang.text(Translations.common.description)} title={lang.text(Translations.iir.otherComments)} />
            </Row>
            {PromptInputText}
        </div>
    );
};

export default IIRReportDetaiPage;
