export enum TNDocumentType {
    /**
     * Link từ 1 website khác - có thể là video
     * upload file, youtube, link web
     */
    Link = 'Link',
    File = 'File',
    Image = 'Image',
}

/**
 * Loại khóa học có thời gian cố định hay không.
 * Nếu ko thì ko vấn đề, nếu có thì CourseEntity phải có thời gian kết thúc,
 * Khi đến thời gian kết thúc ko thể làm bài Quiz nữa
 */
export enum CourseLimitType {
    Limit = 'Limit',
    Unlimit = 'Unlimit',
}

export enum TNQuestionType {
    /**
     * Trắc nghiệm chỉ chọn 1 câu
     */
    Experiment = 'Experiment',

    /**
     * Trắc nghiêm chọn nhiều câu
     */
    Mutiple = 'Mutiple',

    /**
     * Tự luận
     */
    Foreword = 'Foreword',
}

export enum CourseStatus {
    /**
     * Sử dụng trên client: waiting : active, remake
     * Sử dụng trên admin: waiting : completed
     */
    Waiting = 'Waiting',
    Completed = 'Completed',
    Remake = 'Remake',
}

export enum TNInfoTab {
    DOCUMENT,
    QUESTION,
    USER,
    SUBMISSION,
    DASHBOARD,
    GENERAL_INFORMATION,
}

// export enum TNQuestionType {
//     EXPERIMENT = 'Experiment',
//     MULTIPLE = 'Mutiple',
//     FOREWORD = 'Foreword',
// }

// export enum CourseLimitType {
//     Limit = 'Limit',
//     Unlimit = 'Unlimit',
// }

// export enum TNCourseStatus {
//     SUCCESS,
//     ERROR
// }

// export enum TNDocumentType {
//     IMAGE = 'Image',
//     LINK = 'Link',
//     FILE = 'File',
// }
