// Redux Selector / Action

import { ModalComponent } from 'components/Modal';
import Translations from 'i18n/translation';
import { useEffect, useState } from 'react';
import { Button, ListGroup, ListGroupItem } from 'react-bootstrap';

import { CustomerInfraResponse } from 'api/models/organization';
import { lang } from 'i18n/lang';

const ModalSelectCustomer = (props: { currentCustomerId: string | null; onSelectCustomer: (cus: CustomerInfraResponse) => void; customers: CustomerInfraResponse[] }) => {
    const [activeCustomerId, setActiveCustomerId] = useState<string | null>(null);
    useEffect(() => {
        setActiveCustomerId(props.currentCustomerId);
    }, []);
    const handleChooseCustomer = () => {
        if (activeCustomerId) {
            let selectedCustomer = props.customers.find((n) => n.id == activeCustomerId);
            selectedCustomer && props.onSelectCustomer(selectedCustomer);
        }
    };

    return (
        <ModalComponent
            titleModal={lang.text(Translations.admin.selectCustomer)}
            showModal={true}
            closeButton={false}
            handleCloseModal={() => {}}
            scrollable={true}
            bodyModal={
                <>
                    <ListGroup as="ul">
                        {props.customers &&
                            props.customers.map((item, index) => (
                                <ListGroupItem
                                    active={activeCustomerId === item.id ? true : false}
                                    key={index}
                                    as="li"
                                    onClick={() => setActiveCustomerId(item.id)}
                                    className={`d-flex justify-content-between align-items-center ${activeCustomerId === item.id ? '' : 'bs-primary-tint-80-hover'} cursor-pointer-hover`}
                                >
                                    {item.name}
                                </ListGroupItem>
                            ))}
                    </ListGroup>
                </>
            }
            footerModal={
                <>
                    <Button onClick={handleChooseCustomer} type="button" variant="primary">
                        Chọn
                    </Button>
                </>
            }
        />
    );
};

export default ModalSelectCustomer;
