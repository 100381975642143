import { FileType } from 'enum/file';
import { mappingFilePath } from './app.helper';

export const FileHelper = {
    getFileNameFromPath: (filePath: string): string => {
        const lastIndex = filePath.lastIndexOf('/');
        return filePath.substring(lastIndex + 1);
    },

    convertExtensionToType: (extension: string): FileType => {
        switch (extension) {
            case '.pdf':
                return FileType.PDF;
            case 'xls':
            case 'xlsx':
                return FileType.Excel;
            case '.jpg':
            case '.png':
            case '.heic':
                return FileType.Image;
            case '.doc':
            case '.docx':
                return FileType.Word;
            case '.txt':
                return FileType.Text;
            default:
                return FileType.None;
        }
    }
};
