import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import { IIRUserType } from 'api/enums/IIR';
import iirReportApi from 'api/v2/iir/iirReportApi';
import CheckboxTreeComponent, { TreeNode } from 'components/Common/CheckBoxTreeComponent';

export interface IIRUserSelectProps extends BaseFormControlProps {
    onValuesChange?: (ids: string[]) => void;
    control: Control<any>;
    type: IIRUserType;
}

export function IIRUserSelect(props: IIRUserSelectProps) {
    const [options, setOptions] = useState<TreeNode[]>([]);
    useEffect(() => {
        loadDatas();
    }, []);

    const loadDatas = () => {
        if (props.type == IIRUserType.Investigator) {
            iirReportApi
                .getInvestigatorsDropdowns()
                .then((res) => {
                    let newValues: TreeNode[] = res.map((n) => ({ label: String(n.label) ?? '', value: String(n.value) }));
                    setOptions(newValues ?? []);
                })
                .catch(() => {});
        } else if (props.type == IIRUserType.Boss) {
            iirReportApi
                .getBossDropdowns()
                .then((res) => {
                    let newValues: TreeNode[] = res.map((n) => ({ label: String(n.label) ?? '', value: String(n.value) }));
                    setOptions(newValues);
                })
                .catch(() => {});
        }
    };

    return <CheckboxTreeComponent isUserForm control={props.control} name={props.name} options={options} onValuesChanged={props.onValuesChange} />;
}
export default IIRUserSelect;
