import { RouteObject } from 'react-router-dom';

import Routers from 'router/const/routers';
import IIRCategoryIncidentTypePage from 'views/iir-report/category/iirCategoryIncidentTypePage';
import IIRCategoryInvestigateLevelPage from 'views/iir-report/category/iirCategoryInvestigateLevel';
import IIRReportCreationPage from 'views/iir-report/report/iirReportCreationPage';
import IIRReportDetaiPage from 'views/iir-report/report/iirReportDetailPage';
import IIRReportListPage from 'views/iir-report/report/iirReportListPage';

const iirRouters: RouteObject[] = [
    {
        path: Routers.iir.report.update,
        element: <IIRReportCreationPage />,
    },
    {
        path: Routers.iir.report.create,
        element: <IIRReportCreationPage />,
    },
    {
        path: Routers.iir.report.detail,
        element: <IIRReportDetaiPage />,
    },
    {
        path: Routers.iir.report.root,
        element: <IIRReportListPage />,
    },
    {
        path: Routers.iir.incidentType,
        element: <IIRCategoryIncidentTypePage />,
    },
    {
        path: Routers.iir.investigateLevel,
        element: <IIRCategoryInvestigateLevelPage />,
    },
];

export default iirRouters;
