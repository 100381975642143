import Translations from 'i18n/translation';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import { lang } from 'i18n/lang';
import usePromptInputText from 'components/Modal/PromptInputText';
import { SelectField, SelectOption } from 'components/FormControl';
import { DocGroupResponse } from 'api/v2/document/model/docGroup.model';
import legaldocumentGroupApi from 'api/v2/legal-document/legalDocumentGroupApi';
import { PaginationBase } from 'api/models/common';
import { LDGroupResponse } from 'api/v2/legal-document/legalDocument.models';
import Select, { SelectProps } from 'components/FormControl/Select';

export interface LegalDocGroupSelectProps extends SelectProps {
    isCanCreate?: boolean;
}

export function LegalDocGroupSelect(props: LegalDocGroupSelectProps) {
    const [options, setOptions] = useState<SelectOption[]>([]);
    const {
        field,
        fieldState: { error },
    } = useController({
        name: props.name,
        control: props.control,
    });
    const { openPromptInputText, PromptInputText } = usePromptInputText();

    const onAdd = async () => {
        const text: string = await openPromptInputText({
            title: lang.text(Translations.common.name),
            defaultValue: '',
        });
        if (text)
            legaldocumentGroupApi
                .create({ groupName: text, id: null })
                .then((id: string) => {
                    setOptions([...options, { value: id, label: text }]);
                })
                .catch((res) => {});
    };

    useEffect(() => {
        legaldocumentGroupApi
            .getByIndex({
                rowsPerPage: 1000,
                currentPage: 1,
            })
            .then((paginate: PaginationBase<LDGroupResponse>) => {
                setOptions(paginate.datas?.toSelectOptions('groupName', 'id') ?? []);
            })
            .catch((e) => {});
    }, []);
    return <Select {...props} label={lang.text(Translations.legalDocument.group)} options={options} />;
}

export default LegalDocGroupSelect;
