import equipmentApi from 'api/v2/equipmentApi';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useQueryParams from 'hooks/useQueryParams';
import EquipmenmtModifyForm from './equipmentForm.component';
import { EquipmentCreateRequest, EquipmentUpdateRequest } from 'api/models';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DateTimeField from 'components/FormControl/DateTimeField';

const EquipmentModifyPage = () => {
    const { t } = useTranslation();
    const { getQueryParam } = useQueryParams();
    const [equipmentDefault, setEquipmentDefault] = useState<any>(null);
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            equipmentApi
                .getById(id)
                .then((res) => {
                    setEquipmentDefault({ ...res });
                })
                .catch((n) => {});
        }
    }, []);
    const onSaveChange = (data: EquipmentCreateRequest) => {
        var equipment: EquipmentUpdateRequest = { ...data, id: id ?? '' };
        equipmentApi
            .update(equipment)
            .then((res) => {})
            .catch((e) => {});
    };

    return (
        <div>
            {/* <DateTimeField
                isShowTimePicker={false}
                readOnly={false}
                onChange={(e: any) => {}}
                defaultValue={new Date('2024-03-14T16:00:00+00:00')}
                name="fasd"
            /> */}
            {equipmentDefault && (
                <EquipmenmtModifyForm
                    readOnly={false}
                    equipment={equipmentDefault}
                    onSaveChange={onSaveChange}
                />
            )}
        </div>
    );
};

export default EquipmentModifyPage;
