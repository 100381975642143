import { yupResolver } from '@hookform/resolvers/yup';
import { InputField, TextAreaField } from 'components/FormControl';
import { ModalHookComponentProps } from 'components/ModalHook';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export interface TNConfirmSetpoinModel {
    point: number;
    comment?: string;
}
interface TNConfirmSetPointProps extends ModalHookComponentProps {}
const TNConfirmSetPointComponentModal = ({ onClose, onCompleted }: TNConfirmSetPointProps) => {
    const { control, handleSubmit, getValues } = useForm<{
        point: number;
        comment?: string;
    }>({
        resolver: yupResolver(
            yup.object().shape({
                point: yup.number().required(),
            })
        ),
    });

    const onSubmit = async () => {
        var form = getValues();
        let data: TNConfirmSetpoinModel = {
            ...form,
        };
        onCompleted(data);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputField type="number" max={100} min={0} name={'point'} control={control} label={lang.text(Translations.training.point)} required={true} />
            <TextAreaField rows={4} name={'comment'} control={control} label={lang.text(Translations.training.comment)} required={true} />
            <Modal.Footer>
                <Button variant="danger" onClick={onClose}>
                    {lang.text(Translations.modal.confirm.cancel)}
                </Button>
                <Button variant="primary" type="submit">
                    {lang.text(Translations.modal.confirm.ok)}
                </Button>
            </Modal.Footer>
        </form>
    );
};
export default TNConfirmSetPointComponentModal;
