import { Fragment, useState } from 'react';

import { InputField, RadioField, TextAreaField, UploadImageField } from 'components/FormControl';
import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { lang } from 'i18n/lang';
import { AreaSelect, CompanySelect } from 'components/Common';
import RiskLevelSelect from 'components/RiskLevelSelect';
import UserSelectField from 'components/Common/UserSelect';
import { RAControlMeasuresCreateRequest, RACreateRequest } from 'api/v2/risk-assessment/model/raRequest.model';
import raApi from 'api/v2/risk-assessment/raApi';
import { AddButton, SaveButton } from 'components/Button';
import { Accordion, Table } from 'react-bootstrap';
import DataListView from 'components/shared/DataListView';
import { toast } from 'react-toastify';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import RATagSelect from './tagSelect';
import { EntityConst } from 'constants/EntityConst';

const RACreatePage = () => {
    const { t } = useTranslation();

    const [companyId, setCompanyId] = useState<string>('');
    const [steps, setSteps] = useState<RAControlMeasuresCreateRequest[]>([]);

    const schema = yup.object().shape({
        areaId: yup.string().notRequired().nullable(),
        companyId: yup.string().required(),
        tagIdsRequest: yup.array().of(yup.string().required()),
        rAName: yup.string().required().max(EntityConst.shortString),
        steps: yup.array().notRequired(),
    });
    const {
        control,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const schemaStep = yup.object().shape({
        stepNumber: yup.number().required(),
        name: yup.string().required().max(EntityConst.short),
        content: yup.string().required().max(EntityConst.descriptionLong),
        pICId: yup.string().required(),
        levelId: yup.string().required(),
        hazards: yup.string().required().max(EntityConst.descriptionLong),
        tagIds: yup.array().of(yup.string().required()),
    });
    const {
        reset: resetStep,
        control: controlStep,
        getValues: getValuesStep,
        handleSubmit: handleSubmitStep,
        formState: { errors: errorsStep },
    } = useForm<RAControlMeasuresCreateRequest>({ resolver: yupResolver(schemaStep) });

    const SaveChangeAddStep = () => {
        if (steps.find((item) => item.stepNumber === getValuesStep().stepNumber)) {
            toast.error('Step number is exist');
            return;
        }
        var form = getValuesStep();
        var item: RAControlMeasuresCreateRequest = {
            ...form,
        };
        let newSteps = [...steps, item];
        setSteps(newSteps);
        resetStep({ stepNumber: parseInt(item.stepNumber.toString()) + 1, name: '', content: '', pICId: '', levelId: '', hazards: '' });
    };
    const SaveChange = () => {
        if (!steps.length || steps.length === 0) {
            toast.error('Nhập ít nhất một biện pháp kiểm soát');
            return;
        }
        // if ( confirm('Bạn có chắc chắn muốn tạo báo cáo?') === false) return;
        var form = getValues();
        var payload: RACreateRequest = {
            ...form,
            steps: steps,
        };
        raApi
            .create(payload)
            .then((res) => {
                console.log(res);
            })
            .catch(() => {});
    };
    const resetFormStep = (obj: RAControlMeasuresCreateRequest) => {
        resetStep(obj);
    };
    const editCurrentStep = (obj: RAControlMeasuresCreateRequest) => {
        var newSteps = steps.filter((item) => item.stepNumber !== obj.stepNumber);
        resetStep(obj);
        setSteps(newSteps);
    };

    const removeStep = (obj: RAControlMeasuresCreateRequest) => {
        var newSteps = steps.filter((item) => item.stepNumber !== obj.stepNumber);
        setSteps(newSteps);
    };

    return (
        <Fragment>
            <form onSubmit={handleSubmit(SaveChange)}>
                <Card>
                    <Card.Header className="d-flex justify-content-between">
                        <h4 className="card-title  ">
                            <span>Tạo báo cáo</span>
                        </h4>
                        <SaveButton />
                    </Card.Header>
                    <Card.Body>
                        <div className="row">
                            <InputField md={6} name={'rAName'} control={control} defaultValue={''} label={lang.text(Translations.common.name)} readOnly={false} />
                            <CompanySelect md={3} name="companyId" control={control} required={true} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                            <AreaSelect md={3} name="areaId" control={control} required={false} companyId={companyId} readOnly={false} />
                            <RATagSelect isCanCreate isMulti={true} control={control} name="tagIds" />
                        </div>
                    </Card.Body>
                </Card>
            </form>

            <div className="row">
                <div className="col-md-6">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <h5 className="card-title  ">
                                {lang.text(Translations.common.addNew)} {lang.text(Translations.ra.controlMeasure).toLocaleLowerCase()}
                            </h5>
                        </Card.Header>
                        <Card.Body>
                            <form onSubmit={handleSubmitStep(SaveChangeAddStep)}>
                                <div className="row">
                                    <InputField label="#" md={2} min={1} max={100} name="stepNumber" type="number" control={controlStep} required defaultValue={0} />
                                    <InputField md={10} label={lang.text(Translations.ra.actionName)} name="name" control={controlStep} required />
                                    <TextAreaField md={12} label={lang.text(Translations.ra.hazard)} name="hazards" control={controlStep} required />
                                    <RiskLevelSelect md={4} name="levelId" control={controlStep} required={true} readOnly={false} />
                                    <UserSelectField readOnly={false} label={t(Translations.common.pic)} name="pICId" control={controlStep} required={true} isMutiple={false} md={6} />
                                    <TextAreaField md={12} label={lang.text(Translations.ra.controlMeasure)} name="content" required control={controlStep} />
                                    <div className="d-flex justify-content-end">
                                        <AddButton isRender={true} type="submit" text={lang.text(Translations.common.addNew)} />
                                    </div>
                                </div>
                            </form>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-6">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <h5 className="card-title  ">{lang.text(Translations.ra.controlMeasure)}</h5>
                        </Card.Header>
                        <Card.Body>
                            <div className="row">
                                <Accordion defaultActiveKey="0">
                                    {steps.map((item, index) => (
                                        <Accordion.Item eventKey={index.toString()} className="">
                                            <Accordion.Header className="d-flex justify-content-between">
                                                <div className="d-flex w-100 justify-content-between px-2">
                                                    <span>
                                                        # {item.stepNumber} {item.name}
                                                    </span>
                                                    <ActionButtons2
                                                        id={item.stepNumber.toString()}
                                                        onDelete={() => {
                                                            removeStep(item);
                                                            return Promise.resolve();
                                                        }}
                                                        editAction={() => editCurrentStep(item)}
                                                    />
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {[
                                                    { label: lang.text(Translations.common.pic), value: item.pICId },
                                                    { label: lang.text(Translations.common.riskLevel), value: item.levelId },
                                                    { label: lang.text(Translations.ra.hazard), value: item.hazards },
                                                    { label: lang.text(Translations.ra.controlMeasure), value: item.content },
                                                ].map((item, index) => (
                                                    <div key={index} className="d-flex">
                                                        <b style={{ minWidth: '25%' }}>{item.label} </b>
                                                        <span style={{ minWidth: '75%' }}>{item.value}</span>
                                                        <hr />
                                                    </div>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </Fragment>
    );
};

export default RACreatePage;
