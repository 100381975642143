import { SIReportType } from './../../../enum/safetyInspection';
import { SIDashboardFilterRequest, SIDashboardResponse, SIReportDetailResponse, SIReportFilterRequest, SIReportRequest, SIReportResponse } from './model/siReport';
import client from '../client';
import { PaginationBase } from 'api/models/common';

const baseURL = '/siReport';
const siReportApi = {
    getById(id: string): Promise<SIReportDetailResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<SIReportDetailResponse>({
            endpoint: url,
        });
    },
    getPagination(filter: SIReportFilterRequest, isLoading: boolean = true): Promise<PaginationBase<SIReportResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<SIReportResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: SIReportRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: SIReportRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
    closeDaily(reportId: string, description: string): Promise<unknown> {
        const url = `${baseURL}/close-daily`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: { reportId: reportId, description: description },
        });
    },
    closeScheduled(reportId: string, groupId: string, description: string): Promise<unknown> {
        const url = `${baseURL}/close-scheduled`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: { reportId: reportId, description: description, groupId: groupId },
        });
    },
    getDashboard(filter: SIDashboardFilterRequest): Promise<SIDashboardResponse> {
        const url = `${baseURL}/dashboard`;
        return client.getNonWrap<SIDashboardResponse>({
            endpoint: url,
            data: filter,
        });
    },
    downloadReport(id: string, type: SIReportType): Promise<any> {
        const url = `${baseURL}/download/${id}`;
        return client.download({
            endpoint: url,
            fileName: `Báo cáo kiểm tra an toàn ${type == SIReportType.Daily ? 'hàng ngày' : 'định kỳ'}`,
        });
    },
};

export default siReportApi;
