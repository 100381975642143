import { Form } from 'react-bootstrap';
interface InputFieldSimpleProps {
    md?: 3 | 6 | 9 | 12 | 4;
    label: string;
    name?: string;
    placeholderProp?: string;
    readOnly?: boolean;
    required?: boolean;
    disabled?: boolean;
    value: string;
}
export default function ({ md, label, name, readOnly, required, disabled, value, placeholderProp }: InputFieldSimpleProps) {
    return (
        <div className={'col-md-' + md}>
            <Form.Group className={`mb-3`}>
                {label && (
                    <Form.Label htmlFor={name} className="">
                        {label} {required && <span className="text-danger">*</span>}
                    </Form.Label>
                )}
                <Form.Control type={'text'} placeholder={placeholderProp} name={name} disabled={disabled} value={value} readOnly={readOnly} />
            </Form.Group>
        </div>
    );
}
