import { LocationModel } from 'api/models/common/locationModel';
import locationApi from 'api/v2/location';
import { selectMyLocation, setMyLocation } from 'app/appSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React from 'react';

const useMyLocation = () => {
    const myLocation = useAppSelector(selectMyLocation);
    const dispatch = useAppDispatch();
    var fistTime = true;
    React.useEffect(() => {
        if (!!myLocation) return;
        if (fistTime && 'geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    locationApi
                        .parse({
                            lat: position.coords.latitude,
                            lon: position.coords.longitude,
                        })
                        .then((res: LocationModel) => {
                            fistTime = false;
                            dispatch(
                                setMyLocation({
                                    ...res,
                                    displayName: `${res?.displayName}`,
                                })
                            );
                        })
                        .catch(() => {});
                },
                (err) => {
                    console.log(err);
                },
                {
                    timeout: 5000,
                    enableHighAccuracy: true,
                }
            );
        } else {
            console.log('Geolocation is not available in your browser.');
        }
    }, [navigator]);

    return myLocation;
};

export default useMyLocation;
