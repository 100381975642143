import { CourseStatus } from 'enum/training';
import { Badge } from 'react-bootstrap';

export const TNCourseStatusBadge = ({ status }: { status: CourseStatus }) => {
    switch (status) {
        case CourseStatus.Waiting:
            return <Badge bg="warning">Pending</Badge>;

        case CourseStatus.Completed:
            return <Badge bg="success">Completed</Badge>;
        default:
            return <></>;
    }
};
