//react-bootstrap
import { Button, Spinner } from 'react-bootstrap';

//components
import { yupResolver } from '@hookform/resolvers/yup';
import configGoldenRuleApi from 'api/v2/configGoldenRuleApi';
import { selectLanguage } from 'app/appSlice';
import { useAppSelector } from 'app/hooks';
import { Save } from 'assets/icons/outlined';
import { InputField, SelectField, TextAreaField } from 'components/FormControl';
import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { useCustomer } from 'providers/contexts';
import { Suspense, lazy, memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { GoldenRuleCreateRequest } from 'api/models';
import { SaveButton } from 'components/Button';

const ConfirmModal = lazy(() => import('components/shared/ConfirmModal'));

const ConfigGoldenRulesCreatePage = memo(() => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        name: yup.string().required(t(Translations.form.validate.nameCannotEmpty)),
        content: yup.string(),
    });

    const {
        control,
        handleSubmit,
        formState: { isSubmitting },
        getValues,
        reset,
    } = useForm<{ name: string; content?: string }>({
        defaultValues: {
            name: '',
            content: '',
        },
        resolver: yupResolver(schema),
    });

    const onSubmitAdd = async () => {
        const values = getValues();
        const _addData: GoldenRuleCreateRequest = {
            name: values.name,
            content: values.content,
        };
        await configGoldenRuleApi.create(_addData);
        reset({
            name: '',
            content: '',
        });
    };
    return (
        <div>
            <Card>
                <Card.Header></Card.Header>
                <Card.Body>
                    <form className="row justify-content-center" onSubmit={handleSubmit(onSubmitAdd)}>
                        <div className="col-lg-8">
                            <InputField name="name" control={control} label={t(Translations.form.name)} placeholder="" />
                            <TextAreaField name="content" control={control} label={t(Translations.form.content)} placeholder="" />

                            <div className="d-flex justify-content-end">
                                <SaveButton type="submit" />
                            </div>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        </div>
    );
});

export default ConfigGoldenRulesCreatePage;
