import clientRequest from './client';
import { CustomerInfraResponse, OrganizationUnitCreateRequest, OrganizationUnitFilterRequest, OrganizationUnitResponse, OrganizationUnitUpdateRequest } from '../models/organization';
import { PaginationBase, PaginationBaseRequest } from '../models/common';
import { OrganizationUnitType } from '../enums/OrganizationUnitType';
import VerticalNav from 'components/builder/verticalNav';

const baseUrl = '/OrganizationUnit';
const orgUnitApi = {
    getByCurrentCustomer: async (paginate: PaginationBaseRequest, type: OrganizationUnitType, parentId?: string) => {
        var paginationRequest: OrganizationUnitFilterRequest = {
            rowsPerPage: paginate.rowsPerPage,
            currentPage: paginate.currentPage,
            unitType: type,
            parentId: parentId,
        };
        const url = `${baseUrl}/ByCurrentTenant`;
        return clientRequest.getNonWrap<PaginationBase<OrganizationUnitResponse>>({
            endpoint: url,
            data: paginationRequest,
        });
    },
    create(data: OrganizationUnitCreateRequest) {
        const url = `${baseUrl}/create`;
        return clientRequest.post<string>(url, data, true, true).catch((n) => {});
    },
    update(data: OrganizationUnitUpdateRequest) {
        const url = `${baseUrl}/update`;
        return clientRequest.post(url, data, true, true);
    },
    getById(id: string) {
        const url = `${baseUrl}/getbyid/${id}`;
        return clientRequest.getNonWrap<OrganizationUnitResponse>({ endpoint: url, isLoading: true });
    },
    delete(id: string) {
        const url = `${baseUrl}/delete/${id}`;
        return clientRequest.post(url);
    },
    toggleActive(id: string, isActive: boolean) {
        const url = `${baseUrl}/${id}/change-status/${isActive}`;
        return clientRequest.post(url, { id, isActive });
    },
    getPagination(filter: OrganizationUnitFilterRequest, isLoading: boolean = true): Promise<PaginationBase<OrganizationUnitResponse>> {
        const url = `${baseUrl}/GetByIndex`;
        return clientRequest.getNonWrap<PaginationBase<OrganizationUnitResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
};

export default orgUnitApi;
