import { defaultProfilePms, ProfilePms, TokenProfile } from 'api/v2/user/model';
import { SelectOption } from 'components/FormControl';
import { PMS } from 'router/const/roles';

export const equalsArrays = (array: (string | number)[], array2: (string | number)[]) => {
    return array.length == array2.length && array.every((val) => array2.includes(val));
};

export function generateRandomId() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return (s4() + s4() + '-' + s4() + '-4')?.toLowerCase();
}
export function generateGUID() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return (s4() + s4() + '-' + s4() + '-4' + s4().substr(0, 3) + '-' + s4() + '-' + s4() + s4() + s4())?.toLowerCase();
}
export function mappingFilePath(path: string) {
    return process.env.REACT_APP_FILE_SEVER_URL + path;
}
export function distinctArray(array: any[]) {
    return Array.from(new Set(array));
}
function getTimezoneOffset(isoString: Date | string) {
    // Create a Date object from the provided ISO string
    let date = new Date(isoString);

    // Get the timezone offset in minutes
    let offsetInMinutes = date.getTimezoneOffset();

    // Convert the offset from minutes to hours and adjust its sign
    let offsetInHours = -(offsetInMinutes / 60);

    return offsetInHours;
}
export const formatDateTime = (date: Date | null | undefined) => {
    if (date) {
        date = new Date(date);
        // Parse the input date string
        // Get the local timezone offset in minutes
        const localOffset = date.getTimezoneOffset() * 60000; // Convert to milliseconds

        // Desired timezone offset in milliseconds (UTC+7)
        const desiredOffset = 7 * 60 * 60000; // 7 hours * 60 minutes * 60000 milliseconds

        // Adjust the date to the desired timezone
        const adjustedDate = new Date(date.getTime() + localOffset + desiredOffset);

        // Extract the components of the adjusted date
        const day = String(adjustedDate.getDate()).padStart(2, '0');
        const month = String(adjustedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = adjustedDate.getFullYear();
        const hours = String(adjustedDate.getHours()).padStart(2, '0');
        const minutes = String(adjustedDate.getMinutes()).padStart(2, '0');

        // Format the date and time as "dd-MM-YYYY HH:ss"
        const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
        return formattedDate;
    }
    return '';
};
export const formatDate = (date: Date | null| undefined) => {
    if (date) {
        date = new Date(date);
        // Parse the input date string
        // Get the local timezone offset in minutes
        const localOffset = date.getTimezoneOffset() * 60000; // Convert to milliseconds

        // Desired timezone offset in milliseconds (UTC+7)
        const desiredOffset = 7 * 60 * 60000; // 7 hours * 60 minutes * 60000 milliseconds

        // Adjust the date to the desired timezone
        const adjustedDate = new Date(date.getTime() + localOffset + desiredOffset);

        // Extract the components of the adjusted date
        const day = String(adjustedDate.getDate()).padStart(2, '0');
        const month = String(adjustedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = adjustedDate.getFullYear();

        // Format the date as "dd-MM-yyyy"
        const formattedDate = `${day}-${month}-${year}`;

        return formattedDate;
    }
    return '';
};
Date.prototype.toDateString = function () {
    if (this) {
        const year = this.getFullYear().toString().padStart(4, '0');
        const month = (this.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const day = this.getDate().toString().padStart(2, '0');

        return `${day}-${month}-${year}`;
    }
    return '';
};
export const convertToSelect2Option = (props: { datas: any; labelProp: string; valueProp: string }) => {
    return (
        props.datas?.map((n: any) => {
            let dt: SelectOption = {
                label: n[props.labelProp],
                value: n[props.valueProp],
            };
            return dt;
        }) ?? []
    );
};

export const parseTokenJwt = (token: string) => {
    if (!token) return null;
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );
    var basePathClaim = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/';
    var auth = JSON.parse(jsonPayload);
    var expriredDate = auth['exp'];
    if (Math.floor(new Date().getTime() / 1000) > expriredDate) return null;
    const roles = (auth['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] as string[]) ?? [];
    const pms = roles.map((role) => PMS[role as keyof typeof PMS]);
    let isSupperAdmin = auth.ad?.toLowerCase() === 'true' ? true : false;
    let allowPms = getPmsProfile(pms, isSupperAdmin);
    var profileToken: TokenProfile = {
        isSuperAdmin: isSupperAdmin,
        fullName: auth.full_name,
        roles: pms,
        userId: auth[basePathClaim + 'nameidentifier'],
        phoneNumber: auth[basePathClaim + 'mobilephone'],
        email: auth[basePathClaim + 'emailaddress'],
        isCusomterAdmin: auth.is_cus_ad?.toLowerCase() === 'true' ? true : false,
        roleId: auth.roleId,
        tenantId: auth.tenant,
        pms: allowPms,
    };

    return profileToken;
};
const getPmsProfile = (pmsOfUser: PMS[], isSuperAdmin: boolean) => {
    if (!isSuperAdmin) {
        let roles = pmsOfUser;
        let rs: ProfilePms = {
            supperAdmin: includeRole([PMS.SUPPER_ADMIN], roles),
            ldAdmin: includeRole([PMS.LD_AD], roles),
            ldRead: includeRole([PMS.LD_R, PMS.LD_AD], roles),
            ldTagCreate: includeRole([PMS.LD_R], roles),

            roleAdmin: includeRole([PMS.ROLE_AD], roles),
            roleView: includeRole([PMS.ROLE_AD], roles),

            orgAdmin: includeRole([PMS.ORG_UNIT_AD], roles),
            tenantAdmin: includeRole([PMS.SUPPER_ADMIN], roles),
            user: {
                admin: includeRole([PMS.USER_AD], roles),
                readOnly: includeRole([PMS.USER_AD, PMS.U_READ], roles),
            },
            equipment: {
                admin: includeRole([PMS.EQ_AD], roles),
                readOnly: includeRole([PMS.EQ_AD, PMS.EQ_V], roles),
            },
            trip: {
                admin: includeRole([PMS.TRIP_ADMIN], roles),
                driver: includeRole([PMS.TRIP_DRIVER, PMS.TRIP_ADMIN], roles),
            },
            training: {
                admin: includeRole([PMS.TN_ADMIN], roles),
                student: includeRole([PMS.TN_SP, PMS.TN_ADMIN], roles),
            },
            iir: {
                admin: includeRole([PMS.IIR_AD], roles),
            },
        };
        return rs;
    } else {
        return defaultProfilePms(isSuperAdmin);
    }
};

function includeRole(pms: PMS[], userPms: PMS[]) {
    return userPms.any(pms);
}

export const encodeBase64 = (str: string) => {
    return btoa(str);
};
export const decodeBase64 = (str: string) => {
    return atob(str);
};

export function isMobileDevide() {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Regular expression for common mobile user agents
    const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

    // Touch capability detection
    const isTouchCapable = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    // Check if user agent matches mobile regex or device is touch capable and screen width is less than 768px
    return mobileRegex.test(userAgent) || (isTouchCapable && window.innerWidth <= 768);
}
