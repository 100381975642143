const ArrowRight = ({ className = 'icon-24' }: { className?: string }) => {
  return (
    <i className="icon">
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path
          d="M19.75 11.7256L4.75 11.7256"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M13.7002 5.70124L19.7502 11.7252L13.7002 17.7502"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    </i>
  );
};

export default ArrowRight;
