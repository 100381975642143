import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

export interface PaginationChange {
  selectedPage: number;
  itemsPerPage: number;
}
export interface PaginationComponentProps {
  onPageChange: (pagination: PaginationChange) => void;
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
}

export default function PaginationComponent({
  totalItems,
  itemsPerPage = 10,
  currentPage = 1,
  onPageChange,
}: PaginationComponentProps) {
  const [pageCount, setPageCount] = useState(+currentPage);

  useEffect(() => {
    setPageCount(Math.ceil(totalItems / itemsPerPage));
  }, [totalItems, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const selectedPage = event.selected + 1;

    onPageChange({ selectedPage, itemsPerPage });
  };

  return (
    <ReactPaginate
      initialPage={currentPage - 1}
      breakLabel="..."
      nextLabel="Next"
      onPageChange={handlePageClick}
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
      pageCount={pageCount}
      previousLabel="Previous"
      renderOnZeroPageCount={null}
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item previous"
      previousLinkClassName="page-link previous-link"
      nextClassName="page-item next"
      nextLinkClassName="page-link next-link"
      breakClassName="page-item break"
      breakLinkClassName="page-link break-link"
      containerClassName="pagination"
      activeClassName="active"
    />
  );
}
