import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Card from 'components/shared/Card';
import { useNavigate } from 'react-router-dom';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { PaginationTable } from 'components/shared/PaginationTable';
import { AddButton } from 'components/Button';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import Routers from 'router/const/routers';
import Translations from 'i18n/translation';
import { lang } from 'i18n/lang';
import { useForm } from 'react-hook-form';
import { InputField, SelectField } from 'components/FormControl';
import { Row } from 'react-bootstrap';
import { FilterButton } from 'components/Button/FilterButton';
import { useQuery } from 'hooks/useQuery';
import { formatDateTime } from 'helper';
import { IIRReportFilterRequest, IIRReportResponse } from 'api/v2/iir/model/iirReport.model';
import { IIRReportStatus } from 'api/enums/IIR';
import { AreaSelect, CompanySelect } from 'components/Common';
import IIRCategoryIncidentLevelSelect from '../category/component/iirCategoryIncidentLevelSelect.component';
import IIRCategoryIncidentTypeSelect from '../category/component/iirCategoryIncidentTypeSelect.Component';
import iirReportApi from 'api/v2/iir/iirReportApi';

const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);

// eslint-disable-next-line react-hooks/exhaustive-deps
const IIRReportListPage = () => {
    const [paginationData, setPaginationData] = useState<PaginationBase<IIRReportResponse>>();
    const navigate = useNavigate();

    const { query, setQuery } = useQuery();

    // useEffect(() => {
    //     handleFetchData(defaultPaginationRequest);
    // }, []);

    const reportStatus = useMemo(() => {
        return [
            { value: IIRReportStatus.Created, label: lang.text(Translations.iir.status.waitInvestigator) },
            { value: IIRReportStatus.BossAgreed, label: lang.text(Translations.training.status.completed) },
        ];
    }, []);

    const cols = useMemo(
        () => [
            {
                field: 'reportName',
                title: lang.text(Translations.iir.reportName),
            },
            {
                field: 'incidentTypeName',
                title: lang.text(Translations.iir.incidentType),
            },
            {
                field: 'levelName',
                title: lang.text(Translations.iir.incidentLevel),
            },
            {
                field: 'companyName',
                title: lang.text(Translations.form.site.name),
            },
            {
                field: 'areaName',
                title: lang.text(Translations.form.area.name),
            },
            {
                field: 'status',
                title: lang.text(Translations.common.status),
            },
            {
                field: 'createdTime',
                title: lang.text(Translations.common.createdTime),
            },
            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );

    const { control, handleSubmit, getValues, reset, formState, watch } = useForm<IIRReportFilterRequest>();
    useEffect(() => {
        handleFetchData(query as IIRReportFilterRequest);
    }, [query]);

    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        iirReportApi
            .getPagination({
                ...query,
                ...pagination,
            })
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                createdTime: formatDateTime(n.createdTime),
                                // status: <TNCourseStatusBadge status={n.status} />,
                                action: <ActionButtons2 id={n.id} onViewDetail={() => navigate(Routers.iir.report.detail.replace(':id', n.id))} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };

    return (
        <Fragment>
            <Card>
                <Card.Header className="d-block">
                    <form
                        className="w-100"
                        onSubmit={handleSubmit((datas) => {
                            setQuery({
                                ...datas,
                                ...defaultPaginationRequest,
                            });
                        })}
                    >
                        <Row>
                            <CompanySelect md={3} control={control} name="companyId" />
                            <AreaSelect md={3} control={control} name="areaId" companyId={watch('companyId')} />
                            <IIRCategoryIncidentLevelSelect md={3} control={control} name="incidenLevelId" companyId={watch('companyId')} />
                            <IIRCategoryIncidentTypeSelect md={3} control={control} name="incidentTypeId" companyId={watch('companyId')} />
                            <SelectField label={lang.text(Translations.common.status)} required={false} options={reportStatus} name="status" control={control} unSelectValue={''} md={3} />
                            <div className="col-md-9 align-self-center">
                                <div className="d-flex justify-content-end">
                                    <AddButton isRender={true} onClick={() => navigate(`${Routers.trainingOnline.courseCreate}`)} />
                                    <FilterButton type="submit" className="mx-2" />
                                </div>
                            </div>
                        </Row>
                    </form>
                </Card.Header>
                <Card.Body>
                    <PaginationTable
                        onPaginate={(pag) => {
                            setQuery({
                                ...query,
                                ...pag,
                            });
                        }}
                        columns={cols}
                        pagination={paginationData}
                        isStriped={true}
                        disableSearch={true}
                        wrapperClass="mt-5"
                    />
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default IIRReportListPage;
