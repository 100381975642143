//react-bootstrap
import { Accordion, Button, Form } from 'react-bootstrap';

//components
import { yupResolver } from '@hookform/resolvers/yup';
// import dashboarApi from 'api/dashboarApi';
import { DashboardItemResponse } from 'api/models/organization';
import { selectLanguage } from 'app/appSlice';
import { useAppSelector } from 'app/hooks';
import { Pencil } from 'assets/icons/solid';
import { SelectField } from 'components/FormControl';
import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { useCustomer } from 'providers/contexts';
import { memo, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const ConfigPage = memo(() => {
    const { t } = useTranslation();
    const { customers } = useCustomer();
    const selectedLanguage: string = useAppSelector(selectLanguage) as string;
    const [data, setData] = useState<DashboardItemResponse[]>([]);

    const schema = yup.object().shape({
        customerId: yup.string(),
    });

    const { control, watch } = useForm<{
        customerId?: string;
    }>({
        defaultValues: {
            customerId: customers[0].id,
        },
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        handleFetchData(customers[0].id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFetchData = (comId: string) => {
        // getData(comId).then(({ result }: { result: DashboardItemResponse[] }) => {
        //     setData(result);
        // });
    };

    const customerOptions = customers.map((item) => ({ label: item.name, value: item.id }));

    const tableData = useMemo(
        () => [
            {
                title: t(Translations.MENUSor),
                reportType: 1,
            },
            {
                title: t(Translations.ReportEquipment),
                reportType: 2,
            },
            {
                title: t(Translations.MENUPpr),
                reportType: 4,
            },
            {
                title: t(Translations.KPIS),
                reportType: 6,
            },
        ],
        [selectedLanguage]
    );

    const handleUpdateStatus = (id: string, active: boolean) => {
        setData((prev) =>
            prev.map((item) => {
                if (item.id === id) {
                    return { ...item, isActive: active };
                }
                return item;
            })
        );
    };

    const handleUpdateData = () => {
        // updateData(watch().customerId, data);
    };

    return (
        <div>
            <Card>
                <Card.Header>
                    <div className="text-end w-100 d-flex justify-content-between align-items-center">
                        <h2 className="mb-0">{t(Translations.sidebar.config.dashboard)}</h2>
                    </div>
                </Card.Header>
                <Card.Body>
                    <form className="row">
                        {/* <SelectField
                            options={customerOptions}
                            formGroupClasses="col-md-6"
                            control={control}
                            label={t(Translations.form.customer.name)}
                            name="customerId"
                            onChange={(value) => handleFetchData(value)}
                        /> */}
                    </form>

                    <Accordion>
                        {tableData.map((item, index) => (
                            <Accordion.Item eventKey={index.toString()} key={index}>
                                <Accordion.Header>{item.title}</Accordion.Header>
                                <Accordion.Body>
                                    <Card.Body className="p-0">
                                        {data
                                            .filter((it) => it.dashboardReportType === item.reportType)
                                            .map((row, rowIndex) => (
                                                <Form.Check className="d-block form-group" key={rowIndex}>
                                                    <Form.Check.Input
                                                        className="me-2"
                                                        type="checkbox"
                                                        id={row.id}
                                                        onClick={() => handleUpdateStatus(row.id, !row.isActive)}
                                                        defaultChecked={row.isActive ? true : false}
                                                    />
                                                    <Form.Check.Label htmlFor="flexCheckChecked">{row.name}</Form.Check.Label>
                                                </Form.Check>
                                            ))}
                                    </Card.Body>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>

                    <div className="text-center">
                        <Button className="mt-5" onClick={handleUpdateData}>
                            <Pencil /> {t(Translations.button.update)}
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
});

export default ConfigPage;
