export interface PaginationBase<T = any> {
    rowsPerPage: number;
    totalItems: number;
    totalPage: number;
    currentPage: number;
    datas?: T[];
}

export interface PaginationBaseRequest {
    rowsPerPage: number;
    currentPage: number;
    keySearch?: string | null | undefined; // use only in pagination table
    filter?: any;
}

export const defaultPaginationRequest: PaginationBaseRequest = {
    rowsPerPage: 10,
    currentPage: 1,
};

export const defaultPagination: PaginationBase<any> = {
    rowsPerPage: 10,
    currentPage: 1,
    datas: [],
    totalItems: 0,
    totalPage: 0,
};
