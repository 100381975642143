import { ChangePasswordRequest } from 'api/v2/user/model';
import ChangePasswordForm from './changePasswordForm';
import authApi from 'api/v2/authApi';

export default function ChangePasswordPage() {
    const initialValues: ChangePasswordRequest = {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    };

    const handleChangePasswordFormSubmit = (formValues: ChangePasswordRequest) => {
        const data = {
            oldPassword: formValues.oldPassword,
            newPassword: formValues.newPassword,
            confirmNewPassword: formValues.newPassword,
        };

        authApi
            .changepass(data)
            .then(() => {})
            .catch((error) => {});
    };

    return (
        <>
            <ChangePasswordForm initialValues={initialValues} onSubmit={handleChangePasswordFormSubmit} />
        </>
    );
}
