export enum PMS {
    TRIP_ADMIN = 'TRIP_ADMIN',
    TRIP_DRIVER = 'TRIP_DRIVER',
    ORG_UNIT_AD = 'ORG_UNIT_AD',
    ORG_UNIT_READ = 'ORG_UNIT_READ',
    CON_CR = 'CON_CR',
    G_CR = 'G_CR',
    SUPPER_ADMIN = 'SUPPER_ADMIN',
    SOR_AD = 'SOR_AD',
    SOR_USER = 'SOR_USER',
    SOR_SYNTHESIS = 'SOR_SYNTHESIS',
    USER_AD = 'USER_AD',
    U_READ = 'U_READ',
    ROLE_AD = 'ROLE_AD',
    SI_DL_AD = 'SI_DL_AD',
    SI_DL_USER = 'SI_DL_USER',
    SI_DL_R_S = 'SI_DL_R_S',
    SI_DL_T_AD = 'SI_DL_T_AD',
    SI_S_AD = 'SI_S_AD',
    SI_S_USER = 'SI_S_USER',
    SI_S_S = 'SI_S_S',
    SI_S_T_AD = 'SI_S_T_AD',
    KPI_AD = 'KPI_AD',
    KPI_USER = 'KPI_USER',
    EQ_AD = 'EQ_AD',
    EQ_V = 'EQ_V',
    EQ_RP_D_AD = 'EQ_RP_D_AD',
    EQ_RP_D_USER = 'EQ_RP_D_USER',
    EQ_RP_S_AD = 'EQ_RP_S_AD',
    EQ_RP_S_USER = 'EQ_RP_S_USER',
    IIR_AD = 'IIR_AD',
    IIR_BOSS = 'IIR_BOSS',
    IIR_INVESTGATOR = 'IIR_INVESTGATOR',
    IIR_USER = 'IIR_USER',
    PPR_AD = 'PPR_AD',
    PPR_USER = 'PPR_USER',
    PPR_C_M = 'PPR_C_M',
    DOC_U = 'DOC_U',
    DOC_V = 'DOC_V',
    SKPI_AD = 'SKPI_AD',
    SKPI_USER = 'SKPI_USER',
    SKPI_A = 'SKPI_A', // Approved
    TN_ADMIN = 'TN_ADMIN',
    TN_SP = 'TN_SP',
    RA_ADMIN = 'RA_ADMIN',
    RA_USER = 'RA_USER',
    MESS_C = 'MESS_C',
    SEC_ADMIN = 'SEC_ADMIN',
    SEC_CHECK_IN_OUT = 'SEC_CHECK_IN_OUT',
    SEC_REPORT = 'SEC_REPORT',
    LD_AD = 'LD_AD',
    LD_R = 'LD_R',
}

const Roles = {
    editor: [PMS.ORG_UNIT_AD, PMS.ORG_UNIT_READ, PMS.G_CR, PMS.CON_CR],
    sec: {
        admin: [PMS.SEC_ADMIN],
        security: [PMS.SEC_ADMIN, PMS.SEC_CHECK_IN_OUT],
    },
    customer: {
        view: [PMS.SUPPER_ADMIN],
        crud: [PMS.SUPPER_ADMIN],
    },
    org_unit: {
        crud: [PMS.ORG_UNIT_AD],
        view: [PMS.ORG_UNIT_READ],
    },
    // Area
    area: {
        crud: [PMS.ORG_UNIT_AD],
        view: [PMS.ORG_UNIT_READ, PMS.ORG_UNIT_AD],
    },

    // Factory
    factory: {
        crud: [PMS.ORG_UNIT_AD],
        view: [PMS.ORG_UNIT_READ],
    },

    // SOR report
    sor: {
        create: [PMS.SOR_AD, PMS.SOR_USER],
        view: [PMS.SOR_AD, PMS.SOR_USER],
        synthesis_view: [PMS.SOR_SYNTHESIS],
    },

    // contractor
    contractor: {
        crud: [PMS.CON_CR],
        view: [PMS.CON_CR],
    },

    // golden rules
    golden_rules: {
        crud: [PMS.G_CR],
        view: [PMS.G_CR],
    },

    // risk level
    risk_level: {
        crud: [PMS.RA_ADMIN],
        view: [PMS.RA_ADMIN, PMS.ORG_UNIT_READ],
    },

    // safety daily report
    si: {
        create: [PMS.SI_DL_AD, PMS.SI_DL_USER],
        view: [PMS.SI_DL_AD, PMS.SI_DL_USER, PMS.SI_DL_R_S],
        view_synthensis: [PMS.SI_DL_AD, PMS.SI_DL_R_S],
        crud_template: [PMS.SI_DL_AD],
        view_template: [PMS.SI_DL_AD, PMS.SI_DL_USER],
    },

    // SOR safety periodically report
    safety_periodically_report: {
        create: [PMS.SOR_AD, PMS.SOR_USER],
        view: [PMS.SOR_AD, PMS.SOR_USER, PMS.SOR_SYNTHESIS],
        view_synthensis: [PMS.SOR_AD, PMS.SOR_SYNTHESIS],
        crud_template: [PMS.SOR_AD],
        view_template: [PMS.SOR_AD, PMS.SOR_USER],
    },

    //account management
    account_management: {
        crud: [PMS.USER_AD],
        view: [PMS.U_READ],
    },
    //account management
    role: {
        admin: [PMS.ROLE_AD],
    },

    // kpi report
    kpi_report: {
        crud_template: [PMS.KPI_AD],
        crud_report: [PMS.KPI_AD, PMS.KPI_USER],
        view_report: [PMS.KPI_AD, PMS.KPI_USER],
    },

    // equipment management
    equipment: {
        crud: [PMS.EQ_AD],
        view: [PMS.EQ_AD, PMS.EQ_V],
    },

    // equipment report management
    equipment_report: {
        create_daily: [PMS.EQ_RP_D_AD, PMS.EQ_RP_D_USER],
        create_periodically: [PMS.EQ_RP_S_AD, PMS.EQ_RP_S_USER],
        view_daily: [PMS.EQ_RP_D_AD, PMS.EQ_RP_D_USER],
        view_periodically: [PMS.EQ_RP_S_AD, PMS.EQ_RP_S_USER],
        templateAdmin: [PMS.EQ_RP_D_AD, PMS.EQ_RP_S_AD],
        templateView: [PMS.EQ_RP_D_AD, PMS.EQ_RP_S_AD, PMS.EQ_RP_D_USER, PMS.EQ_RP_S_USER],
    },

    //IIR report
    iir_report: {
        crud_category: [PMS.IIR_AD],
        report_modify_boss: [PMS.IIR_BOSS],
        report_modify_investigator: [PMS.IIR_INVESTGATOR],
        report_view: [PMS.IIR_AD, PMS.IIR_BOSS, PMS.IIR_INVESTGATOR, PMS.IIR_USER],
        report_modify: [PMS.IIR_AD, PMS.IIR_USER],
    },

    //PPR report
    ppr_report: {
        crud_report: [PMS.PPR_AD, PMS.PPR_USER],
        view_report: [PMS.PPR_AD, PMS.PPR_USER],
        rud_category: [PMS.PPR_AD],
        view_category: [PMS.PPR_AD, PMS.PPR_USER],
    },

    //document
    document: {
        upload: PMS.DOC_U,
        view: [PMS.DOC_V, PMS.DOC_U],
    },
    legalDocument: {
        admin: [PMS.DOC_U],
        view: [PMS.DOC_V, PMS.DOC_U],
    },
    //skpi -- ppr
    skpi: {
        create: [PMS.SKPI_AD, PMS.SKPI_USER],
        view: [PMS.SKPI_AD, PMS.SKPI_USER],
        approve: [PMS.SKPI_AD, PMS.SKPI_A],
    },

    // training
    training: {
        admin: [PMS.TN_ADMIN],
        sp: [PMS.TN_SP,PMS.TN_ADMIN],
        student: [],
    },

    // risk assessment
    risk_assessment: {
        crud: [PMS.RA_ADMIN, PMS.RA_USER],
        view: [PMS.RA_ADMIN, PMS.RA_USER],
    },

    // message
    message: {
        crud: [PMS.MESS_C],
    },
    //Trip
    trip: {
        report: [PMS.TRIP_ADMIN],
        create: [PMS.TRIP_DRIVER,PMS.TRIP_ADMIN],
        view: [PMS.TRIP_DRIVER,PMS.TRIP_ADMIN],
    },
};

export default Roles;
