import { RouteObject } from 'react-router-dom';

import Routers from 'router/const/routers';
import {
    SICreatePage,
    SIListPage
} from 'views/safety-inspections';
import SITemplateListPage from 'views/safety-inspections/templates';
import SIReportDashboard from 'views/safety-inspections/reports/dashboard';

const safetyInspectionsRouters: RouteObject[] = [
    {
        path: Routers.safetyInspections.template.root,
        element: <SITemplateListPage />,
    },

    {
        path: Routers.safetyInspections.report.create,
        element: <SICreatePage />,
    },
    {
        path: Routers.safetyInspections.report.root,
        element: <SIListPage />,
    },
    {
        path: Routers.safetyInspections.report.dashboard,
        element: <SIReportDashboard />,
    },
    // {
    //   path: Routers.safetyInspections.daily.group,
    //   element: <SafetyInspectionsGroupPage />,
    // },
    // {
    //   path: Routers.safetyInspections.daily.synthesis,
    //   element: <SafetyInspectionsSynthesisPage />,
    // },
    // {
    //   path: Routers.safetyInspections.daily.unapprovedReport,
    //   element: <SafetyInspectionsUnapprovePage />,
    // },

    // // periodically
    // {
    //   path: Routers.safetyInspections.periodically.create,
    //   element: <SafetyInspectionsCreatePage />,
    // },
    // {
    //   path: Routers.safetyInspections.periodically.detail,
    //   element: <SafetyInspectionsDetailPage />,
    // },
    // {
    //   path: Routers.safetyInspections.periodically.group,
    //   element: <SafetyInspectionsGroupPage />,
    // },
    // {
    //   path: Routers.safetyInspections.periodically.synthesis,
    //   element: <SafetyInspectionsSynthesisPage />,
    // },
    // {
    //   path: Routers.safetyInspections.periodically.unapprovedReport,
    //   element: <SafetyInspectionsUnapprovePage />,
    // },
];

export default safetyInspectionsRouters;
