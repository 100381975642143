import StorageKeys from 'constants/storageKeys';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { ResponseApi } from '../models';
import qs from 'qs';
import CommonConstants from 'constants/common';
import { Store } from '@reduxjs/toolkit';
import { loadingPageAction, setCustomer, setIsLoggin } from 'app/appSlice';
import { toast } from 'react-toastify';
let store: Store;
export const injectStoreFileClient = (_store: any) => {
    store = _store;
};

// import { createStore } from 'redux';
// import rootReducer from './reducers'; // import your root reducer

// // Create the store
// const store = createStore(rootReducer);

// // Dispatch an action
// store.dispatch({ type: 'YOUR_ACTION_TYPE', payload: 'your payload' });

export class ClientFileRequest {
    private axiosInstance: AxiosInstance;

    constructor() {
        this.axiosInstance = axios.create({
            baseURL: `${process.env.REACT_APP_FILE_SEVER_URL}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': localStorage.getItem(StorageKeys.language) || 'vi',
                Flatformtype: 'WEB',
            },
        });

        this.axiosInstance.defaults.timeout = 500000;

        this.axiosInstance.interceptors.request.use(
            (config) => {
                config.headers['Authorization'] = `Bearer ${localStorage.getItem(StorageKeys.token)}`;
                config.headers['tenant'] = store.getState().app.customer?.id;

                return config;
            },
            (error) => Promise.reject(error)
        );
        this.axiosInstance.interceptors.response.use(
            function (response) {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response.data;
            },
            function (error: AxiosError) {
                console.log('error', error);
                let errorMessage = '';
                const status = error.response?.status;
                if (status === 500 || status === 400) {
                    errorMessage = (error.response?.data as any).title ?? (error.response?.data as string);
                } else if (status === 401) {
                    // console.log('call logut');
                    // localStorage.removeItem(StorageKeys.token);
                    // localStorage.removeItem(StorageKeys.customer);
                    // store.dispatch(setIsLoggin(false));
                    // store.dispatch(setCustomer());
                    // window.location.href = '/sign-in?url=' + window.location.pathname;
                    errorMessage = CommonConstants.UnAuthorizationMessage;
                } else if (status === 403) {
                    errorMessage = 'Bạn không có quyền truy cập vào resource này!' + error.request.responseURL;
                } else if (status === 404) {
                    errorMessage = 'Không tìm thấy resource yêu cầu ' + error.request.responseURL;
                }
                errorMessage && toast.error(errorMessage);
                return Promise.reject(errorMessage);
            }
        );
    }

    async download(endpoint: string, fileName: string, params?: Record<string, any>, isShowError?: boolean | null | undefined, isLoading?: boolean | null | undefined): Promise<Blob> {
        try {
            if (isLoading) {
                store.dispatch(loadingPageAction(true));
            }
            const response: Blob = await this.axiosInstance.get(endpoint, {
                params,
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            return response;
        } catch (errorMessage) {
            if (isShowError) {
                toast.error('Có lỗi xảy ra, vui lòng thử lại sau!' + errorMessage ?? '');
            }
            return Promise.reject(errorMessage);
        } finally {
            if (isLoading === true) {
                store.dispatch(loadingPageAction(false));
            }
        }
    }
    async upload<T>(endpoint: string, data: FormData): Promise<T> {
        try {
            store.dispatch(loadingPageAction(true));
            const response: T = await this.axiosInstance.post(endpoint, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            var dataResponse = response as T;
            store.dispatch(loadingPageAction(false));
            return dataResponse;
        } catch (errorMessage) {
            store.dispatch(loadingPageAction(false));
            return Promise.reject(errorMessage);
        }
    }
}

export default new ClientFileRequest();
