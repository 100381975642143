import { RouteObject } from 'react-router-dom';

import Routers from 'router/const/routers';
import { HotlinePage } from 'views/support';

const supportRouters: RouteObject[] = [
  {
    path: Routers.hotline.root,
    element: <HotlinePage />,
  },
];

export default supportRouters;
