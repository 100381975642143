import equipmentApi from 'api/v2/equipmentApi';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useQueryParams from 'hooks/useQueryParams';
import EquipmenmtModifyForm from './equipmentForm.component';
import { EquipmentCreateRequest } from 'api/models';

const EquipmentCreatePage = () => {
    const { t } = useTranslation();

    const [equipmentDefault, setEquipmentDefault] = useState<any>(null);
    const onSaveChange = (modelRequest: EquipmentCreateRequest) => {
        equipmentApi
            .create(modelRequest)
            .then((res) => {
                const defaultEquipmentCreateRequest: EquipmentCreateRequest = {
                    companyId: '',
                    equipmentCode: '',
                    equipmentName: '',
                    yearOfManuf: 0,
                    serialNo: '',
                    inspectedDate: new Date(),
                    inspectionExpriredDate: new Date(),
                    brand: '',
                    specifications: '',
                };
                setEquipmentDefault(defaultEquipmentCreateRequest);
            })
            .catch((e) => {});
    };
    return (
        <EquipmenmtModifyForm
            readOnly={false}
            equipment={equipmentDefault}
            onSaveChange={onSaveChange}
        />
    );
};

export default EquipmentCreatePage;
