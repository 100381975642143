import { OrganizationUnitType } from 'api/enums/OrganizationUnitType';
import _ from 'lodash';
import Multiselect from 'multiselect-react-dropdown';
import { useCustomer } from 'providers/contexts';
import React, { Fragment, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Control, useController } from 'react-hook-form';

interface OrganizationOption {
    id: string;
    groupCompany: string;
    name: string;
    parentId: string;
    type: OrganizationUnitType;
}
interface OrganizationMultiSelectProps {
    onSelected?: (orgIds: string[]) => void;
    control?: Control<any>;
    name: string;
    isSingleCompany: boolean;
    isSingleArea: boolean;
    defaultValues?: string[];
    isShowAreaSelect?: boolean;
    errorMessage?: string;
    requiredCompany: boolean;
    requiredArea?: boolean;
    md?: 3 | 4 | 6 | 12;
}
/**
 * example: 
 *  <OrganizationUnitMultiSelect
                                    name="organizationUnits"
                                    control={control}
                                    // defaultValues={defaultOrganizations}
                                    isSingle={false}
                                    isShowAreaSelect={true}
                                    requiredCompany={true}
                                    requiredArea={true}
                                />
 * @param param0 
 * @returns 
 */
const OrganizationUnitMultiSelect: React.FC<OrganizationMultiSelectProps> = ({
    name,
    control,
    onSelected,
    isSingleCompany,
    isSingleArea,
    defaultValues,
    isShowAreaSelect,
    errorMessage,
    requiredCompany,
    requiredArea,
    md = 3,
}) => {
    const {
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
        formState: { errors },
    } = useController({
        name,
        control,
        defaultValue: [],
    });
    const { currentCustomer } = useCustomer();
    const [organizationOptions, setOrganizationOptions] = useState<OrganizationOption[]>([]);
    const [selectedOrganizationOptions, setSelectedOrganizationOptions] = useState<OrganizationOption[]>([]);
    useEffect(() => {
        let orgs: OrganizationOption[] = [];
        currentCustomer?.companies.map((company) => {
            company.areas?.map((area) => {
                orgs.push({
                    id: area.id,
                    parentId: company.id,
                    name: area.name,
                    groupCompany: company.name,
                    type: OrganizationUnitType.Area,
                });
            });
            orgs.push({
                id: company.id,
                name: company.name,
                parentId: '',
                groupCompany: '',
                type: OrganizationUnitType.Company,
            });
        });
        setOrganizationOptions(orgs);
    }, []);

    useEffect(() => {
        var selecteds = selectedOrganizationOptions.map((n) => n.id);
        defaultValues = defaultValues ?? [];

        if (!defaultValues.hasValue() || defaultValues.equals(selecteds)) return;
        let orgs = organizationOptions?.filter((n) => _.some(defaultValues ?? [], (id) => id == n.id && n.type));
        if (orgs.hasValue()) {
            onSelectedChagne(orgs);
        }
    }, [defaultValues]);

    useEffect(() => {
        var orgIds = selectedOrganizationOptions.map((a) => a.id);
        var values: string[] = Array.isArray(value) ? value : [value];
        if (!values.hasValue() && orgIds.hasValue()) {
            setSelectedOrganizationOptions([]);
        } else if (!values.equals(orgIds)) {
            let newSelected = organizationOptions.filter((n) => values.includes(n.id));
            onSelectedChagne(newSelected);
        }
    }, [value]);

    const onCompanyChange = (coms: OrganizationOption[]) => {
        let vals = selectedOrganizationOptions.filter((n) => n.type == OrganizationUnitType.Area && coms.find((x) => x.id == n.parentId));
        let newSelected = [...coms, ...vals];
        onSelectedChagne(newSelected);
    };

    const onAreaChange = (areas: OrganizationOption[], selectedArea: OrganizationOption) => {
        let vals = selectedOrganizationOptions.filter((n) => n.type == OrganizationUnitType.Company);
        let newSelected = [...areas, ...vals];
        onSelectedChagne(newSelected);
    };
    const onSelectedChagne = (newSelected: OrganizationOption[]) => {
        setSelectedOrganizationOptions(newSelected);
        onChange(newSelected.map((n) => n.id));
        if (onSelected) onSelected(newSelected.map((n) => n.id));
    };

    return (
        <Fragment>
            <div className={'col-md-' + md}>
                <Form.Group className="mb-3">
                    <Form.Label>
                        Chọn nhà máy
                        {requiredCompany && <span className="text-danger">*</span>}
                    </Form.Label>
                    <Multiselect
                        options={organizationOptions?.filter((n) => n.type == OrganizationUnitType.Company)} // Options to display in the dropdown
                        displayValue="name" // Property name to display in the dropdown options
                        showCheckbox={!isSingleCompany}
                        isObject={true}
                        onSelect={onCompanyChange}
                        onRemove={onCompanyChange}
                        singleSelect={isSingleCompany}
                        selectedValues={selectedOrganizationOptions.filter((n) => n.type == OrganizationUnitType.Company) ?? []}
                        avoidHighlightFirstOption={true}
                        selectedValueDecorator={(e, b) => {
                            return e;
                        }}
                    />

                    {requiredCompany && <div className="invalid invalid-feedback d-block">{error?.message}</div>}
                </Form.Group>
            </div>

            {isShowAreaSelect ? (
                <div className={'col-md-' + md}>
                    <Form.Group className="mb-3">
                        <Form.Label>Chọn khu vực {requiredArea && <span className="text-danger">*</span>}</Form.Label>
                        <Multiselect
                            avoidHighlightFirstOption={true}
                            options={organizationOptions?.filter((n) => n.type == OrganizationUnitType.Area && selectedOrganizationOptions.find((x) => x.id == n.parentId))} // Options to display in the dropdown
                            displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={!isSingleArea}
                            isObject={true}
                            onSelect={onAreaChange}
                            onRemove={onAreaChange}
                            singleSelect={isSingleArea}
                            groupBy={'groupCompany'}
                            className={error?.message ? 'is-invalid' : ''}
                            selectedValues={selectedOrganizationOptions.filter((n) => n.type == OrganizationUnitType.Area && selectedOrganizationOptions.find((x) => x.id == n.parentId)) ?? []}
                        />
                        <div className="invalid-feedback">{error?.message}</div>
                        {requiredArea && isShowAreaSelect && selectedOrganizationOptions.filter((n) => n.type == OrganizationUnitType.Area).length < 1 && (
                            <div className="invalid-feedback d-block">Please select area</div>
                        )}
                    </Form.Group>
                </div>
            ) : (
                ''
            )}
        </Fragment>
    );
};

export default OrganizationUnitMultiSelect;
