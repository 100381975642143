import client from '../client';
import { PaginationBase, PaginationBaseRequest } from 'api/models/common';
import { SelectOption } from 'components/FormControl';
import { KPITypeCreateRequest, KPITypeFilter, KPITypeResponse, KPITypeUpdateRequest } from './model/kpiType.model';

const baseURL = '/kpitype';
const kpiTypeApi = {
    getById(id: string): Promise<KPITypeResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<KPITypeResponse>({
            endpoint: url,
        });
    },
    getPagination(filter: KPITypeFilter, isLoading: boolean = true): Promise<PaginationBase<KPITypeResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<KPITypeResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: KPITypeCreateRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: KPITypeUpdateRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
    getDropdown(companyId?: string): Promise<SelectOption[]> {
        const url = `${baseURL}/dropdown`;
        return client.getNonWrap<SelectOption[]>({
            endpoint: url,
            data: { companyId: companyId },
        });
    },
};

export default kpiTypeApi;
