import * as yup from 'yup';
import { BaseAuditResponse } from 'api/models/common';
import { Gender, IIRCausesType, IIRUserStatus, IIRUserType } from 'api/enums/IIR';

export interface IIRUserModel {
    userId: string; // Guid
    fullName: string;
    /**
     * Điều tra viên(investigator) hoặc Boss
     */
    userType: IIRUserType;
    /**
     * Trạng thái user đối với báo cáo Thể hiện báo cáo đã được đẩy lên đối vs Investigator hoặc báo cáo đã được đóng đối vs Boss
     */
    iIRUserStatus: IIRUserStatus;
}

/**
 * Trình tự diễn biến
 */
export interface IIRSequenOfEventModel {
    dateTimeHappen: Date; // DateTimeOffset
    description: string;
}

/**
 * DESCRIPTIONS OF EVIDENCES / Mô tả chứng cứ
 */
export interface IIREvidencesModel {
    description: string;
    evidenceFiles?: string[];
}

export interface IIREquipmentReportModel {
    equipmentName: string;
    /**
     * Giá trị tổn thất - Lost Values
     */
    lostValues?: number;
    comment?: string;
}

/*
 * Tổn thất môi trường
 */
export interface IIREnvironmentalDamageModel {
    /**
     * Vị trí
     */
    location: string;
    /**
     * Hình thức tổn thất
     */
    formOfLoss: string;
    /**
     * Mức độ tổn hại
     */
    damageLevel: string;
}

export interface IIRCauseModel {
    id: string;
    causeName: string;
    causeName_Es: string;
    description: string;
    descriptionEn: string;
    groupId: string; // Guid
    groupName: string;
    groupNameEn: string;
}

export interface IIRCauseGroupModel {
    id: string; // Guid
    name: string;
    nameEs: string;
    causes: IIRCauseModel[];
    type: IIRCausesType;
}

export interface IIRReportHistory extends BaseAuditResponse {
    title: string;
    content: string;
}
/**
 * Công việc tiếp theo
 */
export interface IIRActionFollowUpModel {
    /**
     * Action - Nội dung công việc
     */
    action: string;
    performUserName: string;
    /**
     * Start date / Ngày bắt đầu
     */
    startDate?: Date; // DateTimeOffset
    /**
     * Start date / Ngày hoàn thành
     */
    completedDate?: Date; // DateTimeOffset
}

export interface IIRPersonModel {
    // id: string; // Guid
    fullName: string;
    age?: number;
    gender?: Gender;
    injuryStatus?: string;
    /**
     * Kinh nghiệm làm việc . Đơn vị / Year
     */
    experiences?: string;
    /**
     * Thời gian phục vụ . Đơn vị / Tháng
     */
    timeOfService?: string;
    /**
     * Ca làm việc
     */
    shift?: string;
    companyName?: string;
    /**
     * Type of contract - Tình trạng hợp đồng
     */
    statusOfContract?: string;

    //detect index
    index?: number;
}

export const IIREnvironmentalDamageModelSchema = yup
    .array()
    .of(
        yup.object().shape({
            location: yup.string().required(),
            formOfLoss: yup.string().required(),
            damageLevel: yup.string().required(),
        })
    )
    .required("Tổn thất môi trường là bắt buộc");
export const IIREquipmentReportModelSchema = yup
    .array()
    .of(yup.object().shape({ equipmentName: yup.string().required() }))
    .required("Thiết bị là bắt buộc");

export const IIRSequenOfEventModelSchema = yup
    .array()
    .of(
        yup.object().shape({
            dateTimeHappen: yup.date().required(),
            description: yup.string().required(),
        })
    )
    .required("Trình tự diễn biến là bắt buộc");
export const IIREvidencesModelSchema = yup
    .array()
    .of(yup.object().shape({ description: yup.string().required() }))
    .required("Mô tả chứng cứ là bắt buộc");
export const IIRActionFollowUpModelSchema = yup
    .array()
    .of(yup.object().shape({ action: yup.string().required(), performUserName: yup.string().required() }))
    .required("Hành động tiếp theo là bắt buộc");
export const IIRPersonModelSchema = yup
    .array()
    .of(yup.object().shape({ fullName: yup.string().required() }))
    .required("Thông tin người làm việc là bắt buộc");
