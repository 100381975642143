import { AddButton } from 'components/Button';
import useModalHook from 'components/ModalHook';
import { Empty } from 'components/shared';
import { lang } from 'i18n/lang';
import { useEffect, useMemo, useState } from 'react';
import Translations from 'i18n/translation';
import { SopTable } from 'components/shared/SopTable';
import LinkFileDownload from 'components/Common/LinkFile';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import profileIncentiveDisciplinaryApi from 'api/v2/user/profileIncentiveDisciplinaryApi';
import { ProfileIncentiveDisciplinaryRequest } from 'api/v2/user/model';
import IncentiveDisciplinaryForm from './incentiveDisciplinary.add.component';
import { formatDate } from 'helper';

export default function IncentiveDisciplinaryComponent() {
    const [datas, setDatas] = useState<any[]>([]);
    useEffect(() => {
        fethData();
    }, []);
    const fethData = () => {
        profileIncentiveDisciplinaryApi.getPagination().then((paginate) => {
            const items =
                paginate?.datas?.map((n) => ({
                    ...n,
                    date: formatDate(n.date as Date),
                    file: n.filePath && <LinkFileDownload path={n.filePath} fileName={n.fileName} />,
                    action: (
                        <ActionButtons2
                            id={n.id ?? ''}
                            onDelete={onDelete}
                            editAction={() => {
                                onUpdate(n);
                            }}
                        />
                    ),
                })) ?? [];
            setDatas(items);
        });
    };
    // <SelectField name={'type'} label={lang.text(Translations.userProfile.profileIncentiveDisciplinaryType)} options={types} control={control} required={true} isMutiple={false} />
    // <InputField name={'content'} control={control} defaultValue={profile?.content} label={lang.text(Translations.form.content)} readOnly={false} />
    // <InputField
    //     name={'decisionMake'}
    //     control={control}
    //     defaultValue={profile?.decisionMake}
    //     label={lang.text(Translations.userProfile.profileIncentiveDisciplinaryDecisionMake)}
    //     readOnly={false}
    // />
    // <DateTimeField name={'date'} control={control} defaultDate={profile?.date} label={lang.text(Translations.userProfile.profileIncentiveDisciplinaryDate)} readOnly={false} />
   
    const cols = useMemo(
        () => [
            {
                field: 'type',
                title: lang.text(Translations.userProfile.profileIncentiveDisciplinaryType),
            },
            {
                field: 'content',
                title: lang.text(Translations.form.content),
            },
            {
                field: 'decisionMake',
                title: lang.text(Translations.userProfile.profileIncentiveDisciplinaryDecisionMake),
            },
            {
                field: 'date',
                title: lang.text(Translations.userProfile.profileIncentiveDisciplinaryDate),
            },
            {
                field: 'file',
                title: lang.text(Translations.document.link),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );

    const { openModal, onClose, onCompleted, Prompt } = useModalHook();

    const onAdd = async () => {
        var item: ProfileIncentiveDisciplinaryRequest = await openModal({
            title: lang.text(Translations.userProfile.profileIncentiveDisciplinary),
            body: <IncentiveDisciplinaryForm onClose={onClose} onCompleted={onCompleted} />,
        });
        if (item) {
            profileIncentiveDisciplinaryApi
                .create(item)
                .then((n) => {
                    fethData();
                })
                .catch((n) => {});
        }
    };

    const onUpdate = async (item: ProfileIncentiveDisciplinaryRequest) => {
        var rs = await openModal({
            title: lang.text(Translations.userProfile.profileIncentiveDisciplinary),
            body: <IncentiveDisciplinaryForm profile={item} onClose={onClose} onCompleted={onCompleted} />,
        });

        rs &&
            profileIncentiveDisciplinaryApi
                .update(rs)
                .then((n) => {
                    fethData();
                })
                .catch(() => {});
    };

    const onDelete = async (id: string) => {
        profileIncentiveDisciplinaryApi
            .delete(id)
            .then((n) => {
                fethData();
            })
            .catch(() => {});
    };

    return (
        <div>
            <div className="d-flex justify-content-end ">
                <AddButton isRender={true} onClick={onAdd} />
            </div>
            {!datas.hasValue() ? <Empty maxHeight={200} /> : <SopTable columns={cols} datas={datas} isStriped={true} />}
            {Prompt}
        </div>
    );
}
