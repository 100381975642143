import Color from 'enum/color';
import { SIReportStatus, SIReportType } from 'enum/safetyInspection';
import { SorReportStatus } from 'enum/sor';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';

export const SORReportHelper = {
    getReportStatusOptions: () => {
        return [
            { label: lang.text(Translations.common.statusOpen), value: SorReportStatus.Opening },
            { label: lang.text(Translations.common.statusClosed), value: SorReportStatus.Closed },
        ];
    },

    getReportTypeColor: (isSafe: boolean) => {
        switch (isSafe) {
            case false:
                return Color.danger;
            case true:
                return Color.success;
            default:
                break;
        }
    },
    getReportTypeText: (isSafe: boolean) => {
        switch (isSafe) {
            case true:
                return lang.text(Translations.common.safe);
            case false:
                return lang.text(Translations.common.unSafe);
            default:
                break;
        }
    },

    getStatusColor: (status: SorReportStatus) => {
        switch (status) {
            case SorReportStatus.Opening:
                return Color.warning;
            case SorReportStatus.Closed:
                return Color.success;
            default:
                break;
        }
    },
};
