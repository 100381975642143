import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useQuery = () => {
    const [_, setSearchParams] = useSearchParams();

    let searchToObject = useMemo(() => {
        let pairs = window.location.search.substring(1).split('&'),
            obj: { [x: string]: any } = {},
            pair: string[],
            i: string | number;

        for (i in pairs) {
            if (pairs?.[parseInt(i)] === '') continue;
            pair = pairs?.[parseInt(i)]?.split('=');
            obj[decodeURIComponent(pair?.[0])] = decodeURIComponent(pair?.[1]);
        }
        return obj;
    }, [window.location.search]);

    Object.keys(searchToObject).forEach((key) => {
        if (key === 'undefined' || searchToObject[key] === undefined) {
            delete searchToObject[key];
        }
    });

    return {
        query: searchToObject,
        setQuery: (params: any) => {
            const filteredParams = Object.fromEntries(
                Object.entries(params).filter(([key, value]) => value !== undefined && value !== 'undefined' && value !== 'null' && value !== null && value !== '')
            );

            setSearchParams(filteredParams as any);
        },
    };
};
