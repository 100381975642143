import { avataUserDefault } from 'constants/common';
import React from 'react';

export default function ImageAvatar(props: { className?: string; path?: string | null }) {
    return (
        <img
            {...props}
            src={props.path ?? avataUserDefault}
            //onError={handleImageError}
            className={`${props.className} bg-primary-subtle rounded img-fluid avatar-40 me-3`}
            onError={(e: any) => {
                e.target.src = avataUserDefault; // Replace with your desired fallback image
            }}
        />
    );
}
