import Color from 'enum/color';
import color from 'enum/color';
import { SIReportStatus, SIReportType } from 'enum/safetyInspection';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';

export const SIReportHelper = {
    getReportTypesOptions: () => {
        return [
            { label: lang.text(Translations.common.dailyReport), value: SIReportType.Daily },
            { label: lang.text(Translations.common.scheduleReport), value: SIReportType.Scheduled },
        ];
    },
    getReportStatusOptions: () => {
        return [
            { label: lang.text(Translations.common.statusOpen), value: SIReportStatus.Opening },
            { label: lang.text(Translations.common.statusClosed), value: SIReportStatus.Closed },
        ];
    },

    getReportTypeColor: (status: SIReportType) => {
        switch (status) {
            case SIReportType.Daily:
                return Color.primary;
            case SIReportType.Scheduled:
                return Color.info;
            default:
                break;
        }
    },
    getStatusColor: (status: SIReportStatus) => {
        switch (status) {
            case SIReportStatus.Opening:
                return Color.warning;
            case SIReportStatus.Closed:
                return Color.success;
            default:
                break;
        }
    },
};
