import { RouteObject, RouterProvider, createBrowserRouter, useLocation, useNavigate } from 'react-router-dom';

import { ChangePasswordPage } from 'views';
import { NoutFoundPage } from 'views/errors';
import organizationRouters from './organization';
import dashboardRouters from './dashboardRouters';
import reportRouters from './sorRouters';
import safetyInspectionsRouters from './safetyInspectionsRouters';
import userRouters from './userRouters';
import supportRouters from './supportRouters';
import equipmentRouters from './equipmentRouters';
import kpiRouters from './kpiRouters';
import tripRouters from './tripRouters';
import wprRouters from './wprRouters';
import trainingRouters from './trainingRouters';
import riskRouters from './riskRouters';
import iirRouters from './iirRouters';
import messageRouters from './messageRouters';
import actionRouters from './actionRouters';
import documentRouters from './documentRouters';
import secRouters from './secRouters';
import { DashboardPage } from 'views/dashboard';

import Routers from 'router/const/routers';
import { Navigate } from 'react-router-dom';
import { DefaultLayout } from 'layouts';
import { useAuth } from 'providers/contexts';
import { SignInPage } from 'views/auth';
import AuthProvider from 'providers/authProvider';
import { appMenus } from './const/pageInfos';
import { PMS } from 'router/const/roles';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'app/hooks';
import { selectIsLoggin, selectLoadingPage, selectTokenProfile } from 'app/appSlice';

interface ChildredProps {
    children: React.ReactNode;
}
const AuthLayout = ({ children }: ChildredProps) => {
    const isLogin = useAppSelector(selectIsLoggin);
    const location = useLocation();
    let path = (location.pathname.includes('sign-in') ? '' : location.pathname) + location.search;
    if (!isLogin) return <Navigate to={'/sign-in?url=' + path} replace state={{ from: location }} />;
    return <>{children}</>;
};

const GuestLayout = ({ children }: ChildredProps) => {
    const isLogin = useAppSelector(selectIsLoggin);
    const location = useLocation();
    let path = location.pathname + location.search;
    const searchString = 'url=';
    const startIndex = path.indexOf(searchString);
    if (startIndex != -1) {
        path = path.substring(startIndex + searchString.length);
    }
    if (isLogin) return <Navigate to={path} replace />;
    return <>{children}</>;
};

var privateRoutes: RouteObject[] = [
    {
        path: Routers.root,
        element: <DashboardPage />,
    },
    {
        path: Routers.auth.changePassword,
        element: <ChangePasswordPage />,
    },
    ...dashboardRouters,
    ...organizationRouters,
    ...userRouters,
    ...reportRouters,
    ...safetyInspectionsRouters,
    ...supportRouters,
    ...equipmentRouters,
    ...kpiRouters,
    ...tripRouters,
    ...wprRouters,
    ...trainingRouters,
    ...riskRouters,
    ...iirRouters,
    ...messageRouters,
    ...actionRouters,
    ...documentRouters,
    ...secRouters,
];
// function findDuplicatePaths(data: any) {
//     const pathMap = new Map();

//     // Populate the map with path and indices
//     data.forEach((item: any, index: any) => {
//         const path = item.path;
//         const indices = pathMap.get(path) || [];
//         indices.push(index);
//         pathMap.set(path, indices);
//     });

//     // Process duplicate paths
//     const duplicates: any[] = [];
//     pathMap.forEach((indices, path) => {
//         if (indices.length > 1) {
//             duplicates.push({ path, indices });
//         }
//     });

//     return duplicates;
// }
const IndexRouters = () => {
    const [userRouters, setUserRouters] = useState<RouteObject[]>(privateRoutes);
    const tokenProfile = useAppSelector(selectTokenProfile);
    // const isLoading  = useAppSelector(selectLoadingPage);
    useEffect(() => {
        if (tokenProfile?.userId) {
            let userPms = tokenProfile?.roles ?? [];

            let validRouters = privateRoutes.filter(
                (n) => tokenProfile?.isSuperAdmin === true || appMenus.some((x) => x.items.some((i) => i.path == n.path && (i.ignorePms === true || userPms.anyPms(i.roles))))
            );
            setUserRouters(validRouters);
        } else {
            setUserRouters([]);
        }
    }, [tokenProfile]);

    const routes: RouteObject[] = [
        {
            path: Routers.root,
            element: (
                <AuthLayout>
                    <DefaultLayout />
                </AuthLayout>
            ),
            children: userRouters.map((n) => ({
                path: n.path,
                element: n.element,
            })),
        },
        {
            path: Routers.auth.login,
            element: (
                <GuestLayout>
                    <SignInPage />
                </GuestLayout>
            ),
        },
        {
            path: '*',
            element: <NoutFoundPage />,
        },
    ];
    const router = createBrowserRouter([...routes], { basename: process.env.PUBLIC_URL });

    // const {useAuth}
    return (
        <AuthProvider>
            <RouterProvider router={router} />
        </AuthProvider>
    );
};

export default IndexRouters;
