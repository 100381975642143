import { RouteObject } from 'react-router-dom';

import Routers from 'router/const/routers';
import { KPIAssignmentPage, KPIDetailReportPage, KPIReportCreatePage, KPIReportListPage, KPIReportTypePage } from 'views/kpi';

const kpiRouters: RouteObject[] = [
    {
        path: Routers.kpi.create,
        element: <KPIReportCreatePage />,
    },
    {
        path: Routers.kpi.assignment,
        element: <KPIAssignmentPage />,
    },
    {
        path: Routers.kpi.report,
        element: <KPIReportListPage />,
    },
    {
        path: Routers.kpi.type,
        element: <KPIReportTypePage />,
    },
];

export default kpiRouters;
