import { RouteObject } from 'react-router-dom';

import Routers from 'router/const/routers';
import { MessageCreatePage, MessageManagerPage, MessagePage } from 'views/message';

const messageRouters: RouteObject[] = [
  {
    path: Routers.message.root,
    element: <MessagePage />,
  },
  {
    path: Routers.message.create,
    element: <MessageCreatePage />,
  },
  {
    path: Routers.message.manager,
    element: <MessageManagerPage />,
  },
];

export default messageRouters;
