//react-bootstrap
import { Badge, Button, Dropdown, Form } from 'react-bootstrap';

//components
import { yupResolver } from '@hookform/resolvers/yup';
import orgUnitApi from 'api/v2/organizationUnitApi';
import { OrganizationUnitResponse } from 'api/models/organization';
import { selectLanguage } from 'app/appSlice';
import { useAppSelector } from 'app/hooks';
import { Plus } from 'assets/icons/outlined';
import { ThreeDots } from 'assets/icons/solid';
import { SelectField } from 'components/FormControl';
import { CustomToggle, FancyTable } from 'components/shared';
import Card from 'components/shared/Card';
import Routers from 'router/const/routers';
import Translations from 'i18n/translation';
import { Suspense, lazy, memo, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { defaultPaginationRequest, PaginationBase, PaginationBaseRequest } from 'api/models/common';
import { AddButtonNavigate } from 'components/Button';
import { OrganizationUnitType } from 'api/enums/OrganizationUnitType';
import { avatarCompanyDefault } from 'constants/common';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { Lock } from 'react-bootstrap-icons';
import { PaginationTable } from 'components/shared/PaginationTable';
import ImageDisplay from 'components/Images/ImageDisplay/imageDisplay';
import ImageWithDefault from 'components/bootstrap/ImageWithDefault';
import { useAuth, useCustomer } from 'providers/contexts';

const ConfirmModal = lazy(() => import('components/shared/ConfirmModal'));

type ActionType = 'delete' | 'update';

const ConfigCompanyPage = memo(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userPms } = useAuth();
    const [paginationData, setPaginationData] = useState<PaginationBase<any>>();
    const [selectedItem, setSelectedItem] = useState<{
        type: ActionType | '';
        id: string;
    } | null>(null);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [updateName, setUpdateName] = useState('');

    useEffect(() => {
        handleFetchData(defaultPaginationRequest);
    }, []);

    const handleFetchData = (paginate?: PaginationBaseRequest) => {
        if (!paginate) paginate = defaultPaginationRequest;
        orgUnitApi
            .getByCurrentCustomer(paginate, OrganizationUnitType.Company)
            .then((result: PaginationBase<OrganizationUnitResponse>) => {
                var dt = {
                    ...result,
                    datas: result.datas?.map((item) => ({
                        ...item,
                        isActive: item.isActive ? (
                            <Badge pill bg="success">
                                {t(Translations.common.show)}
                            </Badge>
                        ) : (
                            <Badge pill bg="warning">
                                {t(Translations.common.hide)}
                            </Badge>
                        ),
                        logo: <ImageWithDefault className=" avatar-40" src={item.logo ?? undefined} />, // <ImageDisplay src={item.logo} />,
                        action: (
                            <ActionButtons2
                                id={item.id}
                                editAction={onEdit}
                                editPms={userPms.orgAdmin}
                                deletePms={userPms.orgAdmin}
                                onDelete={(id: string) => onDelete(id)}
                                onDeleteSuccess={() => handleFetchData()}
                                customActions={
                                    <Button onClick={() => onActiveToggle(item.id, !item.isActive)} variant="warning" size="sm">
                                        <Lock />
                                    </Button>
                                }
                            />
                        ),
                    })),
                };
                setPaginationData(dt);
            })
            .catch(() => {});
    };

    const handleAccept = () => {
        if (selectedItem && selectedItem.type === 'delete') {
            orgUnitApi
                .delete(selectedItem.id)
                .then(() => handleFetchData())
                .catch((n) => {})
                .finally(() => setOpenModal(false));
        }

        if (selectedItem && selectedItem.type === 'update') {
            orgUnitApi
                .update({ id: selectedItem.id, name: updateName })
                .then(() => handleFetchData())
                .catch((n) => {})
                .finally(() => setOpenModal(false));
        }
    };

   
    const cols = useMemo(
        () => [
            {
                field: 'logo',
                title: 'Logo',
                style: {
                    width: '50px',
                },
            },
            {
                field: 'name',
                title: t(Translations.form.site.name),
            },
            {
                field: 'isActive',
                title: t(Translations.common.status),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    const onEdit = (id: string) => {
        navigate(Routers.config.companyEdit.replace(':id', id));
    };

    const onDelete = (id: string) => {
        setSelectedItem({ type: 'delete', id });
        setOpenModal(true);
        return Promise.resolve();
    };
    const onActiveToggle = (id: string, isActive: boolean) => {
        orgUnitApi.toggleActive(id, isActive).then(() => handleFetchData(defaultPaginationRequest));
    };

    return (
        <div>
            {/* Confirm Delete */}
            <Suspense fallback={<div>Modal is Loading...</div>}>
                {selectedItem && (
                    <ConfirmModal
                        title={selectedItem.type === 'update' ? t(Translations.form.enterName) : undefined}
                        show={openModal}
                        content={
                            selectedItem.type === 'delete' ? (
                                t(Translations.modal.confirm.confirmDelete)
                            ) : (
                                <Form.Control value={updateName} onChange={(e) => setUpdateName(e.target.value)} name="name" as="textarea" id="name" />
                            )
                        }
                        handleCancel={() => {
                            setUpdateName('');
                            setSelectedItem(null);
                            setOpenModal(false);
                        }}
                        handleAccept={handleAccept}
                    />
                )}
            </Suspense>

            <Card>
                <Card.Header>
                    <div className="text-end w-100 d-flex justify-content-end align-items-center">
                        <AddButtonNavigate url={Routers.config.companyCreate} isRender={userPms.orgAdmin} />
                    </div>
                </Card.Header>
                <Card.Body>
                    <PaginationTable disableSearch={true} onSearch={handleFetchData} onPaginate={handleFetchData} columns={cols} pagination={paginationData} isStriped={true} wrapperClass="mt-5" />
                </Card.Body>
            </Card>
        </div>
    );
});

export default ConfigCompanyPage;
