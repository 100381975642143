import { PaginationBaseRequest } from 'api/models/common';
import { LocationModel, locationValidation } from 'api/models/common/locationModel';
import * as yup from 'yup';
// ROUTE
export interface TRRoute {
    id?: string;
    displayName: string;
    fromPlace: LocationModel;
    toPlace: LocationModel;
    places?: LocationModel[];
    safeInformations?: TRSafeInformation[];
    companyId: string;
}

export interface TRSafeInformation {
    id?: string;
    fromPlace: LocationModel;
    toPlace: LocationModel;
    distance: number;
    safeInformation: string;
    imagePath?: string;
}

export interface TRRouteFilter extends PaginationBaseRequest {
    companyId?: string;
}

export const trSafeInformationValidation = yup.object().shape({
    id: yup.string().optional(),
    fromPlace: locationValidation.required(),
    toPlace: locationValidation.required(),
    distance: yup.number().required(),
    safeInformation: yup.string().required(),
    imagePath: yup.string().optional(),
});
