import { yupResolver } from '@hookform/resolvers/yup';
import { InputField } from 'components/FormControl';
import Translations from 'i18n/translation';
import { t } from 'i18next';
import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useController, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

interface PromptProps {
    show: boolean;
    title: string;
    description?: string;
    defaultValue: string;
    onClose: () => void;
    onConfirm: (text: string) => void;
}

const PromptInputText: React.FC<PromptProps> = ({
    show,
    onClose,
    onConfirm,
    title,
    description,
    defaultValue,
}) => {
    const handleConfirm = () => {
        var value = getValues();
        if (!value.name.trim().hasValue()) toast.error(t(Translations.form.enterName));
        else {
            onConfirm(value.name);
            reset();
            onClose();
        }
    };
    const schema = yup.object().shape({
        name: yup.string().required(),
    });
    const { control, handleSubmit, getValues, reset, formState } = useForm({
        defaultValues: {
            name: defaultValue ?? '',
        },

        resolver: yupResolver(schema),
    });

    return (
        <Modal show={show}  backdrop="static" onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(handleConfirm)}>
                    <InputField
                        disabled={false}
                        required={true}
                        name="name"
                        control={control}
                        label={description ?? t(Translations.form.enterName)}
                        placeholder=""
                        type="text"
                        defaultValue={defaultValue}
                    />
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onClose}>
                            {t(Translations.modal.confirm.cancel)}
                        </Button>
                        <Button variant="primary" type="submit" onClick={handleConfirm}>
                            {t(Translations.modal.confirm.ok)}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    );
};

interface OpenPromptInputTextProps {
    title: string;
    description?: string;
    defaultValue: string;
}
const usePromptInputText = () => {
    const [show, setShow] = useState(false);
    const [resolve, setResolve] = useState<(value: string) => void>(() => {});
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string | undefined>('');
    const [defaultValue, setDefaultValue] = useState<string>('');
    const openPromptInputText = (props: OpenPromptInputTextProps) => {
        setShow(true);

        setTitle(props.title);
        setDescription(props.description);
        setDefaultValue(props.defaultValue);
        return new Promise<string>((res) => {
            setResolve(() => res);
        });
    };

    const handleClose = () => {
        setShow(false);
        setResolve(() => {});
    };

    const handleConfirm = (text: string) => {
        if (resolve) {
            resolve(text);
        }
    };

    return {
        openPromptInputText,
        PromptInputText: (
            <PromptInputText
                title={title}
                description={description}
                defaultValue={defaultValue}
                show={show}
                onClose={handleClose}
                onConfirm={handleConfirm}
            />
        ),
    };
};

export default usePromptInputText;

// Usage in your component:

// const { openPrompt, Prompt } = usePrompt();

// const handleOpenPrompt = async () => {
//   const result = await openPrompt();
//   console.log('Entered text:', result);
// };

// ...

// {Prompt}
