import React, { useRef, useEffect, useState, Fragment } from 'react';
import { useController } from 'react-hook-form';
import UploadFileField, { UploadFileFieldProps } from '../UploadFileField';
import ImageWithDefault from 'components/bootstrap/ImageWithDefault';
import CommonConstants from 'constants/common';
export function UploadImageField({ name, control, className = '', md = 4, altText, btnText, src, readOnly = false, accepts = CommonConstants.imageAccepts}: UploadFileFieldProps) {
    const {
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
    } = useController({
        name,
        control,
    });
    useEffect(() => {
        if (src) {
            onChange(src);
        }
    }, [src]);
    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <ImageWithDefault style={{ maxWidth: '266px' }} src={value} />
                </div>
            </div>
            {!readOnly && (
                <div className="row mt-1" style={{ maxWidth: '266px' }}>
                    <UploadFileField defaultFilePath={value} name={name} text="" control={control} readOnly={readOnly} onValueChange={onChange} accepts={accepts} btnText={btnText} md={12} />
                </div>
            )}
        </Fragment>
    );
    // const { t } = useTranslation();
    // const inputRef = useRef<HTMLInputElement>(null);
    // const inputValueRef = useRef<HTMLInputElement>(null);
    // const previewImageRef = useRef<HTMLImageElement>(null);
    // // const { execute: upload } = useAsync();

    // const handleUpload = () => {
    //     if (readOnly === true || readOnly == undefined) return;
    //     const input = inputRef.current;
    //     if (input) {
    //         input.click();
    //     }
    // };

    // const showPreview = async () => {
    //     if (inputRef.current?.files && inputRef.current.files.length) {
    //         const formData = new FormData();
    //         formData.append('image', inputRef.current.files[0]);
    //         fileApi
    //             .uploadImage(formData)
    //             .then((res: FileResponse) => {
    //                 if (res.filePath) {
    //                     //@ts-ignore
    //                     previewImageRef.current.src = process.env.REACT_APP_FILE_SEVER_URL + res.path;
    //                     if (inputValueRef && inputValueRef.current) inputValueRef.current.value = res.filePath;
    //                     onChange(res.filePath);
    //                     if (onValueChange) onValueChange(res.filePath);
    //                 } else {
    //                     // handle exception
    //                 }
    //             })
    //             .catch((error) => {});
    //     }
    // };

    // useEffect(() => {
    //     if (inputRef && inputRef.current) {
    //         if (inputRef && inputRef.current) {
    //             inputRef.current.addEventListener('change', showPreview);
    //         }
    //         return () => {
    //             if (inputRef && inputRef.current) inputRef.current.removeEventListener('change', showPreview);
    //         };
    //     }
    // }, [inputRef]);

    // return (
    //     <div className={`${className} text-start`} style={{ maxWidth: '300px' }}>
    //         <img
    //             ref={previewImageRef}
    //             width="100%"
    //             src={value ? process.env.REACT_APP_FILE_SEVER_URL + value : ImagePlaceholder}
    //             alt={altText ? altText : 'placeholder'}
    //             loading="lazy"
    //             className="rounded img-thumbnail"
    //             style={{
    //                 objectFit: 'contain',
    //             }}
    //         />
    //         <input readOnly type="file" ref={inputRef} hidden accept={accepts} />
    //         <input readOnly type="text" ref={inputValueRef} hidden />
    //         {!readOnly && (
    //             <Button onClick={handleUpload} className="mt-1 btn btn-info w-100 gap-2">
    //                 <span className="btn-inner me-2">
    //                     <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-cloud-arrow-up-fill" viewBox="0 0 16 16">
    //                         <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0z" />
    //                     </svg>
    //                 </span>
    //                 {btnText ? btnText : t(Translations.common.upload)}
    //             </Button>
    //         )}
    //         <div className="invalid-feedback">{error?.message}</div>
    //     </div>
    // );
}
