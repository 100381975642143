import { selectLoadingPage } from 'app/appSlice';
import { useAppSelector } from 'app/hooks';
import './styles.scss';

export default function LoadingPage() {
  const loadingPage = useAppSelector(selectLoadingPage);
  if (!loadingPage) return <></>;
  return (
    <div className="wrap-loading">
      {/* <div className="loader"></div> */}
      <div className="iq-loader-box">
        <div className="iq-loader-1"></div>
      </div>
    </div>
  );
}
