import { PaginationBase, PaginationBaseRequest } from 'api/models/common';
import client from '../client';
import { LDGroupRequest, LDGroupResponse } from './legalDocument.models';

const baseURL = '/legaldocument/group';
const legaldocumentGroupApi = {
    create(data: LDGroupRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: LDGroupRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
    getByIndex(payload: PaginationBaseRequest): Promise<PaginationBase<LDGroupResponse>> {
        const url = `${baseURL}/getbyindex`;
        return client.getNonWrap<PaginationBase<LDGroupResponse>>({
            endpoint: url,
            data: payload,
        });
    },
};

export default legaldocumentGroupApi;
