import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EquipmentReportDetailResponse, EquipmentReportGroupResponse } from 'api/models';
import equipmentReportApi from 'api/v2/equipmentReportApi';
import { Badge, Button, Card } from 'react-bootstrap';
import GroupContent from './group.component';
import Translations from 'i18n/translation';
import InputFieldSimple from 'components/FormControl/InputFieldSimple';
import { EquipmentReportStatusEnum } from 'enum';
import { useForm } from 'react-hook-form';
import { EquipmentHelper } from 'helper/Feature/equipment.help';
import { lang } from 'i18n/lang';
import usePromptInputText from 'components/Modal/PromptInputText';
import { DeleteButton, DownloadButton } from 'components/Button';
import ToastConfirm from 'components/Modal/ToastConfirm';
import { DefaultButton } from 'components/Button/DefaultButton';
import { formatDateTime } from 'helper';
import { RadioField } from 'components/FormControl';
import { CloseButton } from 'components/Button/CloseButton';

interface EquipmentReportDetailPageProps {
    id?: string | undefined | null;
}
const EquipmentReportDetailPage = (props: EquipmentReportDetailPageProps) => {
    const { t } = useTranslation();
    const [report, setReport] = useState<EquipmentReportDetailResponse | null>(null);
    const { control } = useForm();
    const { openPromptInputText, PromptInputText } = usePromptInputText();
    useEffect(() => {
        loadReport();
    }, []);
    const loadReport = () => {
        if (props.id)
            equipmentReportApi
                .getById(props.id)
                .then((res) => {
                    console.log(res);
                    setReport({ ...res });
                })
                .catch((n) => {});
    };

    const onDelete = () => {
        if (report)
            equipmentReportApi
                .delete(report.id)
                .then((n) => {})
                .catch((n) => {});
    };

    const onCloseReport = async () => {
        if (!report) return;
        const text: string = await openPromptInputText({
            title: lang.text(Translations.common.description),
            description: '',
            defaultValue: '',
        });
        equipmentReportApi
            .close(report.id, text)
            .then((n) => {
                loadReport();
            })
            .catch((n) => {});
    };
    const onDownload = () => {
        if (report) {
            equipmentReportApi
                .downloadReport(report.id, report.equipment.equipmentCode)
                .then((n) => {})
                .catch((n) => {});
        }
    };
    return (
        report && (
            <div>
                <Card>
                    <Card.Body>
                        <div className="row">
                            <InputFieldSimple label={lang.text(Translations.common.reportType)} md={3} disabled value={report.reportType.toString()} />
                            <InputFieldSimple label={lang.text(Translations.equipment.equipment)} md={3} disabled value={report.equipment.equipmentCode} />
                            <InputFieldSimple label={lang.text(Translations.contractor)} md={3} disabled value={report.equipment.contractorName} />

                            <InputFieldSimple label={lang.text(Translations.equipment.reportPersonInCharge)} md={3} disabled value={report.pICFullName} />
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <div className="row">
                            <div className="col-md-6">
                                <p>
                                    {lang.text(Translations.equipment.reportRating)}: <Badge color={EquipmentHelper.getLevelColor(report.level)}>{report.level}</Badge>
                                </p>
                                <p>
                                    {lang.text(Translations.common.status)}: <Badge bg={EquipmentHelper.getStatusColor(report.status)}>{report.status}</Badge>
                                </p>

                                <p>
                                    {lang.text(Translations.common.description)}: {report.description}
                                </p>
                            </div>
                            <div className="col-md-3">
                                {report.status == EquipmentReportStatusEnum.Closed && (
                                    <div>
                                        <p>
                                            {lang.text(Translations.common.closedDate)}: {formatDateTime(report.closedDate)}
                                        </p>
                                        <p>
                                            {lang.text(Translations.common.description)}: {report.closedDescription}
                                        </p>
                                        <p>
                                            {lang.text(Translations.common.closedBy)}: {report.closedUserFullName}
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-3">
                                <div className="d-flex justify-content-end">
                                    <DownloadButton onClick={onDownload} isRender={report != undefined} />
                                    <CloseButton isRender={report?.canClose} onClick={onCloseReport} />
                                    {report?.canDelete && (
                                        <ToastConfirm message={lang.text(Translations.modal.confirm.confirmDelete)} onConfirm={onDelete}>
                                            <DeleteButton isRender={report?.canDelete} />
                                        </ToastConfirm>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        {report?.groups?.map((group: EquipmentReportGroupResponse) => (
                            <div className="row">
                                <div className="col-md-12">
                                    <GroupContent onItemChange={() => {}} readonly={true} key={group.groupTemplateId} group={group} />
                                </div>
                            </div>
                        ))}
                    </Card.Body>
                </Card>

                {PromptInputText}
            </div>
        )
    );
};

export default EquipmentReportDetailPage;
