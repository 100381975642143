import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import Card from 'components/shared/Card';
import { useNavigate } from 'react-router-dom';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PaginationTable } from 'components/shared/PaginationTable';
import { lang } from 'i18n/lang';
import { AddButton } from 'components/Button';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import useModalHook from 'components/ModalHook';
import kpiTypeApi from 'api/v2/kpi/kpiType';
import { KPITypeCreateRequest, KPITypeResponse, KPITypeUpdateRequest } from 'api/v2/kpi/model/kpiType.model';
import usePromptInputText from 'components/Modal/PromptInputText';
import KPITypeForm from './formCrud';
import { CompanySelect } from 'components/Common';
import { toast } from 'react-toastify';

const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);

// eslint-disable-next-line react-hooks/exhaustive-deps
const KPITypeListPage = () => {
    const [paginationData, setPaginationData] = useState<PaginationBase<any>>();
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    useEffect(() => {
        handleFetchData(defaultPaginationRequest);
    }, []);
    const { openPromptInputText, PromptInputText } = usePromptInputText();

    const cols = useMemo(
        () => [
            {
                field: 'name',
                title: lang.text(Translations.form.site.name),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        var comid = getValues().companyId;
        toast.error(comid?.hasValue());
        kpiTypeApi
            .getPagination({
                ...pagination,
                companyId: comid?.hasValue() ? comid : null,
            })
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                action: <ActionButtons2 id={n.id} onDelete={onDelete} editAction={() => onUpdate(n)} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };
    const onAdd = async () => {
        var request: KPITypeCreateRequest = await openModal({
            title: lang.text(Translations.equipment.templateCategories),
            body: <KPITypeForm name="" companyIds={[]} onClose={onClose} onCompleted={onCompleted} />, // <SORCategoryForm description="" name="" id="" onClose={onClose} onCompleted={onCompleted} />,
        });
        request &&
            kpiTypeApi
                .create(request)
                .then(() => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch(() => {});
    };

    const onUpdate = async (data: KPITypeResponse) => {
        var request: KPITypeCreateRequest = await openModal({
            title: lang.text(Translations.equipment.templateCategories),
            body: <KPITypeForm name={data.name} companyIds={data.companyIds} onClose={onClose} onCompleted={onCompleted} />, // <SORCategoryForm description="" name="" id="" onClose={onClose} onCompleted={onCompleted} />,
        });
        request &&
            kpiTypeApi
                .update({ ...request, id: data.id })
                .then(() => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch(() => {});
    };
    const onDelete = async (id: string) => {
        kpiTypeApi
            .delete(id)
            .then(() => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch(() => {});
    };
    const { control, getValues } = useForm();
    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <div className="row">
                        <CompanySelect md={4} name="companyId" control={control} required={false} disable={false} readOnly={false} onCompanyChange={() => handleFetchData(defaultPaginationRequest)} />
                    </div>
                    <div className="d-flex justify-content-end">
                        <AddButton isRender={true} onClick={onAdd} />
                    </div>
                    <PaginationTable
                        onSearch={handleFetchData}
                        onPaginate={handleFetchData}
                        onRowClick={(item) => {}}
                        columns={cols}
                        pagination={paginationData}
                        isStriped={true}
                        disableSearch={true}
                        wrapperClass="mt-5"
                    />
                </Card.Body>
            </Card>
            {Prompt}
        </Fragment>
    );
};

export default KPITypeListPage;
