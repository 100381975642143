export enum IIRCausesType {
    UnsafeAction = 'UnsafeAction',
    UnsafeCondition = 'UnsafeCondition',
    PersonalFactors = 'PersonalFactors',
    JobFactors = 'JobFactors',
}

export enum IIRCommentType {
    CreatorReport = 'CreatorReport',
    Investigator = 'Investigator',
}

export enum IIRUserStatus {
    New = 'New',
    Agree = 'Agree',
    Refuse = 'Refuse',
}

export enum IIRUserType {
    Boss = 'Boss',
    Investigator = 'Investigator',
}

export enum IIRReportStatus {
    Created = 'Created',
    InvestigatorAgreed = 'InvestigatorAgreed',
    InvestigatorRefuse = 'InvestigatorRefuse',
    BossAgreed = 'BossAgreed',
    BossRefuse = 'BossRefuse',
}

export enum Gender {
    Female = 'Female',
    Male = 'Male',
}

export enum IIRCategoryType {
    IncidentLevel = 'IncidentLevel',
    IncidentType = 'IncidentType',
}
