import client from '../client';
import { PaginationBase, PaginationBaseRequest } from 'api/models/common';
import { SelectOption } from 'components/FormControl';
import { SECGateFilterRequest, SECGateUpdateRequest, SECGateResponse, SECGateCreateRequest } from './model/gate.model';
import { SECRegisterCreateRequest, SECRegisterDetailResponse, SECRegisterFilterRequest, SECRegisterResponse } from './model/register.model';

const baseURL = '/SECRegister';
const secRegisterApi = {
    /**FInd by CCCD or Biển số xe */
    findByCode(code: string, date:Date): Promise<SECRegisterDetailResponse> {
        const url = `${baseURL}/findByCode/${code}/date/${date.toISOString()}`;
        return client.getNonWrap<SECRegisterDetailResponse>({
            endpoint: url,
        });
    },
    getById(id: string): Promise<SECRegisterDetailResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<SECRegisterDetailResponse>({
            endpoint: url,
        });
    },
    getPagination(filter: SECRegisterFilterRequest, isLoading: boolean = true): Promise<PaginationBase<SECRegisterResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<SECRegisterResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: SECRegisterCreateRequest): Promise<string> {
        data.from = data.from.toStartDay();
        data.to = data.to.toEndDay();
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: SECRegisterCreateRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
};

export default secRegisterApi;
