//react-bootstrap
import { Accordion, Button, Form } from 'react-bootstrap';

//components
import riskLevelApi from 'api/v2/common/riskLevel/riskLevelApi';
import { selectLanguage } from 'app/appSlice';
import { useAppSelector } from 'app/hooks';
import { Plus } from 'assets/icons/outlined';
import { Empty } from 'components/shared';
import Card from 'components/shared/Card';
import Routers from 'router/const/routers';
import Translations from 'i18n/translation';
import { useCustomer } from 'providers/contexts';
import { ResponseApi } from 'api/models/common/apiResponse';
import { Suspense, lazy, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RiskLevelResponse } from 'api/models/organization';
import { AddButtonNavigate, DeleteButton, UpdateButtonNavigate } from 'components/Button';
import { PaginationBase } from 'api/models/common';
import ToastConfirm from 'components/Modal/ToastConfirm';

const ConfirmModal = lazy(() => import('components/shared/ConfirmModal'));

const ConfigRiskLevelPage = memo(() => {
    // const { execute: getData } = useAsync(riskLevelApi.getByCurrentCustomer, {
    //     loadingPage: false,
    //     errorPage: true,
    // });
    // const { execute: deleteData } = useAsync(riskLevelApi.delete, {
    //     loadingPage: false,
    //     errorPage: true,
    // });
    const [data, setData] = useState<RiskLevelResponse[]>([]);
    useEffect(() => {
        handleFetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFetchData = () => {
        riskLevelApi
            .getByCurrentCustomer()
            .then((res: PaginationBase<RiskLevelResponse>) => {
                setData(res.datas ?? []);
            })
            .catch((n) => {});
    };

    const handleDeleteData = (id: string) => {
        riskLevelApi
            .delete(id)
            .then(() => {
                handleFetchData();
            })
            .catch((n) => {});
    };

    return (
        <div>
            <Card>
                <Card.Header>
                    <div className="text-end w-100">
                        <AddButtonNavigate url={Routers.config.riskLevelCreate}   isRender={true}/>
                    </div>
                </Card.Header>
                <Card.Body>
                    {/* Table */}
                    {data.length ? (
                        <Accordion>
                            {data.map((item, index) => (
                                <Accordion.Item eventKey={index.toString()} key={index}>
                                    <Accordion.Header>{item.fieldNameVn}</Accordion.Header>
                                    <Accordion.Body>
                                        <Card.Body className="p-0">
                                            <div>
                                                <span className="d-block">{item.note}</span>
                                                <div className="d-lg-flex justify-content-end gap-2">
                                                    <ToastConfirm onConfirm={() => handleDeleteData(item.id)}>
                                                        <DeleteButton />
                                                    </ToastConfirm>
                                                    <UpdateButtonNavigate url={Routers.config.riskLevelUpdate.replace(':id', item.id)} />
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    ) : (
                        <Empty />
                    )}
                </Card.Body>
            </Card>
        </div>
    );
});

export default ConfigRiskLevelPage;
