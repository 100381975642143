export enum EquipmentReportType {
    All = 'All',
    Daily = 'Daily',
    Scheduled = 'Scheduled',
}

export enum EquipmentReportStatusEnum {
    All = 'All', //100
    Safe = 'Safe', //0
    Opening = 'Opening', //1
    Closed = 'Closed', //2
}

/**
 * low is green,
 * Medium is yellow
 * hight is res
 */
export enum EquipmentReportLevel {
    Low = 'Low', //0
    Medium = 'Medium', //1
    Hight = 'Hight', //2
    All = 'All', //110
}
