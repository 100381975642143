import client from '../client';
import { PaginationBase, PaginationBaseRequest } from 'api/models/common';
import { SelectOption } from 'components/FormControl';
import { IIRActionRequest, IIRReportDetailResponse, IIRReportRequest, IIRReportResponse } from './model/iirReport.model';
import { IIRCauseGroupModel } from './model/iirReportComponent.model';

const baseURL = '/IIRReport';
const iirReportApi = {
    getById(id: string): Promise<IIRReportDetailResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<IIRReportDetailResponse>({
            endpoint: url,
        });
    },
    getPagination(filter: PaginationBaseRequest, isLoading: boolean = true): Promise<PaginationBase<IIRReportResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<IIRReportResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: IIRReportRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: IIRReportRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
    investigatorAction(request: IIRActionRequest): Promise<any> {
        const url = `${baseURL}/action/investigator`;
        return client.postNonWrap<any>({
            endpoint: url,
            data: request,
        });
    },
    bossAction(request: IIRActionRequest): Promise<any> {
        const url = `${baseURL}/action/boss`;
        return client.postNonWrap<any>({
            endpoint: url,
            data: request,
        });
    },
    getBossDropdowns(): Promise<SelectOption[]> {
        const url = `${baseURL}/boss-fordropdown`;
        return client.getNonWrap<SelectOption[]>({
            endpoint: url,
            isLoading: false,
        });
    },
    getInvestigatorsDropdowns(): Promise<SelectOption[]> {
        const url = `${baseURL}/ivnestigator-fordropdown`;
        return client.getNonWrap<SelectOption[]>({
            endpoint: url,
            isLoading: false,
        });
    },
    getAllCauseGroups(): Promise<IIRCauseGroupModel[]> {
        const url = `${baseURL}/get-all-cause`;
        return client.getNonWrap<IIRCauseGroupModel[]>({
            endpoint: url,
            isLoading: false,
        });
    },
    creatorResendAction(request: IIRActionRequest): Promise<any> {
        const url = `${baseURL}/action/creator`;
        return client.postNonWrap<any>({
            endpoint: url,
            data: request,
        });
    },
};

export default iirReportApi;
