import { PaginationBase } from 'api/models/common';
import client from '../client';
import { TRRoute, TRRouteFilter } from './model/route.model';

const baseURL = '/Route';
const routeApi = {
    getByIndex(filter: TRRouteFilter): Promise<PaginationBase<TRRoute>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<TRRoute>>({
            endpoint: url,
            data: filter,
            isLoading: true,
        })
    },
    getById(id: string): Promise<TRRoute> {
        const url = `${baseURL}/getById/${id}`;
        return client.getNonWrap<TRRoute>({
            endpoint: url,
            isLoading: true,
        })
    },
    create(data: TRRoute): Promise<TRRoute[]> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<TRRoute[]>({
            endpoint: url,
            data,
            isLoading: true,
        })
    },
    update(data: TRRoute): Promise<TRRoute[]> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<TRRoute[]>({
            endpoint: url,
            data,
            isLoading: true,
        })
    },
    delete(id: string): Promise<string> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<string>({
            endpoint: url,
            isLoading: true,
        })
    },
};

export default routeApi;
