import { CustomerInfraResponse } from 'api/models/organization';
import React from 'react';

const CustomerContext = React.createContext<
    | {
          customers: CustomerInfraResponse[];
          currentCustomer: CustomerInfraResponse | null;
          loadInfrastructure: () => void;
      }
    | undefined
>(undefined);

export default CustomerContext;
