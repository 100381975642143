import { Button } from 'react-bootstrap';
import { BaseButtonProps } from './BaseButton.props';
import { Fragment } from 'react';

export function BaseButton(props: BaseButtonProps) {
    return props.isRender === true ? (
        <Button disabled={props.disable} onClick={props.onClick} variant={props.color} className={' px-2 py-1 mx-2 rounded ' + (props.className ?? '')} type={props.type} size="sm">
            <div className="d-flex flex-row align-items-center">
                {props.icon ?? <span className="btn-inner me-2">{props.icon}</span>}
                {props.text && <div style={{ marginLeft: '4px' }}>{props.text}</div>}
            </div>
        </Button>
    ) : (
        <Fragment></Fragment>
    );
}
