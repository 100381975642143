import { RouteObject } from 'react-router-dom';

import Routers from 'router/const/routers';
import { TNTrainingCoursesPage, TNTopicPage, TNMyCoursesPage, TNTakeQuizPage, TNCourseDetailPage, TNCourseCreatePage } from 'views/training';
import TNSetpointPage from 'views/training/manager/tnSetPointPage';

const trainingRouters: RouteObject[] = [
    {
        path: Routers.trainingOnline.courseCreate,
        element: <TNCourseCreatePage />,
    },
    {
        path: Routers.trainingOnline.course,
        element: <TNTrainingCoursesPage />,
    },
    {
        path: Routers.trainingOnline.courseDetail,
        element: <TNCourseDetailPage />,
    },
    {
        path: Routers.trainingOnline.category,
        element: <TNTopicPage />,
    },
    {
        path: Routers.trainingOnline.myCourse,
        element: <TNMyCoursesPage />,
    },
    {
        path: Routers.trainingOnline.myCourseTakeQuiz,
        element: <TNTakeQuizPage />,
    },
    {
        path: Routers.trainingOnline.setPoint,
        element: <TNSetpointPage />,
    },
];

export default trainingRouters;
