import { RouteObject } from 'react-router-dom';
import Routers from 'router/const/routers';
import DocumentListPage from 'views/document/documentListPage';
import LegalDocumentGroupPage from 'views/legal-document/group/legalDocumentGroupPage';
import LegalDocumentCreatePage from 'views/legal-document/legalDocumentCreatePage';
import LegalDocumentDetailPage from 'views/legal-document/legalDocumentDetailPage';
import LegalDocumentListPage from 'views/legal-document/legalDocumentListPage';

const documentRouters: RouteObject[] = [
    {
        path: Routers.library.root,
        element: <DocumentListPage />,
    },
    {
        path: Routers.legalDocument.root,
        element: <LegalDocumentListPage />,
    },
    {
        path: Routers.legalDocument.create,
        element: <LegalDocumentCreatePage />,
    },
    {
        path: Routers.legalDocument.detail,
        element: <LegalDocumentDetailPage />,
    },
    {
        path: Routers.legalDocument.group,
        element: <LegalDocumentGroupPage />,
    },
];

export default documentRouters;
