import { ReactNode, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

interface ModalComponentProps {
  showModal: boolean;
  handleCloseModal: () => void;
  titleModal: String;
  bodyModal: ReactNode;
  footerModal: ReactNode;
  closeButton?: boolean;
  scrollable?: boolean;
}
export function ModalComponent({
  showModal,
  handleCloseModal,
  titleModal,
  bodyModal,
  footerModal,
  closeButton = true,
  scrollable = false,
}: ModalComponentProps) {
  return (
    <Modal
      scrollable={scrollable}
      backdrop="static"
      show={showModal}
      onHide={handleCloseModal}
      centered
    >
      <Modal.Header closeButton={closeButton}>
        <Modal.Title>{titleModal}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{bodyModal}</Modal.Body>
      <Modal.Footer>{footerModal}</Modal.Footer>
    </Modal>
  );
}
