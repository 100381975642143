import { DocumentFilterRequest, DocumentRequest, DocumentResponse } from './model/document.model';
import client from '../client';
import { PaginationBase, PaginationBaseRequest } from 'api/models/common';
import { SelectOption } from 'components/FormControl';
import { DocGroupRequest, DocGroupResponse } from './model/docGroup.model';

const baseURL = '/document';
const documentApi = {
    getById(id: string): Promise<DocumentResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<DocumentResponse>({
            endpoint: url,
        });
    },
    getPagination(filter: DocumentFilterRequest, isLoading: boolean = true): Promise<PaginationBase<DocumentResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<DocumentResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: DocumentRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: DocumentRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
    group: {
        create(data: DocGroupRequest): Promise<string> {
            const url = `${baseURL}/group/create`;
            return client.postNonWrap<string>({
                endpoint: url,
                data: data,
            });
        },

        update(data: DocGroupRequest): Promise<unknown> {
            const url = `${baseURL}/group/update`;
            return client.postNonWrap<unknown>({
                endpoint: url,
                data: data,
            });
        },
        delete(id: string): Promise<unknown> {
            const url = `${baseURL}/group/delete/${id}`;
            return client.postNonWrap<unknown>({
                endpoint: url,
            });
        },
        getByCompany(companyId?: string): Promise<DocGroupResponse[]> {
            const url = `${baseURL}/group/bycompany?companyId=${companyId}`;
            return client.getNonWrap<DocGroupResponse[]>({
                endpoint: url,
            });
        },
    },
};

export default documentApi;
