import { yupResolver } from '@hookform/resolvers/yup';
import { IIREquipmentReportModel } from 'api/v2/iir/model/iirReportComponent.model';
import { InputField, TextAreaField } from 'components/FormControl';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import IIRReportItemBaseComponent, { IIRListComponentProps } from './iirBaseItem.component';
import { useEffect } from 'react';

const schema = yup.object().shape({
    equipmentName: yup.string().required('Tên thiết bị không được để trống'),
    // lostValues: yup.number().required('Lost value required'),
    // comment: yup.string().required('Comment is required'),
});

export default function IIREquipmentViewComponent({ datas, isReadOnly, arrayForm }: IIRListComponentProps<IIREquipmentReportModel>) {
    const itemForm = useForm({
        resolver: yupResolver(schema),
    });

    const FormComponent = ({ data }: { data: IIREquipmentReportModel }) => {
        useEffect(() => {
            itemForm.reset(data);
        }, [data]);
        return (
            <Row>
                <InputField required md={12} control={itemForm.control} name="equipmentName" label={lang.text(Translations.iir.equipment.equipmentName)} />
                <TextAreaField md={12} control={itemForm.control} name="comment" label={lang.text(Translations.iir.equipment.comment)} />
                <InputField type='number' min={0} md={12} control={itemForm.control} name="lostValues" label={lang.text(Translations.iir.equipment.lostValues)} />
            </Row>
        );
    };

    return (
        <IIRReportItemBaseComponent
            title={lang.text(Translations.iir.equipmentInfo)}
            datas={datas}
            columns={[
                {
                    field: 'equipmentName',
                    title: lang.text(Translations.iir.equipment.equipmentName),
                },
                {
                    field: 'lostValues',
                    title: lang.text(Translations.iir.equipment.lostValues),
                },
                {
                    field: 'comment',
                    title: lang.text(Translations.iir.equipment.comment),
                },
                {
                    field: 'action',
                    title: '',
                },
            ]}
            isReadOnly={isReadOnly}
            formComponent={(data) => <FormComponent data={data} />}
            formComponentControl={itemForm}
            arrayForm={arrayForm}
        />
    );
}
