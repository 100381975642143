import { IIRUserStatus } from 'api/enums/IIR';
import { IIRReportHistory } from 'api/v2/iir/model/iirReportComponent.model';
import TimelineComponent from 'components/Common/TimeLine';
import { ChevronDown } from 'heroicons-react';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { Circle } from 'react-bootstrap-icons';

export default function IIRHistoryViewComponent({ datas }: { datas: IIRReportHistory[] }) {
    const [showAll, setShowAll] = useState(false);
    const getTitle = () => {
        return (
            <h6 className="text-primary" onClick={() => setShowAll(!showAll)} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {lang.text(Translations.common.history)}
            </h6>
        );
    };
    // const generateBagedStatus = (status: IIRUserStatus) => {};
    return (
        <Collapse in={true}>
            <div>
                <TimelineComponent
                    bodyStyle={{
                        maxHeight: '400px',
                        overflowY: 'auto',
                    }}
                    title={getTitle()}
                    items={
                        datas?.map((n) => ({
                            date: n.createdTime,
                            title: n.title,
                            createdFulName: n.createdUserName,
                            description: n.content,
                            content: n.content,
                        })) ?? []
                    }
                />
            </div>
        </Collapse>
    );
}
