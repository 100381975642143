import { TreeModel } from 'api/v2/user/model';
import userApi from 'api/v2/user/userApi';
import { set } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CheckSquare, ChevronDown, ChevronRight, DashSquare, NodeMinus, PlusSquare, Square } from 'react-bootstrap-icons';
import CheckboxTree, { Node, OnCheckNode, Node as ReactCheckBoxNode } from 'react-checkbox-tree';
import { randomUUID } from 'crypto';
import { distinctArray, generateGUID } from 'helper';

interface TNCourseUsersComponentProps {
    checked: string[]; //`${subChild.id}`,
    onValueChanged: Dispatch<SetStateAction<string[]>>;
    isReadOnly: boolean;
}

export const TNCourseUsersComponent = ({ isReadOnly, checked, onValueChanged }: TNCourseUsersComponentProps) => {
    const [users, setUsers] = useState<ReactCheckBoxNode[]>([]);
    const [expanded, setExpanded] = useState<string[]>([]);
    const [checkedTemp, setCheckedTemp] = useState<string[]>([]); // format `${child.id}"${subChild.id}`,

    /**Set selected value when checked changed */
    useEffect(() => {
        var ids = checkedTemp.filter((item) => item.includes(':')).map((item) => parseKey(item)) ?? [];
        var result = distinctArray(ids);
        if (!result.equals(checked)) onValueChanged(result);
    }, [checkedTemp]);

    useEffect(() => {
        let checkedTempIds: string[] = GetCheckedTemps(users);
        setCheckedTemp(checkedTempIds);
    }, [checked]);

    const onCheckedTempChanged = (checkedChange: Array<string>, node: OnCheckNode) => {
        if ((node as any).treeDepth != 2) {
            // Nếu node đang được check thì thêm tất cả các node con vào checkedTemp
            setCheckedTemp([...checkedChange]);
        } else if ((node as any).treeDepth == 2) {
            // nếu uncheck user, thì user nay sẽ đk remove khỏi tất cả selected có kết thúc = userId đó
            var checkedChangeTemp = checkedChange.filter((item) => !item.includes(parseKey(node.value)));
            setCheckedTemp(checkedChangeTemp);
        }
    };

    const createKey = (prefix: string, suffix: string) => `${prefix}:${suffix}`;
    const parseKey = (key: string) => key.split(':')[1];
    useEffect(() => {
        userApi
            .dropdownTree()
            .then((users: TreeModel[]) => {
                var nodes: Node[] = users.map((user) => {
                    return {
                        label: user.name,
                        value: user.id.toString(),
                        children: user.childrens?.map((child: TreeModel) => ({
                            label: child.name,
                            value: child.id,
                            children: child.childrens?.map((subChild: TreeModel) => ({
                                label: subChild.name,
                                value: createKey(child.id, subChild.id),
                            })),
                        })),
                    };
                });
                let checkedIds: string[] = GetCheckedTemps(nodes);

                setCheckedTemp(checkedIds);
                setUsers(nodes);
            })
            .catch((n) => {});
    }, []);
    const GetCheckedTemps = (nodes: ReactCheckBoxNode[]) => {
        let checkedIds: string[] = [];
        nodes.forEach((company) => {
            company.children?.forEach((areaOrDepartment: ReactCheckBoxNode) => {
                areaOrDepartment.children?.forEach((user: ReactCheckBoxNode) => {
                    if (checked.includes(parseKey(user.value))) {
                        checkedIds.push(user.value);
                    }
                });
            });
        });
        return checkedIds;
    };
    
    return (
        <div>
            <div className="course-users">
                <CheckboxTree
                    icons={{
                        check: <CheckSquare className="text-primary bold" />,
                        uncheck: <Square className="text-primary bold" />,
                        halfCheck: <DashSquare className="text-primary bold" />,
                        expandClose: <ChevronRight />,
                        expandOpen: <ChevronDown />,
                        expandAll: <PlusSquare className="text-primary" />,
                        collapseAll: <NodeMinus className="text-primary" />,
                        parentClose: <></>,
                        parentOpen: <></>,
                        leaf: <></>,
                    }}
                    nodes={users}
                    checked={checkedTemp}
                    expanded={expanded}
                    onCheck={onCheckedTempChanged}
                    onExpand={setExpanded}
                    disabled={isReadOnly}
                />
            </div>
        </div>
    );
};
