import { AddButton, SaveButton } from 'components/Button';
import useModalHook from 'components/ModalHook';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { ColumnType } from 'components/shared/PaginationTable';
import { on } from 'events';
import { formatDateTime, mappingFilePath } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import React, { useCallback, useMemo } from 'react';
import { Card, Table } from 'react-bootstrap';
import { Control, UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';

import { FieldValues } from 'react-hook-form';
import { toast } from 'react-toastify';
import { IIRReportLocalRequest } from './../iirReportCreationPage';

export interface IIRReportItemBaseComponentProps<T = any> {
    title: string;
    datas: T[];
    columns: ColumnType[];
    isReadOnly?: boolean;
    formComponent?: (data: T) => JSX.Element;
    formComponentControl?: UseFormReturn<any, any, undefined>;
    control?: Control<any>;
    isTextField?: boolean;
    arrayForm?: UseFieldArrayReturn<IIRReportLocalRequest, any, 'id'>;
}

export interface IIRListComponentProps<T extends FieldValues = any> {
    arrayForm?: UseFieldArrayReturn<IIRReportLocalRequest, any, 'id'>;
    isReadOnly?: boolean;
    datas: T[];
}

const IIRReportItemBaseComponent = <T,>({ isTextField, columns, datas, title, isReadOnly, formComponent, formComponentControl, arrayForm }: IIRReportItemBaseComponentProps<T>) => {
    const { openModal, Prompt, onCompleted, onClose } = useModalHook();
    const { getValues, trigger, reset } = formComponentControl || {};

    const openModalForm = ({ data, index }: { data?: T; index?: number }) => {
        reset?.(data);
        openModal({
            isFullScreen: '',
            title: lang.text(index ? Translations.common.edit : Translations.common.addNew),
            body: (
                <form>
                    <Card.Body>
                        {formComponent?.(data!)!}
                        <div className="d-flex justify-content-end pb-2">
                            <SaveButton type="button" onClick={() => onSubmit(data, index)} />
                        </div>
                    </Card.Body>
                </form>
            ),
        });
    };

    const onSubmit = async (data?: T, index?: number) => {
        const isValid = await trigger?.();
        if (isValid) {
            const formData = getValues?.();
            if (index) {
                arrayForm?.update(index, isTextField ? formData.name : formData);
            } else {
                arrayForm?.append(isTextField ? { name: formData.name } : formData);
            }
            onClose && onClose();
        } else {
            console.log('Validation failed');
        }
    };

    return (
        <Card>
            <Card.Header>
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <h6 className="me-3 mb-0 text-primary">{title}</h6>
                        <div>
                            <span className="btn btn-sm btn-icon btn-soft-primary text-primary rounded-pill">{datas?.length ?? 0}</span>
                        </div>
                    </div>
                    <AddButton
                        isRender={!isReadOnly}
                        type="button"
                        onClick={() => {
                            reset?.({});
                            openModalForm({});
                        }}
                    />
                </div>
            </Card.Header>
            <Card.Body>
                <Table className={`paginate-table mb-0 table-striped}`} id="datatable" data-toggle="data-table">
                    <thead>
                        <tr className="bg-white">
                            <th scope="col" className="px-1" style={{ width: '20px' }}>
                                <span>#</span>
                            </th>
                            {columns?.map((item, index) => (
                                <th scope="col" key={index} className={`${item.className || ''} px-2`} style={{ ...item.style }}>
                                    {item.title}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {(isReadOnly
                            ? datas
                            : datas?.map((n, index) => ({
                                  ...n,
                                  action: (
                                      <ActionButtons2
                                          id={String(index)}
                                          isConfirmDelete={false}
                                          deletePms={true}
                                          editAction={() => openModalForm({ data: n, index })}
                                          editPms={true}
                                          onDelete={() => {
                                              arrayForm && arrayForm.remove(index);
                                              return Promise.resolve();
                                          }}
                                      />
                                  ),
                              }))
                        )?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    {columns.map((col, colIndex) => {
                                        const value = col.field?.trim() ? (item as any)[col.field.trim()] : '';
                                        if (col.field == 'evidenceFiles') {
                                            return (
                                                <td key={colIndex + 1} className={`${col.className}`}>
                                                    <ul>
                                                        {value?.map((filePath: any, i: number) => (
                                                            <li>{<a href={mappingFilePath(filePath)}>{filePath}</a>}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            );
                                        } else
                                            return (
                                                <td key={colIndex + 1} className={`${col.className}`}>
                                                    {(value as any) instanceof Date ? formatDateTime(value) : value}
                                                </td>
                                            );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Card.Body>
            {Prompt}
        </Card>
    );
};

export default IIRReportItemBaseComponent;
