import Roles, { PMS } from 'router/const/roles';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { Accordion, AccordionContext, OverlayTrigger, Tooltip, useAccordionButton } from 'react-bootstrap';
import SidebarMenu from './sidebarMenu';
import Routers from 'router/const/routers';
import { FC, Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, File } from 'react-bootstrap-icons';

export interface AccordionItemChildProps {
    roles: PMS[];
    path: string;
    title: string;
    icon?: any;
    ignorePms?: boolean; // user nào cũng có thể vào
    groupName?: string;
    isDisplayVerticalNav: boolean;
}
export interface MenuGroupProps {
    groupIcon: any;
    groupName: string;
    key: string;
    active: string;
    items: AccordionItemChildProps[];
}
export const AccordionGroup = ({
    activeMenu,
    setActiveMenu,
    setActive,
    active,
    group,
}: {
    group: MenuGroupProps;
    setActiveMenu: (activeKey: string) => void;
    setActive: (activeKey: string) => void;
    activeMenu: string;
    active: string;
}) => {
    return (
        <>
            {group.items && (
                <Accordion.Item as="li" eventKey={group.key} bsPrefix={`nav-item ${active === group.key ? 'active' : ''} `} onClick={() => setActive(group.key)}>
                    <CustomToggle eventKey={group.key} active={activeMenu === group.key ? true : false} onClick={(activeKey: any) => setActiveMenu(activeKey.eventKey)}>
                        <OverlayTrigger placement="right" overlay={<Tooltip>{lang.text(Translations.sec.menuBar)}</Tooltip>}>
                            <Fragment> {group.groupIcon}</Fragment>
                        </OverlayTrigger>
                        <span className="item-name">{lang.text(group.groupName)}</span>
                        <ChevronRight />
                    </CustomToggle>

                    <Accordion.Collapse eventKey={group.key}>
                        <ul className="sub-nav">
                            {group.items.map((item, index) => (
                                <SidebarMenu
                                    key={index}
                                    ignorePms={item.ignorePms}
                                    roles={item.roles}
                                    isTag="false"
                                    staticIcon="true"
                                    pathname={item.path}
                                    title={lang.text(item.title)}
                                    minititle={item.title?.slice(0, 1)}
                                ></SidebarMenu>
                            ))}
                        </ul>
                    </Accordion.Collapse>
                </Accordion.Item>
            )}
        </>
    );
};

interface CustomToggleProps {
    children: React.ReactNode;
    eventKey: string;
    onClick: (activeKey: { state: boolean; eventKey: string }) => void;
    active?: boolean;
}

const CustomToggle: FC<CustomToggleProps> = ({ children, eventKey, onClick }) => {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, (active) => onClick({ state: !active, eventKey: eventKey }));

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <Link
            to="#"
            aria-expanded={isCurrentEventKey ? 'true' : 'false'}
            className="nav-link"
            role="button"
            onClick={(e) => {
                //@ts-ignore
                decoratedOnClick(isCurrentEventKey);
            }}
        >
            {children}
        </Link>
    );
};
