import { Content, Footer, HeaderPro } from 'components/builder';
import Sidebar from 'components/builder/sideBar';
import { ModalSelectCustomer } from 'components/shared';
import CustomerProvider from 'providers/customerProvider';

export default function DefaultLayout() {
    return (
        <CustomerProvider>
            <div>
            
                {/*Layout*/}
                <Sidebar />
                <main className="main-content">
                    <div className={`iq-banner default position-relative `}>
                        <HeaderPro />
                    </div>

                    {/* Content Page */}
                    <Content />
                    <Footer />
                </main>
            </div>
        </CustomerProvider>
    );
}
