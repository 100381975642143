//react-bootstrap
import orgUnitApi from 'api/v2/organizationUnitApi';
//components
import { yupResolver } from '@hookform/resolvers/yup';
import { TextAreaField, UploadImageField } from 'components/FormControl';
import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { Suspense, lazy, memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { OrganizationUnitResponse } from 'api/models/organization';
import { SaveButton } from 'components/Button';
import { useCustomer } from 'providers/contexts';

const ConfirmModal = lazy(() => import('components/shared/ConfirmModal'));

const ConfigCompanyUpdatePage = memo(() => {
    const { loadInfrastructure } = useCustomer();
    const { t } = useTranslation();
    const params = useParams();
    const [logo, setLogo] = useState<string | null>('');

    const schema = yup.object().shape({
        name: yup.string().required(t(Translations.form.validate.nameCannotEmpty)),
        logo: yup.string(),
    });

    const {
        control,
        handleSubmit,
        formState: { isSubmitting },
        getValues,
        setValue,
    } = useForm<{ name: string; logo?: string | undefined }>({
        defaultValues: {
            name: '',
            logo: '',
        },
        resolver: yupResolver(schema),
    });

    const [openConfirm, setOpenConfirm] = useState(false);

    useEffect(() => {
        params.id &&
            orgUnitApi
                .getById(params.id)
                .then((res: OrganizationUnitResponse) => {
                    setValue('name', res.name);
                    setValue('logo', res.logo ?? undefined);
                    setLogo(res.logo);
                })
                .catch(() => {});
    }, []);

    const handleConfirmSubmit = () => {
        setOpenConfirm(true);
    };

    const handleAddCategory = () => {
        if (!params.id) return;
        const values = getValues();

        const _addData = {
            name: values.name,
            logo: values.logo,
            id: params.id,
        };

        orgUnitApi
            .update(_addData)
            .then(() => {
                setOpenConfirm(false);
                loadInfrastructure();
            })
            .catch((n) => {});
    };

    return (
        <div>
            {/* Confirm add category */}
            <Suspense fallback={<div>Modal is Loading...</div>}>
                <ConfirmModal
                    show={openConfirm}
                    content={t(Translations.modal.confirm.confirmCreate)}
                    handleCancel={() => {
                        setOpenConfirm(false);
                    }}
                    handleAccept={handleAddCategory}
                />
            </Suspense>
            <Card>
                <Card.Body>
                    <form className="row justify-content-center" onSubmit={handleSubmit(handleConfirmSubmit)}>
                        <div className="col-md-8">
                            <TextAreaField name="name" control={control} label={t(Translations.common.upload)} />
                        </div>

                        <div className="col-md-4">
                            <UploadImageField src={logo} control={control} btnText={t(Translations.common.upload)} name="logo" />
                        </div>
                        <div className="row justify-content-center">
                            <SaveButton type="submit" className="d-flex align-items-end gap-1 mt-5 w-max" />
                        </div>
                    </form>
                </Card.Body>
            </Card>
        </div>
    );
});

export default ConfigCompanyUpdatePage;
// <Card>
//     <Card.Header>
//         <div className="text-end w-100 d-flex justify-content-between align-items-center">
//             <h2 className="mb-0">{t(Translations.button.update)}</h2>
//         </div>
//     </Card.Header>
//     <Card.Body>
//         <form
//             className="row justify-content-center"
//             onSubmit={handleSubmit(handleConfirmSubmit)}
//         >
//             <div className="col-lg-8 row">
//                 <div className="col-md-6">
//                     <TextAreaField
//                         name="name"
//                         control={control}
//                         label={t(Translations.form.name)}
//                         placeholder=""
//                     />
//                 </div>

//                 <Button
//                     type="submit"
//                     className="d-flex align-items-end gap-1 mx-auto mt-5 w-max"
//                 >
//                     {isSubmitting ? (
//                         <Spinner animation="border" className="me-3" variant="danger" />
//                     ) : (
//                         <Save className="icon-20" />
//                     )}
//                     {t(Translations.form.done)}
//                 </Button>
//             </div>
//         </form>
//     </Card.Body>
// </Card>
