import Translations from 'i18n/translation';
import React, { Suspense, lazy, useState } from 'react';
import { Eye, TrashFill, PencilSquare, Trash, EyeFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import ToastConfirm from 'components/Modal/ToastConfirm';
import { useAuth } from 'providers/contexts';
import { PMS } from 'router/const/roles';
// import { ThreeDots } from 'assets/icons/solid';
type ActionType = 'delete' | 'edit';

const ConfirmModal = lazy(() => import('components/shared/ConfirmModal'));

export const ActionButtons2 = ({
    id,
    editAction,
    onDelete,
    onDeleteSuccess,
    // viewAction,
    customActions,
    editPms,
    deletePms,
    viewPms,
    onViewDetail,
    isConfirmDelete = true,
}: {
    id: string;
    editPms?: boolean;
    viewPms?: boolean;
    deletePms?: boolean;
    editAction?: (id: string) => void;
    // viewAction?: (id: string) => void;
    onDeleteSuccess?: (id: string) => void;
    onViewDetail?: (id: string) => void;
    onDelete?: (id: string) => Promise<any>;
    isConfirmDelete?: boolean;
    customActions?: React.ReactNode;
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { tokenProfile } = useAuth();
    const [openConfirm, setOpenConfirm] = useState(false);

    const handleSelectAction = async (type: ActionType, id: string) => {
        if (type == 'edit' && editAction) {
            editAction(id);
        }
    };

    const deleteItem = async () => {
        if (onDelete) {
            setOpenConfirm(false);
            await onDelete(id).then((n) => {
                if (onDeleteSuccess) {
                    onDeleteSuccess(id);
                } else {
                    // setTimeout(() => {
                    //     navigate(0);
                    // }, 2000);
                }
            });
        }
    };

    return (
        <div className="d-flex justify-content-end align-items-center gap-1">
            {customActions}
            {/* {viewAction && viewAction && (
                <Button variant="icon btn-sm btn-info rounded" onClick={() => viewAction(id)}>
                    <span className="btn-inner">
                        <Eye size={20} />
                    </span>
                </Button>
            )} */}
            {editAction && editPms && (
                <Button variant="icon btn-sm btn-success rounded" onClick={() => handleSelectAction('edit', id)}>
                    <PencilSquare />
                </Button>
            )}
            {onDelete && deletePms && isConfirmDelete && (
                <ToastConfirm message={t(Translations.modal.confirm.confirmDelete)} onConfirm={deleteItem}>
                    <Button variant="icon btn-sm btn-danger rounded">
                        <Trash />
                    </Button>
                </ToastConfirm>
            )}

            {onDelete && deletePms && !isConfirmDelete && (
                <Button onClick={deleteItem} variant="icon btn-sm btn-danger rounded">
                    <Trash />
                </Button>
            )}
            {onViewDetail && (
                <Button variant="icon btn-sm btn-info rounded" onClick={() => onViewDetail(id)}>
                    <EyeFill />
                </Button>
            )}
        </div>
    );
};
