import Translations from 'i18n/translation';
import { MouseEventHandler } from 'react';
import { Button } from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { BaseButton } from './BaseButton';
import { lang } from 'i18n/lang';
import { BaseButtonProps } from './BaseButton.props';
import Color from 'enum/color';

export function UpdateIconButton({ onClick }: BaseButtonProps) {
    return (
        <div onClick={onClick} className="d-flex btn-edit align-items-center justify-content-center btn btn-primary btn-icon btn-sm rounded-circle me-2 ">
            <Pencil />
        </div>
    );
}

export function UpdateButton(props: BaseButtonProps) {
    return <BaseButton {...props} icon={<Pencil />} color={Color.info} text={lang.text(Translations.button.update)} />;
}

export function UpdateButtonNavigate({ url }: { url: string; onClick?: MouseEventHandler }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Button onClick={() => navigate(url)} variant="success" size="sm" className="d-flex flex-row align-items-center rounded m-1">
            <span className="btn-inner me-2">
                <Pencil />
            </span>
            <div>{t(Translations.button.update)}</div>
        </Button>
    );
}
