import { Logo as MainLogo } from 'assets/images';
import { Fragment, memo } from 'react';
import { Image } from 'react-bootstrap';

const Logo = memo(() => {
  return (
    <Fragment>
      <div>
        <Image className="logo-custom" width={26} height={26} src={MainLogo} />
      </div>
    </Fragment>
  );
});

Logo.displayName = 'Logo';
export default Logo;
