import { Form, Table } from 'react-bootstrap';
import DataListView from './DataListView';
import { isMobileDevide } from 'helper';
import { ColumnType } from './PaginationTable';
import Empty from './Empty';

interface PaginationTableProps {
    columns: ColumnType[];
    datas: any[];
    isStriped?: boolean;
    wrapperClass?: string;
    onRowClick?: (row: any) => void;
    loading?: boolean;
    hideHead?: boolean;
    action?: () => React.ReactNode;
}
export function SopTable({
    datas,
    columns,
    isStriped = false,
    wrapperClass = '',
    onRowClick,
    loading = false,
    hideHead = false,
}: // action,
PaginationTableProps) {
    const loadMobileLayout = () => {
        return datas?.map((item, index) => {
            return <DataListView datas={columns.map((n) => ({ label: n.title, value: item[n.field] }))} />;
        });
    };
    const loadTableLayout = () => {
        return (
            <Table className={`mb-0 ${isStriped ? 'table-striped' : ''}`} id="datatable" data-toggle="data-table">
                <thead>
                    <tr className="bg-white">
                        <th scope="col">#</th>
                        {columns.map((item, index) => (
                            <th scope="col" key={index} className={item.className ? item.className : ''}>
                                {item.title}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {datas?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                {columns.map((col, colIndex) => (
                                    <td
                                        key={colIndex + 1}
                                        className={`${col.className} ${onRowClick ?? ' cursor-pointer-hover'}}`}
                                        onClick={() => {
                                            col.field != 'action' && onRowClick && onRowClick(item);
                                        }}
                                    >
                                       
                                        <div>
                                            <span> 
                                                {item[col.field?.toString().trim()]}
                                            </span>
                                        </div>
                                    </td>
                                ))}
                                {/* {action && <td key={index}>{item['action']}</td>} */}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    };
    return (
        <section>
            {/* Table body */}
            {datas?.length ? (
                <div className="position-relative">
                    <div className={`fancy-table table-responsive rounded ${wrapperClass}`}>{isMobileDevide() ? loadMobileLayout() : loadTableLayout()}</div>
                    {loading && (
                        <div
                            className="position-absolute top-0 w-100 left-0 h-100 d-flex justify-content-center align-items-center"
                            style={{
                                backgroundColor: 'rgba(255, 255, 2555, .3)',
                            }}
                        >
                            <div className="iq-loader-box">
                                <div className="iq-loader-6">
                                    <span className="inner inner1"></span>
                                    <span className="inner inner2"></span>
                                    <span className="inner inner3"></span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <Empty />
            )}
        </section>
    );
}
