import { useEffect } from 'react';
import { Form, Row, Col, FormCheck } from 'react-bootstrap';
import { Control, Controller, useController } from 'react-hook-form';

export interface RadioOption {
    label?: string;
    value: number | string;
    defaultChecked?: boolean;
    className?: string;
}

export interface RadioFieldProps {
    name: string;
    control: Control<any>;
    label?: string;
    options?: RadioOption[];
    formGroupClasses?: string;
    readonly?: boolean;
    disabled?: boolean;
    defaultValue?: any;
    required?: boolean;
    inline: boolean;
    orientation?: 'horizontal' | 'vertical';
    onValueChange?: (value: string | number) => void;
}

export function RadioField({ orientation, inline, required, name, control, label, options, formGroupClasses, readonly = false, disabled = false, onValueChange, defaultValue }: RadioFieldProps) {
    const {
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
    } = useController({
        name,
        control,
    });

    const handleChangeOption = (e: any) => {
        if (onValueChange) {
            onValueChange(e);
        }
    };
    useEffect(() => {
        defaultValue && onChange(defaultValue);
    }, [defaultValue]);

    return (
        <>
            <Form.Group className={formGroupClasses}>
                {label && (
                    <Form.Label>
                        {label} {orientation}
                    </Form.Label>
                )}
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    rules={{ required }}
                    render={({ field }) => (
                        <div className={(orientation ?? 'horizontal') == 'vertical' ? 'd-flex flex-column' : 'd-flex'}>
                            {/* <h1>{readonly.toString()}</h1> */}
                            {options?.map((option, index) => (
                                <Form.Check
                                    key={index}
                                    type="radio"
                                    label={option.label}
                                    value={option.value}
                                    checked={field.value === option.value}
                                    onChange={(e) => {
                                        if (readonly) return;
                                        console.log('on change ', e.target.value);
                                        // field.onChange(e.target.value);
                                        // onValueChange?.(e.target.value);
                                        field.onChange(option.value);
                                        handleChangeOption(option.value);
                                    }}
                                    inline={inline}
                                    readOnly={readonly}
                                    disabled={disabled}
                                />
                            )) ?? []}
                        </div>
                    )}
                />
            </Form.Group>
            {/* <Form.Group className={`mb-3 ${formGroupClasses}`}>
                {label && (
                    <Form.Label htmlFor={name} className="">
                        {label}
                    </Form.Label>
                )}
                <Row>
                    {options?.map((item, index) => {
                        return (
                            <Col key={index}>
                                <Form.Check
                                    required={required}
                                    inline={inline}
                                    className={item.className}
                                    id={`radio-${item.value}}`}
                                    value={defaultValue ?? value}
                                    type="radio"
                                    label={item.label}
                                    name={name}
                                    onChange={(n) => {
                                        // if (readonly) return;
                                        onChange(item.value);
                                        handleChangeOption(item.value);
                                    }}
                                >
                                </Form.Check>
                            </Col>
                        );
                    })}
                </Row>
                <div className="invalid-feedback">{error?.message}</div>
            </Form.Group> */}
        </>
    );
}
