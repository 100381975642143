import { PaginationBase } from 'api/models/common';
import client from '../client';
import { TripCreateRequest, TripDetailResponse, TripFilter, TripReportFilter, TripReportResponse, TripReqChangeRoute, TripResponse, TripStatus, TripUpdateRequest } from './model/trip.model';

const baseURL = '/Trip';
const tripApi = {
    getByIndex(filter: Partial<TripFilter>): Promise<PaginationBase<TripResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<TripResponse>>({
            endpoint: url,
            data: filter,
            isLoading: true,
        });
    },
    getById(id: string): Promise<TripDetailResponse> {
        const url = `${baseURL}/getById/${id}`;
        return client.getNonWrap<TripDetailResponse>({
            endpoint: url,
            isLoading: true,
        });
    },
    create(data: TripCreateRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data,
            isLoading: true,
        });
    },
    update(data: TripUpdateRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<TripUpdateRequest>({
            endpoint: url,
            data,
            isLoading: true,
        });
    },
    delete(id: string): Promise<string> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<string>({
            endpoint: url,
            isLoading: true,
        });
    },
    updateStatus(data: { status: TripStatus; tripId: string; description: string }): Promise<unknown> {
        const url = `${baseURL}/updateStatus`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
            isLoading: true,
        });
    },
    addNote(data: { id: string; text: string }): Promise<unknown> {
        const url = `${baseURL}/addNote/${data?.id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: {
                text: data?.text,
            },
            isLoading: true,
        });
    },
    requestChangeRoute(data: TripReqChangeRoute): Promise<unknown> {
        const url = `${baseURL}/requestchangeRoute`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data,
            isLoading: true,
        });
    },
    approveChangeRoute(id: string): Promise<unknown> {
        const url = `${baseURL}/ApproveChangeRoute/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            isLoading: true,
        });
    },
    rejectChangeRoute(id: string): Promise<unknown> {
        const url = `${baseURL}/RejectChangeRoute/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            isLoading: true,
        });
    },
    getTripReport(filter: TripReportFilter): Promise<TripReportResponse[]> {
        const url = `TripReport`;
        return client.getNonWrap<TripReportResponse[]>({
            endpoint: url,
            data: filter,
            isLoading: true,
        });
    },
};

export default tripApi;
