import React from 'react';

const Call = React.forwardRef((props: { className?: string }, ref) => {
  const { className = 'icon-24' } = props;
  return (
    <i className="icon">
      <svg
        width="32"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        height="32"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z"
          fill="currentColor"
        ></path>
      </svg>
    </i>
  );
});

export default Call;
