import { MouseEvent, ReactNode, forwardRef } from 'react';
import { Link } from 'react-router-dom';

interface CustomToggleProps {
    children: ReactNode;
    variant: string;
    onClick: (event: MouseEvent) => void;
}

const CustomToggle2 = forwardRef<HTMLAnchorElement, CustomToggleProps>(({ children, variant, onClick }, ref) => (
    <Link
        to="/"
        ref={ref}
        onClick={(e: MouseEvent) => {
            e.preventDefault();
            onClick(e);
        }}
        className={variant}
        style={{ color: 'unset' }}
    >
        {children}
    </Link>
));
export default CustomToggle2;
