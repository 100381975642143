import { RouteObject } from 'react-router-dom';

import Routers from 'router/const/routers';
import { ActionPage } from 'views/action';

const actionRouters: RouteObject[] = [
  {
    path: Routers.action.root,
    element: <ActionPage />,
  },
];

export default actionRouters;
