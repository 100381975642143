import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { AddButton } from 'components/Button';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import OrganizationUnitMultiSelect from 'components/Common/OrganizationUnitMultiSelect';
import { EquipmentReportTemplateResponse } from 'api/models';
import { FancyTable, FancyTableDataType } from 'components/shared';
import { useForm } from 'react-hook-form';
import useModalHook from 'components/ModalHook';
import equipmentApi from 'api/v2/equipmentApi';
import { EquipmentHelper } from 'helper/Feature/equipment.help';
import { Badge } from 'react-bootstrap';
import AddOrUpdateComponent from './addOrUpdate.component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CompanySelect } from 'components/Common';
import { PaginationTable } from 'components/shared/PaginationTable';
import { FilterButton } from 'components/Button/FilterButton';
import { useAuth } from 'providers/contexts';
import useQueryParams from 'hooks/useQueryParams';
import { lang } from 'i18n/lang';
import EquipmentTemplateDetail from './detailPage';

const EquipmentTemplatePage = memo(() => {
    const [paginationData, setPaginationData] = useState<PaginationBase<FancyTableDataType>>();
    const { t } = useTranslation();
    const [companyId, setCompanyId] = useState<string | null>(null);
    const { userPms } = useAuth();
    const { control, ...rest } = useForm();
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    const location = useLocation();
    var navigate = useNavigate();
    const { getQueryParam } = useQueryParams();
    useEffect(() => {
        handleFetchData(defaultPaginationRequest);
    }, [companyId]);

    useEffect(() => {
        let id = getQueryParam('id');
        id && showDetail(id);
    }, [location.search]);

    const showDetail = async (id: string) => {
        id &&
            (await openModal({
                isFullScreen: true,
                title: lang.text(Translations.equipment.checkList),
                isResetRoute: true,
                body: <EquipmentTemplateDetail id={id} />,
            }));
    };
    const handleFetchData = (pagination: PaginationBaseRequest) => {
        pagination.filter = companyId;
        equipmentApi
            .getTemplatePagination(pagination)
            .then((pagination: PaginationBase<EquipmentReportTemplateResponse>) => {
                let pagi: PaginationBase = {
                    ...pagination,
                    datas:
                        pagination.datas?.map((item) => {
                            return {
                                ...item,
                                templateName: item.templateName,
                                reportType: EquipmentHelper.reportTypeToString(item.reportType),
                                companies: item.companies?.map((n) => (
                                    <Badge bg="info" className="m-1">
                                        {n.name}
                                    </Badge>
                                )),
                                typeGroups: item.typeGroups?.map((n) => (
                                    <Badge bg="warning" className="m-1">
                                        {n.typeGroupName}
                                    </Badge>
                                )),
                                action: (
                                    <ActionButtons2
                                        onViewDetail={() => navigate('?id=' + item.id, { replace: true })}
                                        id={item.id}
                                        editPms={userPms.equipment.admin}
                                        deletePms={userPms.equipment.admin}
                                        editAction={() => onUpdate(item)}
                                        onDelete={(id) => onDelete(id)}
                                    />
                                ),
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((n) => {});
    };

    const onAdd = async () => {
        var rs = await openModal({
            title: t(Translations.equipment.templateCategories),
            body: <AddOrUpdateComponent onClose={onClose} onCompleted={onCompleted} />,
        });
        if (rs) handleFetchData(defaultPaginationRequest);
    };

    const onDelete = async (id: string) => {
        equipmentApi
            .deleteTemplate(id)
            .then((n) => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch((n) => {});
    };

    const onUpdate = async (template: EquipmentReportTemplateResponse) => {
        var rs = await openModal({
            title: t(Translations.equipment.type),
            body: <AddOrUpdateComponent onClose={onClose} onCompleted={onCompleted} template={template} />,
        });
        if (rs) {
            handleFetchData(defaultPaginationRequest);
        }
    };

    const cols = useMemo(
        () => [
            {
                field: 'templateName',
                title: t(Translations.common.name),
            },
            {
                field: 'reportType',
                title: t(Translations.common.reportType),
            },
            {
                field: 'typeGroups',
                title: t(Translations.equipment.type),
            },
            {
                field: 'companies',
                title: t(Translations.form.site.name),
            },
            {
                field: 'createdUserName',
                title: t(Translations.common.createdBy),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    return (
        <div>
            <Card>
                <Card.Header>
                    <div className="row w-100">
                        <CompanySelect name="companyId" control={control} onCompanyChange={setCompanyId} md={4} />
                        <div className="col-md-4 "></div>
                        <div className="col-md-4 d-flex justify-content-end">
                            <div>
                                <AddButton isRender={true} onClick={onAdd} />
                                <FilterButton type="button" onClick={() => handleFetchData(defaultPaginationRequest)} className="mx-2 " />
                            </div>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <PaginationTable onSearch={handleFetchData} onPaginate={handleFetchData} columns={cols} pagination={paginationData} isStriped={true} wrapperClass="mt-5" />
                </Card.Body>
            </Card>
            {Prompt}
        </div>
    );
});

export default EquipmentTemplatePage;
