import client from '../client';
import { RATagCreateRequest, RATagResponse, RATagUpdateRequest } from './model/rs.Response.model';

const baseURL = '/raTag';
const raTagApi = {
    create(data: RATagCreateRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: RATagUpdateRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
    getTag(): Promise<RATagResponse[]> {
        const url = `${baseURL}/getTag`;
        return client.getNonWrap<RATagResponse[]>({
            endpoint: url,
        });
    },
};

export default raTagApi;
