import { PaginationBaseRequest, BaseAuditResponse } from 'api/models/common';
import { TNCourseSubmissionAnswer } from './tnCourse.model';

export interface TNCourseSubmissionSetPointRequest {
    point: number;
    comment?: string;
    submissionItemId: string;
    answerComments: {
        questionId: string;
        comment?: string;
    }[];
    isRemake: boolean;
}

export interface TNCourseSubmissionFilterRequest extends PaginationBaseRequest {
    courseId?: string;
    status?: TNSubmissionStatus;
}

export enum TNSubmissionStatus {
    Active = 'Active',
    UserCompleted = 'UserCompleted',
    Remake = 'Remake',
    Finishe = 'Finished',
}
// export enum TNSubmissionItemStatus {
//     /**
//      *  Nộp bài chờ chấm điểm
//      */
//     Active = 'Active',
//     /**
//      *  Đã chấm điểm
//      */
//     Finishe = 'Finished',
//     /**
//      *  Đã chấm điểm
//      */
//     Remake = 'Remake',
// }
// export interface TNUserSubmissionResponse {
//     userId: string;
//     lastPoint: number;
//     fullName: string;
//     lastComment?: string;
//     startDate: Date;
//     completedDate: Date;
//     status: TNSubmissionStatus;
//     submissions: TNUserSubmissionItem[];
//     course: TNCourseDetailResponse; // chi co value trong api get data setpoint
// }

export interface TNSubmissionDetailResponse {
    id: string;
    userId: string;
    fullName: string;
    startLearnDate: Date;
    endLearnDate?: Date;
    comment?: string;
    lastSetPointTime?: Date;
    lastPoint: number;
    completedDate?: Date;
    courseId: string;
    status: TNSubmissionStatus;
    items: TNSubmissionItemResponse[];
}

export interface TNSubmissionItemResponse extends BaseAuditResponse {
    id: string;
    submissionId: string;
    comment: string;
    commentUserName: string;
    point: number;
    status: TNSubmissionStatus;
    answers: TNCourseSubmissionAnswer[];
}
