import { SelectField, SelectOption } from 'components/FormControl';
import Translations from 'i18n/translation';
import { useCustomer } from 'providers/contexts';
import { useEffect, useState } from 'react';
import tnTopicAPI from 'api/v2/training/tnTopicApi';
import { SelectProps } from 'components/FormControl/Select';
import { lang } from 'i18n/lang';

export interface TopicSelectProps extends SelectProps {}
export function TNTopicSelect(props: TopicSelectProps) {
    const { currentCustomer } = useCustomer();
    const [options, setOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        loadDatas();
    }, [currentCustomer]);
    useEffect(() => {
        loadDatas();
    }, []);
    const loadDatas = () => {
        tnTopicAPI
            .getDropdown()
            .then((res) => {
                setOptions(res);
            })
            .catch(() => {});
    };

    return (
        <SelectField
            {...props}
            label={lang.text(Translations.training.topic)}
            options={options}
            name={props.name}
            isMutiple={false}
            isAddAll={true}
            onValueChange={(e) => {
                props.onValueChange && props.onValueChange(e);
            }}
        />
    );
}
export default TNTopicSelect;
