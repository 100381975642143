import Images from 'constants/images';
import Translations from 'i18n/translation';
import { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Image, Row, Tab, Tabs } from 'react-bootstrap';
import * as yup from 'yup';
import { UserDetailResponse } from 'api/v2/user/model';
import DataListView from 'components/shared/DataListView';
import { lang } from 'i18n/lang';
import { InputField } from 'components/FormControl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DateTimeField from 'components/FormControl/DateTimeField';
import CertificationComponent from './component/certification.component';
import CourseComponent from './component/course.component';
import IncentiveDisciplinaryComponent from './component/incentiveDisciplinary.component';
import { EntityConst } from 'constants/EntityConst';
import profileApi from 'api/v2/user/profileApi';
import { SaveButton } from 'components/Button';

export default function UserProfilePage() {
    const [userProfile, setUserProfile] = useState<UserDetailResponse>();
    useEffect(() => {
        profileApi
            .getProfile()
            .then((n) => {
                setUserProfile(n);
            })
            .catch((n) => {});
    }, []);
    const schema = yup.object().shape({
        height: yup.string().max(EntityConst.short),
        weight: yup.string().max(EntityConst.short),
        bloodType: yup.string().max(EntityConst.short),
        healthStatus: yup.string().max(EntityConst.longString),
        healthCheckResultDate: yup.string().max(EntityConst.longString),
    });
    const {
        control: controlMedical,
        handleSubmit: handleSubmitMedical,
        getValues: getValuesMedical,
    } = useForm({
        defaultValues: {},
        resolver: yupResolver(schema),
    });

    const SaveMedical = () => {
        let form = getValuesMedical();
        profileApi
            .updateMedical(form)
            .then((n) => {})
            .catch((n) => {});
    };

    return (
        <>
            {userProfile && (
                <Tab.Container defaultActiveKey="first">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                <div className="d-flex flex-wrap align-items-center">
                                                    <div className=" position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1">
                                                        <Image className="theme-color-default-img  img-fluid rounded-pill avatar-100" src={Images.avatar} alt="profile-pic" />
                                                    </div>
                                                    <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                                        <h4 className="me-2 h4">{userProfile.fullName}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-flex justify-content-end">
                                            {/* <div>{userProfile.qRCode}</div> */}

                                            <div className="avatar-130" dangerouslySetInnerHTML={{ __html: userProfile.qRCode ?? '' }} />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                    <div className="header-title">
                                        <h5 className="card-title">{lang.text(Translations.pageName.userProfile)}</h5>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <DataListView
                                        datas={[
                                            {
                                                label: lang.text(Translations.form.fullName.name),
                                                value: userProfile.fullName,
                                            },
                                            {
                                                label: lang.text(Translations.form.title.name),
                                                value: userProfile.title,
                                            },
                                            {
                                                label: lang.text(Translations.form.username.name),
                                                value: userProfile.userName,
                                            },
                                            {
                                                label: lang.text(Translations.common.role),
                                                value: userProfile.roleName,
                                            },
                                            {
                                                label: lang.text(Translations.form.phone.name),
                                                value: userProfile.phoneNumber,
                                            },
                                            {
                                                label: lang.text(Translations.form.email.name),
                                                value: userProfile.email,
                                            },
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                    <div className="header-title">
                                        <h5 className="card-title">{'Thông tin tổ chức'}</h5>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <DataListView
                                        datas={
                                            (userProfile.organizationUnits &&
                                                userProfile.organizationUnits
                                                    .filter((n) => !n.parentId)
                                                    ?.map((n) => ({
                                                        label: n.name,
                                                        value: userProfile.organizationUnits?.filter((x) => x.parentId == n.id).map((x) => <li>{x.name}</li>),
                                                    }))) ??
                                            []
                                        }
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/* Hồ sơ y tế */}
                    <div className="row">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title d-flex">
                                    <h5 className="card-title ">{lang.text(Translations.userProfile.medicalRecord)}</h5>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <form onSubmit={handleSubmitMedical(SaveMedical)}>
                                    <div className="row">
                                        <InputField
                                            md={4}
                                            defaultValue={userProfile.height}
                                            required={false}
                                            name="height"
                                            control={controlMedical}
                                            label={lang.text(Translations.userProfile.height)}
                                            placeholder="m"
                                        />
                                        <InputField
                                            md={4}
                                            defaultValue={userProfile.weight}
                                            required={false}
                                            name="weight"
                                            control={controlMedical}
                                            label={lang.text(Translations.userProfile.weight)}
                                            placeholder="kg"
                                        />
                                        <InputField
                                            md={4}
                                            defaultValue={userProfile.bloodType}
                                            required={false}
                                            name="bloodType"
                                            control={controlMedical}
                                            label={lang.text(Translations.userProfile.bloodType)}
                                            placeholder=""
                                        />
                                        <InputField
                                            md={6}
                                            defaultValue={userProfile.healthStatus}
                                            required={false}
                                            name="healthStatus"
                                            control={controlMedical}
                                            label={lang.text(Translations.userProfile.healthStatus)}
                                            placeholder=""
                                        />
                                        <InputField
                                            md={6}
                                            defaultValue={userProfile.healthCheckResultDate}
                                            required={false}
                                            name="healthCheckResultDate"
                                            control={controlMedical}
                                            label={lang.text(Translations.userProfile.healthCheckResultDate)}
                                            placeholder="dd-MM-yyyy"
                                        />

                                        <div className="col-md-12 d-flex justify-content-end">
                                            <SaveButton text={lang.text(Translations.button.update)} type="submit" color='info'  />
                                        </div>
                                    </div>
                                </form>
                            </Card.Body>
                        </Card>
                    </div>
                    {/* // Hồ sơ đào tạo */}
                    <div className="row">
                        <Card>
                            <Card.Body>
                                <div className="row">
                                    <Tabs variant={'tabs'} defaultActiveKey="certificate" className="mb-3">
                                        <Tab color="info" eventKey="certificate" title={lang.text(Translations.userProfile.certificate)}>
                                            <CertificationComponent />
                                        </Tab>
                                        <Tab eventKey="course" title={lang.text(Translations.userProfile.course)}>
                                            <CourseComponent />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    {/* // Hồ sơ đào tạo */}
                    <div className="row">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title d-flex">
                                    <h5 className="card-title ">{lang.text(Translations.userProfile.profileIncentiveDisciplinary)}</h5>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="row">
                                    <IncentiveDisciplinaryComponent />
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </Tab.Container>
            )}
        </>
    );
}
