import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { CSSProperties } from 'react';
import { Table } from 'react-bootstrap';
export interface DataListViewItem {
    label: any | string;
    value: any;
}
interface DataListViewProps {
    datas: DataListViewItem[];
    bordered?: boolean;
    classNameRow?: string;
    firstColumnStyle?: CSSProperties; // class fist column, use incase first column is index (1,2,3,4...)
    header?: any;
    headerGeneralInfo?: boolean;
    headerClosedInfo?: boolean;
}
const DataListView = ({ firstColumnStyle, datas, headerGeneralInfo, header, headerClosedInfo, bordered, classNameRow = 'py-2' }: DataListViewProps) => {
    return (
        datas && (
            <div className={`table-reposive`}>
                {headerGeneralInfo == true ? <h6 className="text-primary">{lang.text(Translations.common.generalInfo)} </h6> : ''}
                {headerClosedInfo == true ? <h6 className="text-primary">{lang.text(Translations.common.closeReport)} </h6> : ''}
                {header && <h6 className="text-primary">{header}</h6>}
                <Table bordered={bordered}>
                    <tbody>
                        {datas.map((item, index) => (
                            <tr key={index}>
                                <td className={classNameRow} style={firstColumnStyle}>
                                    {item.label}
                                </td>
                                <td className={classNameRow}>{item.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    );
};
export default DataListView;
