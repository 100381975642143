import { PMS } from 'router/const/roles';
import { isMobileDevide } from 'helper';
import { useAuth } from 'providers/contexts';
import { Fragment, memo } from 'react';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { hidenMiniSideBar } from './actions';
import roleApi from 'api/v2/role/roleApi';

interface SidebarMenuProps {
    isTag: string;
    pathname: string;
    title: string;
    children?: any;
    modalopen?: () => void;
    target?: string;
    staticIcon?: string;
    minititle?: string;
    roles?: PMS[] | PMS;
    ignorePms?: boolean;
}

const SidebarMenu = memo((props: SidebarMenuProps) => {
    let location = useLocation();
    const { tokenProfile } = useAuth();
    const allRoles = tokenProfile?.roles ?? [];
    const hideMinisideBar = () => {
        if (isMobileDevide()) {
            hidenMiniSideBar();
        }
    };
    // if (props.pathname.includes('/sor')) {
    //     let a = (props.ignorePms != true && Array.isArray(props.roles) && !props.roles?.hasValue()) ;
    //     let b = (!Array.isArray(props.roles) && !allRoles.find((r) => r == props.roles));
    //     let aa = 1;
    // }

    // if ((props.ignorePms != true && Array.isArray(props.roles) && !props.roles?.hasValue()) || (!Array.isArray(props.roles) && !allRoles.find((r) => r == props.roles))) return <></>;
    return (
        <Fragment>
            {props.isTag === 'true' && (
                <li className={`${location.pathname === `${props.pathname}` ? 'active' : ''} nav-item `}>
                    <Link onClick={hideMinisideBar} className={`${location.pathname === `${props.pathname}` ? 'active' : ''} nav-link `} aria-current="page" to={props.pathname}>
                        <OverlayTrigger placement="right" overlay={<Tooltip>{props.title}</Tooltip>}>
                            {props.children}
                        </OverlayTrigger>
                        <span className="item-name">{props.title}</span>
                    </Link>
                </li>
            )}
            {props.isTag === 'false' && (
                <Nav.Item as="li">
                    <Link
                        className={`${location.pathname === `${props.pathname}` ? 'active' : ''} nav-link`}
                        to={props.pathname}
                        onClick={() => {
                            hideMinisideBar();
                            props.modalopen && props.modalopen();
                        }}
                        target={props.target}
                    >
                        {props.staticIcon === 'true' && (
                            <i className="icon">
                                <svg className="icon-10" xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                                    <g>
                                        <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                                    </g>
                                </svg>
                            </i>
                        )}
                        {props.children}
                        <OverlayTrigger placement="right" overlay={<Tooltip>{props.title}</Tooltip>}>
                            <i className="sidenav-mini-icon text-uppercase"> {props.minititle} </i>
                        </OverlayTrigger>
                        <span className="item-name"> {props.title} </span>
                    </Link>
                </Nav.Item>
            )}
        </Fragment>
    );
});

SidebarMenu.displayName = 'SidebarMenu';
export default SidebarMenu;
