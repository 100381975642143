import { RouteObject } from 'react-router-dom';

import Routers from 'router/const/routers';
import { RACreatePage, RAListPage } from 'views/risk-assessment';

const riskRouters: RouteObject[] = [
    {
        path: Routers.riskAssessment.register,
        element: <RACreatePage />,
    },
    {
        path: Routers.riskAssessment.onlineDataBase,
        element: <RAListPage />,
    },
];

export default riskRouters;
