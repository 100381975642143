import Translations from 'i18n/translation';
import { Button } from 'react-bootstrap';
import { Save2Fill } from 'react-bootstrap-icons';
import { BaseButtonProps } from './BaseButton.props';
import { lang } from 'i18n/lang';

export function SaveButton({ onClick, type, disable, className, text, color }: BaseButtonProps) {
    return (
        <Button disabled={disable ?? false} onClick={onClick} variant={color ?? 'success'} type={type} size="sm" className={className ?? '' + ' rounded d-flex align-items-end gap-1  w-max'}>
            <span className="btn-inner me-2">
                <Save2Fill />
            </span>
            <div>{text ?? lang.text(Translations.form.done)}</div>
        </Button>
    );
}
