import { RouteObject } from 'react-router-dom';

import Routers from 'router/const/routers';
import { ReportPage, RouteTemplateCreatePage, RouteTemplatePage, TripCreatePage, TripPage } from 'views/trip';
import TripDetailPage from 'views/trip/detailPage';
import TripRequestChangeRoute from 'views/trip/requestChangeRoute';

const tripRouters: RouteObject[] = [
    {
        path: Routers.trip.trip,
        element: <TripPage />,
    },
    {
        path: Routers.trip.create,
        element: <TripCreatePage />,
    },
    {
        path: Routers.trip.update,
        element: <TripCreatePage />,
    },
    {
        path: Routers.trip.tripDetail,
        element: <TripDetailPage />,
    },
    {
        path: Routers.trip.report,
        element: <ReportPage />,
    },
    {
        path: Routers.trip.routeDetail,
        element: <RouteTemplateCreatePage />,
    },
    {
        path: Routers.trip.route,
        element: <RouteTemplatePage />,
    }
];

export default tripRouters;
