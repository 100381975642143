import { IIREnvironmentalDamageModel } from 'api/v2/iir/model/iirReportComponent.model';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import * as yup from 'yup';
import IIRReportItemBaseComponent, { IIRListComponentProps } from './iirBaseItem.component';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputField, TextAreaField } from 'components/FormControl';
import { Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const schema = yup.object().shape({
    location: yup.string().required('Location is required'),
    formOfLoss: yup.string().required('Form of loss is required'),
    damageLevel: yup.string().required('Damage level is required'),
});

export default function IIREnvironmentalDamageViewComponent({ datas, isReadOnly, arrayForm }: IIRListComponentProps<IIREnvironmentalDamageModel>) {
    const itemForm = useForm({
        resolver: yupResolver(schema),
    });

    const FormComponent = ({ data }: { data: IIREnvironmentalDamageModel }) => {
        useEffect(() => {
            itemForm.reset(data);
        }, [data]);
        return (
            <Row>
                <InputField md={12} required control={itemForm.control} name="location" label={lang.text(Translations.iir.environmentalDamage.location)} />
                <TextAreaField md={12} required control={itemForm.control} name="formOfLoss" label={lang.text(Translations.iir.environmentalDamage.formOfLoss)} />
                <InputField md={12} required control={itemForm.control} name="damageLevel" label={lang.text(Translations.iir.environmentalDamage.damageLevel)} />
            </Row>
        );
    };

    return (
        <IIRReportItemBaseComponent
            title={lang.text(Translations.iir.environmentalDamages)}
            datas={datas}
            arrayForm={arrayForm}
            isReadOnly={isReadOnly}
            formComponent={(data) => <FormComponent data={data} />}
            formComponentControl={itemForm}
            columns={[
                {
                    field: 'location',
                    title: lang.text(Translations.iir.environmentalDamage.location),
                },
                {
                    field: 'damageLevel',
                    title: lang.text(Translations.iir.environmentalDamage.damageLevel),
                },
                {
                    field: 'formOfLoss',
                    title: lang.text(Translations.iir.environmentalDamage.formOfLoss),
                },
                {
                    field: 'action',
                    title: '',
                },
            ]}
        />
    );
}
