import { yupResolver } from '@hookform/resolvers/yup';
import { ProfileIncentiveDisciplinaryRequest } from 'api/v2/user/model';
import { InputField, SelectField, SelectOption } from 'components/FormControl';
import DateTimeField from 'components/FormControl/DateTimeField';
import UploadFileField from 'components/FormControl/UploadFileField';
import { ModalHookComponentProps } from 'components/ModalHook';
import { EntityConst } from 'constants/EntityConst';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { title } from 'process';
import { useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface IncentiveDisciplinaryProps extends ModalHookComponentProps {
    profile?: ProfileIncentiveDisciplinaryRequest;
}
const IncentiveDisciplinaryForm = ({ profile, onClose, onCompleted }: IncentiveDisciplinaryProps) => {
    const types: SelectOption[] = useMemo(
        () => [
            {
                label: 'Khen thưởng',
                value: 'Khen thưởng',
            },
            {
                label: 'Kỷ luật',
                value: 'Kỷ luật',
            },
        ],
        []
    );
    const { control, handleSubmit, getValues } = useForm<ProfileIncentiveDisciplinaryRequest>({
        resolver: yupResolver(
            yup.object().shape({
                type: yup.string().required().max(EntityConst.shortString),
                content: yup.string().required().max(EntityConst.descriptionShort),
            })
        ),
    });
    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const onSubmit = async () => {
        var form = getValues();
        let data: ProfileIncentiveDisciplinaryRequest = {
            ...form,
            fileName: fileName,
            id: profile?.id,
        };
        onCompleted(data);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SelectField
                name={'type'}
                label={lang.text(Translations.userProfile.profileIncentiveDisciplinaryType)}
                defaultValue={profile?.type}
                options={types}
                control={control}
                required={true}
                isMutiple={false}
            />
            <InputField name={'content'} control={control} defaultValue={profile?.content} label={lang.text(Translations.form.content)} readOnly={false} />
            <InputField
                name={'decisionMake'}
                control={control}
                defaultValue={profile?.decisionMake}
                label={lang.text(Translations.userProfile.profileIncentiveDisciplinaryDecisionMake)}
                readOnly={false}
            />
            <DateTimeField name={'date'} control={control} defaultDate={profile?.date} label={lang.text(Translations.userProfile.profileIncentiveDisciplinaryDate)} readOnly={false} />
            <UploadFileField
                accepts=".pdf"
                md={6}
                name="filePath"
                control={control}
                onUploaded={(n) => {
                    setFileName(n.fileName);
                }}
                defaultFileName={profile?.fileName ?? ''}
                defaultFilePath={profile?.filePath ?? ''}
            />
            <Modal.Footer>
                <Button variant="danger" onClick={onClose}>
                    {lang.text(Translations.modal.confirm.cancel)}
                </Button>
                <Button variant="primary" type="submit">
                    {lang.text(Translations.modal.confirm.ok)}
                </Button>
            </Modal.Footer>
        </form>
    );
};

export default IncentiveDisciplinaryForm;
