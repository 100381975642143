const ZoomOut = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7542 10.1932L18.1867 5.79319"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
      ></path>
      <path
        d="M17.2976 10.212L13.7547 10.1934L13.7871 6.62518"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      ></path>
      <path
        d="M10.4224 13.5726L5.82149 18.1398"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
      ></path>
      <path
        d="M6.74391 13.5535L10.4209 13.5723L10.3867 17.2755"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

export default ZoomOut;
