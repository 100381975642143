import { yupResolver } from '@hookform/resolvers/yup';
import { TripCommodity, TripCreateRequest, TripDetailResponse, TripType, TripUpdateRequest } from 'api/v2/trip/model/trip.model';
import { trSafeInformationValidation } from 'api/v2/trip/model/route.model';
import routeApi from 'api/v2/trip/routeApi';
import tripApi from 'api/v2/trip/tripApi';
import { AddButton, DeleteButton, SaveButton, UpdateButton } from 'components/Button';
import { CompanySelect } from 'components/Common';
import EquipmentSelectField from 'components/Common/EquipmentSelect';
import UserSelectField from 'components/Common/UserSelect';
import { InputField, RadioField, SelectField, SelectOption, TextAreaField } from 'components/FormControl';
import DateTimeField from 'components/FormControl/DateTimeField';
import { LocationField } from 'components/FormControl/LocationField';
import { ActionEnum } from 'enum';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { ModalDetailProps } from 'models/modalDetailProps';
import { useAuth } from 'providers/contexts';
import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Routers from 'router/const/routers';
import * as yup from 'yup';
import RestPlacesComponent from './components/restPlaces.component';
import SafeInformationPlaceComponent from './components/safeInformation.component';
import { locationValidation } from 'api/models/common/locationModel';
import { toast } from 'react-toastify';
import { stringArrayValidation } from 'helper/yup.common.validation';

//70eec63e-b0de-447b-90b7-ce60a60c7cae
interface TripCreatePageProps extends ModalDetailProps {
    detail?: TripDetailResponse | null;
}
export default function TripCreatePage({ detail }: TripCreatePageProps) {
    const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
    const [routes, setRoutes] = useState<SelectOption[]>([]);
    const [type, setType] = useState(TripType.SOMETIME);
    // const [detail, setDetail] = useState<TripDetailResponse>();
    const [companyId, setCompanyId] = useState<string>();
    const [actionType, setActionType] = useState<ActionEnum>(ActionEnum.Add);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        var action = ActionEnum.Add;
        if (location.pathname === Routers.trip.create) action = ActionEnum.Add;
        else if (location.pathname === Routers.trip.update) action = ActionEnum.Update;
        else action = ActionEnum.View;
        setActionType(action);

        if (action == ActionEnum.View) {
            setIsReadOnly(true);
        } else {
            setIsReadOnly(false);
        }
    }, []);

    const tripCommodityValidation = yup.object<TripCommodity>().shape({
        quantity: yup.number().required('Quantity is required'),
        commodityName: yup.string().required('Name is required'),
    });
    const schema = yup.object().shape({
        equipmentId: yup.string().required('Equipment ID is required'),
        companyId: yup.string().required('Company ID is required'),
        approveUserId: yup.string().required('Approve User ID is required'),
        reason: yup.string().required('Reason is required'),
        fromPlace: locationValidation.required(),
        toPlace: locationValidation.required(),
        planCompletedTime: yup.date().required('Plan Completed Time is required'),
        planStartTime: yup.date().required('Plan Start Time is required'),
        distance: yup.number().required('Distance is required'),
        routeId: yup.string().optional(),
        tripType: yup.mixed<TripType>().oneOf(Object.values(TripType)).required('Trip Type is required'),
        contacts: stringArrayValidation,
        notes: stringArrayValidation,
        passengerIds: yup.array().of(yup.string().required()).optional(),
        driverIds: yup.array().of(yup.string().required()).required(),
        commodities: yup.array().of(tripCommodityValidation.required()).optional(),
        restPlaces: yup.array().of(locationValidation.required()).optional(),
        safeInformations: yup.array().of(trSafeInformationValidation.required()).optional(),
    });

    const {
        control,
        getValues,
        reset,
        trigger,
        register,
        setValue,
        handleSubmit,
        watch,
        formState: { errors },
        setFocus,
    } = useForm<TripCreateRequest>({
        resolver: yupResolver(schema),
        defaultValues: {
            notes: [''],
            contacts: [''],
        },
    });

    const {
        fields: commodities,
        append: appendCommodities,
        remove: removeCommodities,
    } = useFieldArray({
        control: control,
        name: 'commodities' as never,
    });

    useEffect(() => {
        routeApi
            .getByIndex({
                currentPage: 1,
                rowsPerPage: 100,
                companyId: companyId,
            })
            .then((res) => {
                setRoutes(res?.datas?.toSelectOptions('displayName', 'id') || []);
            });
    }, [watch('companyId')]);

    // useEffect(() => {
    //     console.log("equipment changed", watch('equipmentId'));
    // }, [watch('equipmentId')]);
    const routeIdChanged = (routerId: string) => {
        routerId &&
            routeApi
                .getById(routerId)
                .then((res) => {
                    setValue('restPlaces', res?.places);
                    setValue('safePlaces', res?.safeInformations);
                })
                .catch(() => {});
    };
    useEffect(() => {
        if (detail) {
            var trip: TripCreateRequest = {
                approveUserId: detail.approveUserId,
                companyId: detail.companyId,
                contacts: detail.contacts,
                commodities: detail.commodities,
                distance: detail.distance,
                driverIds: detail.drivers.map((x) => x.id),
                equipmentId: detail.equipmentId,
                fromPlace: detail.fromPlace,
                notes: detail.notes,
                planCompletedTime: new Date(detail.planCompletedTime),
                planStartTime: new Date(detail.planStartTime),
                reason: detail.reason,
                toPlace: detail.toPlace,
                tripType: detail.tripType,
                passengerIds: detail.passengers?.map((x) => x.id),
                restPlaces: detail.restPlaces,
                safePlaces: detail.safePlaces,
            };
            reset(trip);
            setType(detail.tripType);
        }
    }, [detail]);
    const onDelete = () => {
        detail &&
            tripApi
                .delete(detail.id)
                .then(() => {
                    navigate(Routers.trip.trip);
                })
                .catch(() => {});
    };
    const onSubmit = (values: TripCreateRequest) => {
        console.log(values);
        if (actionType === ActionEnum.Add) {
            tripApi
                .create(values)
                .then((res) => {
                    navigate(Routers.trip.trip);
                })
                .catch(() => {});
        } else {
            if (!detail?.id) return;
            var payloadUpdate: TripUpdateRequest = {
                ...values,
                id: detail.id,
            };

            tripApi
                .update(payloadUpdate)
                .then(() => {
                    setIsReadOnly(false);
                })
                .catch(() => {});
        }
    };

    return (
        <Fragment>
            <div>
                <form
                    onSubmit={handleSubmit(onSubmit, (e) => {
                        console.log(e);
                    })}
                >
                    <Card>
                        <Card.Header>
                            <div className="d-flex justify-content-between">
                                <h5>{lang.text(Translations.trip.tripInformation)}</h5>
                                <div className="d-flex" style={{ gap: 16 }}>
                                    {!isReadOnly && <SaveButton type="submit" />}
                                    <DeleteButton showConfirm={true} onConfirmed={onDelete} type="button" isRender={detail?.buttonsAction?.canDelete && isReadOnly} />
                                    <UpdateButton onClick={() => setIsReadOnly(false)} type='button' className="mx-2" isRender={detail?.buttonsAction?.canUpdate == true && isReadOnly} />
                                  
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <CompanySelect onCompanyChange={setCompanyId} md={6} name="companyId" control={control} required={true} readOnly={isReadOnly} />
                                <EquipmentSelectField
                                    md={6}
                                    companyId={companyId}
                                    name="equipmentId"
                                    disable={isReadOnly}
                                    readOnly={isReadOnly}
                                    label={lang.text(Translations.trip.equipment)}
                                    control={control}
                                />
                                <UserSelectField md={6} name="approveUserId" readOnly={isReadOnly} label={lang.text(Translations.trip.approval)} control={control} />
                                {actionType == ActionEnum.Add && (
                                    <SelectField
                                        onValueChange={(e: string) => {
                                            routeIdChanged(e);
                                        }}
                                        md={6}
                                        name="routeId"
                                        options={routes}
                                        readOnly={isReadOnly}
                                        label={lang.text(Translations.trip.route)}
                                        control={control}
                                        required={false}
                                    />
                                )}

                                <LocationField md={6} name="fromPlace" readOnly={isReadOnly} label={lang.text(Translations.trip.from)} control={control} required={true} />
                                <LocationField md={6} name="toPlace" readOnly={isReadOnly} label={lang.text(Translations.trip.to)} control={control} required={true} />
                                <InputField disabled={isReadOnly} required={true} control={control} name="distance" label={lang.text(Translations.trip.estimatedDistance)} md={6} suffixText="km" />

                                <Col md={6}>
                                    <div className="d-flex flex-column gap-1">
                                        <Form.Label htmlFor="tripType">{lang.text(Translations.trip.regularTrip)}</Form.Label>
                                        <Form.Check className="form-switch d-flex">
                                            <Form.Check.Input
                                                disabled={isReadOnly}
                                                name="tripType"
                                                type="checkbox"
                                                className="inline-block"
                                                id="tripType"
                                                defaultValue={type}
                                                onChange={(e) => {
                                                    setValue('tripType', e?.target?.checked ? TripType.USUALY : TripType.SOMETIME);
                                                    setType(e?.target?.checked ? TripType.USUALY : TripType.SOMETIME);
                                                }}
                                                checked={type === TripType.USUALY}
                                            />
                                        </Form.Check>
                                    </div>
                                </Col>
                                <UserSelectField md={12} name="driverIds" isMutiple={true} readOnly={isReadOnly} label={lang.text(Translations.trip.operator)} control={control} required={true} />
                                <TextAreaField md={12} name="reason" control={control} defaultValue={''} label={'Lý do'} required={true} readOnly={isReadOnly} placeholder="Nhập lý do" />
                            </Row>
                            <Row>
                                <DateTimeField isShowTimePicker={true} md={6} name={'planStartTime'} label={lang.text(Translations.trip.estimatedTimeFrom)} control={control} readOnly={isReadOnly} />
                                <DateTimeField isShowTimePicker={true} md={6} name={'planCompletedTime'} label={lang.text(Translations.trip.estimatedTimeTo)} control={control} readOnly={isReadOnly} />
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Row>
                                <div className="col-md-12">
                                    <label>{lang.text(Translations.trip.commodities)}</label>
                                    <AddButton
                                        type="button"
                                        onClick={async () => {
                                            const isValid = await trigger('commodities'); // Validate 'name' field
                                            if (!isValid) {
                                                toast.error('Vui lòng điền thông tin hành hóa trước khi thêm mới');
                                                return;
                                            }
                                            appendCommodities(
                                                {},
                                                {
                                                    shouldFocus: true,
                                                }
                                            );
                                        }}
                                        isRender={!isReadOnly}
                                        text={''}
                                    />
                                </div>
                                {commodities?.map((_, index) => {
                                    return (
                                        <Row key={index} className="align-items-center">
                                            <InputField control={control} name={`commodities.${index}.commodityName`} label={lang.text(Translations.common.name)} md={6} readOnly={isReadOnly} />
                                            <InputField
                                                md={3}
                                                control={control}
                                                name={`commodities.${index}.quantity`}
                                                defaultValue={1}
                                                type="number"
                                                label={lang.text(Translations.common.quantity)}
                                                readOnly={isReadOnly}
                                            />
                                            {!isReadOnly && (
                                                <div style={{ height: 30 }} className="col-md-2">
                                                    <DeleteButton type="button" onClick={() => removeCommodities(index)}></DeleteButton>
                                                </div>
                                            )}
                                        </Row>
                                    );
                                })}
                            </Row>
                            <Row className="mt-4">
                                <UserSelectField
                                    md={12}
                                    name="passengerIds"
                                    isMutiple={true}
                                    readOnly={isReadOnly}
                                    label={lang.text(Translations.trip.passengers)}
                                    control={control}
                                    required={false}
                                />
                                <TextAreaField md={12} name={`notes.0`} control={control} label={lang.text(Translations.trip.note)} readOnly={isReadOnly}></TextAreaField>
                                <TextAreaField md={12} name={`contacts.0`} control={control} label={lang.text(Translations.trip.contact)} readOnly={isReadOnly}></TextAreaField>
                            </Row>
                        </Card.Body>
                    </Card>
                    <RestPlacesComponent trigger={trigger} setValue={setValue} canAdd={!isReadOnly} isReadOnly={isReadOnly} name="restPlaces" required={false} control={control} />
                    <SafeInformationPlaceComponent trigger={trigger} setValue={setValue} canAdd={!isReadOnly} isReadOnly={isReadOnly} name="safePlaces" required={false} control={control} />
                </form>
            </div>
        </Fragment>
    );
}
