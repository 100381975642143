import UserRegisterPage from './userRegister';
import { Badge, Tab, Tabs } from 'react-bootstrap';
import { SECRegisterType } from 'enum/sec';
import { useState } from 'react';
import useModalHook from 'components/ModalHook';

const defaultToDate = new Date();
const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);
// eslint-disable-next-line react-hooks/exhaustive-deps
const SECRegisterPage = () => {
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    const [key, setKey] = useState<'equipment' | 'user'>('user');
    return (
        <>
            <Tabs activeKey={key} onSelect={(k) => setKey(k == 'user' ? 'user' : 'equipment')} className="mb-3">
                <Tab eventKey="user" title={'Người dùng'}>
                    {key == 'user' && <UserRegisterPage registerType={SECRegisterType.PERSON} />}
                </Tab>
                <Tab eventKey="equipment" title="Thiết bị">
                    {key == 'equipment' && <UserRegisterPage registerType={SECRegisterType.EQUIPMENT} />}
                </Tab>
            </Tabs>
        </>
    );
};

export default SECRegisterPage;
