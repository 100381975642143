import React from 'react';

const ZoomIn = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5528 5.99656L13.8595 10.8961"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
      ></path>
      <path
        d="M14.8016 5.97618L18.5524 5.99629L18.5176 9.96906"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
      ></path>
      <path
        d="M5.8574 18.896L10.5507 13.9964"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
      ></path>
      <path
        d="M9.60852 18.9164L5.85775 18.8963L5.89258 14.9235"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

export default ZoomIn;
