import { OrganizationUnitResponse } from 'api/models';
import { SelectField, SelectFieldProps, SelectOption } from 'components/FormControl';
import Translations from 'i18n/translation';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import { useCustomer } from 'providers/contexts';
import orgUnitApi from 'api/v2/organizationUnitApi';
import { OrganizationUnitType } from 'api/enums/OrganizationUnitType';

export interface DepartmentSelectProps extends SelectFieldProps {
    onAreaChange?: (company: OrganizationUnitResponse | null) => void;
    isAddAll?: boolean;
    companyId: string;
}

export function DepartmentSelect({ name, onSelectedOptionChange, control, required, md = 4, companyId, readOnly }: DepartmentSelectProps) {
    const [options, setOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        orgUnitApi
            .getByCurrentCustomer(
                {
                    rowsPerPage: 1000,
                    currentPage: 1,
                },
                OrganizationUnitType.Department,
                companyId
            )
            .then((result) => {
                setOptions(result.datas?.toSelectOptions('name', 'id') ?? []);
            })
            .catch((err) => {});
    }, [companyId]);

    return (
        <SelectField
            readOnly={readOnly}
            onSelectedOptionChange={onSelectedOptionChange}
            label={t(Translations.form.department.name)}
            options={options}
            name={name ?? 'departmentId'}
            control={control}
            required={required}
            isMutiple={false}
            md={md}
        />
    );
}
