import favicon from '../assets/images/favicon.ico';
import logo3d from '../assets/images/3d-logo.png';
import topImage from '../assets/images/dashboard/top-image.jpg';
import logo from '../assets/images/sop/logo.png';
import qrCode from '../assets/images/sop/qr.png';
import googlePlay from '../assets/images/sop/googleplay.png';
import appleStore from '../assets/images/sop/applestore.png';
import avatar from '../assets/images/avatars/01.png';

const Images = {
  favicon,
  logo3d,
  topImage,
  logo,
  qrCode,
  googlePlay,
  appleStore,
  avatar,
};

export default Images;
