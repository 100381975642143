// arrayUtils.ts

import { SelectOption } from 'components/FormControl';
import { PMS } from 'router/const/roles';
import * as yup from 'yup';
declare module 'yup' {
    interface StringSchema {
        noSpaces(): StringSchema;
    }
}

yup.addMethod(yup.string, 'required', function (message: string = 'Field is require.') {
    return this.transform((value) => (value === undefined ? '' : value)).test('required', message, function (value) {
        // Your custom validation logic here
        const trimmedValue = (value || '').trim();
        return trimmedValue.length > 0;
    });
});
yup.addMethod(yup.array, 'required', function (minLength: number, message: string = 'Field is require') {
    return this.test('required', message, function (value) {
        if (!minLength) minLength = 1;
        return Array.isArray(value) && value.length >= minLength;
    });
});

declare global {
    interface Array<T> {
        equals(otherArray: T[]): boolean;
        hasValue(): boolean;
        toQueryParams(key: string): string;
        any(values: (string | number)[]): boolean;
        anyPms(values: PMS[]): boolean;
        toSelectOptions(display: string, value?: string): SelectOption[];
    }

    interface String {
        hasValue(): boolean;
    }
    interface Date {
        toEndDay(): Date;
        toStartDay(): Date;
        toDateOnly(): string;
        // toDateTimeString(): string;
        // toDateString(): string;
    }
}
Array.prototype.toQueryParams = function (key: string): string {
    // Check if key and array have values
    if (!key || !this.length) return '';

    // Encode key and values (optional, based on your requirements)
    const encodedKey = encodeURIComponent(key);
    const encodedValues = this.map((value) => encodeURIComponent(value));

    // Join encoded values with '&'
    return encodedValues.map((value) => `${encodedKey}=${value}`).join('&');
};

Array.prototype.toSelectOptions = function <T>(display: string, value?: string): SelectOption[] {
    if (!this.hasValue()) return [];
    var rs =
        this?.map((n: any) => {
            let dt: SelectOption = {
                label: n[display],
                value: n[value ?? 'id'],
            };
            return dt;
        }) ?? [];
    return rs;
};
Array.prototype.any = function (values: (string | number)[]): boolean {
    if (this.length == 0 || values.length == 0) return false;
    return this.some((n) => values.includes(n));
};

Array.prototype.anyPms = function (values: PMS[]): boolean {
    if (this.length == 0 || values.length == 0) return false;
    return this.some((n) => values.includes(n));
};
/**
 * compare all value in aray 1 existed in  array 2
 */
Array.prototype.equals = function <T>(otherArray: T[]): boolean {
    return this.length === otherArray.length && this.every((val) => otherArray.includes(val));
};
Array.prototype.hasValue = function <T>(): boolean {
    return this != undefined && this != null && this.length > 0;
};

String.prototype.hasValue = function () {
    return this != undefined && this != null && this.trim() != '';
};

Date.prototype.toEndDay = function () {
    if (this) {
        this.setHours(23);
        this.setMinutes(59);
        this.setSeconds(59);
        this.setMilliseconds(999);
    }
    return this;
};

Date.prototype.toStartDay = function () {
    if (this) {
        this.setHours(0);
        this.setMinutes(0);
        this.setSeconds(0);
        this.setMilliseconds(1);
    }
    return this;
};
Date.prototype.toDateOnly = function () {
    return this.toISOString().split('T')[0];
};
// Date.prototype.toDateTimeString = function () {
//     if (this) {
//         let date = new Date(this);

//         const year = date.getFullYear().toString().padStart(4, '0');
//         const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
//         const day = date.getDate().toString().padStart(2, '0');
//         const hour = date.getHours().toString().padStart(2, '0');
//         const minute = date.getMinutes().toString().padStart(2, '0');

//         return `${day}-${month}-${year} ${hour}:${minute}`;
//     }
//     return '';
// };
// Date.prototype.toDateString = function () {
//     if (this) {
//         let date = new Date(this);
//         const year = date.getFullYear().toString().padStart(4, '0');
//         const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
//         const day = date.getDate().toString().padStart(2, '0');

//         return `${day}-${month}-${year}`;
//     }
//     return '';
// };
export {};
