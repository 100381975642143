import { SITemplateGroupModel, SITemplateItemModel } from 'api/v2/safe-inspection/model/siTemplate';
import { AddButton, DeleteButton } from 'components/Button';
import { InputField } from 'components/FormControl';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { Fragment, useState } from 'react';
import { Accordion, Button, Table } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { Control } from 'react-hook-form';

const SiGroupTemplateComponent = ({
    group,
    control,
    onDeleteGroup,
    onAddItem,
    index,
    onDeleteItem,
}: {
    group: SITemplateGroupModel;
    control: Control<any>;
    index: any;
    onDeleteGroup: (group: SITemplateGroupModel) => void;
    onAddItem: (group: SITemplateGroupModel) => void;
    onDeleteItem: (group: SITemplateGroupModel, item: SITemplateItemModel) => void;
}) => {
    const [groupName, setGroupName] = useState<string>('');
    return (
        <Accordion.Item eventKey={index} key={group.id} className="">
            <Accordion.Header>
                <span>{groupName?.hasValue() ? groupName : 'Nhóm danh mục'}</span>

                {/* {group?.items ? group?.items?.length : 0}{' '}
                                            {t(Translations.equipment.templateGroupItem)} */}
            </Accordion.Header>
            <Accordion.Body className="table-responsive">
                <Fragment>
                    <Table className="table-striped table-sm">
                        <tbody>
                            <tr>
                                <td className="w-100">
                                    <InputField
                                        md={12}
                                        name={`group-${group.id}`}
                                        control={control}
                                        value={group.groupName}
                                        defaultValue={group.groupName}
                                        placeholder={lang.text(Translations.common.name)}
                                        required={true}
                                        onValueChange={setGroupName}
                                        label={lang.text(Translations.form.safetyInspections.categoryGroup)}
                                    />
                                </td>
                                <td className="d-flex">
                                    <DeleteButton text="" onClick={() => onDeleteGroup(group)} />
                                    <AddButton isRender={true} text={''} onClick={() => onAddItem(group)} />
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                    <Table className="table-striped table-sm">
                        <tbody>
                            {group.items &&
                                group.items.map((item) => (
                                    <tr key={item.id}>
                                        <td className="w-100">
                                            <InputField
                                                md={12}
                                                name={`group-${group.id}-item-${item.id}`}
                                                control={control}
                                                defaultValue={item.itemName}
                                                required={true}
                                                value={item.itemName}
                                            />
                                        </td>
                                        <td>
                                            <div>
                                                <Button
                                                    onClick={() => {
                                                        onDeleteItem(group, item);
                                                    }}
                                                    variant="icon btn-sm btn-danger rounded"
                                                >
                                                    <Trash />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </Fragment>
            </Accordion.Body>
        </Accordion.Item>
    );
};
export default SiGroupTemplateComponent;
