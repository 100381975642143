import { TNCourseCreateRequest, TNCourseDetailResponse } from 'api/v2/training/models/tnCourse.model';
import { svgInfo, svgUser } from 'assets/icons/Svg';
import { InputField, TextAreaField } from 'components/FormControl';
import AppointmentsCard from 'components/shared/AppointmentsCard';
import Color from 'enum/color';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { Fragment } from 'react';
import { Row } from 'react-bootstrap';
import { Control, UseFormWatch } from 'react-hook-form';

interface TNGeneralInfoComponentProps {
    control: Control<TNCourseCreateRequest>;
    isReadOnly?: boolean;
    watch: UseFormWatch<TNCourseCreateRequest>;
    detail?: TNCourseDetailResponse | null;
    isShowChart: boolean;
}
export const TNGeneralInfoComponent = ({ isShowChart, control, isReadOnly, watch, detail }: TNGeneralInfoComponentProps) => {
    const renderReadOnly = () => {
        return !detail ? (
            <div></div>
        ) : (
            <Fragment>
                {isShowChart && (
                    <Row>
                        <AppointmentsCard md={3} svg={svgUser} color={Color.warning} counter={detail!.courseUsers.length} content={lang.text(Translations.common.all)} />
                        <AppointmentsCard md={3} svg={svgUser} color="primary" counter={detail!.chart.completed} content={lang.text(Translations.training.status.completed)} />
                        <AppointmentsCard md={3} svg={svgUser} color="info" counter={detail!.chart.pending} content={lang.text(Translations.training.status.waiting)} />
                        <AppointmentsCard md={3} svg={svgUser} color="warning" counter={detail!.chart.active} content={lang.text(Translations.training.status.unDo)} />
                    </Row>
                )}

                <Row>
                    <div className="col-md-12">
                        <h5 className="mb-0">{lang.text(Translations.training.summary)}</h5>
                        <p className="mt-3">{detail?.summary} </p>
                        <h5 className="mb-0">{lang.text(Translations.training.requirement)}</h5>
                        <p className="mt-3 mb-3">{detail?.require}</p>
                        <h5 className="mb-0">{lang.text(Translations.training.content)}</h5>
                        <p className="mt-3 mb-3">{detail?.content}</p>
                    </div>
                </Row>
            </Fragment>
        );
    };
    const renderForm = () => {
        return (
            <Row>
                <InputField md={12} name={'title'} control={control} label={lang.text(Translations.training.courseName)} readOnly={isReadOnly} />
                <TextAreaField rows={2} md={12} name={'summary'} control={control} label={lang.text(Translations.training.summary)} readOnly={isReadOnly} />
                <TextAreaField rows={2} md={12} name={'require'} control={control} label={lang.text(Translations.training.requirement)} readOnly={isReadOnly} />
                <TextAreaField rows={2} md={12} name={'content'} control={control} label={lang.text(Translations.training.content)} readOnly={isReadOnly} />
            </Row>
        );
    };
    return isReadOnly ? renderReadOnly() : renderForm();
};

export default TNGeneralInfoComponent;
