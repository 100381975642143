import clientFile from './v2/clientFile';
import { FileResponse } from './models';

const baseUrl = '/file';
const fileApi = {
    uploadImage: async (formData: FormData): Promise<FileResponse> => {
        const url = `${baseUrl}/upload-image`;
        return clientFile.upload<FileResponse>(url, formData);
        // if (fileRs.isSuccess && fileRs.result) {
        //     fileRs.result.path = `${fileRs.result.path}`;
        // }
        // return Promise.resolve(fileRs);
    },
    uploadFile: async (formData: FormData) => {
        const url = `${baseUrl}/upload-file`;
        return clientFile.upload<FileResponse>(url, formData);

        // return Promise.resolve(fileRs);
    },
    downloadFile: async (path: string, fileName: string) => {
        const url = `/file?path=${path}`;
        return clientFile.download(url, fileName, undefined, true, true);

        // return Promise.resolve(fileRs);
    },
};

export default fileApi;
