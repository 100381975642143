import clientRequest from './client';
import { GoldenRuleCreateRequest, GoldenRuleResponse, GoldenRuleUpdateRequest } from '../models/organization';
import { PaginationBase, PaginationBaseRequest } from '../models/common';

const baseUrl = '/GoldenRule';
const configGoldenRuleApi = {
    getByCurrentCustomer: async () => {
        var paginationRequest: PaginationBaseRequest = {
            rowsPerPage: 100,
            currentPage: 1,
        };
        const url = `${baseUrl}/getbyindex`;
        return clientRequest.getNonWrap<PaginationBase<GoldenRuleResponse>>({
            endpoint: url,
            data: paginationRequest,
        });
    },
    async create(data: GoldenRuleCreateRequest) {
        const url = `${baseUrl}/create`;
        return await clientRequest.post<string>(url, data, true, true);
    },
    async update(data: GoldenRuleUpdateRequest) {
        const url = `${baseUrl}/update`;
        return await clientRequest.post(url, data, true, true);
    },
    async getById(id: string) {
        const url = `${baseUrl}/getbyid/${id}`;
        return await clientRequest.getNonWrap<GoldenRuleResponse>({ endpoint: url, isLoading: true });
    },
    async delete(id: string) {
        const url = `${baseUrl}/delete/${id}`;
        return await clientRequest.post(url, null, true, true);
    },
};

export default configGoldenRuleApi;
