import { RouteObject } from 'react-router-dom';

import Routers from 'router/const/routers';
import { UserPage, UserProfilePage } from 'views/user';
import UserCreatePage from 'views/user/userCreatePage';
import RoleListPage from 'views/role/roleListPage';

const userRouters: RouteObject[] = [
  {
    path: Routers.user.root,
    element: <UserPage />,
  },
  {
    path: Routers.user.profile,
    element: <UserProfilePage />,
  },
  {
    path: Routers.user.action,
    element: <UserCreatePage />,
  },
  {
    path: Routers.config.role,
    element: <RoleListPage />,
  }
];

export default userRouters;
