import { yupResolver } from '@hookform/resolvers/yup';
import { PermissionGroupDetailResponse, RoleCreateRequest, RoleUpdateRequest } from 'api/v2/role/model/role.model';
import roleApi from 'api/v2/role/roleApi';
import { InputField } from 'components/FormControl';
import { ModalHookComponentProps } from 'components/ModalHook';
import { Card } from 'components/shared';
import { group } from 'console';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface RoleCreateComponentProps extends ModalHookComponentProps {
    permissionIds: string[]; // Permission selected in role
    name: string;
}
export default function RoleCreateComponent({ name, permissionIds, onCompleted, onClose }: RoleCreateComponentProps) {
    const schema = yup.object().shape({
        name: yup.string().required(),
    });
    const {
        control,
        watch,
        handleSubmit,
        getValues,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema)
    });

    const [permissionGroups, setPermissionGroups] = useState<PermissionGroupDetailResponse[]>([]);

    useEffect(() => {
        roleApi
            .getAllPermissions()
            .then((groups) => {
                if (permissionIds && groups) {
                    groups.forEach((g) => {
                        g.permissions &&
                            g.permissions.forEach((p) => {
                                if (permissionIds.includes(p.id)) {
                                    p.isInRole = true;
                                }
                            });
                    });
                }
                setPermissionGroups(groups);
            })
            .catch((n) => {});
    }, []);
    const onTogglePms = (groupId: string, pmsId: string, value: boolean) => {
        let group = permissionGroups.find((n) => n.id == groupId);
        let pms = group && group.permissions.find((n) => n.id == pmsId);
        if (pms) pms.isInRole = value;
        setPermissionGroups([...permissionGroups]);
    };
    const onHandleSubmit = () => {
        const form = getValues();
        let pmsIds: string[] = [];
        permissionGroups.forEach((g) => {
            g.permissions
                .filter((p) => p.isInRole === true)
                .forEach((x) => {
                    pmsIds.push(x.id);
                });
        });
        let payload: RoleCreateRequest = {
            name: form.name,
            permissionIds: pmsIds,
        };
        onCompleted(payload);
    };
    return (
        <form onSubmit={handleSubmit(onHandleSubmit)}>
            <div className="row">
                <InputField defaultValue={name} label={lang.text(Translations.common.name)} name={'name'} control={control} required={true} md={12} type="string" />
            </div>
            <div className="row">
                <div className="col-md-12">
                    <h5>{lang.text(Translations.role.role)}</h5>
                </div>
                {permissionGroups &&
                    permissionGroups.map((g) => (
                        <div className="col-md-4">
                            <Card>
                                <Card.Header className="bg-soft-success">{g.groupName}</Card.Header>
                                <Card.Body className="bg-soft-secondary">
                                    {g.permissions &&
                                        g.permissions.map((p, i) => (
                                            <Form.Check className="d-block" key={i}>
                                                <Form.Check.Input
                                                    checked={p.isInRole}
                                                    name={p.id}
                                                    className="me-2"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id={p.id}
                                                    onChange={(v) => {
                                                        onTogglePms(g.id, p.id, v.target.checked);
                                                    }}
                                                />
                                                <Form.Check.Label htmlFor={p.id}>{p.displayName}</Form.Check.Label>
                                            </Form.Check>
                                        ))}
                                </Card.Body>
                            </Card>
                        </div>
                    ))}
            </div>

            <Modal.Footer>
                <Button variant="danger" onClick={onClose}>
                    {lang.text(Translations.modal.confirm.cancel)}
                </Button>
                <Button variant="primary" type="submit">
                    {lang.text(Translations.modal.confirm.ok)}
                </Button>
            </Modal.Footer>
        </form>
    );
}
