import client from '../client';
import { PaginationBase, PaginationBaseRequest } from 'api/models/common';
import { PPRLevelFilterRequest, PPRLevelRequest, PPRLevelResponse } from './model/pprLevel.model';

const baseURL = '/pprLevel';
const pprLevelApi = {
    getById(id: string): Promise<PPRLevelResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<PPRLevelResponse>({
            endpoint: url,
        });
    },
    getPagination(filter: PPRLevelFilterRequest, isLoading: boolean = true): Promise<PaginationBase<PPRLevelResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<PPRLevelResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: PPRLevelRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: PPRLevelRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
};

export default pprLevelApi;
