import * as yup from 'yup';

export interface LocationModel {
    displayName: string;
    longitude: number;
    latitude: number;
    id?: string;
}
export const locationValidation = yup
    .object()
    .shape({
        displayName: yup.string().required(),
        latitude: yup.number().required(),
        longitude: yup.number().required(),
    })
    .test('location-fields', (value) => {
        // If all fields are provided, pass validation
        if (value?.displayName && value?.latitude !== undefined && value?.longitude !== undefined) {
            return true;
        }
        // Otherwise, fail validation with a single error message
        return false;
    });
