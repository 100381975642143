import { SelectField, SelectFieldProps, SelectOption } from 'components/FormControl';
import Translations from 'i18n/translation';
import { t } from 'i18next';
import { useCustomer } from 'providers/contexts';
import { useEffect, useState } from 'react';

export interface AreaSelectProps extends SelectFieldProps {
    onAreaChange?: (area: string | undefined) => void;
    isAddAll?: boolean;
    companyId: string | null | undefined;
}

export function AreaSelect({ name, onSelectedOptionChange, onAreaChange, control, required, md = 4, companyId, readOnly }: AreaSelectProps) {
    const { currentCustomer } = useCustomer();
    const [areaOptions, setAreaOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        const options = currentCustomer?.companies.find((n) => n.id === companyId)?.areas.toSelectOptions('name', 'id') ?? [];
        setAreaOptions(options);
    }, [companyId]);

    return (
        <SelectField
            onValueChange={onAreaChange}
            onSelectedOptionChange={onSelectedOptionChange}
            readOnly={readOnly}
            label={t(Translations.form.area.name)}
            options={areaOptions}
            name={name ?? 'areaId'}
            control={control}
            required={required}
            isMutiple={false}
            md={md}
        />
    );
}
