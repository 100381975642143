import client from '../client';
import { PaginationBase, PaginationBaseRequest } from 'api/models/common';
import { IIRCategoryFilterRequest, IIRCategoryRequest, IIRCategoryResponse } from './model/iirCategory.model';
import { SelectOption } from 'components/FormControl';
import { IIRCategoryType } from 'api/enums/IIR';

const baseURL = '/IIRCategory';
const iirCategoryApi = {
    getById(id: string): Promise<IIRCategoryResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<IIRCategoryResponse>({
            endpoint: url,
        });
    },
    getPagination(filter: IIRCategoryFilterRequest, isLoading: boolean = true): Promise<PaginationBase<IIRCategoryResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<IIRCategoryResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: IIRCategoryRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: IIRCategoryRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
    getDropdown(type: IIRCategoryType, companyId: string): Promise<SelectOption[]> {
        const url = `${baseURL}/dropdown/${type}`;
        return client.getNonWrap<SelectOption[]>({
            endpoint: url,
            data: { companyId: companyId },
        });
    },
};

export default iirCategoryApi;
