import PaginationComponent, { PaginationChange } from 'components/Common/PaginationComponent';
import Translations from 'i18n/translation';
import { useDebounce } from 'hooks';
import { useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Empty from './Empty';

type ColumnType = {
    field: string;
    title: string;
    className?: string;
};

export type FancyTableDataType = {
    [key: string]: any;
};

export const fancyTableDefaultPerPage = [10, 20, 30, 40, 50];

interface FancyTableProps {
    disableSearch?: boolean;
    columns: ColumnType[];
    data: FancyTableDataType[];
    isStriped?: boolean;
    wrapperClass?: string;
    handleSearch?: (value: string) => void;
    pagination?: {
        onChange?: (page: number) => void;
        total: number;
        currentPage: number;
    };
    onPerPageChange?: (value: number) => void;
    loading?: boolean;
    hideHead?: boolean;
    onRowClick?: (row: FancyTableDataType) => void;
}

export function FancyTable({
    disableSearch,
    columns,
    data,
    isStriped = false,
    wrapperClass = '',
    pagination,
    onPerPageChange,
    handleSearch,
    onRowClick,
    loading = false,
    hideHead = false,
}: FancyTableProps) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        perPage: fancyTableDefaultPerPage[0],
        currentPage: 1,
        search: '',
    });

    const debouncedValue = useDebounce(state.search, 500);

    useEffect(() => {
        if (handleSearch) {
            handleSearch(debouncedValue);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    const handlePageChange = (page: PaginationChange) => {
        setState((prev) => ({
            ...prev,
            perPage: page.itemsPerPage,
            currentPage: page.selectedPage,
        }));

        if (pagination && pagination.onChange) {
            pagination.onChange(page.selectedPage);
        }
    };

    const handlePerPageChange = (per: number) => {
        setState((prev) => ({
            ...prev,
            perPage: per,
        }));

        if (onPerPageChange) {
            onPerPageChange(per);
        }
    };

    const handleSearchChange = (value: string) => {
        setState((prev) => ({ ...prev, search: value }));
    };

    return (
        <section>
            {!hideHead && (
                <div className="flex-column gap-2 flex-md-row justify-content-between row mb-4 align-items-end">
                    {!disableSearch && (
                        <div className={`col-md-4`}>
                            <Form.Label htmlFor="search">{t(Translations.table.find)}</Form.Label>
                            <Form.Control
                                value={state.search}
                                onChange={(e) => handleSearchChange(e.target.value)}
                                id="search"
                                type="text"
                            />
                        </div>
                    )}

                    {pagination && (
                        <div className="col-md-4 d-flex align-items-center gap-2 justify-content-end">
                            <span>{t(Translations.table.display)}</span>
                            <select
                                className="form-select"
                                value={state.perPage}
                                onChange={(e) => handlePerPageChange(Number(e.target.value))}
                                style={{ width: 90 }}
                                id="perpage"
                                disabled={data.length ? false : true}
                            >
                                {fancyTableDefaultPerPage.map((opt, index) => (
                                    <option value={opt} key={index}>
                                        {opt}
                                    </option>
                                ))}
                            </select>
                            {/* <span>{t(Translations.table.data)}</span> */}
                        </div>
                    )}
                </div>
            )}

            {/* Table body */}
            {data.length ? (
                <div className="position-relative">
                    <div className={`fancy-table table-responsive rounded ${wrapperClass}`}>
                        <Table
                            className={`table-hover mb-0 ${isStriped ? 'table-striped' : ''}`}
                            id="datatable"
                            data-toggle="data-table"
                        >
                            <thead>
                                <tr className="bg-white">
                                    {columns.map((item, index) => (
                                        <th
                                            scope="col"
                                            key={index}
                                            className={item.className ? item.className : ''}
                                        >
                                            {item.title}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => {
                                    return (
                                        <tr className="cursor-pointer-hover" key={index}>
                                            {columns.map((col, colIndex) => (
                                                <td
                                                    onClick={() => {
                                                        col.field != 'action' &&
                                                            onRowClick &&
                                                            onRowClick(item);
                                                    }}
                                                    key={colIndex}
                                                    className={col.className ? col.className : ''}
                                                >
                                                    {item[col.field]}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                    {loading && (
                        <div
                            className="position-absolute top-0 w-100 left-0 h-100 d-flex justify-content-center align-items-center"
                            style={{
                                backgroundColor: 'rgba(255, 255, 2555, .3)',
                            }}
                        >
                            <div className="iq-loader-box">
                                <div className="iq-loader-6">
                                    <span className="inner inner1"></span>
                                    <span className="inner inner2"></span>
                                    <span className="inner inner3"></span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <Empty />
            )}

            <div className="row align-items-center">
                <div className="col-md-6">
                    {pagination && pagination.total ? (
                        <div className="dataTables_info">
                            Showing 1 to{' '}
                            {pagination.total > state.perPage ? state.perPage : pagination.total} of{' '}
                            {pagination.total} entries
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <div className="col-md-6">
                    <div className="d-flex align-items-center justify-content-end pt-3">
                        {pagination && (
                            <PaginationComponent
                                onPageChange={handlePageChange}
                                currentPage={pagination.currentPage}
                                totalItems={pagination.total}
                                itemsPerPage={state.perPage}
                            />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}
