import { SORDashboardResponse, SORReportCreateRequest, SORReportDetailResponse, SORReportFilterRequest, SORReportResponse } from './model/sorReport.model';
import client from '../client';
import { PaginationBase } from 'api/models/common';

const baseURL = '/sorReport';
const sorReportApi = {
    getById(id: string): Promise<SORReportDetailResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<SORReportDetailResponse>({
            endpoint: url,
        });
    },
    getPagination(filter: SORReportFilterRequest, isLoading: boolean = true): Promise<PaginationBase<SORReportResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<SORReportResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: SORReportCreateRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: SORReportCreateRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },

    closeSReport(reportId: string): Promise<unknown> {
        const url = `${baseURL}/close/${reportId}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
    getDashboard(filter: any): Promise<SORDashboardResponse> {
        const url = `${baseURL}/dashboard`;
        return client.getNonWrap<any>({
            endpoint: url,
            data: filter,
        });
    },
    downloadReport(id: string): Promise<any> {
        const url = `${baseURL}/download/${id}`;
        return client.download({
            endpoint: url,
            fileName: 'Báo cáo an toàn',
        });
    },
};

export default sorReportApi;
