const Color = {
    primary: 'primary',
    danger: 'danger',
    info: 'info',
    warning: 'warning',
    success: 'success',
    softSuccess: 'soft-success',
    softWarning: 'soft-warning',
    softDanger: 'soft-danger',
    softInfo: 'soft-info',
    softPrimary: 'soft-primary',
    softSecondary: 'soft-secondary',
    softLight: 'soft-light',
    softDark: 'soft-dark',
    white: 'white',
    muted: 'muted',
};
export const ColorHex = {
    primary: '#28a745',
    danger: '#bf3020',
    info: '#08b1ba',
    warning: '#f16a1b',
    success: '#1aa053',
};

export default Color;
