import { EquipmentResponse, EquipmentSimpleResponse, OrganizationUnitResponse } from 'api/models';
import { BaseAuditResponse, PaginationBaseRequest } from 'api/models/common';
import { LocationModel } from 'api/models/common/locationModel';
import { UserResponse, UserSimpleResponse } from '../../user/model';
import { TRSafeInformation } from './route.model';

export enum TripType {
    SOMETIME = 'SOMETIME',
    USUALY = 'USUALY',
}
export enum TripStatus {
    ACTIVE = 'ACTIVE',
    REFUSE = 'REFUSE',
    APPROVED = 'APPROVED',
    PROCESSING = 'PROCESSING',
    COMPLETED = 'COMPLETED',
}

export enum TripReportType {
    Equipment = 'Equipment',
    Passenger = 'Passenger',
}

// export interface Trip extends BaseAuditResponse {
//     equipmentId: string;
//     companyId: string;
//     approveUserId: string;
//     reason: string;
//     routerId: string;
//     fromPlace: LocationModel;
//     toPlace: LocationModel;
//     planCompletedTime: string;
//     planStartTime: string;
//     distance: number;
//     tripType: TripType;
//     contacts: string;
//     notes: string;
//     commodities: TripCommodity[];
//     safePlaces: LocationModel[];
//     restPlaces: LocationModel[];
//     id: string;
//     passengerIds: string;
//     driverIds: string[];
//     drivers: {
//         id: string;
//         fullName: string;
//     }[];
//     status: TripStatus;
//     description: string;
//     passengers?: {
//         id: string;
//         fullName: string;
//     }[];
//     buttonsAction: TripButtonAction;
// }
export interface TripUpdateRequest extends TripCreateRequest {
    id: string;
}
export interface TripCreateRequest {
    equipmentId: string;
    companyId: string;
    approveUserId: string;
    reason: string;
    routerId?: string;
    fromPlace: LocationModel;
    toPlace: LocationModel;
    planCompletedTime: Date;
    planStartTime: Date;
    distance: number;
    tripType: TripType;
    contacts: string[];
    notes: string[];
    commodities?: TripCommodity[];
    safePlaces?: TRSafeInformation[];
    restPlaces?: LocationModel[];
    passengerIds?: string[];
    driverIds: string[];
}

// export interface Trip extends BaseAuditResponse {
//     equipmentId: string;
//     companyId: string;
//     approveUserId: string;
//     reason: string;
//     routerId: string;
//     fromPlace: LocationModel;
//     toPlace: LocationModel;
//     planCompletedTime: string;
//     planStartTime: string;
//     distance: number;
//     tripType: TripType;
//     contacts: string;
//     notes: string;
//     commodities: TripCommodity[];
//     safePlaces: LocationModel[];
//     restPlaces: LocationModel[];
//     id: string;
//     passengerIds: string;
//     driverIds: string[];
//     drivers: {
//         id: string;
//         fullName: string;
//     }[];
//     status: TripStatus;
//     description: string;
//     passengers?: {
//         id: string;
//         fullName: string;
//     }[];
//     buttonsAction: TripButtonAction;
// }

export interface TripDetailResponse extends TripResponse {
    companyId: string;
    equipmentId: string;
    reason: string;
    distance: number;
    tripType: TripType;
    contacts: string[];
    notes: string[];
    commodities: TripCommodity[];
    safePlaces: TRSafeInformation[];
    restPlaces: LocationModel[];
    drivers: UserSimpleResponse[];
    passengers?: UserSimpleResponse[];
    histories?: TripHistoryResponse[];
    buttonsAction: TripButtonAction;
    approveUserId: string;
    requestChangeRoute: TripReqChangeRoute | null;
}
export interface TripHistoryResponse {
    content: string;
    createDate: Date;
    createUserName: string;
    tripStatus: TripStatus;
    crudActionType: string;
}

export interface TripResponse extends BaseAuditResponse {
    id: string;
    equipment: EquipmentSimpleResponse;
    company: OrganizationUnitResponse;
    approveUser: UserSimpleResponse;
    reason: string;
    fromPlace: LocationModel;
    toPlace: LocationModel;
    planCompletedTime: Date;
    planStartTime: Date;
    distance: number;
    status: TripStatus;
    tripType: TripType;
}

export interface TripButtonAction {
    isVisibleBtnApprove: boolean;
    isVisibleBtnRefuse: boolean;
    isVisibleBtnComplete: boolean;
    isVisibleBtnStart: boolean;
    isVisibleAddNote: boolean;
    isVisibleChangeRoute: boolean;
    isVisibleViewChangeRoute: boolean;
    isVisibleApproveChangeRoute: boolean;
    isVisibleRejectChangeRoute: boolean;
    canDelete: boolean;
    canUpdate: boolean;
}

export interface TripCommodity {
    commodityName: string;
    id?: string;
    quantity: number;
}

export interface TripFilter extends PaginationBaseRequest {
    keySearch?: string | null;
    companyId?: string;
    passengerId?: string;
    tripType?: TripType;
    driverId?: string;
    equipmentId?: string;
    status?: TripStatus;
}

export enum TripReqChangeRouteStatus {
    CREATED = 'CREATED',
    APPROVED = 'APPROVED',
    REJECT = 'REJECT',
}
export interface TripReqChangeRoute {
    id?: string;
    reason: string;
    restPlaces?: LocationModel[];
    fromPlace: LocationModel;
    toPlace: LocationModel;
    planCompletedTime: Date;
    planStartTime: Date;
    distance: number;
    tripId: string;
    status?: TripReqChangeRouteStatus;
}

export interface TripReportFilter {
    companyId?: string;
    passengerId?: string;
    equipmentId?: string;
    fromDate: Date;
    toDate: Date;
    tripReportType: TripReportType;
}

export interface TripReportResponse {
    totalKm: number;
    countTrip: number;
    name: string;
}
