import { ApexOptions } from 'apexcharts';
import Color, { ColorHex } from 'enum/color';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import Chart from 'react-apexcharts';
import { Translation } from 'react-i18next';
interface BardChartProps {
    series: series[];
    labels: string[];
    minColor?: number;
}
interface series {
    data: number[];
    name: string;
}
export const BardChart = (props: BardChartProps) => {
    var colors = useMemo(() => [ColorHex.primary, ColorHex.danger, ColorHex.success, ColorHex.warning, ColorHex.info], []);
    colors = colors.slice(0, props.labels.length < 2 ? 2 : props.labels.length);

    let option: ApexOptions = {
        colors: colors,
        series: props.series,
        labels: [lang.text(Translations.common.safe), lang.text(Translations.common.unSafe)],
        xaxis: {
            categories: props.labels,
        },
        chart: {
            type: 'bar',
            height: 430,
        },
    };
    return (
        <div>
            <ReactApexChart options={option} series={props.series} type="bar" height={430} />
        </div>
    );
};
