import { ProfileCertificateRequest, UserCreateRequest, UserDetailResponse, UserFilterRequest, UserResponse, UserUpdateRequest } from 'api/v2/user/model';
import client from '../client';
import { PaginationBase } from 'api/models/common';

const baseUserApi = '/ProfileCertificate';
const profileCertificateApi = {
    create(data: ProfileCertificateRequest): Promise<string> {
        const url = `${baseUserApi}/create`;
        return client.postNonWrap({
            endpoint: url,
            data: data,
            isLoading: true,
            isShowError: true,
            isShowSuccess: true,
        });
    },
    update(data: ProfileCertificateRequest): Promise<unknown> {
        const url = `${baseUserApi}/update`;
        return client.postNonWrap({
            endpoint: url,
            data: data,
            isLoading: true,
            isShowError: true,
            isShowSuccess: true,
        });
    },
    async delete(id: string): Promise<unknown> {
        const url = `${baseUserApi}/delete/${id}`;
        return client
            .postNonWrap({
                endpoint: url,
                data: true,
                isLoading: true,
                isShowError: true,
                isShowSuccess: true,
            })
            .catch(() => {});
    },
    getPagination: () => {
        const url = `${baseUserApi}/getbyindex`;
        return client.getNonWrap<PaginationBase<ProfileCertificateRequest>>({
            endpoint: url,
            data: {
                rowsPerPage: 1000,
                currentPage: 1,
            },
            isLoading: true,
            isShowError: true,
            isShowSuccess: false,
        });
    },
};

export default profileCertificateApi;
