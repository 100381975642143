import Progress from 'components/uiKit/progress';
import Color from 'enum/color';
interface ProgressItemProps {
    value: number;
    text: string;
    successText: string;
    errorText: string;
    maxValue?: number;
}
const ProgressItem = ({ value, errorText, successText, text, maxValue }: ProgressItemProps) => {
    return (
        <div className="row border-bottom pb-3 align-items-center iq-social-keyMetrics">
            <div className="col-md-5">
                <label className="iq-key-metrics mb-0 text-info">{text}</label>
            </div>
            <div className="col-md-7 iq-drive-progress d-flex align-items-center">
                <span className="key-number">{successText} </span>
                <Progress softcolors={Color.danger} color={Color.primary} className="shadow-none w-100 mx-1" value={value} minvalue={0} maxvalue={maxValue ?? 100} style={{ height: '6px' }} />
                <span className="ms-3"> {errorText}</span>
            </div>
        </div>
    );
};

export default ProgressItem;
