import CustomerContext from 'providers/contexts/customerContext';

import React, { useState, useEffect } from 'react';
import { CustomerInfraResponse } from 'api/models/organization';
import { useAuth } from 'providers/contexts';
import customerApi from 'api/v2/customerApi';
import { selectCustomer, selectShowCustomerModal, setCustomer, setShowCustomerModal } from 'app/appSlice';
import { useAppSelector } from 'app/hooks';
import { useDispatch } from 'react-redux';
import { ModalSelectCustomer } from 'components/shared';
import LoadingPage from 'components/LoadingPage';
import _ from 'lodash';

export default function CustomerProvider({ children }: { children: React.ReactNode }) {
    const { tokenProfile } = useAuth();
    const dispatch = useDispatch();

    var ishowCustomerModal = useAppSelector(selectShowCustomerModal);
    var currentCustomer = useAppSelector(selectCustomer);

    const [customers, setCustomers] = useState<CustomerInfraResponse[]>([]);
    const loadCustomers = async () => {
        tokenProfile?.userId &&
            customerApi
                .getInfrastructures()
                .then((customerInfra) => {
                    setCustomers(customerInfra);
                    if (!tokenProfile.isSuperAdmin) {
                        onSelectCustomer(customerInfra[0]);
                    }
                })
                .catch(() => {});
    };
    useEffect(() => {
        loadCustomers();
        if (tokenProfile?.isSuperAdmin) {
            dispatch(setShowCustomerModal(true));
        }
    }, []);

    /**action when click choose cusstomer on modal */
    const onSelectCustomer = (customer: CustomerInfraResponse) => {
        dispatch(setCustomer(customer ?? undefined));
        dispatch(setShowCustomerModal(false));
    };

    /**action when change area, company or department */
    const loadInfrastructure = () => {
        customerApi
            .getInfrastructures()
            .then((customerInfra) => {
                setCustomers(customerInfra);
                var cus = customerInfra.find((n) => n.id == currentCustomer?.id);
                if (cus) {
                    onSelectCustomer(cus);
                }
            })
            .catch(() => {});
    };
    if (tokenProfile?.isSuperAdmin && ishowCustomerModal)
        return <ModalSelectCustomer currentCustomerId={(currentCustomer as CustomerInfraResponse | undefined)?.id ?? null} customers={customers} onSelectCustomer={onSelectCustomer} />;
    if (tokenProfile?.isSuperAdmin && !currentCustomer) return <LoadingPage />;
    return (
        <CustomerContext.Provider
            value={{
                currentCustomer: currentCustomer ?? null,
                customers,
                loadInfrastructure: loadInfrastructure,
            }}
        >
            {children}
        </CustomerContext.Provider>
    );
}
