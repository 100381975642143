import { ChangePasswordRequest } from '../../api/v2/user/model/changePassword';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputField } from 'components/FormControl';
import Translations from 'i18n/translation';
import { useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export interface ChangePasswordFormProps {
  initialValues?: ChangePasswordRequest;
  onSubmit?: (formValues: ChangePasswordRequest) => void;
}

const ChangePasswordForm = ({ initialValues, onSubmit }: ChangePasswordFormProps) => {
  const [error, setError] = useState<string>('');
  const { t } = useTranslation();

  const schema = yup.object().shape({
    oldPassword: yup
      .string()
      .required(t(Translations.form.validate.required))
      .min(6, t(Translations.form.password.length))
      .max(30, t(Translations.form.password.length)),
    newPassword: yup
      .string()
      .required(t(Translations.form.validate.required))
      .min(6, t(Translations.form.password.length))
      .max(30, t(Translations.form.password.length)),
    confirmNewPassword: yup
      .string()
      .required(t(Translations.form.validate.required))
      .min(6, t(Translations.form.password.length))
      .max(30, t(Translations.form.password.length))
      .oneOf([yup.ref('newPassword')], t(Translations.form.password.notMatch)),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ChangePasswordRequest>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = async (formValues: ChangePasswordRequest) => {
    try {
      setError('');
      await onSubmit?.(formValues);
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <Row className="d-flex align-items-center justify-content-center vh-100 w-100 m-0">
      <Col className="vh-100  align-items-center bg-panel py-5">
        <Card className="p-2 ">
          <CardBody>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <InputField
                name="oldPassword"
                type="password"
                control={control}
                label={t(Translations.form.password.old)}
              />
              <InputField
                type="password"
                name="newPassword"
                control={control}
                label={t(Translations.form.password.new)}
              />
              <InputField
                type="password"
                name="confirmNewPassword"
                control={control}
                label={t(Translations.form.password.confirm)}
              />

              <div className="d-flex justify-content-center pt-3 pb-3">
                <Button className="d-flex align-items-center" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <Spinner animation="border" className="me-3" variant="danger" />}
                  {t(Translations.common.changePassword)}
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ChangePasswordForm;
