import { selectLanguage, setLanguage } from 'app/appSlice';
import { useAppSelector } from 'app/hooks';
import { SelectOption } from 'components/FormControl';
import CustomToggle from './CustomToggle';
import StorageKeys from 'constants/storageKeys';
import Translations from 'i18n/translation';
import i18n from 'i18next';
import { useEffect } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface SelectLanguageProps {
  isRounded?: boolean;
  className?: string;
}

export default function SelectLanguage(props: SelectLanguageProps) {
  const { className = '' } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedLanguage: string = useAppSelector(selectLanguage) as string;

  const languageOptions: SelectOption[] = [
    {
      label: t(Translations.common.language.vi),
      value: 'vi',
    },
    {
      label: t(Translations.common.language.en),
      value: 'en',
    },
  ];

  const handleChange = (value: string) => {
    i18n.changeLanguage(value);
    localStorage.setItem(StorageKeys.language, value);
    dispatch(setLanguage(value));
  };

  useEffect(() => {
    if (selectedLanguage) i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const options = languageOptions.filter((i) => i.value !== selectedLanguage);

  if (props.isRounded) {
    return (
      <Dropdown as="li" className={`nav-item iq-tour ps-3 ps-lg-0 ${className}`}>
        <Dropdown.Toggle as={CustomToggle} variant="py-0  d-flex align-items-center nav-link">
          <div className="btn btn-primary btn-icon btn-sm rounded-pill">
            <span className="btn-inner text-uppercase">{selectedLanguage}</span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu variant="end">
          {options.map((item) => (
            <span key={item.value} onClick={() => handleChange(item.value.toString())}>
              <Dropdown.Item>{t((Translations.common.language as any)[item.value])}</Dropdown.Item>
            </span>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  return (
    <Dropdown className="d-flex align-items-center">
      <span className="me-2">{t(Translations.common.language.name)}</span>
      <Dropdown.Toggle as={CustomToggle} variant="text-secondary">
        <Button variant="outline-primary" className="border-0">
          {t((Translations.common.language as any)[selectedLanguage])}
        </Button>
      </Dropdown.Toggle>

      <Dropdown.Menu aria-labelledby="dropdownMenuButton1">
        {options.map((item) => (
          <li key={item.value} onClick={() => handleChange(item.value.toString())}>
            <Dropdown.Item>{t((Translations.common.language as any)[item.value])}</Dropdown.Item>
          </li>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
