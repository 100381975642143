import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { AddButton } from 'components/Button';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { toast } from 'react-toastify';
import equipmentApi from 'api/v2/equipmentApi';
import { EquipmentTypeGroupCreateRequest, EquipmentTypeGroupResponse, EquipmentTypeGroupUpdateRequest } from 'api/models';
import { FancyTable, FancyTableDataType } from 'components/shared';
import { useForm } from 'react-hook-form';
import PromptModal from 'components/Modal/PromptModal';
import usePromptInputText from 'components/Modal/PromptInputText';
import { CompanySelect } from 'components/Common';

type ActionType = 'delete' | 'update';

const EquipmentTypePage = memo(() => {
    const { t } = useTranslation();
    const [companyId, setCompanyId] = useState<string | null>(null);
    const [datas, setDatas] = useState<FancyTableDataType[]>([]);
    const { control, handleSubmit, getValues, reset } = useForm({
        defaultValues: {},
    });

    const { openPromptInputText, PromptInputText } = usePromptInputText();
    useEffect(() => {
        handleFetchData(defaultPaginationRequest);
    }, [companyId]);

    const handleFetchData = (pagination: PaginationBaseRequest) => {
        pagination.filter = companyId;
        equipmentApi
            .getTypePagination(pagination)
            .then((pagination: PaginationBase<EquipmentTypeGroupResponse>) => {
                const tableData = datas
                    ? pagination.datas?.map((item: FancyTableDataType) => ({
                          ...item,
                          action: <ActionButtons2 id={item.id} editAction={() => onUpdate(item.typeGroupName, item.id)} onDelete={(id) => onDelete(id)} />,
                      }))
                    : [];
                setDatas(tableData ?? []);
            })
            .catch((n) => {});
    };

    const onDelete = async (id: string) => {
        equipmentApi
            .deleteType(id)
            .then((n) => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch((n) => {});
    };

    const onAdd = async () => {
        if (!companyId) {
            toast.error(t(Translations.error.requireCompany));
            return;
        }
        const result: string = await openPromptInputText({
            title: t(Translations.equipment.type),
            defaultValue: '',
        });
        equipmentApi
            .createType({
                companyId: companyId,
                typeGroupName: result,
            })
            .then((n) => handleFetchData(defaultPaginationRequest))
            .catch((n) => {});
    };

    const onUpdate = async (oldName: string, id: string) => {
        const result: string = await openPromptInputText({
            title: t(Translations.equipment.type),
            defaultValue: oldName,
        });
        equipmentApi
            .updateType({
                typeGroupName: result,
                id: id,
            })
            .then((n) => handleFetchData(defaultPaginationRequest))
            .catch((n) => {});
    };

    const cols = useMemo(
        () => [
            {
                field: 'typeGroupName',
                title: t(Translations.common.name),
            },
            {
                field: 'companyName',
                title: t(Translations.form.site.name),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    return (
        <div>
            <Card>
                <Card.Header>
                    <div className="row w-100">
                        <CompanySelect name="companyId" control={control} onCompanyChange={setCompanyId} md={4} />
                        <div className="col-md-4 "></div>
                        <div className="col-md-4 d-flex justify-content-end">
                            <div>
                                <AddButton isRender={true} onClick={onAdd} />
                            </div>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    {/* Table */}
                    {datas && <FancyTable hideHead={true} loading={false} columns={cols} data={datas} isStriped={true} wrapperClass="mt-5" />}
                </Card.Body>
            </Card>
            {PromptInputText}
        </div>
    );
});

export default EquipmentTypePage;
