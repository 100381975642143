import { Accordion, Button, Card } from 'react-bootstrap';
import SIItemContent from './item.component';
import { SIReportDetailResponse, SIReportGroupRequest, SIReportGroupResponse } from 'api/v2/safe-inspection/model/siReport';
import { Control } from 'react-hook-form';
import UserSelectField from 'components/Common/UserSelect';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import Color from 'enum/color';
import DataListView from 'components/shared/DataListView';
import { formatDateTime } from 'helper';
import { SIReportStatus } from 'enum/safetyInspection';

const SIGroupContent = ({
    group,
    readonly,
    showPIC,
    control,
    generageName,
    closeGroupScheduled,
}: {
    group: SIReportGroupRequest | SIReportGroupResponse;
    readonly: boolean;
    showPIC: boolean;
    control: Control<any>;
    generageName: (groupId: string, itemId: string, name: string) => string;
    closeGroupScheduled?: (groupId: string) => void;
}) => {
    const renderButtonClose = () => (
        <div className="d-flex justify-content-end">
            {(group as SIReportGroupResponse)?.canClose && (
                <Button size="sm" className="mt-2" color={Color.info} onClick={() => closeGroupScheduled && closeGroupScheduled((group as SIReportGroupResponse).id)}>
                    {lang.text(Translations.button.close)}
                </Button>
            )}
        </div>
    );
    // only render for scheduled report
    const generageClosedInformation = () => {
        var groupResponse = group as SIReportGroupResponse;
        return (
            showPIC &&
            groupResponse.status == SIReportStatus.Closed && (
                <Card>
                    <Card.Body>
                        <div>{renderButtonClose()}</div>
                        <DataListView
                            datas={[
                                {
                                    label: lang.text(Translations.common.closedBy),
                                    value: groupResponse.closedUserFullName,
                                },
                                {
                                    label: lang.text(Translations.common.closedDate),
                                    value: groupResponse.closedDate && formatDateTime(groupResponse.closedDate),
                                },
                                {
                                    label: lang.text(Translations.common.closedBy),
                                    value: groupResponse.closedDescription,
                                },
                            ]}
                        />
                    </Card.Body>
                </Card>
            )
        );
    };
    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="2">
                <Accordion.Header>{<span className={group.items.some((n) => !n.isOk) ? 'text-danger' : ''}>{group.groupTemplateName}</span>}</Accordion.Header>

                <Accordion.Body>
                    <div className="row">
                        {showPIC && !readonly && (
                            <UserSelectField
                                readOnly={false}
                                label={lang.text(Translations.common.pic)}
                                name={generageName(group.groupTemplateId, '', 'pICId')}
                                control={control}
                                required={true}
                                isMutiple={false}
                                md={12}
                            />
                        )}
                        {generageClosedInformation()}
                        {group.items?.map((item) => (
                            <div key={item.itemTemplateId} id={item.itemTemplateId} className="col-md-6">
                                <SIItemContent
                                    generageName={generageName}
                                    groupTemplateId={group.groupTemplateId}
                                    showPIC={showPIC}
                                    readonly={readonly}
                                    key={group.groupTemplateId}
                                    item={item}
                                    control={control}
                                />
                            </div>
                        ))}
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};
export default SIGroupContent;
