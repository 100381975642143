import Color from 'enum/color';
import { EquipmentReportType } from 'enum';
import { SelectOption } from 'components/FormControl';
import { PPRStatus, SkpiStatus } from 'enum/ppr';

export class EnumHelper {
    static toEquipmentReportType(value: string): EquipmentReportType {
        if (!value) return EquipmentReportType.All;
        const normalizedValue = value.toLowerCase() as keyof typeof EquipmentReportType;
        if (normalizedValue in EquipmentReportType) {
            return EquipmentReportType[normalizedValue];
        }

        return EquipmentReportType.Daily; // Default value if input is not a valid string or number
    }

    static generatePPRReportStatus(): SelectOption[] {
        return [
            {
                label: PPRStatus.OPENING,
                value: PPRStatus.OPENING,
            },
            {
                label: PPRStatus.CLOSED,
                value: PPRStatus.CLOSED,
            },
        ];
    }

    static getPPRReportStatusColor(status: PPRStatus): string {
        if (status == PPRStatus.OPENING) {
            return Color.warning;
        }
        return Color.success;
    }
    static getSKPIStatusColor(status: SkpiStatus): string {
        if (status == SkpiStatus.OPENING) {
            return Color.warning;
        }
        return Color.success;
    }
}
