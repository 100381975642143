import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import Card from 'components/shared/Card';
import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import Translations from 'i18n/translation';
import { PaginationTable } from 'components/shared/PaginationTable';
import { lang } from 'i18n/lang';
import { AddButton } from 'components/Button';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import useModalHook from 'components/ModalHook';
import legaldocumentGroupApi from 'api/v2/legal-document/legalDocumentGroupApi';
import { LDGroupRequest, LDGroupResponse } from 'api/v2/legal-document/legalDocument.models';
import usePromptInputText from 'components/Modal/PromptInputText';
import Roles, { PMS } from 'router/const/roles';
import { useAuth } from 'providers/contexts';

// eslint-disable-next-line react-hooks/exhaustive-deps
const LegalDocumentGroupPage = () => {
    const {tokenProfile} = useAuth();
    const { openPromptInputText, PromptInputText } = usePromptInputText();
    const [paginationData, setPaginationData] = useState<PaginationBase<any>>();
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    useEffect(() => {
        handleFetchData(defaultPaginationRequest);
    }, []);
    const cols = useMemo(
        () => [
            {
                field: 'groupName',
                title: lang.text(Translations.legalDocument.group),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        legaldocumentGroupApi
            .getByIndex(pagination)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                action: <ActionButtons2 editPms={tokenProfile?.pms.ldAdmin} deletePms={tokenProfile?.pms.ldAdmin} id={n.id} onDelete={onDelete} editAction={() => onUpdate(n)} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };
    const onAdd = async () => {
        const text: string = await openPromptInputText({
            title: lang.text(Translations.legalDocument.group),
            defaultValue: '',
        });
        legaldocumentGroupApi
            .create({ groupName: text, id: null })
            .then(() => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch(() => {});
    };

    const onUpdate = async (data: LDGroupResponse) => {
        const text: string = await openPromptInputText({
            title: lang.text(Translations.legalDocument.group),
            defaultValue: data.groupName,
        });
        text &&
            legaldocumentGroupApi
                .update({ groupName: text, id: data.id })
                .then(() => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch(() => {});
    };
    const onDelete = async (id: string) => {
        legaldocumentGroupApi
            .delete(id)
            .then(() => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch(() => {});
    };
    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <div className="d-flex justify-content-end">
                        <AddButton isRender={true} onClick={onAdd} />
                    </div>
                    <PaginationTable
                        onSearch={handleFetchData}
                        onPaginate={handleFetchData}
                        onRowClick={(item) => {}}
                        columns={cols}
                        pagination={paginationData}
                        isStriped={true}
                        disableSearch={true}
                        wrapperClass="mt-5"
                    />
                </Card.Body>
            </Card>

            {PromptInputText}
        </Fragment>
    );
};

export default LegalDocumentGroupPage;
