import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import Card from 'components/shared/Card';
import { useLocation, useNavigate } from 'react-router-dom';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { AreaSelect, CompanySelect, ContractorSelect } from 'components/Common';
import { SelectField, SelectOption } from 'components/FormControl';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FilterButton } from 'components/Button/FilterButton';
import { EquipmentHelper } from 'helper/Feature/equipment.help';
import DateTimeField from 'components/FormControl/DateTimeField';
import { Badge, Modal } from 'react-bootstrap';
import { ModalFullScreen } from 'components/Modal/ModalFullScreen';
import useQueryParams from 'hooks/useQueryParams';
import { formatDateTime } from 'helper';
import { PaginationTable } from 'components/shared/PaginationTable';
import { SIReportFilterRequest } from 'api/v2/safe-inspection/model/siReport';
import siReportApi from 'api/v2/safe-inspection/siReportApi';
import { SIReportHelper } from 'helper/Feature/si.help';
import { SIReportType } from 'enum/safetyInspection';
import UserSelectField from 'components/Common/UserSelect';
import SIReportDetailPage from './detailPage';
import { lang } from 'i18n/lang';
import useModalHook from 'components/ModalHook';

const defaultToDate = new Date();
const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);
const SIListPage = () => {
    const cols = useMemo(
        () => [
            {
                field: 'companyName',
                title: lang.text(Translations.form.site.name),
            },
            {
                field: 'areaName',
                title: lang.text(Translations.form.area.name),
            },
            {
                field: 'pICFullName',
                title: lang.text(Translations.common.pic),
            },
            {
                field: 'status',
                title: lang.text(Translations.common.status),
            },
            {
                field: 'reportType',
                title: lang.text(Translations.common.reportType),
            },
            {
                field: 'createdTime',
                title: lang.text(Translations.common.createdTime),
            },
            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    const reportTypes = useMemo(() => EquipmentHelper.getReportTypesOptions(), []);
    const reportStatus = useMemo(() => SIReportHelper.getReportStatusOptions(), []);

    var navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [paginationData, setPaginationData] = useState<PaginationBase<any>>();
    const [companyId, setCompanyId] = useState<string | null>(null);
    const [pICId, setPicId] = useState<string | null>(null);
    const [reportType, setReportType] = useState<SIReportType>(SIReportType.Daily);
    const { control, handleSubmit, getValues, reset, formState } = useForm();
    const { getQueryParam } = useQueryParams();
    useEffect(() => {
        let id = getQueryParam('id');
        id && showDetail(id);
    }, [location.search]);

    const showDetail = async (id: string) => {
        id &&
            (await openModal({
                isFullScreen: true,
                title: lang.text(Translations.common.reportDetail),
                isResetRoute: true,
                body: (
                    <SIReportDetailPage
                        id={id}
                        onDeleted={() => {
                            handleFetchData(defaultPaginationRequest);
                            navigate('', { replace: true });
                            onClose();
                        }}
                    />
                ),
            }));
    };

    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        var form = getValues();
        // return;
        let filter = {
            ...pagination,
            ...form,
        } as SIReportFilterRequest;
        filter.toDate = filter.toDate?.toEndDay();
        siReportApi
            .getPagination(filter)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                createdTime: formatDateTime(n.createdTime),
                                status: <Badge bg={SIReportHelper.getStatusColor(n.status)}>{n.status}</Badge>,
                                reportType: <Badge bg={SIReportHelper.getReportTypeColor(n.reportType)}>{n.reportType}</Badge>,
                                action: <ActionButtons2 id={n.id} onViewDetail={() => navigate('?id=' + n.id, { replace: true })} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };

    return (
        <Fragment>
            <div>
                <Card>
                    <Card.Header>
                        <form className="w-100" onSubmit={handleSubmit(() => handleFetchData(defaultPaginationRequest))}>
                            <div className="row ">
                                <SelectField
                                    label={t(Translations.common.reportType)}
                                    control={control}
                                    name="reportType"
                                    options={reportTypes}
                                    isMutiple={false}
                                    md={3}
                                    onValueChange={(value) => {}}
                                />
                                <CompanySelect md={3} readOnly={false} disabled={false} name="companyId" control={control} onCompanyChange={setCompanyId} />
                                <AreaSelect md={3} name="areaId" control={control} required={false} companyId={companyId} readOnly={false} />
                                {reportType && (
                                    <UserSelectField
                                        readOnly={false}
                                        label={t(Translations.common.pic)}
                                        name="pICId"
                                        control={control}
                                        required={false}
                                        isMutiple={false}
                                        onValueChange={setPicId}
                                        md={3}
                                    />
                                )}
                                <SelectField
                                    label={t(Translations.common.status)}
                                    options={reportStatus}
                                    name="status"
                                    control={control}
                                    required={false}
                                    isMutiple={false}
                                    unSelectValue={''}
                                    md={3}
                                />
                                <DateTimeField label={lang.text(Translations.common.fromDate)} md={3} control={control} readOnly={false} name={'fromDate'} defaultDate={defaultFromDate} />
                                <DateTimeField label={lang.text(Translations.common.toDate)} md={3} control={control} readOnly={false} name={'toDate'} defaultDate={defaultToDate} />{' '}
                                <div className="col-md-3 col-sm-4 align-self-center">
                                    <div className="d-flex justify-content-end">
                                        <FilterButton type="submit" className="mx-2 mt-3" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Card.Header>
                    <Card.Body>
                        <PaginationTable onSearch={handleFetchData} onPaginate={handleFetchData} columns={cols} pagination={paginationData} isStriped={true} disableSearch={true} wrapperClass="mt-5" />

                        {Prompt}
                    </Card.Body>
                </Card>
            </div>
        </Fragment>
    );
};

export default SIListPage;
