import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { memo, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import SIReportDashboard from 'views/safety-inspections/reports/dashboard';
import SORDashboard from 'views/sor/report/dashboard';

const DashboardPage = memo(() => {
    return (
        <div className="row">
            <div className="col-md-12">
                <SORDashboard />
            </div>
            <div className="col-md-12">
                <SIReportDashboard />
            </div>
        </div>
    );
});

export default DashboardPage;
