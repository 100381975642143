import { defaultProfilePms, ProfilePms, TokenProfile } from 'api/v2/user/model';
import React from 'react';

interface AuthContextProps {
    tokenProfile: TokenProfile | undefined;
    loginSuccess: (token: string) => void;
    logOut: () => void;
    handleRefesh: () => void;
    userPms: ProfilePms;
}
const AuthContext = React.createContext<AuthContextProps>({
    tokenProfile: undefined,
    loginSuccess: () => {},
    handleRefesh: () => {},
    logOut: () => {},
    userPms: defaultProfilePms(false),
});
export default AuthContext;
