import Translations from 'i18n/translation';
import { useDebounce } from 'hooks';
import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Empty from './Empty';
import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import DataListView from './DataListView';
import { isMobileDevide } from 'helper';
import { lang } from 'i18n/lang';

export type ColumnType = {
    field: string;
    title: string;
    className?: string;
    style?: CSSProperties | undefined;
};

const fancyTableDefaultPerPage = [10, 20, 30, 40, 50];

interface PaginationTableProps {
    disableSearch?: boolean;
    columns: ColumnType[];
    pagination: PaginationBase<any> | undefined;
    isStriped?: boolean;
    wrapperClass?: string;
    onSearch?: (paginate: PaginationBaseRequest) => void;
    onPaginate?: (paginate: PaginationBaseRequest) => void;
    onRowClick?: (row: any) => void;
    searchPlaceholder?: string;
    loading?: boolean;
    hideHead?: boolean;
    action?: () => React.ReactNode;
}
export function PaginationTable({
    disableSearch,
    columns,
    pagination,
    isStriped = false,
    wrapperClass = '',
    onSearch,
    onPaginate,
    onRowClick,
    loading = false,
    hideHead = false,
    searchPlaceholder = lang.text(Translations.table.find),
}: // action,
PaginationTableProps) {
    const defaultRequest = defaultPaginationRequest;
    const { t } = useTranslation();
    const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRequest.rowsPerPage);
    const [currentPage, setCurrentPage] = useState<number>(defaultRequest.currentPage);
    const [keySearch, setKeySearch] = useState<string>('');
    //duration after search
    const debouncedValue = useDebounce(keySearch, 500);

    useEffect(() => {}, [debouncedValue]);

    const handlePaginate = (page: PaginationBaseRequest) => {
        if (pagination?.currentPage == page.currentPage && pagination.rowsPerPage == page.rowsPerPage) return;
        setCurrentPage(page.currentPage);
        setRowsPerPage(page.rowsPerPage);

        if (pagination && onPaginate) {
            onPaginate({
                currentPage: page.currentPage,
                rowsPerPage: page.rowsPerPage,
                keySearch: keySearch,
            });
        }
    };
    useEffect(() => {
        setRowsPerPage(pagination?.rowsPerPage ?? defaultRequest.rowsPerPage);
        setCurrentPage(pagination?.currentPage ?? defaultRequest.currentPage);
    }, [pagination]);
    const handleSearchChange = (value: string) => {
        setKeySearch(value);
        onSearch &&
            onSearch({
                currentPage: currentPage,
                rowsPerPage: rowsPerPage,
                keySearch: value,
            });
    };

    const loadMobileLayout = () => {
        return pagination?.datas?.map((item, index) => {
            return <DataListView datas={columns.map((n) => ({ label: n.title, value: item[n.field] }))} />;
        });
    };
    const loadTableLayout = () => {
        return (
            <Table className={`paginate-table mb-0 ${isStriped ? 'table-striped' : ''}`} id="datatable" data-toggle="data-table">
                <thead>
                    <tr className="bg-white">
                        <th scope="col" style={{ width: '20px' }}>
                            <span>#</span>
                        </th>
                        {columns.map((item, index) => (
                            <th scope="col" key={index} className={`${item.className || ''} px-2`} style={{ ...item.style }}>
                                {item.title}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {pagination?.datas?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    {/* <b>{state.rowsPerPage} - {state.currentPage ?? 1}</b> */}
                                    {rowsPerPage * (currentPage ?? 1) - (rowsPerPage - (index + 1))}
                                </td>
                                {columns.map((col, colIndex) => (
                                    <td
                                        key={colIndex + 1}
                                        className={`${col.className} ${onRowClick ?? ' cursor-pointer-hover'}}`}
                                        onClick={() => {
                                            col.field != 'action' && onRowClick && onRowClick(item);
                                        }}
                                    >
                                        {item[col.field?.trim()]}
                                    </td>
                                ))}
                                {/* {action && <td key={index}>{item['action']}</td>} */}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    };
    return (
        <section>
            {!hideHead && (
                <div className="flex-column gap-2 flex-md-row justify-content-between row mb-4 align-items-end">
                    {!disableSearch && (
                        <div className={`col-md-4`}>
                            {/* <Form.Label htmlFor="search">{t(Translations.table.find)}</Form.Label> */}
                            <Form.Control placeholder={searchPlaceholder} value={keySearch} onChange={(e) => handleSearchChange(e.target.value)} id="search" type="text" />
                        </div>
                    )}
                    {disableSearch && <div className={`col-md-4`}></div>}
                    {pagination && (
                        <div className="col-md-4 d-flex align-items-center gap-2 justify-content-end">
                            <span>{t(Translations.table.display)}</span>
                            <select
                                className="form-select"
                                value={rowsPerPage}
                                onChange={(e) => {
                                    if (rowsPerPage == Number(e.target.value)) return;

                                    handlePaginate({
                                        rowsPerPage: Number(e.target.value),
                                        currentPage: 1,
                                    });
                                }}
                                style={{ width: 90 }}
                                id="perpage"
                                disabled={pagination.datas?.length ? false : true}
                            >
                                {fancyTableDefaultPerPage.map((opt, index) => (
                                    <option value={opt} key={index}>
                                        {opt}
                                    </option>
                                ))}
                            </select>
                            {/* <span>{t(Translations.table.data)}</span> */}
                        </div>
                    )}
                </div>
            )}

            {/* Table body */}
            {pagination?.datas?.length ? (
                <div className="position-relative">
                    <div className={`fancy-table table-responsive rounded ${wrapperClass}`}>{isMobileDevide() ? loadMobileLayout() : loadTableLayout()}</div>
                    {loading && (
                        <div
                            className="position-absolute top-0 w-100 left-0 h-100 d-flex justify-content-center align-items-center"
                            style={{
                                backgroundColor: 'rgba(255, 255, 2555, .3)',
                            }}
                        >
                            <div className="iq-loader-box">
                                <div className="iq-loader-6">
                                    <span className="inner inner1"></span>
                                    <span className="inner inner2"></span>
                                    <span className="inner inner3"></span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <Empty />
            )}

            <div className="row align-items-center">
                <div className="col-md-6">
                    {pagination && pagination.totalItems ? (
                        <div className="dataTables_info">
                            Showing 1 to {pagination.totalItems > pagination.rowsPerPage ? pagination.rowsPerPage : pagination.totalItems} of {pagination.totalItems} entries
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <div className="col-md-6">
                    <div className="d-flex align-items-center justify-content-end pt-3">
                        {pagination && (
                            <ReactPaginate
                                initialPage={currentPage - 1}
                                breakLabel="..."
                                nextLabel="Next"
                                onPageChange={(event: any) => {
                                    if (currentPage == event.selected + 1) return;
                                    handlePaginate({
                                        currentPage: event.selected + 1,
                                        rowsPerPage: pagination.rowsPerPage,
                                    });
                                }}
                                // forcePage={currentPage - 1}
                                onPageActive={(event: any) => {
                                    console.log('Evenmt me', event);
                                }}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={2}
                                pageCount={pagination.totalPage}
                                previousLabel="Previous"
                                renderOnZeroPageCount={null}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item previous"
                                previousLinkClassName="page-link previous-link"
                                nextClassName="page-item next"
                                nextLinkClassName="page-link next-link"
                                breakClassName="page-item break"
                                breakLinkClassName="page-link break-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                disabledClassName="text-danger"
                            />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}
