import { RouteObject } from 'react-router-dom';

import Routers from 'router/const/routers';
import { SORCategoryListPage, SORCreatePage, SORDetailPage } from 'views/sor';
import SORDashboard from 'views/sor/report/dashboard';
import SORListPage from 'views/sor/report/sorListPage';

const reportRouters: RouteObject[] = [
    {
        path: Routers.sor.create,
        element: <SORCreatePage />,
    },
    {
        path: Routers.sor.root,
        element: <SORListPage />,
    },
    {
        path: Routers.sor.detail,
        element: <SORDetailPage />,
    },
    {
        path: Routers.sor.dashboard,
        element: <SORDashboard />,
    },
    {
        path: Routers.sor.category,
        element: <SORCategoryListPage />,
    },
];

export default reportRouters;
