import Routers from 'router/const/routers';
import { RouteObject } from 'react-router-dom';
import { DashboardPage } from 'views/dashboard';

const dashboardRouters: RouteObject[] = [
  {
    path: Routers.dashboard.root,
    element: <DashboardPage />,
  },
];

export default dashboardRouters;
