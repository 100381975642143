import { ImageEmpty } from 'assets/images';
import Translations from 'i18n/translation';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Empty = ({ maxHeight = 360 }: { maxHeight?: number }) => {
    const { t } = useTranslation();

    return (
        <div className="text-center mt-5" style={{ maxHeight: maxHeight }}>
            <Image src={ImageEmpty} width={500} height={maxHeight} alt="empty image" className="w-100 " style={{ maxWidth: '500px' }} />
            <h4>{t(Translations.noData)}</h4>
        </div>
    );
};

export default Empty;
