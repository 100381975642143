import { SKPIActionRequest } from 'api/v2/skpi/model/skpiRequest.model';
import { SKPIActionResponse } from 'api/v2/skpi/model/skpiResponse.model';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { Table } from 'react-bootstrap';

interface SkpiActionComponentProps {
    onDelete?: (action: SKPIActionRequest | SKPIActionResponse) => void;
    onEdit?: (action: SKPIActionRequest | SKPIActionResponse) => void;
    actions: (SKPIActionRequest | SKPIActionResponse)[];
}

const SkpiActionComponent = (props: SkpiActionComponentProps) => {
    return (
        <div className="table-responsive">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>{lang.text(Translations.common.pic)}</th>
                        <th>{lang.text(Translations.wpr.skpi.actions)}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.actions.map((item, i) => (
                        <tr key={i}>
                            <td>
                                <span style={{ minWidth: '25%' }}>{item.pICFullName} </span>
                            </td>
                            <td>
                                <span style={{ minWidth: '75%' }}>{item.name}</span>
                            </td>
                            {(props.onDelete || props.onEdit) && (
                                <td>
                                    <ActionButtons2
                                        id={item.uiId ?? ''}
                                        onDelete={
                                            props.onDelete
                                                ? () => {
                                                      props.onDelete && props.onDelete(item);
                                                      return Promise.resolve();
                                                  }
                                                : undefined
                                        }
                                        editAction={
                                            props.onEdit
                                                ? () => {
                                                      props.onEdit && props.onEdit(item);
                                                  }
                                                : undefined
                                        }
                                    />
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};
export default SkpiActionComponent;
