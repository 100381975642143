import { SelectOption } from 'components/FormControl';
import { EquipmentReportLevel, EquipmentReportStatusEnum, EquipmentReportType } from 'enum';
import Translations from 'i18n/translation';
import { t } from 'i18next';

export const EquipmentHelper = {
    getLevelColor: (level: EquipmentReportLevel) => {
        switch (level) {
            case EquipmentReportLevel.Hight:
                return 'danger';
            case EquipmentReportLevel.Medium:
                return 'warning';
            case EquipmentReportLevel.Low:
                return 'success';
            default:
                break;
        }
    },
    getStatusColor: (status: EquipmentReportStatusEnum) => {
        switch (status) {
            case EquipmentReportStatusEnum.Opening:
                return 'warning';
            case EquipmentReportStatusEnum.Closed:
                return 'success';
            case EquipmentReportStatusEnum.Safe:
                return 'info';
            default:
                break;
        }
    },
    reportTypeToString: (type: EquipmentReportType) => {
        return type == EquipmentReportType.Daily ? t(Translations.common.dailyReport) : t(Translations.common.scheduleReport);
    },
    getLevelOptions: () => {
        return [
            {
                label: t(Translations.equipment.reportRatingGreen),
                value: EquipmentReportLevel.Low,
            },
            {
                label: t(Translations.equipment.reportRatingYellow),
                value: EquipmentReportLevel.Medium,
            },
            {
                label: t(Translations.equipment.reportRatingRed),
                value: EquipmentReportLevel.Hight,
            },
        ];
    },
    getReportTypesOptions: () => {
        return [
            { label: t(Translations.common.dailyReport), value: EquipmentReportType.Daily },
            { label: t(Translations.common.scheduleReport), value: EquipmentReportType.Scheduled },
        ];
    },
    getReportStatusOptions: () => {
        return [
            { label: t(Translations.common.statusClosed), value: EquipmentReportStatusEnum.Closed },
            { label: t(Translations.common.statusOpen), value: EquipmentReportStatusEnum.Opening },
            { label: t(Translations.common.safe), value: EquipmentReportStatusEnum.Safe },
        ];
    },
    // getReportStatusText: (status: ) => {
    //     switch (status) {
    //         case EquipmentReportStatusEnum.Opening:
    //             return 'warning';
    //         case EquipmentReportStatusEnum.Closed:
    //             return 'success';
    //         case EquipmentReportStatusEnum.Safe:
    //             return 'info';
    //         default:
    //             break;
    //     }
    // },
};
