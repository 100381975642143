import equipmentApi from 'api/v2/equipmentApi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EquipmenmtModifyForm from './equipmentForm.component';
import { EquipmentCreateRequest, EquipmentUpdateRequest } from 'api/models';
import { useParams } from 'react-router-dom';

const EquipmentDetailPage = (props: { id?: string | null | undefined }) => {
    const { t } = useTranslation();
    const [equipmentDefault, setEquipmentDefault] = useState<any>(null);
    const { id } = useParams();

    useEffect(() => {
        let idSearch = props.id ?? id;
        if (idSearch) {
            equipmentApi
                .getById(idSearch)
                .then((res) => {
                    setEquipmentDefault({ ...res });
                })
                .catch((n) => {});
        }
    }, []);
    const onSaveChange = (data: EquipmentCreateRequest) => {
        var equipment: EquipmentUpdateRequest = { ...data, id: id ?? '' };
        equipmentApi
            .update(equipment)
            .then((res) => {})
            .catch((e) => {});
    };

    return (
        <div>
            {equipmentDefault && (
                <EquipmenmtModifyForm
                    readOnly={true}
                    equipment={equipmentDefault}
                    onSaveChange={onSaveChange}
                />
            )}
        </div>
    );
};

export default EquipmentDetailPage;
