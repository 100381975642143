import { UserCreateRequest, UserDetailResponse, UserFilterRequest, UserResponse, UserUpdateRequest } from 'api/v2/user/model';
import client from '../client';
import { ProfileMedicalRequest } from './model/profile.model';

const baseUserApi = '/profile';
const profileApi = {
   
    updateMedical(data: ProfileMedicalRequest): Promise<unknown> {
        const url = `${baseUserApi}/updateMedical`;
        return client.postNonWrap({
            endpoint: url,
            data: data,
            isLoading: true,
            isShowError: true,
            isShowSuccess: true,
        });
    },
   
    async resetPassword(id: string): Promise<unknown> {
        const url = `${baseUserApi}/resetPassword/${id}`;
        return client
            .postNonWrap({
                endpoint: url,
                data: true,
                isLoading: true,
                isShowError: true,
                isShowSuccess: true,
            })
            .catch(() => {});
    },
   
    getProfile: () => {
        const url = `${baseUserApi}`;
        return client.getNonWrap<UserDetailResponse>({
            endpoint: url,
            isLoading: true,
            isShowError: true,
            isShowSuccess: false,
        });
    }
};

export default profileApi;
