import React, { memo, Fragment, ReactNode } from 'react';
import Card from './Card';
import { Color } from 'react-bootstrap/esm/types';
import { Col, Row } from 'react-bootstrap';

// Components

interface AppointmentsCardProps {
    color: Color;
    svg: ReactNode;
    counter: number;
    content: string;
    md?: number;
}

const AppointmentsCard: React.FC<AppointmentsCardProps> = memo((props) => {
    return (
        <Col md={props.md ?? 4}>
            <Card>
                <Card.Body>
                    <div className="d-flex align-items-center gap-3">
                        <div className={`bg-soft-${props.color} p-2 rounded`}>{props.svg}</div>
                        <div>
                            <h4 className="counter">{props.counter}</h4>
                            <span className="text-dark">{props.content}</span>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
});

AppointmentsCard.displayName = 'AppointmentsCard';
export default AppointmentsCard;
