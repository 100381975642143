const ArrowLeft = ({ className = 'icon-24' }: { className?: string }) => {
  return (
    <i className="icon">
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path
          d="M4.25 12.2744L19.25 12.2744"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M10.2998 18.2988L4.2498 12.2748L10.2998 6.24976"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    </i>
  );
};

export default ArrowLeft;
