import { FC, Fragment, ReactNode, memo, useContext, useEffect, useMemo, useState } from 'react';

//React-bootstrap
import { Accordion } from 'react-bootstrap';

//Components
import {
    Bell,
    BriefCase,
    Calendar,
    Call,
    ChartSquareBar,
    CircleDirection,
    ExclamationTriangle,
    Eye,
    File,
    Folder,
    Search,
    Setting,
    ShieldCheck,
    Timer,
    Video,
    ViewGrid,
} from 'assets/icons/double-tone';
import { Analytics } from 'assets/icons/solid';
import Routers from 'router/const/routers';
import Translations from 'i18n/translation';
import SidebarMenu from './sidebarMenu';
import { useAuth } from 'providers/contexts';
import Roles from 'router/const/roles';
import { lang } from 'i18n/lang';
import { AccordionGroup, AccordionItemChildProps, MenuGroupProps } from './verticalNavGroup';
import { appMenus } from 'router/const/pageInfos';

const VerticalNav: FC = memo(() => {
    const [activeMenu, setActiveMenu] = useState('false');
    const [active, setActive] = useState('');
    const { tokenProfile } = useAuth();
    const userRoles = tokenProfile?.roles ?? [];
    const [userMenus, setUserMenus] = useState<MenuGroupProps[]>([]);
    useEffect(() => {
        var menus: MenuGroupProps[] = [];
        appMenus.forEach((n) => {
            let newItems: AccordionItemChildProps[] = [];
            var itemsFiltered: AccordionItemChildProps[] =
                n.items
                    .filter((n) => n.isDisplayVerticalNav == true)
                    .filter((item) => {
                        if (Array.isArray(item.roles)) {
                            return tokenProfile?.isSuperAdmin == true || item.ignorePms == true || item.roles.some((role) => userRoles.includes(role));
                        } else {
                            return tokenProfile?.isSuperAdmin == true || item.ignorePms == true || userRoles.includes(item.roles);
                        }
                    }) ?? [];
            newItems = itemsFiltered.map((n) => ({ ...n }));
            let group = {
                ...n,
                items: newItems,
            };
            menus.push(group);
        });
        menus = menus.filter((n) => n.items.length > 0);
        setUserMenus(menus);
    }, []);

    return (
        userRoles && (
            <Fragment>
                <Accordion as="ul" className="navbar-nav iq-main-menu">
                    {/* Dashboard */}
                    <SidebarMenu
                        isTag="true"
                        roles={Roles.account_management.crud}
                        pathname={Routers.dashboard.root}
                        minititle={lang.text(Translations.sidebar.dashboard)}
                        title={lang.text(Translations.sidebar.dashboard)}
                    >
                        <ViewGrid />
                    </SidebarMenu>
                    {userMenus &&
                        userMenus.map((group, index) => <AccordionGroup active={active} activeMenu={activeMenu} group={group} key={index} setActive={setActive} setActiveMenu={setActiveMenu} />)}
                </Accordion>
            </Fragment>
        )
    );
});

VerticalNav.displayName = 'VerticalNav';
export default VerticalNav;
