import client from '../client';
import { PaginationBase, PaginationBaseRequest } from 'api/models/common';
import { TNTopicCreateRequest, TNTopicResponse, TNTopicUpdateRequest } from './models/tnTopic.model';
import { SelectOption } from 'components/FormControl';

const baseURL = '/Topic';
const tnTopicAPI = {
    getById(id: string): Promise<TNTopicResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<TNTopicResponse>({
            endpoint: url,
        });
    },
    getPagination(filter: PaginationBaseRequest, isLoading: boolean = true): Promise<PaginationBase<TNTopicResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<TNTopicResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: TNTopicCreateRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: TNTopicUpdateRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
    getDropdown(): Promise<SelectOption[]> {
        const url = `${baseURL}/dropdown`;
        return client.getNonWrap<SelectOption[]>({
            endpoint: url,
        });
    },
};

export default tnTopicAPI;
