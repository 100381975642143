import { TokenProfile } from '../api/v2/user/model/user.model';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import StorageKeys from 'constants/storageKeys';
import { CustomerInfraResponse } from 'api/models/organization';
import { LocationModel } from 'api/models/common/locationModel';

export interface AppState {
    loadingPage?: boolean;
    isLoggin?: boolean;
    customer?: CustomerInfraResponse | undefined;
    isShowCustomerModal?: boolean;
    language: string | null;
    tokenProfile: TokenProfile | null;
    title: string;
    myLocation?: LocationModel;
}

const initialState: AppState = {
    loadingPage: false,
    title: '',
    tokenProfile: null,
    isLoggin: false,
    customer: undefined,
    isShowCustomerModal: false,
    language: localStorage.getItem(StorageKeys.language) ? localStorage.getItem(StorageKeys.language) : 'vi',
    myLocation: undefined,
};

const appSlice = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        loadingPageAction(state, action) {
            state.loadingPage = action.payload;
        },
        setIsLoggin(state, action) {
            state.isLoggin = action.payload;
        },
        setTokenProfile(state, action) {
            state.tokenProfile = action.payload;
        },

        setLanguage(state, action) {
            state.language = action.payload;
        },

        setTitlePage(state, action) {
            state.title = action.payload;
        },

        setShowCustomerModal(state, action) {
            state.isShowCustomerModal = action.payload;
        },

        setCustomer(state, action: PayloadAction<CustomerInfraResponse | undefined>) {
            state.customer = action.payload;
        },

        setMyLocation(state, action: PayloadAction<LocationModel>) {
            state.myLocation = action.payload;
        },
    },
});

export const { loadingPageAction, 
    setIsLoggin, 
    setTokenProfile, 
    setCustomer, 
    setShowCustomerModal, 
    setLanguage, 
    setTitlePage, 
    setMyLocation 
} = appSlice.actions;

// Selectors
export const selectLoadingPage = (state: RootState) => state.app.loadingPage;
export const selectIsLoggin = (state: RootState) => state.app.isLoggin;
export const selectCustomer = (state: RootState) => state.app.customer;
export const selectShowCustomerModal = (state: RootState) => state.app.isShowCustomerModal;
export const selectLanguage = (state: RootState) => state.app.language;
export const selectTokenProfile = (state: RootState) => state.app.tokenProfile;
export const selectTitlePage = (state: RootState) => state.app.title;
export const selectMyLocation = (state: RootState) => state.app.myLocation;

export default appSlice.reducer;
