//scrollbar
import { isMobileDevide } from 'helper';
import { useEffect } from 'react';
import Scrollbar from 'smooth-scrollbar';

//toggle full screen
const fullscreen = () => {
    if (
        !document.fullscreenElement &&
        //@ts-ignore
        !document.mozFullScreenElement &&
        //@ts-ignore
        !document.webkitFullscreenElement &&
        //@ts-ignore
        !document.msFullscreenElement
    ) {
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
            //@ts-ignore
        } else if (document.documentElement.mozRequestFullScreen) {
            //@ts-ignore
            document.documentElement.mozRequestFullScreen();
            //@ts-ignore
        } else if (document.documentElement.webkitRequestFullscreen) {
            //@ts-ignore
            document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            //@ts-ignore
        } else if (document.documentElement.msRequestFullscreen) {
            //@ts-ignore
            document.documentElement.msRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
    } else {
        //@ts-ignore
        if (document.cancelFullScreen) {
            //@ts-ignore
            document.cancelFullScreen();
            //@ts-ignore
        } else if (document.mozCancelFullScreen) {
            //@ts-ignore
            document.mozCancelFullScreen();
            //@ts-ignore
        } else if (document.webkitCancelFullScreen) {
            //@ts-ignore
            document.webkitCancelFullScreen();
            //@ts-ignore
        } else if (document.msExitFullscreen) {
            //@ts-ignore
            document.msExitFullscreen();
        }
    }
};

// toggle mini sidebar
const minisidebar = () => {
    document.getElementsByTagName('ASIDE')[0].classList.toggle('sidebar-mini');
};

// hiden mini sizebar when init mobile or select menu on mobile
export const hidenMiniSideBar = () => {
    document.getElementsByTagName('ASIDE')[0].classList.add('sidebar-mini');
};

// resize scroll bar
const resizeScrollbar = () => {
    Scrollbar.init(document.querySelector('#my-scrollbar')!);

    window.addEventListener('resize', () => {
        const tabs = document.querySelectorAll('.nav');
        const sidebarResponsive = document.querySelector('[data-sidebar="responsive"]');
        if (window.innerWidth < 1025) {
            Array.from(tabs, (elem) => {
                if (!elem.classList.contains('flex-column') && elem.classList.contains('nav-tabs') && elem.classList.contains('nav-pills')) {
                    elem.classList.add('flex-column', 'on-resize');
                }
                return elem.classList.add('flex-column', 'on-resize');
            });
            if (sidebarResponsive !== null) {
                if (!sidebarResponsive.classList.contains('sidebar-mini')) {
                    sidebarResponsive.classList.add('sidebar-mini', 'on-resize');
                }
            }
        } else {
            Array.from(tabs, (elem) => {
                if (elem.classList.contains('on-resize')) {
                    elem.classList.remove('flex-column', 'on-resize');
                }
                return elem.classList.remove('flex-column', 'on-resize');
            });
            if (sidebarResponsive !== null) {
                if (sidebarResponsive.classList.contains('sidebar-mini') && sidebarResponsive.classList.contains('on-resize')) {
                    sidebarResponsive.classList.remove('sidebar-mini', 'on-resize');
                }
            }
        }
    });
};

export { fullscreen, minisidebar, resizeScrollbar };
