import { useState } from 'react';

import { InputField, RadioField, TextAreaField, UploadImageField } from 'components/FormControl';
import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { lang } from 'i18n/lang';
import { AreaSelect, CompanySelect, ContractorSelect } from 'components/Common';
import { SaveButton } from 'components/Button';
import DateTimeField from 'components/FormControl/DateTimeField';
import { SECRegisterCreateRequest } from 'api/v2/sec/model/register.model';
import { SECRegisterType } from 'enum/sec';
import secRegisterApi from 'api/v2/sec/secRegisterApi';
import { EntityConst } from 'constants/EntityConst';
import useQueryParams from 'hooks/useQueryParams';

const defaultDate = new Date();
defaultDate.setHours(8);
defaultDate.setMinutes(0);

const defaultToDate = new Date();
defaultToDate.setHours(17);
defaultToDate.setMinutes(0);
const SECRegisterCreatePage = () => {
    const queryPram = useQueryParams();
    const registerType = queryPram.getQueryParam('type');
    const [companyId, setCompanyId] = useState<string>('');
    const schema = yup.object().shape({
        objectCode: yup.string().required().max(EntityConst.shortString),
        name: yup.string().required().max(EntityConst.shortString),
        organizationId: yup.string().required(),
        registerType: yup.mixed<SECRegisterType>().oneOf(Object.values(SECRegisterType)).required(),
        contractorId: yup.string().required(),
        content: yup.string().required().max(EntityConst.descriptionShort),
        from: yup.date().required(),
        to: yup.date().required(),
    });
    const {
        control,
        watch,
        handleSubmit,
        getValues,
        reset,
        setValue,
        formState: { errors },
    } = useForm<SECRegisterCreateRequest>({
        resolver: yupResolver(schema),
    });

    const SaveChange = () => {
        var form = getValues();
        var payload: SECRegisterCreateRequest = {
            ...form,
            registerType: registerType as SECRegisterType,
        };
        secRegisterApi
            .create(payload)
            .then((res) => {
                setValue('name', '');
                setValue('objectCode', '');
            })
            .catch(() => {});
    };
    const setOrganizationId = (id: string) => {
        setValue('organizationId', id ?? companyId);
    };
    return (
        <form onSubmit={handleSubmit(SaveChange)}>
            <Card>
                <Card.Header className="d-flex justify-content-between">
                    <h4 className="card-title  ">{lang.text(Translations.sec.register)}</h4>
                    <SaveButton />
                </Card.Header>
                <Card.Body>
                    <div className="row">
                        <CompanySelect
                            md={4}
                            name="companyId"
                            control={control}
                            required={true}
                            disable={false}
                            readOnly={false}
                            onCompanyChange={(id) => {
                                setCompanyId(id);
                                setOrganizationId(id);
                            }}
                        />
                        <AreaSelect
                            md={4}
                            name="areaId"
                            control={control}
                            required={false}
                            companyId={companyId}
                            readOnly={false}
                            onAreaChange={(id) => {
                                setOrganizationId(id ?? '');
                            }}
                        />
                        <ContractorSelect md={4} name="contractorId" control={control} required={true} readOnly={false} />
                        <InputField
                            md={3}
                            control={control}
                            name="objectCode"
                            label={lang.text(registerType == SECRegisterType.EQUIPMENT ? Translations.equipment.code : Translations.sec.dkshNumber)}
                            required={true}
                        />
                        <InputField
                            md={3}
                            control={control}
                            name="name"
                            label={registerType == SECRegisterType.EQUIPMENT ? lang.text(Translations.common.name) : lang.text(Translations.form.fullName.name)}
                            required={true}
                        />
                        {/* /**organizationId and registerType is hidden */}
                        <InputField md={3} control={control} name="organizationId" className="d-none" />
                        <InputField md={3} control={control} name="registerType" className="d-none" defaultValue={registerType as SECRegisterType} />
                        <DateTimeField
                            label={lang.text(Translations.common.fromDate)}
                            md={3}
                            isShowTimePicker={true}
                            control={control}
                            readOnly={false}
                            name={'from'}
                            defaultDate={defaultDate}
                            onValueChange={(date) => {}}
                        />
                        <DateTimeField
                            label={lang.text(Translations.common.toDate)}
                            md={3}
                            isShowTimePicker={true}
                            control={control}
                            readOnly={false}
                            name={'to'}
                            defaultDate={defaultToDate}
                            onValueChange={(date) => {}}
                        />
                        <TextAreaField name={'content'} control={control} defaultValue={''} label={lang.text(Translations.sec.content)} readOnly={false} />
                        {/* <UserSelectField readOnly={false} label={t(Translations.common.pic)} name="pICId" control={control} required={true} isMutiple={false} md={6} /> */}
                    </div>

                    <div className="row">
                        <UploadImageField name={'imagePath'} control={control} readOnly={false} />
                    </div>
                </Card.Body>
            </Card>
        </form>
    );
};

export default SECRegisterCreatePage;
