export enum EntityConst {
    /**
     * Length is 12
     */
    dkshNumber = 12,

    /**
     * Length is 10
     */
    short = 10,
    enumLenth = 20,
    /**
     * Length is 200
     */
    filePath = 200,
    /**
     * Length is 100
     */
    shortString = 100,
    /**
     * Length is 200
     */
    longString = 200,

    /**
     * Length is 500
     */
    descriptionShort = 500,

    /**
     * Length is 1000
     */
    descriptionLong = 1000,
    descriptionVeryLong = 5000,

    /**
     * Length is 12
     */
    phoneNumber = 12,

    /**
     * Length is 100
     */
    fullname = 100,

    /**
     * Length is 100
     */
    email = 100,
}
