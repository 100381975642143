import { SelectField, SelectOption } from 'components/FormControl';
import Translations from 'i18n/translation';
import { t } from 'i18next';
import { useMemo, useState } from 'react';
import { Control } from 'react-hook-form';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import { SIReportHelper } from 'helper/Feature/si.help';

export interface SIReportTypeSelectProps extends BaseFormControlProps {
    onValueChange?: (id: string) => void;
    isAddAll?: boolean;
    control: Control<any>;
}
export function SIReportTypeSelect({
    name = 'reportType',
    control,
    required,
    md = 4,
    onValueChange,
    isAddAll,
    readOnly,
}: SIReportTypeSelectProps) {
    const options = useMemo(() => SIReportHelper.getReportTypesOptions(), []);

    return (
        <SelectField
            readOnly={readOnly}
            label={t(Translations.common.reportType)}
            options={options}
            name={name ?? 'reportType'}
            control={control}
            required={required}
            isMutiple={false}
            md={md}
            isAddAll={isAddAll}
            onValueChange={(e) => {
                onValueChange && onValueChange(e);
            }}
        />
    );
}
export default SIReportTypeSelect;
